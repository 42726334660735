import React, { useEffect, useState } from 'react'
import CirculoCromatico from '../CirculoCromatico/CirculoCromatico'
import helpers from '../../helpers'
import './ColorsSelector.scss'
import useCustomTranslation from '../../hook/useCustomTranslation'

const ColorsSelector = ({onCloseSelector, colorList, onClickColor, colors, selectedColorIndex}) => { 

  const [primaryColor, setPrimaryColor] = useState(5)
  const [isPickingCombination, setIsPickingCombination] = useState(false)
  const [colorCombinations, setColorCombinations] = useState('MonoCromaticos')

  useEffect(() => {
    if (!colors[selectedColorIndex].Id || colors[selectedColorIndex].EsPrincipal) {
      setColorCombinations('MonoCromaticos')
    }


    let targetColor = colors[selectedColorIndex]

    let colorInList = colorList.find(clr => clr.Id === targetColor.Id)

    if (targetColor.Id && colorInList.Id && targetColor.GroupNum !== primaryColor) {
      setPrimaryColor(colorInList.GroupNum)
    }

  }, [colors, selectedColorIndex])


  const selectPrimaryColor = (color) => {
    setColorCombinations('MonoCromaticos')
    setPrimaryColor(color)
  }

  const getSelectedPrimaryColor = () => {
    return colorList.find(color => color.Id === primaryColor)
  }

  const changeColorCombination = (e) => {
    setColorCombinations(e.target.value)
    getSelectedColorTrios()
  }

  const getPrimaryColorMonocromaticos = () => {
    let _primaryColor = getSelectedPrimaryColor()
    let groups = _primaryColor.MonoCromaticos.map(e => [])
    // this.selectedPrimaryColor.MonoCromaticos
    colorList.forEach(color => {
      
      if (_primaryColor.MonoCromaticos.includes(color.SubGroupNum)) {
        let targetId = _primaryColor.MonoCromaticos.findIndex(id => id === color.SubGroupNum)
        groups[targetId].push(color)
      }
    })
    return groups
  }

  const getSelectedColorTrios = () => {
    let targetColor = colors[selectedColorIndex];
    let colorInList = colorList.find(clr => clr.Id === targetColor.Id)

    if (targetColor.Id && colorInList.Id && targetColor.GroupNum !== primaryColor) {
      targetColor = colorInList
    }
    if (targetColor.Id){
      let groups = targetColor[colorCombinations].map(e => [])
      // this.selectedPrimaryColor.MonoCromaticos
      colorList.forEach(color => {
        
        if (targetColor[colorCombinations].includes(color.SubGroupNum)) {
          let targetId = targetColor[colorCombinations].findIndex(id => id === color.SubGroupNum)
          groups[targetId].push(color)
        }
      })
      return groups
    }
    return null
    
  }

  const { translate } = useCustomTranslation();
  
  return (
    <div className="colors-selector-wrapper">
      <h2 className="color-selector-primary-title" style={{color: '#' + getSelectedPrimaryColor().CodigoHtml}}>
        {getSelectedPrimaryColor().Nombre.toUpperCase()}
        <span className="color-selector-primary-title-shadow">
          {getSelectedPrimaryColor().Nombre.toUpperCase()}
        </span>
      </h2>
      <div className="colors-selector">
        <div className="circulo-cromatico-wrapper">
          <CirculoCromatico selectedColor={primaryColor} onClickColor={selectPrimaryColor} />
        </div>
        <div className="colors-list">
          <div className="colors-list-title">
          {translate("colorTrendsHead.colorWheelTitle")}
            <select value={colorCombinations} onChange={e => changeColorCombination(e)} disabled={!colors[selectedColorIndex].Id || colors[selectedColorIndex].EsPrincipal}>
                <option value="MonoCromaticos"> {translate("colorTrendsHead.mono")}</option>
                <option value="Triadicos">{translate("colorTrendsHead.tria")}</option>
                <option value="Adjacentes">{translate("colorTrendsHead.adj")}</option>
                <option value="Complementarios">{translate("colorTrendsHead.comp")}</option>
            </select>
            </div>
          
            {
              colorCombinations === 'MonoCromaticos' || !colors[selectedColorIndex].Id ?
              <div className="color-container-monocromaticos">
              {
                getPrimaryColorMonocromaticos().map((colorRow, idxRow) => (
                  <div className="color-row" key={idxRow}>
                  {
                    colorRow.map((color, idxColor) => (
                      <span className={`color-single ${colors.find(el => el.Id === color.Id) ? 'color-single-selected': ''}`} key={`${idxRow}-${idxColor}`} onClick={() => onClickColor(color)} style={{background: `rgb(${color.colorR},${color.colorG},${color.colorB})`}}>
                        <span className="color-single-description" style={{color: helpers.getContrastingColorText(color.colorR, color.colorG, color.colorB)}}>
                          <div>{color.ColorNum}</div>
                        </span>
                      </span>
                    ))
                  }   
                  </div>
                ))
              }
              </div>
              :
              <div className="color-container-trio">
              {
                getSelectedColorTrios().length > 0 ?
                getSelectedColorTrios().map((colorRow, idxRow) => (
                  <div className="color-col" key={idxRow}>
                  {
                    colorRow.map((color, idxColor) => (
                      <span className={`color-single ${colors.find(el => el.Id === color.Id) ? 'color-single-selected': ''}`} key={`${idxRow}-${idxColor}`} onClick={() => onClickColor(color)} style={{background: `rgb(${color.colorR},${color.colorG},${color.colorB})`}}>
                        <span className="color-single-description" style={{color: helpers.getContrastingColorText(color.colorR, color.colorG, color.colorB)}}>
                          <div>{color.ColorNum}</div>
                        </span>
                      </span>
                    ))
                  }   
                  </div>
                ))
                : <p style={{paddingLeft: '25px'}}>Este color no posee combinaciones, te recomendamos que pruebes con otro color.</p>
              }
              </div>
            }
          
        </div>
      </div>
      <div className="btn-apply-wrapper">
          <button className="btn-apply-colors" onClick={onCloseSelector}>
          {translate("colorTrendsHead.trendsButton")}
          </button>
        </div>
    </div>
  )
}

export default ColorsSelector
