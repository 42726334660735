import React from 'react'
import './CirculoCromatico.scss'

const CirculoCromatico = ({selectedColor, onClickColor}) => {
  return (
    <svg className="circulo-cromatico" viewBox="0 0 350 350" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={`circulo-path ${selectedColor === 10? 'selected' : ''}`} data-color="violet" onClick={() => onClickColor(10)} d="M67.709 140.64L6.33228 120.85C6.33228 120.85 -7.79412 162.539 6.00754 213.312L67.0596 194.009C67.0596 194.009 59.1033 166.919 67.709 140.64Z" fill="#886CAD"/>
      <path className={`circulo-path ${selectedColor === 8? 'selected' : ''}`} data-color="cyan" onClick={() => onClickColor(8)} d="M73.23 305.288C73.23 305.288 108.465 331.567 161.073 334L161.561 270.087C161.561 270.087 133.308 269.439 110.9 253.217L73.23 305.288Z" fill="#5BAAB4"/>
      <path className={`circulo-path ${selectedColor === 7? 'selected' : ''}`} data-color="green" onClick={() => onClickColor(7)} d="M172.927 269.601L173.251 334C173.251 334 217.254 334.324 261.257 305.45L223.912 253.542C223.749 253.379 200.53 269.439 172.927 269.601Z" fill="#86C06A"/>
      <path className={`circulo-path ${selectedColor === 5? 'selected' : ''}`} data-color="yellow" onClick={() => onClickColor(5)} d="M266.291 193.36L327.505 213.15C327.505 213.15 341.631 171.461 327.83 120.688L266.778 139.991C266.94 140.153 274.896 167.081 266.291 193.36Z" fill="#FCE05D"/>
      <path className={`circulo-path ${selectedColor === 6? 'selected' : ''}`} data-color="light-green" onClick={() => onClickColor(6)} d="M263.206 203.904C263.206 203.904 253.788 230.507 231.381 246.729L269.376 298.799C269.376 298.799 305.26 273.494 323.933 224.181L263.206 203.904Z" fill="#CFDB6B"/>
      <path className={`circulo-path ${selectedColor === 11? 'selected' : ''}`} data-color="light-brown" onClick={() => onClickColor(11)} d="M161.886 107.062C130.061 109.657 105.055 136.26 105.055 168.703C105.055 201.146 130.061 227.749 161.886 230.345V107.062Z" fill="#BF9372"/>
      <path className={`circulo-path ${selectedColor === 12? 'selected' : ''}`} data-color="gray" onClick={() => onClickColor(12)} d="M172.115 107.062V230.183C203.94 227.587 228.945 200.984 228.945 168.541C228.945 136.098 203.777 109.657 172.115 107.062Z" fill="#ACADAD"/>
      <path className={`circulo-path ${selectedColor === 1? 'selected' : ''}`} data-color="purple" onClick={() => onClickColor(1)} d="M65.1115 34.8762C62.1888 36.9849 28.5778 62.2904 10.7168 109.333L71.4441 129.447C71.4441 129.447 80.8617 103.006 102.944 86.7848L65.1115 34.8762Z" fill="#A8527B"/>
      <path className={`circulo-path ${selectedColor === 2? 'selected' : ''}`} data-color="red" onClick={() => onClickColor(2)} d="M162.373 64.5614L162.048 0.162213C162.048 0.162213 118.045 -0.162214 74.042 28.712L111.388 80.6207C111.388 80.7829 134.607 64.5614 162.373 64.5614Z" fill="#DB3D4A"/>
      <path className={`circulo-path ${selectedColor === 3? 'selected' : ''}`} data-color="orange" onClick={() => onClickColor(3)} d="M260.77 28.712C260.77 28.712 225.535 2.43322 172.927 0L172.439 63.9126C172.439 63.9126 200.692 64.5614 223.1 80.7829L260.77 28.712Z" fill="#EB714C"/>
      <path className={`circulo-path ${selectedColor === 4? 'selected' : ''}`} data-color="light-orange" onClick={() => onClickColor(4)} d="M269.051 35.3628L268.889 35.525L231.706 87.2715C231.706 87.2715 254.275 104.142 263.206 130.258L324.095 109.495C324.258 109.657 310.619 67.8057 269.051 35.3628Z" fill="#F9B763"/>
      <path className={`circulo-path ${selectedColor === 9? 'selected' : ''}`} data-color="blue" onClick={() => onClickColor(9)} d="M102.294 246.729C102.294 246.729 79.7247 229.858 70.7942 203.742L9.74219 224.505C9.74219 224.505 23.3815 266.356 64.7864 298.799L102.294 246.729Z" fill="#478BC9"/>
    </svg>
  )
}

export default CirculoCromatico
