export const history = {
  data: {
    prepaint: [],
    paint: [],
    colors: []
  },
  push(prepaintData, paintData, colorsData) {
    this.data.prepaint.push(prepaintData)
    this.data.paint.push(paintData)
    this.data.colors.push(colorsData)
  },
  canRestore(){
    return this.data.prepaint.length > 0 && this.data.paint.length > 0 & this.data.colors.length > 0
  },
  restore(){
    let prepaintData = this.data.prepaint.pop()
      let paintData = this.data.paint.pop()
      let colorsData = this.data.colors.pop()
  
  
      return {
        prepaint: prepaintData,
        paint: paintData,
        colors: colorsData
      }
  }
}