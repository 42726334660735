import { useEffect } from 'react';
import i18n from '../i18n/config';

const DomainChecker = () => {
  useEffect(() => {
    const extractCountryCode = () => {
      const hostname = window.location.hostname;
      const tld = hostname.split('.').pop();
      if (tld === 'br' || tld === 'ar') {
        if (tld === 'br') {
          i18n.changeLanguage('pr');
        } else {
          i18n.changeLanguage('es');
        }
      } else {
        i18n.changeLanguage('es');
      }
    };

    extractCountryCode();
  }, []);
};

export default DomainChecker;
