import { useEffect, useRef, useState } from 'react';
import ReactNotification, {store} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import './App.scss';
import ColorCard from './components/ColorCard/ColorCard';
import Header from './components/Header/Header';
import SelectorWindow from './components/SelectorWindow/SelectorWindow';
import Tool from './components/tool/Tool';
import helpers from './helpers';
import baseImage from './images/baseimage.png'
import {colorListTemp} from './ColorList'
import { history } from './history';
import VideoWindow from './components/VideoWindow/VideoWindow';
import SpinnerProcess from './components/SpinnerProcess/SpinnerProcess';
import ShareModal from './components/ShareModal/ShareModal';
import useCustomTranslation from './hook/useCustomTranslation';
import i18n from './i18n/config';
import { colorListTempBr } from './ColorListBr';
import { getVideos } from './api/calls/decorador';
import DomainChecker from './hook/useDomianChecker';

function App() {
  const { translate } = useCustomTranslation();
  const actualLenguage = i18n.language;

  DomainChecker()

  const image = new Image()
  const tools = {
    PAINT: 'PAINT',
    ERASER: 'ERASER',
    POLYGON: 'POLYGON',
    FREEPAINT: 'FREEPAINT',
    PAN: 'PAN'
  }


  // let imageData;
  // let prepaintData;
  // let paintData;
  // let drawData;

  // API
  // const apiUrl = 'https://sinteplast-api-desa.dev.qavant.com'
  const apiUrl = 'https://sinteplast-api-desa.dev.qavant.com'
  // const apiUrl = 'https://api-sinteplast.qavant.com'

  // REFS
  const workspaceRef = useRef()
  const canvasWrapperRef = useRef(null)
  const canvasResultRef = useRef(null)
  const ctxResultRef = useRef(null)
  const canvasBaseImageRef = useRef(null)
  const ctxBaseImageRef = useRef(null)
  const canvasPrepaintRef = useRef(null)
  const ctxPrepaintRef = useRef(null)
  const canvasPaintRef = useRef(null)
  const ctxPaintRef = useRef(null)
  const canvasDrawRef = useRef(null)
  const ctxDrawRef = useRef(null)

  // STATE
 
  const [tendencias, setTendencias] = useState(null)
  const [colorList, setColorList] = useState(colorListTemp)
  const [videoURL, setVideoURL] = useState('')
  const [videoURLPT, setVideoURLPT] = useState('')
  const [selectorWindowVisibility, setSelectorWindowVisibility] = useState(false)
  const [imageData, setImageData] = useState(null)
  const [prepaintData, setPrepaintData] = useState(null)
  const [paintData, setPaintData] = useState(null)
  const [drawData, setDrawData] = useState(null)
  const [hasImage, setHasImage] = useState(false)
  const [colors, setColors] = useState([{"Id":8375,"CodigoHtml":"e49ba3","Nombre":"Blooming in Pink","ColorNum":"3690","EsPrincipal":false,"GroupNum":2,"SubGroupNum":198,"colorR":228,"colorG":155,"colorB":163,"MonoCromaticos":[4,5,6,7,8,9,10,11,12,198],"Complementarios":[],"Triadicos":[],"Adjacentes":[]},{},{}])
  const [prevColors, setPrevColors] = useState(null)
  const [selectedColorIndex, setSelectedColorIndex] = useState(0)
  const [imageURL, setImageURL] = useState(null)
  const [tool, setTool] = useState(tools.PAINT)

  //CANVAS
  const [canvasWrapperWidth, setCanvasWrapperWidth] = useState(0)
  const [canvasWrapperHeight, setCanvasWrapperHeight] = useState(0)
  const [canvasWidth, setCanvasWidth] = useState(0)
  const [canvasHeight, setCanvasHeight] = useState(0)

  // CURSOR 
  const [isCursorActive, setIsCursorActive] = useState(false)
  const [cursorPosition, setCursorPosition] = useState({x: 0, y: 0})

  // PREPAINT
  const [prepaintImpactPoints, setPrepaintImpactPoints] = useState([])
  const [prepaintStrength, setPrepaintStrength] = useState(5)

  // FREEPAINT
  const [freepaintStrokeWidth, setFreepaintStrokeWidth] = useState(13)
  
  const [freepaintPointShape, setfreepaintPointShape] = useState('circle')
  const [freepaintPreviousPosition, setFreepaintPreviousPosition] = useState(null)
  const [isHoldingFreepaint, setIsHoldingFreePaint] = useState(false)

  // ERASER
  const [eraserStrokeWidth, setEraserStrokeWidth] = useState(10)
  const [eraserPointShape, setEraserPointShape] = useState('circle')
  const [eraserPreviousPosition, setEraserPreviousPosition] = useState(null)
  const [isHoldingEraser, setIsHoldingEraser] = useState(false)

  // POLYGON
  const [isPolygonOpen, setIsPolygonOpen] = useState(true)
  const [polygonNodes, setPolygonNodes] = useState([])
  const [polygonSelectedNode, setPolygonSelectedNode] = useState(-1)

  // UI 
  const [isHelpOpen, setIsHelpOpen] = useState(true)
  const [isShareOpen, setIsShareOpen] = useState(false)
  const [isShareLoading, setIsShareLoading] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  // ZOOM & PAN
  const [zoomScale, setZoomScale] = useState(1)
  const [zoomOffset, setZoomOffset] = useState({x: 0, y: 0})
  const [zoomTraslation, setZoomTraslation] = useState({x: 0, y: 0})
  const [isPanning, setIsPanning] = useState(false)
  const [previousPanningPosition, setPreviousPanningPosition] = useState({x:0, y:0})

  const getTendencias = async () => {
    fetch(process.env.REACT_APP_API_URL + '/decorador/web/tendencias/all')
      .then(res => res.json())
      .then(res => setTendencias(res))
  }

  const getVideoURL = async () => {

    try {
      const response = await getVideos()
        setVideoURL(response.data.VideoWebURL)
        setVideoURLPT(response.data.VideoBRWebURL)
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if(actualLenguage === 'es'){
      setColorList(colorListTemp)
    }else{
      setColorList(colorListTempBr)
    }
   
  }, [actualLenguage])
  

  useEffect(() => {
    getTendencias()
    getVideoURL()
    const canvasBaseImage = canvasBaseImageRef.current;
    const contextBaseImage = canvasBaseImage.getContext('2d');


    ctxBaseImageRef.current = contextBaseImage;
    ctxResultRef.current = canvasResultRef.current.getContext('2d');
    ctxPrepaintRef.current = canvasPrepaintRef.current.getContext('2d');
    ctxPaintRef.current = canvasPaintRef.current.getContext('2d');
    ctxDrawRef.current = canvasDrawRef.current.getContext('2d')

    image.src = baseImage
    image.onload = loadImage
    
  }, [])

  useEffect(() => {
    if (prepaintImpactPoints.length > 0) {
      let _strokeWidth = 0
      if (tool === tools.FREEPAINT || tool === tools.ERASER) {
       _strokeWidth = tool === tools.FREEPAINT ? freepaintStrokeWidth : eraserStrokeWidth
      }

        let arrX = prepaintImpactPoints.map(point => point.x)
        let arrY = prepaintImpactPoints.map(point => point.y)
        let minX = Math.max(Math.round(Math.min(...arrX)) - Math.ceil(_strokeWidth/2), 0)
        let maxX = Math.min(Math.round(Math.max(...arrX)) + Math.ceil(_strokeWidth/2), canvasBaseImageRef.current.width)
        let minY = Math.max(Math.round(Math.min(...arrY)) - Math.ceil(_strokeWidth/2), 0)
        let maxY = Math.min(Math.round(Math.max(...arrY)) + Math.ceil(_strokeWidth/2), canvasBaseImageRef.current.height)
        fillPrepaint(minX, maxX, minY, maxY, tool === tools.FREEPAINT)
        setPrepaintImpactPoints([])
      
    } 
  }, [paintData])


  useEffect(() => {
    if (!isPolygonOpen) {
      const ctxDraw = ctxDrawRef.current
          
      ctxDraw.clearRect(0, 0, canvasDrawRef.current.width, canvasDrawRef.current.height)
      ctxDraw.beginPath();
      ctxDraw.strokeStyle = 'rgba(122,122,122,0.5)';
      ctxDraw.setLineDash([5, 10]);
      polygonNodes.forEach((node, nodeIndex) => {
        ctxDraw.lineWidth = 2;
        if (nodeIndex === 0) {
          ctxDraw.moveTo(node.x, node.y);
        } else {
          ctxDraw.lineTo(node.x, node.y);
          ctxDraw.stroke();
        }
      })
        ctxDraw.lineTo(polygonNodes[0].x, polygonNodes[0].y)
        ctxDraw.stroke();
        ctxDraw.fillStyle = 'rgba(255,255,255,0.4)'
        ctxDraw.fill()
        ctxDraw.closePath()
      polygonNodes.forEach((node, nodeIndex) => {
        ctxDraw.fillStyle = 'rgba(0,0,0,0.7)'
        ctxDraw.beginPath()
        ctxDraw.arc(node.x, node.y, 5, 0, 2 * Math.PI);
        ctxDraw.fill();
        ctxDraw.closePath()
      })
    }
  }, [isPolygonOpen])


  const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {

    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    if(srcWidth > maxWidth || srcHeight > maxHeight) {
      return { imgWidth: srcWidth*ratio, imgHeight: srcHeight*ratio };
    }

    return { imgWidth: srcWidth, imgHeight: srcHeight };
 }

  const loadImage = () => {
    
    const canvasWrapper = canvasWrapperRef.current;
    const canvasResult = canvasResultRef.current;
    const ctxResult = ctxResultRef.current;
    const canvasBaseImage = canvasBaseImageRef.current;
    const ctxBaseImage = ctxBaseImageRef.current;
    const canvasPrepaint = canvasPrepaintRef.current;
    const ctxPrepaint = ctxPrepaintRef.current;
    const canvasPaint = canvasPaintRef.current;
    const ctxPaint = ctxPaintRef.current;
    const canvasDraw = canvasDrawRef.current;
    const ctxDraw = ctxDrawRef.current;
    

    // let imgWidth = image.width
    // let imgHeight = image.height

    let _width = workspaceRef.current.offsetWidth
    let _height = workspaceRef.current.offsetHeight

    let {imgWidth, imgHeight } = calculateAspectRatioFit(image.width, image.height, _width, _height);

    canvasWrapper.style.width = imgWidth + 'px'
    canvasWrapper.style.height = imgHeight + 'px'

    canvasResult.width = image.width
    canvasResult.height = image.height
    canvasResult.style.width = image.width + 'px'
    canvasResult.style.height = image.height + 'px'

    canvasBaseImage.width = imgWidth
    canvasBaseImage.height = imgHeight
    canvasBaseImage.style.width = imgWidth + 'px'
    canvasBaseImage.style.height = imgHeight + 'px'
    canvasPrepaint.width = imgWidth
    canvasPrepaint.height = imgHeight
    canvasPrepaint.style.width = imgWidth + 'px'
    canvasPrepaint.style.height = imgHeight + 'px'
    canvasPaint.width = imgWidth
    canvasPaint.height = imgHeight
    canvasPaint.style.width = imgWidth + 'px'
    canvasPaint.style.height = imgHeight + 'px'
    canvasDraw.width = imgWidth
    canvasDraw.height = imgHeight
    canvasDraw.style.width = imgWidth + 'px'
    canvasDraw.style.height = imgHeight + 'px'
    // ctxBaseImage.width = imgWidth + 'px'
    // ctxBaseImage.height = imgHeight + 'px'

    ctxResult.drawImage(image, 0, 0, image.width, image.height);
    setImageURL(image)

    ctxBaseImage.drawImage(image, 0, 0, imgWidth, imgHeight);
    setImageData(ctxBaseImage.getImageData(0, 0, imgWidth, imgHeight));
    let _paintData = ctxBaseImage.getImageData(0, 0, imgWidth, imgHeight)
    _paintData.data.fill(0)
    setPaintData(_paintData)

    ctxPrepaint.drawImage(image, 0, 0, imgWidth, imgHeight);
    let _prepaintData = ctxPrepaint.getImageData(0, 0, imgWidth, imgHeight);
    let idt = _prepaintData.data
    for (let i = 0; i < idt.length; i += 4) {
      var brightness = 0.34 * idt[i] + 0.5 * idt[i + 1] + 0.16 * idt[i + 2];
      idt[i] = Math.min(brightness + ((255 - brightness) / prepaintStrength), 255);
      idt[i + 1] = Math.min(brightness + ((255 - brightness) / prepaintStrength), 255);
      idt[i + 2] = Math.min(brightness + ((255 - brightness) / prepaintStrength), 255);
      idt[i + 3] = 0;
    } 
    setPrepaintData(_prepaintData)
    ctxPrepaint.putImageData(_prepaintData, 0,0)


    let _drawData = ctxBaseImage.getImageData(0, 0, imgWidth, imgHeight)
    _drawData.data.fill(0)
    setDrawData(_drawData)
    setHasImage(true)

    setCanvasWrapperHeight(imgHeight)
    setCanvasWrapperWidth(imgWidth)
    setCanvasHeight(imgHeight)
    setCanvasWidth(imgWidth)

  }

  const isColorSelected = () => {
    const currentColor = colors[selectedColorIndex]
    return currentColor.Id && currentColor.colorR && currentColor.colorG && currentColor.colorB
  }

  const toggleSelectorWindow = (bool) => {
    if (bool) {
      setPrevColors(colors.map(a => ({...a})))
    } else {
      if (colors[0].Id !== prevColors[0].Id || colors[1].Id !== prevColors[1].Id || colors[2].Id !== prevColors[2].Id ) {
        pushToHistory()
        colors.forEach((color, index) => {
          if(color.Id && colors[index].Id !== prevColors[index].Id) {
            replacePaintColor(prevColors[index], color)
          }
        })
      }
      
    }
    setSelectorWindowVisibility(bool)
  }

  const getImage = (img) => {
    image.src = URL.createObjectURL(img)
    
    image.onload = loadImage
  }

  const changeSelectedColorIndex = (index) => {
    setSelectedColorIndex(index)
    if (index === selectedColorIndex ||! colors[index].Id) {
      toggleSelectorWindow(true)
    }
  }

  const changeTool = (tool) => {
    if (!selectorWindowVisibility) {
      setTool(tool)

      ctxDrawRef.current.clearRect(0,0,canvasDrawRef.current.width, canvasDrawRef.current.height)
      if(tool !== 'polygon') {
        setPolygonNodes([])
        setIsPolygonOpen(true)
        setPolygonSelectedNode(-1)
      }
    }
  }

  const changeStroke = (targetTool, shape, width) => {
    if (!selectorWindowVisibility) {
      if (targetTool === 'Freepaint') {
        setfreepaintPointShape(shape)
        setFreepaintStrokeWidth(width)
      } else if (targetTool === 'Eraser') {
        setEraserPointShape(shape)
        setEraserStrokeWidth(width)
      }
    }
  }

  const clickColor = (color) => {
    let currentColors = [...colors]
    currentColors[selectedColorIndex] = color
    setColors(currentColors)
  }

  const pushNotification = (type, message) => {
    store.addNotification({
      title: '',
      message: message,
      type: type,
      insert: "bottom",
      container: "bottom-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000
      }
    });
  }

  const fillPolygonSelection = (isPainting) => {
    if (!isPolygonOpen) {
      pushToHistory()
      let ctxPaint = ctxPaintRef.current
      //this.decoradorData.history.push(new Uint8ClampedArray(this.prepaintData.data), new Uint8ClampedArray(this.paintData.data), JSON.stringify(colors));
      let col = colors[selectedColorIndex]

      ctxPaint.beginPath();
      polygonNodes.forEach((node, nodeIndex) => {
        ctxPaint.lineWidth = 2;
        if (nodeIndex === 0) {
          ctxPaint.moveTo(node.x, node.y);
        } else{
          ctxPaint.lineTo(node.x, node.y);    
        }
      })
      ctxPaint.lineTo(polygonNodes[0].x, polygonNodes[0].y)
      ctxPaint.fillStyle = `rgba(${col.colorR},${col.colorG},${col.colorB},1)`
      ctxPaint.globalCompositeOperation = isPainting ? 'source-over' : 'destination-out';
      ctxPaint.fill()
      ctxPaint.closePath()
      setPrepaintImpactPoints(polygonNodes)
      setPaintData(ctxPaint.getImageData(0, 0, canvasPaintRef.current.width, canvasPaintRef.current.height));

      // let arrX = polygonNodes.map(point => point.x)
      // let arrY = polygonNodes.map(point => point.y)
      // let minX = Math.max(Math.round(Math.min(...arrX)) - 1, 0)
      // let maxX = Math.min(Math.round(Math.max(...arrX)) + 1, canvasPaintRef.current.width)
      // let minY = Math.max(Math.round(Math.min(...arrY)) - 1, 0)
      // let maxY = Math.min(Math.round(Math.max(...arrY)) + 1, canvasPaintRef.current.height)
      // this.fillPrepaint(minX, maxX, minY, maxY, isPainting)
      // this.ctxPrepaint.putImageData(this.prepaintData,0,0)
      
      ctxDrawRef.current.clearRect(0,0,canvasDrawRef.current.width,canvasDrawRef.current.height)
      setPolygonNodes([])
      setIsPolygonOpen(true);
    }
    
  }

  const replacePaintColor = (colorA, colorB) => {

    const colorSim = (a,b) => Math.abs(a - b) < 20

    for(let i = 0; i < paintData.data.length; i += 4) {
      if (colorSim(paintData.data[i],colorA.colorR) && colorSim(paintData.data[i+1],colorA.colorG) && colorSim(paintData.data[i+2],colorA.colorB)) {
        // console.log('entrando al if')
        paintData.data[i] = colorB.colorR
        paintData.data[i+1] = colorB.colorG
        paintData.data[i+2] = colorB.colorB
        // paintData.data[i+3] = 255
      }
    }


    ctxPaintRef.current.putImageData(paintData,0,0)
  }

  const togglePrepaint = (targetStrength = prepaintStrength === 5 ? 1.5 : 5) => {
    redoPrepaint(targetStrength)
    setPrepaintStrength(targetStrength)
  }

  const redoPrepaint = (val = prepaintStrength) => {

    const canvasPrepaint = canvasPrepaintRef.current;
    const ctxPrepaint = ctxPrepaintRef.current;
    
    let _prepaintData = new ImageData(
      new Uint8ClampedArray(imageData.data),
      imageData.width,
      imageData.height
    )

    let idt = _prepaintData.data
    for (let i = 0; i < idt.length; i += 4) {
      var brightness = 0.34 * idt[i] + 0.5 * idt[i + 1] + 0.16 * idt[i + 2];
      idt[i] = Math.min(brightness + ((255 - brightness) / val), 255);
      idt[i + 1] = Math.min(brightness + ((255 - brightness) / val), 255);
      idt[i + 2] = Math.min(brightness + ((255 - brightness) / val), 255);
      idt[i + 3] = 255;
    } 
    // setPrepaintData(_prepaintData)
    fillPrepaint(0, canvasPrepaint.width,0,canvasPrepaint.height, true, _prepaintData)

//     prepaintData.data.fill(0)
//     ctxPrepaintRef.current.drawImage(image, 0, 0, canvasBaseImageRef.current.width, canvasBaseImageRef.current.height);
//     let _prepaintData = ctxPrepaintRef.current.getImageData(0, 0, canvasBaseImageRef.current.width, canvasBaseImageRef.current.height);
//     // let idt = _prepaintData.data
//     // for (let i = 0; i < idt.length; i += 4) {
//     //   var brightness = 0.34 * idt[i] + 0.5 * idt[i + 1] + 0.16 * idt[i + 2];
//     //   idt[i] = Math.min(brightness + ((255 - brightness) / val), 255);
//     //   idt[i + 1] = Math.min(brightness + ((255 - brightness) / val), 255);
//     //   idt[i + 2] = Math.min(brightness + ((255 - brightness) / val), 255);
//     //   idt[i + 3] = 255;
//     // } 
//     setPrepaintData(_prepaintData)
//     ctxPrepaintRef.current.putImageData(_prepaintData,0,0)
//     // fillPrepaint(0, canvasPrepaintRef.current.width, 0, canvasPrepaintRef.current.height, tool === tools.FREEPAINT)
//  console.log("canvasPrepaintRef.current.width ", canvasPrepaintRef.current.width);
  }

  const fillPrepaint = (minX, maxX, minY, maxY, isFilling, _prepaintData = prepaintData) => {

    let _paintData = paintData
    // let _prepaintData = prepaintData
    for(let ix = minX; ix <= maxX; ix++) {
      for(let iy = minY; iy <= maxY; iy++) {
        _prepaintData.data[(4 * (_prepaintData.width * iy + ix)) + 3] = _paintData.data[(4 * (_paintData.width * iy + ix)) + 3]
      }
    }
    setPrepaintData(_prepaintData)
    ctxPrepaintRef.current.putImageData(_prepaintData,0,0)
  }

  const pushToHistory =() => {
    history.push(new Uint8ClampedArray(prepaintData.data), new Uint8ClampedArray(paintData.data), JSON.stringify(colors))
  }

  const undo = () => {
    if (history.canRestore()) {
      let reloadData = history.restore()
      prepaintData.data.set(reloadData.prepaint)
      paintData.data.set(reloadData.paint)
      ctxPaintRef.current.putImageData(paintData,0,0)
      ctxPrepaintRef.current.putImageData(prepaintData,0,0)
      setColors(JSON.parse(reloadData.colors))
      togglePrepaint(prepaintStrength)
    }
  }

  const clickCanvas = (e) => {
    const rect = canvasBaseImageRef.current.getBoundingClientRect()
    const invScale = 1 / zoomScale
    const absoluteX = Math.round(e.clientX - rect.left)
    const absoluteY = Math.round(e.clientY - rect.top)
    const x = Math.round(absoluteX * invScale)
    const y = Math.round(absoluteY * invScale)

    if (isColorSelected()) {
      switch (tool) {
        case tools.PAINT:
          pushToHistory()
          paintCanvas(x,y)
          break;
  
        case tools.POLYGON:
          const selectedPolygon = polygonNodes.findIndex(node => {
            return Math.abs(x - node.x) <= 12 && Math.abs(y - node.y) <= 12
          })
          const ctxDraw = ctxDrawRef.current
          
          if ((selectedPolygon === -1 || polygonNodes.length === 0) && isPolygonOpen) {
            polygonNodes.push({ x, y })
          } else if (selectedPolygon === 0 && polygonNodes.length > 1 && isPolygonOpen) {
            setIsPolygonOpen(false);
          }
  
          ctxDraw.clearRect(0, 0, canvasDrawRef.current.width, canvasDrawRef.current.height)
          ctxDraw.beginPath();
          ctxDraw.strokeStyle = 'rgba(122,122,122,0.5)';
          ctxDraw.setLineDash([5, 10]);
          polygonNodes.forEach((node, nodeIndex) => {
            ctxDraw.lineWidth = 2;
            if (nodeIndex === 0) {
              ctxDraw.moveTo(node.x, node.y);
            } else {
              ctxDraw.lineTo(node.x, node.y);
              ctxDraw.stroke();
            }
          })
          if (!isPolygonOpen) {
            ctxDraw.lineTo(polygonNodes[0].x, polygonNodes[0].y)
            ctxDraw.stroke();
            ctxDraw.fillStyle = 'rgba(255,255,255,0.4)'
            ctxDraw.fill()
            ctxDraw.closePath()
          }
          polygonNodes.forEach((node, nodeIndex) => {
            ctxDraw.fillStyle = 'rgba(0,0,0,0.7)'
            ctxDraw.beginPath()
            ctxDraw.arc(node.x, node.y, 5, 0, 2 * Math.PI);
            ctxDraw.fill();
            ctxDraw.closePath()
          })
          
          break;
  
        default:
          break;
      }
    }

  }

  const mouseEnterCanvas = (e) => {
    setIsCursorActive(true)
    setCursorPosition({x: e.clientX, y: e.clientY})
  }

  const mouseLeaveCanvas = (e) => {

    setIsCursorActive(false)
  }

  const mouseDownCanvas = (e) => {
    const rect = canvasBaseImageRef.current.getBoundingClientRect()
    const invScale = 1 / zoomScale
    const absoluteX = Math.round(e.clientX - rect.left)
    const absoluteY = Math.round(e.clientY - rect.top)
    const x = Math.round(absoluteX * invScale)
    const y = Math.round(absoluteY * invScale)
    let ctxPaint = ctxPaintRef.current;

    if (isColorSelected()) {
      switch (tool) {
        case tools.PAN:
          setPreviousPanningPosition({x:absoluteX, y:absoluteY})
          setIsPanning(true)
          break;

        case tools.FREEPAINT:
          pushToHistory()
          setIsHoldingFreePaint(true)
          let selectedColor = colors[selectedColorIndex]
          ctxPaint.fillStyle = `rgb(${selectedColor.colorR}, ${selectedColor.colorG}, ${selectedColor.colorB})`;
          ctxPaint.globalCompositeOperation = 'source-over';
          ctxPaint.beginPath();
          if (freepaintPointShape === "circle") {
            ctxPaint.arc(x, y, freepaintStrokeWidth / 2, 0, 2 * Math.PI);
          } else {
            ctxPaint.fillRect(x - (freepaintStrokeWidth / 2), y - (freepaintStrokeWidth / 2), freepaintStrokeWidth, freepaintStrokeWidth)
          }
          ctxPaint.fill();
          setFreepaintPreviousPosition({x: x, y:y})
          setPrepaintImpactPoints([{x: x, y:y}])
          break;
  
        case tools.ERASER:
          pushToHistory()
          setIsHoldingEraser(true)
          ctxPaint.globalCompositeOperation = 'destination-out';
          ctxPaint.beginPath();
          if (eraserPointShape === "circle") {
            ctxPaint.arc(x, y, eraserStrokeWidth / 2, 0, 2 * Math.PI);
          } else {
            ctxPaint.fillRect(x - (eraserStrokeWidth / 2), y - (eraserStrokeWidth / 2), eraserStrokeWidth, eraserStrokeWidth)
          }
          
          ctxPaint.fill();
    
          ctxPaint.lineWidth = eraserStrokeWidth;
          ctxPaint.beginPath();
          ctxPaint.moveTo(x, y);
          ctxPaint.lineTo(x, y);
          ctxPaint.stroke();
    
          setEraserPreviousPosition({x: x, y: y});
          setPrepaintImpactPoints([{x: x, y:y}])
          break;
  
        case tools.POLYGON:
          const selectedTarget = polygonNodes.findIndex(node => {
            return Math.abs(x - node.x) <= 10 && Math.abs(y - node.y) <= 10
          })
  
          if (selectedTarget !== -1 && isPolygonOpen === false) {
            setPolygonSelectedNode(selectedTarget)
          }
          break;

  
        default:
          break;
      }
    }
    
  }

  const mouseMoveCanvas = (e) => {
    const rect = canvasBaseImageRef.current.getBoundingClientRect()
    const invScale = 1 / zoomScale
    const absoluteX = Math.round(e.clientX - rect.left)
    const absoluteY = Math.round(e.clientY - rect.top)
    const x = Math.round(absoluteX * invScale)
    const y = Math.round(absoluteY * invScale)
    setCursorPosition({x: e.clientX, y: e.clientY})
    let ctxPaint = ctxPaintRef.current;
    if (isColorSelected()) {
      switch (tool) {
        case tools.PAN:
          if (isPanning) {
            let _offsetX = absoluteX - previousPanningPosition.x;
            let _offsetY = absoluteY - previousPanningPosition.y;

            let targetOffsetX = zoomTraslation.x + _offsetX
            if (targetOffsetX > 0) {
              targetOffsetX = 0
            } else if (targetOffsetX < -canvasWidth + canvasWrapperWidth) {
              targetOffsetX = -canvasWidth + canvasWrapperWidth
            }
            let targetOffsetY = zoomTraslation.y + _offsetY

            if (targetOffsetY > 0) {
              targetOffsetY = 0
            } else if (targetOffsetY < -canvasHeight + canvasWrapperHeight) {
              targetOffsetY = -canvasHeight + canvasWrapperHeight
            }

              setZoomTraslation({x:Math.round(targetOffsetX), y:Math.round(targetOffsetY)})
 
              // setPreviousPanningPosition({x:absoluteX, y:absoluteY})
            
          }
          break;

        case tools.FREEPAINT:
          if (isHoldingFreepaint) {
            let selectedColor = colors[selectedColorIndex]
            ctxPaint.fillStyle = `rgb(${selectedColor.colorR}, ${selectedColor.colorG}, ${selectedColor.colorB})`;
            ctxPaint.strokeStyle = `rgb(${selectedColor.colorR}, ${selectedColor.colorG}, ${selectedColor.colorB})`;
            ctxPaint.fill();
  
            ctxPaint.lineWidth = freepaintStrokeWidth;
            ctxPaint.beginPath();
            ctxPaint.moveTo(x, y);
            ctxPaint.lineTo(freepaintPreviousPosition.x, freepaintPreviousPosition.y);
            ctxPaint.stroke();
  
            if (freepaintPointShape === "circle") {
              ctxPaint.arc(x, y, freepaintStrokeWidth / 2, 0, 2 * Math.PI);
            } else {
              ctxPaint.fillRect(x - (freepaintStrokeWidth / 2), y - (freepaintStrokeWidth / 2), freepaintStrokeWidth, freepaintStrokeWidth)
            }
            setFreepaintPreviousPosition({x: x, y:y})
            setPrepaintImpactPoints([...prepaintImpactPoints, {x: x, y:y}])
            
          }
          
          break;
  
          case tools.ERASER:
            if (isHoldingEraser) {
  
              ctxPaint.globalCompositeOperation = 'destination-out';
              ctxPaint.beginPath();
              if (eraserPointShape === "circle") {
                ctxPaint.arc(x, y, eraserStrokeWidth / 2, 0, 2 * Math.PI);
              } else {
                ctxPaint.fillRect(x - (eraserStrokeWidth / 2), y - (eraserStrokeWidth / 2), eraserStrokeWidth, eraserStrokeWidth)
              }
              
              ctxPaint.fill();
    
              ctxPaint.lineWidth = eraserStrokeWidth;
              ctxPaint.beginPath();
              ctxPaint.moveTo(eraserPreviousPosition.x, eraserPreviousPosition.y);
              ctxPaint.lineTo(x, y);
              ctxPaint.stroke();
    
              setEraserPreviousPosition({ x: x, y: y });
              setPrepaintImpactPoints([...prepaintImpactPoints, {x: x, y:y}])
              // this.toolSettings.prepaint.impactPoints.push({x: x, y: y})
            }
            break;
  
          case tools.POLYGON: {
            const ctxDraw = ctxDrawRef.current
            if (polygonSelectedNode > -1 && !isPolygonOpen) {
              polygonNodes[polygonSelectedNode].x = x
              polygonNodes[polygonSelectedNode].y = y
              ctxDraw.clearRect(0, 0, canvasDrawRef.current.width, canvasDrawRef.current.height)
              ctxDraw.beginPath();
              ctxDraw.strokeStyle = 'rgba(122,122,122,0.5)';
              ctxDraw.setLineDash([5, 10]);
              polygonNodes.forEach((node, nodeIndex) => {
                ctxDraw.lineWidth = 2;
                if (nodeIndex === 0) {
                  ctxDraw.moveTo(node.x, node.y);
                } else {
                  ctxDraw.lineTo(node.x, node.y);
                  ctxDraw.stroke();
                }
              })
              if (!isPolygonOpen) {
                ctxDraw.lineTo(polygonNodes[0].x, polygonNodes[0].y)
                ctxDraw.stroke();
                ctxDraw.fillStyle = 'rgba(255,255,255,0.4)'
                ctxDraw.fill()
                ctxDraw.closePath()
              }
              polygonNodes.forEach((node, nodeIndex) => {
                ctxDraw.fillStyle = 'rgba(0,0,0,0.7)'
                ctxDraw.beginPath()
                ctxDraw.arc(node.x, node.y, 5, 0, 2 * Math.PI);
                ctxDraw.fill();
                ctxDraw.closePath()
              })
      
            }
            break;
          }
  
        default:
          break;
      }
    }
    
  }

  const mouseUpCanvas = (e) => {


    switch (tool) {
      case tools.FREEPAINT:
        setIsHoldingFreePaint(false)
        setFreepaintPreviousPosition(null);
        break;

      case tools.ERASER:
        setIsHoldingEraser(false)
        setEraserPreviousPosition(null)
        break;

      case tools.POLYGON:
        setPolygonSelectedNode(-1)
        break;

      case tools.PAN:
        setIsPanning(false)
        break;

      default:
        break;
    }

    if (tool === tools.FREEPAINT || tool === tools.ERASER) {
      // let arrX = prepaintImpactPoints.map(point => point.x)
      // let arrY = prepaintImpactPoints.map(point => point.y)
      // let minX = Math.max(Math.round(Math.min(...arrX)) - Math.ceil(_strokeWidth/2), 0)
      // let maxX = Math.min(Math.round(Math.max(...arrX)) + Math.ceil(_strokeWidth/2), canvasBaseImageRef.current.width)
      // let minY = Math.max(Math.round(Math.min(...arrY)) - Math.ceil(_strokeWidth/2), 0)
      // let maxY = Math.min(Math.round(Math.max(...arrY)) + Math.ceil(_strokeWidth/2), canvasBaseImageRef.current.height)
      setPaintData(ctxPaintRef.current.getImageData(0, 0, canvasPaintRef.current.width, canvasPaintRef.current.height))

      // console.log(ctxPaintRef.current.getImageData(0, 0, canvasPaintRef.current.width, canvasPaintRef.current.height))

      // fillPrepaint(minX, maxX, minY, maxY, true)
    }
    // setPrepaintImpactPoints([])

    
  }

  const paintCanvas = (x,y) => {

    switch(tool) {
      case tools.PAINT:
        setIsProcessing(true)
        setTimeout(() => {
          floodFill(x,y, colors[selectedColorIndex])
          ctxPaintRef.current.putImageData(paintData,0,0)
          ctxPrepaintRef.current.putImageData(prepaintData,0,0)
        }, 0)
        
        // this.ctxPrepaint.putImageData(this.prepaintData,0,0)
        break;

      default:
        break;
    }
  }

  const floodFill = (xx, yy, color) => {
      let baseColor = helpers.getColorAtPixel(imageData, xx, yy)
    let queue = []
    queue.push({ x: xx, y: yy, c: baseColor })

    let _paintData = paintData
    let _prepaintData = prepaintData

    let visited = {}
    while (queue.length > 0) {
      let { x, y, c } = queue.pop()
      //console.log("x, y  ", x, y );
      
      //this.helpers.setColorAtPixel(this.drawData, color, x, y)

      

      const _index = 4 * (_paintData.width * y + x)
      _paintData.data[_index] = color.colorR
      _paintData.data[_index + 1] = color.colorG
      _paintData.data[_index + 2] = color.colorB
      _paintData.data[_index + 3] = 255
      _prepaintData.data[_index + 3] = 255

      //helpers.setAlphaAtPixel(prepaintData, x, y, true)
      visited[4 * (imageData.width * y + x)] = true;

      for (let ix = -1; ix <= 1; ix++) {
        for (let iy = -1; iy <= 1; iy++) {
          if (
            !visited[4 * (imageData.width * (y+ iy) + (x + ix))] &&
            ((x + ix) >= 0) && ((x + ix) <= canvasBaseImageRef.current.width -1) && 
            ((y + iy) >= 0) && ((y + iy) <= canvasBaseImageRef.current.height)  && 
            helpers.colorMatch(c, helpers.getColorAtPixel(imageData, x + ix, y + iy), 1.3)            
            ) {
            queue.push({ x: x + ix, y: y + iy, c: helpers.getColorAtPixel(imageData, x + ix, y + iy) })
          }
        }
      }

      
    }

    setPaintData(_paintData)
    setIsProcessing(false)
  }

  const getCursorStyle = () => {
    const styles = {}
    switch (tool) {

      case tools.POLYGON:
        styles.width = 0;
        styles.height = 0;
        styles.border = 'none';
        break;

      case tools.FREEPAINT:
        styles.width = freepaintStrokeWidth * zoomScale + 'px'
        styles.height = freepaintStrokeWidth * zoomScale + 'px'
        if (freepaintPointShape === 'circle') {
          styles.borderRadius = '50%'
        }
        break;

      case tools.ERASER:
        styles.width = eraserStrokeWidth * zoomScale + 'px'
        styles.height = eraserStrokeWidth * zoomScale + 'px'
        if (eraserPointShape === 'circle') {
          styles.borderRadius = '50%'
        }
        break;

      default:
        break;
    }

    return styles
  }

  const saveImage = (shareData) => {

    if (shareData) {
      setIsShareLoading(true)
    }
    const canvasResult = canvasResultRef.current
    const ctxResult = ctxResultRef.current

    canvasResult.height = imageURL.height
    canvasResult.width = imageURL.width

    var colorsHeight = canvasResult.height / 10

    var scaleW = canvasResult.width / canvasBaseImageRef.current.width
    var scaleH = canvasResult.height / canvasBaseImageRef.current.height

    canvasResult.height = canvasResult.height + colorsHeight + (colorsHeight/2)
    ctxResult.globalCompositeOperation = 'source-over';
    ctxResult.clearRect(0,0,canvasResult.width, canvasResult.height)
    ctxResult.drawImage(imageURL,0,0)

    
    let resultData = ctxResult.getImageData(0, 0, canvasResult.width, canvasResult.height);
    ctxResult.drawImage(canvasPrepaintRef.current,0,0, canvasPrepaintRef.current.width*scaleW,canvasPrepaintRef.current.height*scaleH)
    ctxResult.globalCompositeOperation = 'multiply';
    ctxResult.drawImage(canvasPaintRef.current,0,0, canvasPaintRef.current.width*scaleW,canvasPaintRef.current.height*scaleH)
    ctxResult.globalCompositeOperation = 'source-over';

    ctxResult.fillStyle = `rgb(255,0,0)`
    ctxResult.fillRect(0, canvasResult.height - (colorsHeight * 1.6), canvasResult.width , canvasResult.height - colorsHeight);
    ctxResult.fillStyle = helpers.getContrastingColorText(255, 0, 0)
    ctxResult.font = `${colorsHeight*0.35}px gotham`;
    ctxResult.fillText('Sinteplast', 10, canvasResult.height-(colorsHeight*1.2));
    let textWidth = ctxResult.measureText('Sinteplast').width
    // ctxResult.font = `${colorsHeight*0.25}px Arial`;
    // ctxResult.fillText('Mi selección creada desde la aplicación:', textWidth + 30, canvasResult.height-(colorsHeight*1.2));

    let filteredColors = colors.filter(c => c.Id)
    filteredColors.forEach((color, index) => {
      
      let startX = (canvasResult.width / filteredColors.length) * (index)
      ctxResult.fillStyle = `rgb(${color.colorR},${color.colorG},${color.colorB})`
      ctxResult.fillRect(startX, canvasResult.height - colorsHeight, canvasResult.width / filteredColors.length , colorsHeight);

      ctxResult.fillStyle = helpers.getContrastingColorText(color.colorR, color.colorG, color.colorB)
      ctxResult.font = `${colorsHeight*0.3}px Arial`;
      ctxResult.fillText(`${canvasResult.width > 400 ? 'COLOR: ' : ''}` + color.ColorNum, startX + 10, canvasResult.height-(colorsHeight*0.4));
    })

    if (!!shareData) {

      let imgQuality = 1
      let totalPixels = canvasResult.width * canvasResult.height

      if (totalPixels > 20000000) {
        imgQuality = 0.4
      } else if (totalPixels > 15000000) {
        imgQuality = 0.5
      } else if (totalPixels > 10000000) {
        imgQuality = 0.6
      } else if (totalPixels > 5000000) {
        imgQuality = 0.7
      } else if (totalPixels > 3000000) {
        imgQuality = 0.8
      } else if (totalPixels > 2000000) {
        imgQuality = 0.9
      }

      let shareBody = {
        Base64Image: canvasResult.toDataURL('image/jpeg', imgQuality).split(',')[1],
        FromName: shareData.from,
        ToEmail: shareData.to,
        Language: actualLenguage === 'es'? 'es': 'pt',
      }

      fetch(process.env.REACT_APP_API_URL + '/decorador/enviar-imagen-email', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(shareBody)
      })
      .then(response => response.json())
      .then(res=> {
        if (res.EnvioOk) {
          
          setIsShareOpen(false)
          pushNotification('success', translate("notifications.susscessSend"))
        } else {
          pushNotification('danger', translate("notifications.errorSend"))
        }

        setIsShareLoading(false)
        
      });
      // setTimeout(() => {
        
        
      // }, 3000)
    } else {
      var link= document.createElement("a")
      link.download = "Decorado_virtual.png"
      link.href = canvasResult.toDataURL('image/jpeg', 0.9)
      document.body.appendChild(link)
      link.click()
      pushNotification('success', translate("notifications.download"))
      document.body.removeChild(link)
    }

    
  }

  const shareImage = () => {
    setIsShareOpen(true)
  }

  const openVideo = () => {
    console.log('open video')
  }

  const toggleHelp = () => {
    setIsHelpOpen(!isHelpOpen)
  }

  const clickDimmer = () => {
    setIsHelpOpen(false)
    setIsShareOpen(false)
  }

  // const mouseWheelCanvas = (e) => {
  //   const rect = canvasBaseImageRef.current.getBoundingClientRect()
  //   const x = Math.round(e.clientX - rect.left)
  //   const y = Math.round(e.clientY - rect.top)
  //   if (e.deltaY < 0) {
  //     zoomIn(x,y)
  //   } else if (e.deltaY > 0) {
  //     zoomOut(x,y)
  //   }
  //   // ctxResultRef.current.putImageData(imageData, 0, 0)
  //   // ctxBaseImageRef.current.scale(1.5,1.5)
  //   // ctxBaseImageRef.current.drawImage(canvasResultRef.current, 0,0)
  // }

  const zoomTrigger = (bool) => {
    if (bool) {
      zoomIn(0,0)
    } else {
      zoomOut(0,0)
    }
  }

  const zoomIn = (x,y) => {
    
    if (zoomScale <= 1.6) {

      let imgWidth = canvasBaseImageRef.current.width * zoomScale * 1.6
      let imgHeight = canvasBaseImageRef.current.height * zoomScale * 1.6

      

      canvasBaseImageRef.current.style.width = imgWidth + 'px'
      canvasBaseImageRef.current.style.height = imgHeight + 'px'
      canvasPrepaintRef.current.style.width = imgWidth + 'px'
      canvasPrepaintRef.current.style.height = imgHeight + 'px'
      canvasPaintRef.current.style.width = imgWidth + 'px'
      canvasPaintRef.current.style.height = imgHeight + 'px'
      canvasDrawRef.current.style.width = imgWidth + 'px'
      canvasDrawRef.current.style.height = imgHeight + 'px'
      
      let traslationX = x - (x - zoomTraslation.x) * zoomScale * 1.6
      let traslationY = y - (y - zoomTraslation.y) * zoomScale * 1.6
      setZoomOffset({x,y})
      setZoomTraslation({x:0,y:0})
      setZoomScale(zoomScale * 1.6)
      setCanvasWidth(imgWidth)
      setCanvasHeight(imgHeight)
    }
    
  }

  const zoomOut = (x,y) => {
    if (zoomScale >= 1.6) {

      let targetZoomOutScale = zoomScale > 1.6 ? 1.6 : 1

      let imgWidth = canvasWrapperWidth * targetZoomOutScale
      let imgHeight = canvasWrapperHeight * targetZoomOutScale

      canvasBaseImageRef.current.style.width = imgWidth + 'px'
      canvasBaseImageRef.current.style.height = imgHeight + 'px'
      canvasPrepaintRef.current.style.width = imgWidth + 'px'
      canvasPrepaintRef.current.style.height = imgHeight + 'px'
      canvasPaintRef.current.style.width = imgWidth + 'px'
      canvasPaintRef.current.style.height = imgHeight + 'px'
      canvasDrawRef.current.style.width = imgWidth + 'px'
      canvasDrawRef.current.style.height = imgHeight + 'px'


      setZoomOffset({x:0,y:0})
      setZoomTraslation({x:0,y:0})
      setZoomScale(targetZoomOutScale)
      if (tool === tools.PAN) {
        setTool(tools.PAINT)
      }
    }
    
  }

  return (
    <div className="container">
      {(isHelpOpen || isShareOpen) && <div className="dimmer" onClick={clickDimmer}></div>}
      <ReactNotification />
      <Header onClickSave={() => saveImage(false)} onClickShare={shareImage} onClickVideo={openVideo} onClickHelp={toggleHelp} isHelpActive={isHelpOpen} />
      {isShareOpen && <ShareModal loading={isShareLoading} onShare={(shareData) => saveImage(shareData)} onClose={() => setIsShareOpen(false)} />}
      {isCursorActive && (tool === tools.FREEPAINT || tool === tools.ERASER) && <div className="cursor" style={{...getCursorStyle(), left: cursorPosition.x, top: cursorPosition.y}}></div>}
      <div className="inner-container">
        
        <div className="colorbar">
          <div className="color-list">
            {
              colors.map((color, index) => (
                <ColorCard key={'colorcard' + index} selected={selectedColorIndex === index} color={color} onClick={() => changeSelectedColorIndex(index)} />
              ))
            }
          </div>
          <label htmlFor="file-upload" className="photo-upload">
            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM14 9V13H10V9H7L12 4L17 9H14Z" fill="white"/>
            </svg>
            <span>{translate('leftButton.uploadImage')}</span>
            <p>a</p>
          </label>
          <input id="file-upload" type="file" onChange={(e) => getImage(e.target.files[0])}/>
          {/* <img className="img-cut" src={logoCut} alt="" /> */}
        </div>

        <div className="workspace" ref={workspaceRef}>
          
          { selectorWindowVisibility && <SelectorWindow tendencias={tendencias} selectedColorIndex={selectedColorIndex} currentColors={colors} colors={colorList} onClickColor={(color) => clickColor(color)} closeWindow={toggleSelectorWindow.bind(this,false)} /> }
          { isHelpOpen && <VideoWindow videoURL={actualLenguage === 'es'? videoURL : videoURLPT} onCloseHelp={toggleHelp} />}
          { isProcessing && <SpinnerProcess />}
          <div className="canvas-wrapper" ref={canvasWrapperRef}>
            <canvas className="canvas-result" ref={canvasResultRef}></canvas>
            <canvas
              className="canvas-baseimage"
              ref={canvasBaseImageRef}
              style={{transform: `translate(${zoomTraslation.x}px,${zoomTraslation.y}px)`}}
            >

            </canvas>
            <canvas className="canvas-prepaint" ref={canvasPrepaintRef} style={{transform: `translate(${zoomTraslation.x}px,${zoomTraslation.y}px)`}}></canvas>
            <canvas className="canvas-paint" ref={canvasPaintRef} style={{transform: `translate(${zoomTraslation.x}px,${zoomTraslation.y}px)`}}></canvas>
            <canvas 
              className={`canvas-draw ${tool === tools.PAINT ? 'crosshair' : ''} ${tool === tools.POLYGON ? isPolygonOpen ? 'crosshair' : 'grab' : ''} ${tool === tools.PAN ? 'grab' : ''}`} 
              ref={canvasDrawRef}
              style={{transform: `translate(${zoomTraslation.x}px,${zoomTraslation.y}px)`}}
              onClick={(e) => clickCanvas(e)}
              onMouseEnter={(e) => mouseEnterCanvas(e)}
              onMouseLeave={(e) => mouseLeaveCanvas(e)}
              onMouseDown={(e) => mouseDownCanvas(e)}
              onMouseMove={(e) => mouseMoveCanvas(e)}
              onMouseUp={(e) => mouseUpCanvas(e)}
              // onWheel={(e) => mouseWheelCanvas(e)}
            />
          </div>
        </div>

        <div className="toolbar">
          <Tool tool="Freepaint" selected={tool === tools.FREEPAINT} shape={freepaintPointShape} width={freepaintStrokeWidth} onChangeStroke={(shape, width) => changeStroke('Freepaint', shape, width)} onClick={() => changeTool(tools.FREEPAINT)} disabled={selectorWindowVisibility || !isColorSelected()} />
          <Tool tool="Paint" selected={tool === tools.PAINT} onClick={() => changeTool(tools.PAINT)} disabled={selectorWindowVisibility || !isColorSelected()} />
          <Tool tool="Polygon" disabledExpanded={isPolygonOpen} selected={tool === tools.POLYGON} applyPolygonSelection={(bool) => fillPolygonSelection(bool)} onClick={() => changeTool(tools.POLYGON)} disabled={selectorWindowVisibility || !isColorSelected()} />
          <Tool tool="Undo" onClick={undo} disabled={selectorWindowVisibility || !history.canRestore()} />
          <Tool tool="Eraser" selected={tool === tools.ERASER} shape={eraserPointShape} width={eraserStrokeWidth} onChangeStroke={(shape, width) => changeStroke('Eraser', shape, width)} onClick={() => changeTool(tools.ERASER)} disabled={selectorWindowVisibility || !isColorSelected()} />
          <Tool tool="Prepaint" selected={prepaintStrength === 1.5} onClick={() => togglePrepaint()} disabled={selectorWindowVisibility || !isColorSelected()} />
          <Tool tool="Zoom" zoom={zoomScale} onZoom={(bool) => zoomTrigger(bool)} />
          <Tool tool="Pan" selected={tool === tools.PAN} onClick={() => zoomScale > 1 ? changeTool(tools.PAN) : false} disabled={zoomScale <= 1} />
        </div>     
        
      </div>
    </div>
  );
}

export default App;