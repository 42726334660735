import React from 'react'
import './Stroke.scss'

const Stroke = ({number, shape, dimensions, onClick, selected}) => {

  const styles = {
    width: dimensions + 'px',
    height: dimensions + 'px',
    borderRadius: shape === 'circle' ? '50%' : '0',
    background: selected ? '#E70808' : '#828282'
  }



  return (
    <span className="stroke" style={styles} onClick={onClick}>{number}</span>
  )
}

export default Stroke
