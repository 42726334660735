import React, { useState } from 'react'
import helpers from '../../helpers'
import './TendenciasSlider.scss'
import useCustomTranslation from '../../hook/useCustomTranslation'


const TendenciasSlider = ({onApply, tendencia, onClickColor, selectedPaleta, colors}) => {

  const [sliderIndex, setSliderIndex] = useState(selectedPaleta)
  const [prevSliderAction, setPrevSliderAction] = useState(null)


  const getFilteredTendencias = () => {

    
    const paletas = tendencia.Paletas
    const hasPreviousIndex = paletas[sliderIndex-1]
    const hasNextIndex = paletas[sliderIndex-1]
    const filteredPaletas = [
      paletas[sliderIndex-1] ? paletas[sliderIndex-1] : paletas[paletas.length -1],
      paletas[sliderIndex],
      paletas[sliderIndex+1] ? paletas[sliderIndex+1] : paletas[0]
    ]
    return filteredPaletas
  }

  const getCardStyles = (index) => {
    switch (index) {
      case 0:
        return {
          left: '10%'
        }

      case 1:
        return {
          left: '50%'
        }

      case 2:
        return {
          left: '90%'
        }

      default:
        return {}
    }
  }

  const changeSliderIndex = (dir) => {

    // console.log('hl:', dir)
    
    if (dir === 1) {
      if (tendencia.Paletas[sliderIndex+1]) {
        setSliderIndex(sliderIndex+1)
      } else {
        setSliderIndex(0)
      }
      
      setPrevSliderAction(-1)
    } else if (dir === -1) {

      if (tendencia.Paletas[sliderIndex-1]) {
        setSliderIndex(sliderIndex-1)
      } else {
        setSliderIndex(tendencia.Paletas.length-1)
      }
      setPrevSliderAction(1)
    }
  }

  const { translate } = useCustomTranslation();

  return (
    <div className="tendencias-slider">
      <div className="tendencias-container">
        <h2 className="tendencia-primary-title">
          {tendencia.Titulo.toUpperCase()}
          <span className="tendencia-primary-title-shadow">
            {tendencia.Titulo.toUpperCase()}
          </span>
        </h2>
        <div className="slider-container">
        {
          getFilteredTendencias().map((tendencia, index) => (
            <div onClick={() => changeSliderIndex(index-1)}className={`tendencia-card ${index === 1 ? 'main' : ''} ${index === 0 && prevSliderAction === 1 ? 'from-left' : ''} ${index === 2 && prevSliderAction === -1 ? 'from-right' : ''}`} style={getCardStyles(index)} key={tendencia.Id}>
              {
                index !== 1 ?
                <>
                  <img className="tendencia-card-image" src={tendencia.ImagenWeb} alt="" />
                  <div className="tendencia-card-colors">
                    {
                      tendencia.Colores.map(color => (
                        <span className="tendencia-card-color" key={color.Id} style={{background: `rgb(${color.colorR},${color.colorG},${color.colorB})`}}></span>
                      ))
                    }
                  </div>
                  <div className="tendencia-card-title">{tendencia.Descripcion}</div>
                </>
                :
                <>
                  <div className="tendencia-card-image-split">
                    <div className="tendencia-card-image-wrapper" style={{backgroundImage: `url(${tendencia.ImagenWeb})`}} alt="" > </div>
                    <div className="tendencia-card-title">{tendencia.Descripcion}</div>
                  </div>
                  <div className="tendencia-card-colors">
                  {
                      tendencia.Colores.map(color => (
                        <span className={`tendencia-card-color ${colors.find(el => el.Id === color.Id) ? 'color-single-selected': ''}`} key={color.Id} style={{background:`rgb(${color.colorR},${color.colorG},${color.colorB})`}} onClick={() => onClickColor(color)}>
                          <span className="tendencia-card-color-description" style={{color: helpers.getContrastingColorText(color.colorR, color.colorG, color.colorB)}}>
                            <div>{color.ColorNum}</div>
                            <div>{color.Nombre}</div>
                          </span>
                        </span>
                      ))
                    }
                  </div>
                </>
              }
            </div>
          ))
        }
        </div>
        <div className="tendencia-slider-bullet-wrapper">
          {
            tendencia.Paletas.map((plt, pltIndex) => (
              <span className="tendencia-slider-bullet" style={{background: sliderIndex === pltIndex ? '#BF0811' : '#C4C4C4'}}  onClick={() => setSliderIndex(pltIndex)}></span>
            ))
          }
        </div>
        <div className="btn-apply-wrapper">
          <button className="btn-apply-colors" onClick={onApply}>
           {translate("colorTrendsHead.trendsButton")}
          </button>
        </div>
        
      </div>
    </div>
  )
}

export default TendenciasSlider
