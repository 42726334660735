import axios from '../index';

const getVideos = async () => {
  return axios.get(
    `/decorador/web/video`
  );
};

const getColors = async () => {
  return axios.get(
    `/decorador/web/tendencias/all`
  );
}

const postEmail = async (email) => {
  return axios.post(
    `/decorador/enviar-imagen-email`,
    {
      email
    }
  );
}

export { getVideos, getColors, postEmail };