import React, { useState } from 'react'
import Reaptcha from 'reaptcha';
import './ShareModal.scss'
import useCustomTranslation from '../../hook/useCustomTranslation';

const ShareModal = ({loading,onShare,onClose}) => {

  const APIKEY = process.env.REACT_APP_API_KEY_RECAPTCHA || '6LcJ9OcUAAAAAFT9Zzv6J9J9J9Zzv6J9J9J9Zzv6J9J9';

  const [isVerified, setIsVerified] = useState(false)
  const [fromValue, setFromValue] = useState('')
  const [fromValueError, setFromValueError] = useState('')
  const [toValue, setToValue] = useState('')
  const [toValueError, setToValueError] = useState('')

  const { translate } = useCustomTranslation();


  const onVerify = recaptchaResponse => {
    setIsVerified(true)
  };

  const validateFrom = () => {
    let isValid = true;
    let errorMessage = ''

    if (fromValue.length <= 0) {
      isValid = false
      errorMessage = translate('errorShareName.enterName')
    } else if (fromValue.length > 20) {
      isValid = false
      errorMessage = translate('errorShareName.maxName')
    } else if (fromValue.match(/[0-9]/)) {
      isValid = false
      errorMessage = translate('errorShareName.validName')
    }
    return {
      isValid,
      errorMessage
    }
  }

  const validateTo = () => {
    let isValid = true;
    let errorMessage = ''

    if (toValue.length <= 0) {
      isValid = false
      errorMessage = translate('errorShareEmail.enterEmail')
    } else if (toValue.length > 40) {
      isValid = false
      errorMessage =  translate('errorShareEmail.maxEmail')
    } else if (!toValue.match(/\S+@\S+\.\S+/)) {
      isValid = false
      errorMessage = translate('errorShareEmail.validEmail')
    }

    return {
      isValid,
      errorMessage
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    let fromValidation = validateFrom()
    if (!fromValidation.isValid) {
      setFromValueError(fromValidation.errorMessage)
    }

    let toValidation = validateTo()
    if (!toValidation.isValid) {
      setToValueError(toValidation.errorMessage)
    }

    if (fromValidation.isValid && toValidation.isValid) {
      onShare({from: fromValue, to: toValue})
    }
  }

  const changeFromValue = (event) => {
    setFromValue(event.target.value)
    setFromValueError('')
  }

  const changeToValue = (event) => {
    setToValue(event.target.value)
    setToValueError('')
  }

  return (
    <div className="share-modal">
      <div className="btn-exit" onClick={onClose}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.4617 6.83999L17.1601 5.53845L12.0001 10.6985L6.84011 5.53845L5.53857 6.83999L10.6986 12L5.53857 17.16L6.84011 18.4615L12.0001 13.3015L17.1601 18.4615L18.4617 17.16L13.3017 12L18.4617 6.83999Z" fill="#646464"/>
        </svg>
      </div>
      <p className="share-modal-title">{translate('share.title')}</p>
      <form className="share-modal-form" onSubmit={handleSubmit}>
        <label className={`share-modal-label ${fromValueError.length > 0 ? 'error' : ''}`} htmlFor="from">{translate('share.name')}:</label>
        <input className={`share-modal-input ${fromValueError.length > 0 ? 'error' : ''}`} name="from" value={fromValue} onChange={changeFromValue} type="text" />
        {fromValueError.length > 0 && <span className="share-modal-input-error">{fromValueError}</span>}
        
        <label className={`share-modal-label ${toValueError.length > 0 ? 'error' : ''}`} htmlFor="to">{translate('share.email')}:</label>
        <input className={`share-modal-input ${toValueError.length > 0 ? 'error' : ''}`} name="to" value={toValue} onChange={changeToValue} type="email" />
        {toValueError.length > 0 && <span className="share-modal-input-error">{toValueError}</span>}

        <hr />

        <Reaptcha sitekey={APIKEY} hl="es" onVerify={onVerify} />

        {
          loading ?
          <div className="loader-container">
            <div className="loader"></div>
            <div className="loader-label">{translate('share.sending')}</div>
          </div>
          :
          <button className="share-modal-button" type="submit" disabled={!isVerified ||toValue.length <= 0 || !toValue.length > 40 || fromValue.length <= 0 || fromValue.length > 20}>{translate('share.send')}</button>
        }
        
      </form>
    </div>
  )
}

export default ShareModal
