import React, { useState } from 'react'
import TendenciasSlider from '../TendenciasSlider/TendenciasSlider'
import './TendenciasSelector.scss'

const TendenciasSelector = ({onCloseSelector, isSelectingTendencia, onEnterTendencia, tendencias, colors, onClickColor, selectedColorIndex}) => {

  const [selectedTendencia, setSelectedTendencia] = useState(null)
  const [selectedPaleta, setSelectedPaleta] = useState(null)

  const selectPaleta = (tendenciaIndex, paletaIndex) => {

    setSelectedTendencia(tendenciaIndex)
    setSelectedPaleta(paletaIndex)
    onEnterTendencia(true)
  }

  return (
    <div className="tendencias-selector-wrapper">
      {
        !isSelectingTendencia ?
        tendencias.map((tendencia, tendenciaIndex) => (
          <div className="tendencia">
            <h2 className="tendencia-titulo">{tendencia.Titulo}</h2>
            <div className="tendencia-paletas">
              {
                tendencia.Paletas.map((paleta, paletaIndex) => (
                  <div className="tendencia-paleta" key={tendenciaIndex + '-' + paletaIndex} onClick={() => selectPaleta(tendenciaIndex, paletaIndex)}>
                    <img className="tendencia-paleta-image" src={paleta.ImagenWeb} alt="" />
                    <div className="tendencia-paleta-colors">
                      {
                        paleta.Colores.map(color => (
                          <span className="tendencia-paleta-color" key={color.Id} style={{background: `rgb(${color.colorR},${color.colorG},${color.colorB})`}} ></span>
                        ))
                      }
                    </div>
                    <div className="tendencia-paleta-title">{paleta.Descripcion}</div>
                  </div>
                ))
              }
            </div>
          </div>
        ))
        :
        <TendenciasSlider onApply={() => onCloseSelector(true)} colors={colors} tendencia={tendencias[selectedTendencia]} selectedPaleta={selectedPaleta} onClickColor={onClickColor} />
      }
    </div>
  )
}

export default TendenciasSelector
