let helpers = {
  getColorAtPixel(imageData, x, y) {
    const _index = 4 * (imageData.width * y + x)

    return {
      r: imageData.data[_index] & 0xff,
      g: imageData.data[_index + 1] & 0xff,
      b: imageData.data[_index + 2] & 0xff,
      a: imageData.data[_index + 3] & 0xff
    }
  },
  setAlphaAtPixel(imageData, x, y, isVisible) {
    const _index = 4 * (imageData.width * y + x)
    imageData.data[_index + 3] = isVisible ? 255 : 0;
  
  },
  rgb2lab(rgb) {
    let r = rgb[0] / 255, g = rgb[1] / 255, b = rgb[2] / 255, x, y, z;
    r = (r > 0.04045) ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92;
    g = (g > 0.04045) ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92;
    b = (b > 0.04045) ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92;
    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047;
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000;
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883;
    x = (x > 0.008856) ? Math.pow(x, 1/3) : (7.787 * x) + 16/116;
    y = (y > 0.008856) ? Math.pow(y, 1/3) : (7.787 * y) + 16/116;
    z = (z > 0.008856) ? Math.pow(z, 1/3) : (7.787 * z) + 16/116;
    return [(116 * y) - 16, 500 * (x - y), 200 * (y - z)]
  },
  deltaE(rgbA, rgbB) {
    let labA = this.rgb2lab(rgbA);
    let labB = this.rgb2lab(rgbB);
    let deltaL = labA[0] - labB[0];
    let deltaA = labA[1] - labB[1];
    let deltaB = labA[2] - labB[2];
    let c1 = Math.sqrt(labA[1] * labA[1] + labA[2] * labA[2]);
    let c2 = Math.sqrt(labB[1] * labB[1] + labB[2] * labB[2]);
    let deltaC = c1 - c2;
    let deltaH = deltaA * deltaA + deltaB * deltaB - deltaC * deltaC;
    deltaH = deltaH < 0 ? 0 : Math.sqrt(deltaH);
    let sc = 1.0 + 0.045 * c1;
    let sh = 1.0 + 0.015 * c1;
    let deltaLKlsl = deltaL / (1.0);
    let deltaCkcsc = deltaC / (sc);
    let deltaHkhsh = deltaH / (sh);
    let i = deltaLKlsl * deltaLKlsl + deltaCkcsc * deltaCkcsc + deltaHkhsh * deltaHkhsh;
    return i < 0 ? 0 : Math.sqrt(i);
  },
  isSimilarColor(rgbA, rgbB) {
    return this.deltaE(rgbA, rgbB)
  },
  colorMatch(a,b,tolerance = 5) {
    const diff = this.isSimilarColor([a.r, a.g, a.b], [b.r, b.g, b.b])
      //console.log('de', diff)
    return diff < tolerance
  },
  getContrastingColorText(r,g,b){
    if (r*0.299 + g*0.587 + b*0.114 > 186){
      return '#000000'
    } else {
      return '#FFFFFF'
    }
  }
}
export default helpers