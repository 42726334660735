import React from 'react'
import './VideoWindow.scss'

const VideoWindow = ({onCloseHelp, videoURL}) => {
  return (
    <div className="video-window">
      <div className="btn-exit" onClick={onCloseHelp}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11" stroke="#FFFFFF" strokeWidth="2"/>
          <path d="M18.4617 6.83999L17.1601 5.53845L12.0001 10.6985L6.84011 5.53845L5.53857 6.83999L10.6986 12L5.53857 17.16L6.84011 18.4615L12.0001 13.3015L17.1601 18.4615L18.4617 17.16L13.3017 12L18.4617 6.83999Z" fill="#FFFFFF"/>
        </svg>
      </div>
      <iframe title="Tutorial decorador virtual" width="420" height="315" src={`//youtube.com/embed/${videoURL}?autoplay=1`} frameBorder="0" allowFullScreen></iframe>
    </div>
  )
}

export default VideoWindow
