import React from 'react'
import Stroke from '../Stroke/Stroke'
import './Tool.scss'
import useCustomTranslation from '../../hook/useCustomTranslation';

const Tool = ({tool, selected, onClick, children, shape, width, onChangeStroke, applyPolygonSelection, disabled, disabledExpanded, zoom, onZoom}) => {
  const { translate } = useCustomTranslation();

  const getToolData = () => {
    switch (tool) {
      case 'Freepaint':
        return (
          <>
            <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            < path d="M6.66574 14.668C4.45271 14.668 2.6663 16.4544 2.6663 18.6674C2.6663 20.4138 1.11984 21.3337 0 21.3337C1.2265 22.9601 3.31954 24 5.33259 24C8.27885 24 10.6652 21.6137 10.6652 18.6674C10.6652 16.4544 8.87877 14.668 6.66574 14.668ZM24.9432 2.17636L23.1568 0.389946C22.6369 -0.129982 21.797 -0.129982 21.277 0.389946L9.33204 12.335L12.9982 16.0011L24.9432 4.0561C25.4631 3.53618 25.4631 2.69629 24.9432 2.17636Z" fill="#828282"/>
            </svg>
            <p>{translate('rightButton.brush')}</p>
          </>

        )
      case 'Paint':
        return (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.85239 4.93701L23.9526 21.0372L20.9901 23.9998L4.88984 7.89956L7.85239 4.93701ZM10.3781 9.45876L7.85239 6.93304L6.88587 7.89956L9.41159 10.4253L10.3781 9.45876Z" fill="#828282"/>
              <path d="M2.97605 1.93163L4.74121 3.69678L3.7432 4.69478L1.97804 2.92963L2.97605 1.93163Z" fill="#828282"/>
              <path d="M3.96977 11.856L4.96777 12.854L3.16935 14.6524L2.17135 13.6544L3.96977 11.856Z" fill="#828282"/>
              <path d="M13.6963 2.12699L14.6943 3.125L12.9071 4.91226L11.9091 3.91426L13.6963 2.12699Z" fill="#828282"/>
              <path d="M0.0587211 7.68213H2.60352V9.09358H0.0587211H0.0464859V7.68213H0.0587211Z" fill="#828282"/>
              <path d="M9.13867 0.00084375V2.54564H7.72722V0.00084375V0L9.13867 0.00084375Z" fill="#828282"/>
            </svg>
            <p>{translate('rightButton.magicBrush')}</p>
          </>

        )
      case 'Polygon':
        return (
          <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6667 17.3333L13.3333 12V7.76C14.88 7.2 16 5.73333 16 4C16 1.78667 14.2133 0 12 0C9.78667 0 8 1.78667 8 4C8 5.73333 9.12 7.2 10.6667 7.76V12L5.33333 17.3333H0V24H6.66667V19.9333L12 14.3333L17.3333 19.9333V24H24V17.3333H18.6667Z" fill="#828282"/>
            </svg>
            <p>{translate('rightButton.freeSelection')}</p>
          </>

        )
        case 'Undo':
          return (
            <>
              <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.6 4.8V0L3.6 6L9.6 12V7.2C13.572 7.2 16.8 10.428 16.8 14.4C16.8 18.372 13.572 21.6 9.6 21.6C5.628 21.6 2.4 18.372 2.4 14.4H0C0 19.704 4.296 24 9.6 24C14.904 24 19.2 19.704 19.2 14.4C19.2 9.096 14.904 4.8 9.6 4.8Z" fill="#828282"/>
              </svg>
              <p>{translate('rightButton.undo')}</p>
            </>
  
          )
        case 'Eraser':
        return (
          <>
            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 8.66667L15.3334 0L0 15.3334L6.33047 21.6638H20.4853V20.2496H12.4171L24 8.66667ZM6.91627 20.2496L2.00002 15.3334L8.1667 9.16669L14.8334 15.8333L10.4171 20.2496H6.91627Z" fill="#828282"/>
            </svg>
            <p>{translate('rightButton.delete')}</p>
          </>

        )
        case 'Prepaint':
          return (
            <>
              <svg width="31" height="33" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.8 11.4V10.2C23.8 9.50001 23.3 9 22.6 9H8.20001C7.50001 9 7 9.50001 7 10.2V15C7 15.7 7.50001 16.2 8.20001 16.2H22.6C23.3 16.2 23.8 15.7 23.8 15V13.8H25V18.6H13V31.8C13 32.5 13.5 33 14.2 33H16.6C17.3 33 17.8 32.5 17.8 31.8V21H27.4V11.4H23.8ZM22.1 13.7H8.39999V10.4H22.1V13.7Z" fill="#828282"/>
                <path d="M6.60037 1.30011L7.20038 0.100098L7.90039 1.30011L9.00037 1.90009L7.90039 2.50009L7.20038 3.7001L6.60037 2.50009L5.40039 1.90009L6.60037 1.30011Z" fill="#828282"/>
                <path d="M23.6003 1.30002L24.2003 0L24.9003 1.30002L26.2003 2L24.9003 2.70001L24.2003 3.89999L23.6003 2.70001L22.3003 2L23.6003 1.30002Z" fill="#828282"/>
                <path d="M14.6001 2.8999L15.2001 1.8999L15.7001 2.8999L16.7001 3.49991L15.7001 3.99991L15.2001 5.09991L14.6001 3.99991L13.6001 3.49991L14.6001 2.8999Z" fill="#828282"/>
                <path d="M28 7L28.6 6L29.1 7L30.1 7.60001L29.1 8.10001L28.6 9.20001L28 8.10001L27 7.60001L28 7Z" fill="#828282"/>
                <path d="M1.10001 7.10001L1.70001 6L2.30002 7.10001L3.40002 7.70001L2.30002 8.30002L1.70001 9.39999L1.10001 8.30002L0 7.70001L1.10001 7.10001Z" fill="#828282"/>
              </svg>
              <p>{translate('rightButton.whiten')}</p>
            </>
  
          )
        
        case 'Zoom':
          return (
            <div className="tool-split">
              <span onClick={() => onZoom(true)} className={zoom > 1.6 ? 'disabled' : ''}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.1527 14.6814H16.0686L15.6844 14.3211C17.0292 12.7995 17.8388 10.8242 17.8388 8.67539C17.8388 3.8839 13.8456 0 8.91938 0C3.99314 0 0 3.8839 0 8.67539C0 13.4669 3.99314 17.3508 8.91938 17.3508C11.1286 17.3508 13.1595 16.5633 14.7238 15.2553L15.0943 15.629V16.6834L21.9554 23.3435L24 21.3548L17.1527 14.6814ZM8.91938 14.6814C5.50257 14.6814 2.74443 11.9987 2.74443 8.67539C2.74443 5.35205 5.50257 2.66935 8.91938 2.66935C12.3362 2.66935 15.0943 5.35205 15.0943 8.67539C15.0943 11.9987 12.3362 14.6814 8.91938 14.6814Z" fill="#828282"/>
                  <path d="M12.3498 9.34289H9.6054V12.0122H8.23319V9.34289H5.48877V8.00822H8.23319V5.33887H9.6054V8.00822H12.3498V9.34289Z" fill="#828282"/>
                </svg>
                <p>{translate('rightButton.zoomIn')}</p>
              </span>

              <span onClick={() => onZoom(false)} className={zoom <= 1 ? 'disabled' : ''}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.1527 15.0943H16.0686L15.6844 14.7238C17.0292 13.1595 17.8388 11.1286 17.8388 8.91938C17.8388 3.99314 13.8456 0 8.91938 0C3.99314 0 0 3.99314 0 8.91938C0 13.8456 3.99314 17.8388 8.91938 17.8388C11.1286 17.8388 13.1595 17.0292 14.7238 15.6844L15.0943 16.0686V17.1527L21.9554 24L24 21.9554L17.1527 15.0943ZM8.91938 15.0943C5.50257 15.0943 2.74443 12.3362 2.74443 8.91938C2.74443 5.50257 5.50257 2.74443 8.91938 2.74443C12.3362 2.74443 15.0943 5.50257 15.0943 8.91938C15.0943 12.3362 12.3362 15.0943 8.91938 15.0943ZM5.48885 8.23328H12.3499V9.60549H5.48885V8.23328Z" fill="#828282"/>
                </svg>
                <p>{translate('rightButton.zoomOut')}</p>
              </span>
            </div>

        )

        case 'Pan':
          return (
            <>
              <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22 5.5V20C22 22.2 20.2 24 18 24H10.7C9.62 24 8.6 23.57 7.85 22.81L0 14.83C0 14.83 1.26 13.6 1.3 13.58C1.52 13.39 1.79 13.29 2.09 13.29C2.31 13.29 2.51 13.35 2.69 13.45C2.73 13.46 7 15.91 7 15.91V4C7 3.17 7.67 2.5 8.5 2.5C9.33 2.5 10 3.17 10 4V11H11V1.5C11 0.67 11.67 0 12.5 0C13.33 0 14 0.67 14 1.5V11H15V2.5C15 1.67 15.67 1 16.5 1C17.33 1 18 1.67 18 2.5V11H19V5.5C19 4.67 19.67 4 20.5 4C21.33 4 22 4.67 22 5.5Z" fill="#828282"/>
              </svg>
              <p>{translate('rightButton.move')}</p>
            </>

        )
      default:
        break;
    }
  }

  const outputStrokeData = (shape, dimensions) => {
    onChangeStroke(shape,dimensions)
  }

  const getToolExpandedData = () => {
    switch (tool) {
      case 'Freepaint':
        return (
          <div className="tool-expanded">
            <div className="tool-expanded-row">
              <Stroke number={1} shape={'circle'} dimensions={13} selected={width === 13 && shape === 'circle'} onClick={() => outputStrokeData('circle', 13)} />
              <Stroke number={2} shape={'circle'} dimensions={17} selected={width === 17 && shape === 'circle'} onClick={() => outputStrokeData('circle', 17)} />
              <Stroke number={3} shape={'circle'} dimensions={20} selected={width === 20 && shape === 'circle'} onClick={() => outputStrokeData('circle', 20)} />
              <Stroke number={4} shape={'circle'} dimensions={22} selected={width === 22 && shape === 'circle'} onClick={() => outputStrokeData('circle', 22)} />
            </div>
            <div className="tool-expanded-row">
              <Stroke number={5} shape={'circle'} dimensions={24} selected={width === 24 && shape === 'circle'} onClick={() => outputStrokeData('circle', 24)} />
              <Stroke number={6} shape={'circle'} dimensions={27} selected={width === 27 && shape === 'circle'} onClick={() => outputStrokeData('circle', 27)} />
              <Stroke number={7} shape={'circle'} dimensions={31} selected={width === 31 && shape === 'circle'} onClick={() => outputStrokeData('circle', 31)} />
            </div>
            <div className="tool-expanded-row">
              <Stroke number={1} shape={'square'} dimensions={13} selected={width === 13 && shape === 'square'} onClick={() => outputStrokeData('square', 13)} />
              <Stroke number={2} shape={'square'} dimensions={17} selected={width === 17 && shape === 'square'} onClick={() => outputStrokeData('square', 17)} />
              <Stroke number={3} shape={'square'} dimensions={20} selected={width === 20 && shape === 'square'} onClick={() => outputStrokeData('square', 20)} />
              <Stroke number={4} shape={'square'} dimensions={22} selected={width === 22 && shape === 'square'} onClick={() => outputStrokeData('square', 22)} />
            </div>
            <div className="tool-expanded-row">
              <Stroke number={5} shape={'square'} dimensions={24} selected={width === 24 && shape === 'square'} onClick={() => outputStrokeData('square', 24)} />
              <Stroke number={6} shape={'square'} dimensions={27} selected={width === 27 && shape === 'square'} onClick={() => outputStrokeData('square', 27)} />
              <Stroke number={7} shape={'square'} dimensions={31} selected={width === 31 && shape === 'square'} onClick={() => outputStrokeData('square', 31)} />
            </div>
          </div>
        )

        case 'Eraser':
          return (
            <div className="tool-expanded">
              <div className="tool-expanded-row">
                <Stroke number={1} shape={'circle'} dimensions={13} selected={width === 13 && shape === 'circle'} onClick={() => outputStrokeData('circle', 13)} />
                <Stroke number={2} shape={'circle'} dimensions={17} selected={width === 17 && shape === 'circle'} onClick={() => outputStrokeData('circle', 17)} />
                <Stroke number={3} shape={'circle'} dimensions={20} selected={width === 20 && shape === 'circle'} onClick={() => outputStrokeData('circle', 20)} />
                <Stroke number={4} shape={'circle'} dimensions={22} selected={width === 22 && shape === 'circle'} onClick={() => outputStrokeData('circle', 22)} />
              </div>
              <div className="tool-expanded-row">
                <Stroke number={5} shape={'circle'} dimensions={24} selected={width === 24 && shape === 'circle'} onClick={() => outputStrokeData('circle', 24)} />
                <Stroke number={6} shape={'circle'} dimensions={27} selected={width === 27 && shape === 'circle'} onClick={() => outputStrokeData('circle', 27)} />
                <Stroke number={7} shape={'circle'} dimensions={31} selected={width === 31 && shape === 'circle'} onClick={() => outputStrokeData('circle', 31)} />
              </div>
              <div className="tool-expanded-row">
                <Stroke number={1} shape={'square'} dimensions={13} selected={width === 13 && shape === 'square'} onClick={() => outputStrokeData('square', 13)} />
                <Stroke number={2} shape={'square'} dimensions={17} selected={width === 17 && shape === 'square'} onClick={() => outputStrokeData('square', 17)} />
                <Stroke number={3} shape={'square'} dimensions={20} selected={width === 20 && shape === 'square'} onClick={() => outputStrokeData('square', 20)} />
                <Stroke number={4} shape={'square'} dimensions={22} selected={width === 22 && shape === 'square'} onClick={() => outputStrokeData('square', 22)} />
              </div>
              <div className="tool-expanded-row">
                <Stroke number={5} shape={'square'} dimensions={24} selected={width === 24 && shape === 'square'} onClick={() => outputStrokeData('square', 24)} />
                <Stroke number={6} shape={'square'} dimensions={27} selected={width === 27 && shape === 'square'} onClick={() => outputStrokeData('square', 27)} />
                <Stroke number={7} shape={'square'} dimensions={31} selected={width === 31 && shape === 'square'} onClick={() => outputStrokeData('square', 31)} />
              </div>
            </div>
          )

        case 'Polygon':
          return (
            <div className="tool-expanded">
              <div className="tool-expanded-split">
                <div className={`tool-expanded-action ${disabledExpanded ? 'disabled-expanded' : ''}`} onClick={() => applyPolygonSelection(false)}>
                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d)">
                    <circle cx="13.5" cy="12.5" r="9.5" fill="white"/>
                    <circle cx="13.5" cy="12.5" r="9" stroke="black"/>
                    </g>
                    <defs>
                    <filter id="filter0_d" x="0" y="0" width="27" height="27" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                    <feOffset dy="1"/>
                    <feGaussianBlur stdDeviation="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    </defs>
                  </svg>
                  <span>{translate('rightButton.freeSelectionDelete')}</span>
                </div>
                <div className={`tool-expanded-action ${disabledExpanded ? 'disabled-expanded' : ''}`} onClick={() => applyPolygonSelection(true)}>
                  <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.7 2.1V1.05C14.7 0.4725 14.2275 0 13.65 0H1.05C0.4725 0 0 0.4725 0 1.05V5.25C0 5.8275 0.4725 6.3 1.05 6.3H13.65C14.2275 6.3 14.7 5.8275 14.7 5.25V4.2H15.75V8.4H5.25V19.95C5.25 20.5275 5.7225 21 6.3 21H8.4C8.9775 21 9.45 20.5275 9.45 19.95V10.5H17.85V2.1H14.7Z" fill="black"/>
                  </svg>
                  <span>{translate('rightButton.freeSelectionPaint')}</span>
                </div>
              </div>
            </div>
          )

      default:
        break;
    }
  }

  return (
    <div className={`tool ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}>
      <div className="tool-icon" onClick={onClick}>
        {getToolData()}
      </div>
      { selected && !disabled &&
        <>
          {getToolExpandedData()}
        </>
      }
    </div>
  )
}

export default Tool
