import React from 'react'
import './ColorCard.scss'
import helpers from '../../helpers'
import useCustomTranslation from '../../hook/useCustomTranslation';

// const getContrastingColorText = (r,g,b) => {
//   if (r*0.299 + g*0.587 + b*0.114 > 186){
//     return '#000000'
//   } else {
//     return '#FFFFFF'
//   }
// }

const ColorCard = ({selected, color, onClick}) => {
  const { translate } = useCustomTranslation();

  return (
    <div onClick={onClick} className={`color-card ${selected ? 'selected' : ''}`} style={{backgroundColor: color.CodigoHtml ? `rgb(${color.colorR},${color.colorG},${color.colorB})` : 'FFF', color: color.Id ? helpers.getContrastingColorText(color.colorR, color.colorG, color.colorB) : '#000'}}>
      
      {
        color.Id ?
        <>
          <div className="color-name">{color.Nombre} <br />{color.ColorNum}</div>
          <svg className="color-card-pencil" width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 14.2525V18.0025H3.75L14.81 6.9425L11.06 3.1925L0 14.2525ZM17.71 4.0425C18.1 3.6525 18.1 3.0225 17.71 2.6325L15.37 0.2925C14.98 -0.0975 14.35 -0.0975 13.96 0.2925L12.13 2.1225L15.88 5.8725L17.71 4.0425Z" fill={helpers.getContrastingColorText(color.colorR, color.colorG, color.colorB)}/>
          </svg>

        </>
        :
        <div className="color-card-body">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z" fill="#BF0811"/>
          </svg>
          <p className='add-color'>{translate('leftButton.addColor')}</p>
        </div>
      }
    </div>
  )
}

export default ColorCard
