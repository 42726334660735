import React, { useState } from 'react'
import helpers from '../../helpers'
import './SearchSelector.scss'
import useCustomTranslation from '../../hook/useCustomTranslation';

const SearchSelector = ({onCloseSelector, colorList, onClickColor, colors, selectedColorIndex}) => {
  const { translate } = useCustomTranslation();

  const [searchQuery, setSearchQuery] = useState('')
  const [htmlQuery, setHtmlQuery] = useState('')

  const getResultColors = () => {
    const primaryColors = colorList.filter(color => color.EsPrincipal)
    const secondaryColors = colorList.filter(color => !color.EsPrincipal)
    const filteredColors = secondaryColors.filter(color => {
      return color.Nombre.toLowerCase().includes(searchQuery.toLowerCase()) || 
             color.ColorNum.toLowerCase().includes(searchQuery.toLowerCase()) ||
             primaryColors.find(pColor => pColor.Id === color.GroupNum).Nombre.toLowerCase().includes(searchQuery.toLowerCase())
    })
    return filteredColors
  }

  return (
    <div className="search-selector-wrapper">
      
      <div className="search-selector">
        
        <div className="search-wrapper">
        <p>{translate("colorTrendsHead.searchColorTitle")}</p>
          <label className="search-box-label"  htmlFor="searchInput">
            <input id="searchInput" type="text" className="search-box" placeholder={translate("colorTrendsHead.searchColorPlaceHolder")} value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.1527 14.6814H16.0686L15.6844 14.3211C17.0292 12.7995 17.8388 10.8242 17.8388 8.67539C17.8388 3.8839 13.8456 0 8.91938 0C3.99314 0 0 3.8839 0 8.67539C0 13.4669 3.99314 17.3508 8.91938 17.3508C11.1286 17.3508 13.1595 16.5633 14.7238 15.2553L15.0943 15.629V16.6834L21.9554 23.3435L24 21.3548L17.1527 14.6814ZM8.91938 14.6814C5.50257 14.6814 2.74443 11.9987 2.74443 8.67539C2.74443 5.35205 5.50257 2.66935 8.91938 2.66935C12.3362 2.66935 15.0943 5.35205 15.0943 8.67539C15.0943 11.9987 12.3362 14.6814 8.91938 14.6814Z" fill="#828282"/>
            </svg>
          </label>
        </div>
        <div className="search-color-container" onClick={getResultColors}>
          {
            searchQuery.length > 1 ?
            getResultColors().map(color => (
              <span className="color-single" style={{background: `rgb(${color.colorR},${color.colorG},${color.colorB})`}} onClick={() => onClickColor(color)}>
                <span className="color-single-description" style={{color: helpers.getContrastingColorText(color.colorR, color.colorG, color.colorB)}}>
                  <div>{color.ColorNum}</div>
                  <div>{color.Nombre}</div>
                </span>
              </span>
            ))
            :
            <span>{translate("colorTrendsHead.searchColorEmpty")}</span>
          }
        </div>
      </div>
      <div className="btn-apply-wrapper">
          <button className="btn-apply-colors" onClick={onCloseSelector}>
           {translate("colorTrendsHead.trendsButton")}
          </button>
        </div>
    </div>
  )
}

export default SearchSelector
