import React from 'react'
import i18n from '../../i18n/config';
import './Header.scss'
// import UseGetCountry from '../../hook/UseGetCountry';

const Header = ({onClickSave,onClickShare,onClickVideo,onClickHelp,isHelpActive}) => {
  const [checked, setChecked] = React.useState(true);

  // const {country} = UseGetCountry()

  // React.useEffect(() => {
  //   if(country === 'BR'){
  //     setChecked(false);
  //     i18n.changeLanguage('pr');
  //   }

  //   if(country === 'AR'){
  //     setChecked(true);
  //     i18n.changeLanguage('es');
  //   }
  
  // }, [country])
  
  const handle =(e)=>{
    const isChecked = e.target.checked;
    setChecked(isChecked);
    i18n.changeLanguage(isChecked ? 'es' : 'pr');
  }
  return (
    <div className="header">
     <label className="switch">
       <input checked={checked} type="checkbox" id="togBtn" onClick={(e)=> handle(e)}/>
         <div className="slider round">
           <span className="on">AR</span>
           <span className="off">BR</span>
          </div>
      </label>
      <span className="icon-container" onClick={onClickSave}>
        <svg className="icon-save" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.9522 7.056C23.9044 6.288 23.5697 5.616 22.6135 4.608C22.1355 4.128 21.1793 3.168 20.7968 2.784C20.4143 2.448 20.1275 2.112 19.8406 1.824C19.5538 1.536 19.3147 1.296 19.0279 1.008C18.2151 0.24 17.6892 0 16.8765 0H2.62948C1.14741 0 0 1.152 0 2.64V21.36C0 22.8 1.14741 24 2.62948 24H21.3705C22.8048 24 24 22.848 24 21.36V7.104L23.9522 7.056ZM7.84064 2.496H14.6295V6.336H7.84064V2.496ZM21.4661 21.36C21.4661 21.408 21.4183 21.456 21.3705 21.456H2.62948C2.58167 21.456 2.53386 21.408 2.53386 21.36V2.64C2.53386 2.592 2.58167 2.544 2.62948 2.544H5.40239V7.008C5.40239 8.064 6.21514 8.928 7.26693 8.928H15.2988C16.3506 8.928 17.1633 8.064 17.1633 7.008V2.688C17.2112 2.736 17.259 2.784 17.3068 2.832C17.5458 3.072 17.7849 3.312 18.0717 3.6C18.3586 3.888 18.6932 4.224 19.0279 4.56C19.4104 4.944 20.3665 5.904 20.7968 6.336C21.3705 6.912 21.4183 7.104 21.4183 7.104V21.36H21.4661Z" fill="white"/>
          <path d="M11.5 19C13.433 19 15 17.433 15 15.5C15 13.567 13.433 12 11.5 12C9.567 12 8 13.567 8 15.5C8 17.433 9.567 19 11.5 19Z" fill="white"/>
        </svg>
      </span>
      <span className="icon-container" onClick={onClickShare}>
        <svg className="icon-share" width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.0723 16.9639C17.1566 16.9639 16.3373 17.3253 15.7108 17.8916L7.12048 12.8916C7.18072 12.6145 7.22892 12.3373 7.22892 12.0482C7.22892 11.759 7.18072 11.4819 7.12048 11.2048L15.6145 6.25301C16.2651 6.85542 17.1205 7.22892 18.0723 7.22892C20.0723 7.22892 21.6867 5.61446 21.6867 3.61446C21.6867 1.61446 20.0723 0 18.0723 0C16.0723 0 14.4578 1.61446 14.4578 3.61446C14.4578 3.90361 14.506 4.18072 14.5663 4.45783L6.07229 9.40964C5.42169 8.80723 4.56626 8.43373 3.61446 8.43373C1.61446 8.43373 0 10.0482 0 12.0482C0 14.0482 1.61446 15.6627 3.61446 15.6627C4.56626 15.6627 5.42169 15.2892 6.07229 14.6867L14.6506 19.6988C14.5904 19.9518 14.5542 20.2169 14.5542 20.4819C14.5542 22.4217 16.1325 24 18.0723 24C20.012 24 21.5904 22.4217 21.5904 20.4819C21.5904 18.5422 20.012 16.9639 18.0723 16.9639Z" fill="white"/>
        </svg>
      </span>
      <span className={`icon-container ${isHelpActive ? 'active' : ''}`} onClick={onClickHelp}>
        <svg className="icon-help" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.8 19.2H13.2V16.8H10.8V19.2ZM12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.708 21.6 2.4 17.292 2.4 12C2.4 6.708 6.708 2.4 12 2.4C17.292 2.4 21.6 6.708 21.6 12C21.6 17.292 17.292 21.6 12 21.6ZM12 4.8C9.348 4.8 7.2 6.948 7.2 9.6H9.6C9.6 8.28 10.68 7.2 12 7.2C13.32 7.2 14.4 8.28 14.4 9.6C14.4 12 10.8 11.7 10.8 15.6H13.2C13.2 12.9 16.8 12.6 16.8 9.6C16.8 6.948 14.652 4.8 12 4.8Z" fill="white"/>
        </svg>
      </span>
    </div>
  )
}

export default Header
