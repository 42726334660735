import { useTranslation } from "react-i18next";

const useCustomTranslation = () => {
  const { t } = useTranslation();

  const translate = (key) => {
    return t(key);
  };

  return { translate };
};

export default useCustomTranslation;
