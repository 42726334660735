/* eslint-disable no-param-reassign */
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const instance = axios.create({ baseURL: apiUrl });

const removeAspxAutoDetectCookieSupport = (url) => {
  const urlObj = new URL(url);
  urlObj.searchParams.delete('AspxAutoDetectCookieSupport');
  return urlObj.toString();
};

instance.interceptors.request.use(
  async (config) => {
    // config.url = removeAspxAutoDetectCookieSupport(config.url);
    config.headers['Content-Type'] = 'application/json';

    return config;
  },
  (error) => Promise.reject(error)
);

// instance.interceptors.response.use(
// 	  (response) => response,
// 	  async (error) => {
//     if (error.response.status === 401) {
//       window.localStorage.removeItem('access_token');
//       window.localStorage.removeItem('ColorShop');
//       window.location.reload();

//     }
//   }
// );

export default instance;
