import React, { useState } from 'react'
import ColorsSelector from '../ColorsSelector/ColorsSelector'
import SearchSelector from '../SearchSelector/SearchSelector'
import TendenciasSelector from '../TendenciasSelector/TendenciasSelector'
import './SelectorWindow.scss'
import useCustomTranslation from '../../hook/useCustomTranslation'

const SelectorWindow = ({tendencias, colors, onClickColor, closeWindow, currentColors, selectedColorIndex}) => {

  const [activeTab, setActiveTab] = useState('tendencias')
  const [isSelectingTendencia, setIsSelectingTendencia] = useState(false)

  const { translate } = useCustomTranslation();


  const getActiveWindow = () => {
    switch (activeTab) {
      case 'tendencias':
        if (!tendencias) {
          return <span>Cargando tendencias</span>
        }
        return <TendenciasSelector onCloseSelector={closeSelector} onEnterTendencia={(bool) => setIsSelectingTendencia(bool)} isSelectingTendencia={isSelectingTendencia} onClickColor={(color) => onClickColor(color)} colors={currentColors} tendencias={tendencias} selectedColorIndex={selectedColorIndex} />  

        case 'colors':
          if (!colors) {
            return <span>Cargando colores</span>
          }
          return <ColorsSelector onCloseSelector={closeSelector} onClickColor={(color) => onClickColor(color)} colors={currentColors} colorList={colors} selectedColorIndex={selectedColorIndex} /> 

        case 'search':
          if (!colors) {
            return <span>Cargando colores</span>
          }
          return <SearchSelector onCloseSelector={closeSelector} onClickColor={(color) => onClickColor(color)} colors={currentColors} colorList={colors} selectedColorIndex={selectedColorIndex} /> 
      

      default:
        break;
    }
  }

  const changeTab = (tab) => {
    if (activeTab !== tab) {

      if (tab !== tendencias) {
        setIsSelectingTendencia(false)
      }
      setActiveTab(tab)

    }
  }

  const closeSelector = (bool) => {

    if (isSelectingTendencia && activeTab === 'tendencias' && !bool) {
      setIsSelectingTendencia(false)
    } else {
      closeWindow()
    }
  } 

  return (
    <div className="selector-window">
      <div className="btn-exit" onClick={() => closeSelector()}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="12" cy="12" r="11" stroke="#828282" strokeWidth="2"/>
          <path d="M18.4617 6.83999L17.1601 5.53845L12.0001 10.6985L6.84011 5.53845L5.53857 6.83999L10.6986 12L5.53857 17.16L6.84011 18.4615L12.0001 13.3015L17.1601 18.4615L18.4617 17.16L13.3017 12L18.4617 6.83999Z" fill="#828282"/>
        </svg>
      </div>
      <div className="selector-nav">
        <div className={`selector-nav-option ${activeTab === 'tendencias' ? 'active' : ''}`} onClick={() => changeTab('tendencias')}>{translate("colorTrendsHead.trends")}</div>
        <div className={`selector-nav-option ${activeTab === 'colors' ? 'active' : ''}`} onClick={() => changeTab('colors')}>{translate("colorTrendsHead.colorWheel")}</div>
        <div className={`selector-nav-option ${activeTab === 'search' ? 'active' : ''}`} onClick={() => changeTab('search')}>{translate("colorTrendsHead.searchColor")}</div>
      </div>
      {getActiveWindow()}
      {/* { 
        tendencias ?
        <>
          {
            tendencias.map(tendencia => {
              return (
                <div className="tendencia">
                  <h3>{tendencia.Titulo}</h3>
                  {tendencia.Paletas.map(paleta => (
                    <div className="tendencia-container">
                      <span>{paleta.Descripcion}</span>
                      <div className="tendencia-color-container">
                        {paleta.Colores.map(color => (
                          <span style={{backgroundColor: `#${color.CodigoHtml}`, width: '10px', height: '10px'}}>&nbsp;</span>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              )
            })
          }
        </> 
        : <h2>Loading</h2>
      } */}
    </div>
  )
}

export default SelectorWindow
