import React from 'react'
import './SpinnerProcess.scss'

const SpinnerProcess = () => {
  return (
    <div className="spinner-process-container">
       <div className="loader"></div>
    </div>
  )
}

export default SpinnerProcess
