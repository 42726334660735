
export let colorListTemp = [
	{
		Id: 1,
		CodigoHtml: "A4547D",
		Nombre: "Rojo-Violeta",
		ColorNum: "P0001",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 164,
		colorG: 84,
		colorB: 125,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 2,
		CodigoHtml: "d84347",
		Nombre: "Rojo",
		ColorNum: "P0002",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 216,
		colorG: 67,
		colorB: 71,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 3,
		CodigoHtml: "e77443",
		Nombre: "Naranja",
		ColorNum: "P0003",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 231,
		colorG: 116,
		colorB: 67,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 4,
		CodigoHtml: "ffba4f",
		Nombre: "Amarillo-Naranja",
		ColorNum: "P0004",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 255,
		colorG: 186,
		colorB: 79,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 5,
		CodigoHtml: "ffe142",
		Nombre: "Amarillo",
		ColorNum: "P0005",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 255,
		colorG: 225,
		colorB: 66,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 6,
		CodigoHtml: "d1df53",
		Nombre: "Amarillo-Verde",
		ColorNum: "P0006",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 209,
		colorG: 223,
		colorB: 83,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 7,
		CodigoHtml: "8abf5e",
		Nombre: "Verde",
		ColorNum: "P0007",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 138,
		colorG: 191,
		colorB: 94,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8,
		CodigoHtml: "5ba8b5",
		Nombre: "Azul-Verde",
		ColorNum: "P0008",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 91,
		colorG: 168,
		colorB: 181,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 9,
		CodigoHtml: "478bd5",
		Nombre: "Azul",
		ColorNum: "P0009",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 71,
		colorG: 139,
		colorB: 213,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 10,
		CodigoHtml: "876cb6",
		Nombre: "Violeta",
		ColorNum: "P0010",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 135,
		colorG: 108,
		colorB: 182,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 11,
		CodigoHtml: "be946f",
		Nombre: "Neutros Cálidos",
		ColorNum: "P0011",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 190,
		colorG: 148,
		colorB: 111,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 12,
		CodigoHtml: "adadad",
		Nombre: "Neutros Fríos",
		ColorNum: "P0012",
		EsPrincipal: true,
		GroupNum: 0,
		SubGroupNum: 0,
		colorR: 173,
		colorG: 173,
		colorB: 173,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 5704,
		CodigoHtml: "F6DDE0",
		Nombre: "Tutu",
		ColorNum: "1139",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 1,
		colorR: 246,
		colorG: 221,
		colorB: 224,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [1, 51],
		Triadicos: [1, 67, 29],
		Adjacentes: [4, 1, 83],
	},
	{
		Id: 5705,
		CodigoHtml: "F5D2D7",
		Nombre: "First Daughter",
		ColorNum: "1140",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 1,
		colorR: 245,
		colorG: 210,
		colorB: 215,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [1, 51],
		Triadicos: [1, 67, 29],
		Adjacentes: [4, 1, 83],
	},
	{
		Id: 5706,
		CodigoHtml: "F3C4CD",
		Nombre: "Deco Pink",
		ColorNum: "1141",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 1,
		colorR: 243,
		colorG: 196,
		colorB: 205,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [1, 51],
		Triadicos: [1, 67, 29],
		Adjacentes: [4, 1, 83],
	},
	{
		Id: 5707,
		CodigoHtml: "E2A0AE",
		Nombre: "Vintage Victorian",
		ColorNum: "1142",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 1,
		colorR: 226,
		colorG: 160,
		colorB: 174,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [1, 51],
		Triadicos: [1, 67, 29],
		Adjacentes: [4, 1, 83],
	},
	{
		Id: 5708,
		CodigoHtml: "D78C9B",
		Nombre: "Rambling Rose",
		ColorNum: "1143",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 1,
		colorR: 215,
		colorG: 140,
		colorB: 155,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [1, 51],
		Triadicos: [1, 67, 29],
		Adjacentes: [4, 1, 83],
	},
	{
		Id: 5709,
		CodigoHtml: "BE6471",
		Nombre: "Temptress",
		ColorNum: "1144",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 1,
		colorR: 190,
		colorG: 100,
		colorB: 113,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [1, 51],
		Triadicos: [1, 67, 29],
		Adjacentes: [4, 1, 83],
	},
	{
		Id: 5710,
		CodigoHtml: "A63B49",
		Nombre: "Romeo O Romeo",
		ColorNum: "1145",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 1,
		colorR: 166,
		colorG: 59,
		colorB: 73,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [1, 51],
		Triadicos: [1, 67, 29],
		Adjacentes: [4, 1, 83],
	},
	{
		Id: 5711,
		CodigoHtml: "F5E8EA",
		Nombre: "Morning Shine",
		ColorNum: "1146",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 2,
		colorR: 245,
		colorG: 232,
		colorB: 234,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [2, 52],
		Triadicos: [2, 68, 30],
		Adjacentes: [5, 2, 84],
	},
	{
		Id: 5712,
		CodigoHtml: "F4DBE3",
		Nombre: "Columbine",
		ColorNum: "1147",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 2,
		colorR: 244,
		colorG: 219,
		colorB: 227,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [2, 52],
		Triadicos: [2, 68, 30],
		Adjacentes: [5, 2, 84],
	},
	{
		Id: 5713,
		CodigoHtml: "EECCDB",
		Nombre: "Persian Delight",
		ColorNum: "1148",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 2,
		colorR: 238,
		colorG: 204,
		colorB: 219,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [2, 52],
		Triadicos: [2, 68, 30],
		Adjacentes: [5, 2, 84],
	},
	{
		Id: 5714,
		CodigoHtml: "ECC1D5",
		Nombre: "Parrot Tulip",
		ColorNum: "1149",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 2,
		colorR: 236,
		colorG: 193,
		colorB: 213,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [2, 52],
		Triadicos: [2, 68, 30],
		Adjacentes: [5, 2, 84],
	},
	{
		Id: 5715,
		CodigoHtml: "DBA9C4",
		Nombre: "Pink Beauty",
		ColorNum: "1150",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 2,
		colorR: 219,
		colorG: 169,
		colorB: 196,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [2, 52],
		Triadicos: [2, 68, 30],
		Adjacentes: [5, 2, 84],
	},
	{
		Id: 5716,
		CodigoHtml: "B77494",
		Nombre: "Fuscia Fizz",
		ColorNum: "1151",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 2,
		colorR: 183,
		colorG: 116,
		colorB: 148,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [2, 52],
		Triadicos: [2, 68, 30],
		Adjacentes: [5, 2, 84],
	},
	{
		Id: 5717,
		CodigoHtml: "894565",
		Nombre: "Berry Patch",
		ColorNum: "1152",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 2,
		colorR: 137,
		colorG: 69,
		colorB: 101,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [2, 52],
		Triadicos: [2, 68, 30],
		Adjacentes: [5, 2, 84],
	},
	{
		Id: 5718,
		CodigoHtml: "F5ECEC",
		Nombre: "Vienna Dawn",
		ColorNum: "1153",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 3,
		colorR: 245,
		colorG: 236,
		colorB: 236,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [3, 53],
		Triadicos: [3, 69, 31],
		Adjacentes: [6, 3, 85],
	},
	{
		Id: 5719,
		CodigoHtml: "F4E1EA",
		Nombre: "Viola",
		ColorNum: "1154",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 3,
		colorR: 244,
		colorG: 225,
		colorB: 234,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [3, 53],
		Triadicos: [3, 69, 31],
		Adjacentes: [6, 3, 85],
	},
	{
		Id: 5720,
		CodigoHtml: "F3CDE5",
		Nombre: "Little Smile",
		ColorNum: "1155",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 3,
		colorR: 243,
		colorG: 205,
		colorB: 229,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [3, 53],
		Triadicos: [3, 69, 31],
		Adjacentes: [6, 3, 85],
	},
	{
		Id: 5721,
		CodigoHtml: "F0BCDF",
		Nombre: "Pink Heath",
		ColorNum: "1156",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 3,
		colorR: 240,
		colorG: 188,
		colorB: 223,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [3, 53],
		Triadicos: [3, 69, 31],
		Adjacentes: [6, 3, 85],
	},
	{
		Id: 5722,
		CodigoHtml: "E59DCB",
		Nombre: "Blossoms in Spring",
		ColorNum: "1157",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 3,
		colorR: 229,
		colorG: 157,
		colorB: 203,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [3, 53],
		Triadicos: [3, 69, 31],
		Adjacentes: [6, 3, 85],
	},
	{
		Id: 5723,
		CodigoHtml: "D772AF",
		Nombre: "Purple Hollyhock",
		ColorNum: "1158",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 3,
		colorR: 215,
		colorG: 114,
		colorB: 175,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [3, 53],
		Triadicos: [3, 69, 31],
		Adjacentes: [6, 3, 85],
	},
	{
		Id: 5724,
		CodigoHtml: "A33D65",
		Nombre: "Bridesmaid",
		ColorNum: "1159",
		EsPrincipal: false,
		GroupNum: 1,
		SubGroupNum: 3,
		colorR: 163,
		colorG: 61,
		colorB: 101,
		MonoCromaticos: [1, 2, 3],
		Complementarios: [3, 53],
		Triadicos: [3, 69, 31],
		Adjacentes: [6, 3, 85],
	},
	{
		Id: 5725,
		CodigoHtml: "F8ECE6",
		Nombre: "Baby Blossom",
		ColorNum: "1076",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 4,
		colorR: 248,
		colorG: 236,
		colorB: 230,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [4, 57],
		Triadicos: [4, 44, 72],
		Adjacentes: [24, 4, 1],
	},
	{
		Id: 5726,
		CodigoHtml: "FBE2DE",
		Nombre: "Lady Anne",
		ColorNum: "1077",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 4,
		colorR: 251,
		colorG: 226,
		colorB: 222,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [4, 57],
		Triadicos: [4, 44, 72],
		Adjacentes: [24, 4, 1],
	},
	{
		Id: 5727,
		CodigoHtml: "FFCBC9",
		Nombre: "Aromatic Breeze",
		ColorNum: "1078",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 4,
		colorR: 255,
		colorG: 203,
		colorB: 201,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [4, 57],
		Triadicos: [4, 44, 72],
		Adjacentes: [24, 4, 1],
	},
	{
		Id: 5728,
		CodigoHtml: "FFB1B0",
		Nombre: "Wildflower Bouquet",
		ColorNum: "1079",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 4,
		colorR: 255,
		colorG: 177,
		colorB: 176,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [4, 57],
		Triadicos: [4, 44, 72],
		Adjacentes: [24, 4, 1],
	},
	{
		Id: 5729,
		CodigoHtml: "F89596",
		Nombre: "Cherished One",
		ColorNum: "1080",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 4,
		colorR: 248,
		colorG: 149,
		colorB: 150,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [4, 57],
		Triadicos: [4, 44, 72],
		Adjacentes: [24, 4, 1],
	},
	{
		Id: 5730,
		CodigoHtml: "DE4F46",
		Nombre: "Endless Possibilities",
		ColorNum: "1081",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 4,
		colorR: 222,
		colorG: 79,
		colorB: 70,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [4, 57],
		Triadicos: [4, 44, 72],
		Adjacentes: [24, 4, 1],
	},
	{
		Id: 5731,
		CodigoHtml: "C24044",
		Nombre: "Tropical Heat",
		ColorNum: "1082",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 4,
		colorR: 194,
		colorG: 64,
		colorB: 68,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [4, 57],
		Triadicos: [4, 44, 72],
		Adjacentes: [24, 4, 1],
	},
	{
		Id: 5732,
		CodigoHtml: "F8E8DF",
		Nombre: "Morning Blush",
		ColorNum: "1083",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 5,
		colorR: 248,
		colorG: 232,
		colorB: 223,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [5, 58],
		Triadicos: [5, 45, 73],
		Adjacentes: [25, 5, 2],
	},
	{
		Id: 5733,
		CodigoHtml: "FBD7D3",
		Nombre: "Go Go Pink",
		ColorNum: "1084",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 5,
		colorR: 251,
		colorG: 215,
		colorB: 211,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [5, 58],
		Triadicos: [5, 45, 73],
		Adjacentes: [25, 5, 2],
	},
	{
		Id: 5734,
		CodigoHtml: "FCCBC9",
		Nombre: "Tint of Rose",
		ColorNum: "1085",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 5,
		colorR: 252,
		colorG: 203,
		colorB: 201,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [5, 58],
		Triadicos: [5, 45, 73],
		Adjacentes: [25, 5, 2],
	},
	{
		Id: 5735,
		CodigoHtml: "F7A6A4",
		Nombre: "Nosegay",
		ColorNum: "1086",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 5,
		colorR: 247,
		colorG: 166,
		colorB: 164,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [5, 58],
		Triadicos: [5, 45, 73],
		Adjacentes: [25, 5, 2],
	},
	{
		Id: 5736,
		CodigoHtml: "E9827F",
		Nombre: "Bay Coral",
		ColorNum: "1087",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 5,
		colorR: 233,
		colorG: 130,
		colorB: 127,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [5, 58],
		Triadicos: [5, 45, 73],
		Adjacentes: [25, 5, 2],
	},
	{
		Id: 5737,
		CodigoHtml: "DF726D",
		Nombre: "Childhood Crush",
		ColorNum: "1088",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 5,
		colorR: 223,
		colorG: 114,
		colorB: 109,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [5, 58],
		Triadicos: [5, 45, 73],
		Adjacentes: [25, 5, 2],
	},
	{
		Id: 5738,
		CodigoHtml: "BF4D47",
		Nombre: "Chuckles",
		ColorNum: "1089",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 5,
		colorR: 191,
		colorG: 77,
		colorB: 71,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [5, 58],
		Triadicos: [5, 45, 73],
		Adjacentes: [25, 5, 2],
	},
	{
		Id: 5739,
		CodigoHtml: "F8DAD6",
		Nombre: "Tartlet ",
		ColorNum: "1090",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 6,
		colorR: 248,
		colorG: 218,
		colorB: 214,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [6, 59],
		Triadicos: [6, 74, 46],
		Adjacentes: [26, 6, 3],
	},
	{
		Id: 5740,
		CodigoHtml: "F7CDCD",
		Nombre: "All Dressed Up",
		ColorNum: "1091",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 6,
		colorR: 247,
		colorG: 205,
		colorB: 205,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [6, 59],
		Triadicos: [6, 74, 46],
		Adjacentes: [26, 6, 3],
	},
	{
		Id: 5741,
		CodigoHtml: "F2BCC0",
		Nombre: "Petals Unfolding",
		ColorNum: "1092",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 6,
		colorR: 242,
		colorG: 188,
		colorB: 192,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [6, 59],
		Triadicos: [6, 74, 46],
		Adjacentes: [26, 6, 3],
	},
	{
		Id: 5742,
		CodigoHtml: "EBA3AA",
		Nombre: "Wood Nymph",
		ColorNum: "1093",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 6,
		colorR: 235,
		colorG: 163,
		colorB: 170,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [6, 59],
		Triadicos: [6, 74, 46],
		Adjacentes: [26, 6, 3],
	},
	{
		Id: 5743,
		CodigoHtml: "DA868C",
		Nombre: "Porcelain Rose",
		ColorNum: "1094",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 6,
		colorR: 218,
		colorG: 134,
		colorB: 140,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [6, 59],
		Triadicos: [6, 74, 46],
		Adjacentes: [26, 6, 3],
	},
	{
		Id: 5744,
		CodigoHtml: "B35250",
		Nombre: "Empower",
		ColorNum: "1095",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 6,
		colorR: 179,
		colorG: 82,
		colorB: 80,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [6, 59],
		Triadicos: [6, 74, 46],
		Adjacentes: [26, 6, 3],
	},
	{
		Id: 5745,
		CodigoHtml: "A24E4D",
		Nombre: "The Ego Has Landed",
		ColorNum: "1096",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 6,
		colorR: 162,
		colorG: 78,
		colorB: 77,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [6, 59],
		Triadicos: [6, 74, 46],
		Adjacentes: [26, 6, 3],
	},
	{
		Id: 5746,
		CodigoHtml: "F6C1C8",
		Nombre: "Pale Petunia",
		ColorNum: "1097",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 7,
		colorR: 246,
		colorG: 193,
		colorB: 200,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [7, 60],
		Triadicos: [7, 75, 47],
		Adjacentes: [27, 7, 4],
	},
	{
		Id: 5747,
		CodigoHtml: "F3B0BA",
		Nombre: "Bubblegum Pink",
		ColorNum: "1098",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 7,
		colorR: 243,
		colorG: 176,
		colorB: 186,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [7, 60],
		Triadicos: [7, 75, 47],
		Adjacentes: [27, 7, 4],
	},
	{
		Id: 5748,
		CodigoHtml: "E5848E",
		Nombre: "Dimple",
		ColorNum: "1099",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 7,
		colorR: 229,
		colorG: 132,
		colorB: 142,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [7, 60],
		Triadicos: [7, 75, 47],
		Adjacentes: [27, 7, 4],
	},
	{
		Id: 5749,
		CodigoHtml: "D56069",
		Nombre: "Heart to Heart",
		ColorNum: "1100",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 7,
		colorR: 213,
		colorG: 96,
		colorB: 105,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [7, 60],
		Triadicos: [7, 75, 47],
		Adjacentes: [27, 7, 4],
	},
	{
		Id: 5750,
		CodigoHtml: "C74A4A",
		Nombre: "Mullen Pink",
		ColorNum: "1101",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 7,
		colorR: 199,
		colorG: 74,
		colorB: 74,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [7, 60],
		Triadicos: [7, 75, 47],
		Adjacentes: [27, 7, 4],
	},
	{
		Id: 5751,
		CodigoHtml: "BC484E",
		Nombre: "Precious Peony",
		ColorNum: "1102",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 7,
		colorR: 188,
		colorG: 72,
		colorB: 78,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [7, 60],
		Triadicos: [7, 75, 47],
		Adjacentes: [27, 7, 4],
	},
	{
		Id: 5752,
		CodigoHtml: "A74C53",
		Nombre: "Moonrose",
		ColorNum: "1103",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 7,
		colorR: 167,
		colorG: 76,
		colorB: 83,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [7, 60],
		Triadicos: [7, 75, 47],
		Adjacentes: [27, 7, 4],
	},
	{
		Id: 5753,
		CodigoHtml: "FBE4E6",
		Nombre: "Valerie",
		ColorNum: "1104",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 8,
		colorR: 251,
		colorG: 228,
		colorB: 230,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [8, 61],
		Triadicos: [8, 76, 48],
		Adjacentes: [28, 8, 5],
	},
	{
		Id: 5754,
		CodigoHtml: "FFD8E0",
		Nombre: "It's A Girl!",
		ColorNum: "1105",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 8,
		colorR: 255,
		colorG: 216,
		colorB: 224,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [8, 61],
		Triadicos: [8, 76, 48],
		Adjacentes: [28, 8, 5],
	},
	{
		Id: 5755,
		CodigoHtml: "FEC0CE",
		Nombre: "Garden Glory",
		ColorNum: "1106",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 8,
		colorR: 254,
		colorG: 192,
		colorB: 206,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [8, 61],
		Triadicos: [8, 76, 48],
		Adjacentes: [28, 8, 5],
	},
	{
		Id: 5756,
		CodigoHtml: "F891A9",
		Nombre: "Bunny Fluff",
		ColorNum: "1107",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 8,
		colorR: 248,
		colorG: 145,
		colorB: 169,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [8, 61],
		Triadicos: [8, 76, 48],
		Adjacentes: [28, 8, 5],
	},
	{
		Id: 5757,
		CodigoHtml: "F1758D",
		Nombre: "Pink Explosion",
		ColorNum: "1108",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 8,
		colorR: 241,
		colorG: 117,
		colorB: 141,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [8, 61],
		Triadicos: [8, 76, 48],
		Adjacentes: [28, 8, 5],
	},
	{
		Id: 5758,
		CodigoHtml: "CE3A3E",
		Nombre: "Party Time",
		ColorNum: "1109",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 8,
		colorR: 206,
		colorG: 58,
		colorB: 62,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [8, 61],
		Triadicos: [8, 76, 48],
		Adjacentes: [28, 8, 5],
	},
	{
		Id: 5759,
		CodigoHtml: "B53F43",
		Nombre: "Unmatched Beauty",
		ColorNum: "1110",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 8,
		colorR: 181,
		colorG: 63,
		colorB: 67,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [8, 61],
		Triadicos: [8, 76, 48],
		Adjacentes: [28, 8, 5],
	},
	{
		Id: 5760,
		CodigoHtml: "FAE4E5",
		Nombre: "Royal Wedding ",
		ColorNum: "1111",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 9,
		colorR: 250,
		colorG: 228,
		colorB: 229,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [9, 62],
		Triadicos: [9, 77, 49],
		Adjacentes: [29, 9, 6],
	},
	{
		Id: 5761,
		CodigoHtml: "FCDCE0",
		Nombre: "Wishful Thinking",
		ColorNum: "1112",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 9,
		colorR: 252,
		colorG: 220,
		colorB: 224,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [9, 62],
		Triadicos: [9, 77, 49],
		Adjacentes: [29, 9, 6],
	},
	{
		Id: 5762,
		CodigoHtml: "FCCBD2",
		Nombre: "Fruitbowl",
		ColorNum: "1113",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 9,
		colorR: 252,
		colorG: 203,
		colorB: 210,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [9, 62],
		Triadicos: [9, 77, 49],
		Adjacentes: [29, 9, 6],
	},
	{
		Id: 5763,
		CodigoHtml: "FCBBC5",
		Nombre: "A Lot of Love",
		ColorNum: "1114",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 9,
		colorR: 252,
		colorG: 187,
		colorB: 197,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [9, 62],
		Triadicos: [9, 77, 49],
		Adjacentes: [29, 9, 6],
	},
	{
		Id: 5764,
		CodigoHtml: "EF91A0",
		Nombre: "Dollie",
		ColorNum: "1115",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 9,
		colorR: 239,
		colorG: 145,
		colorB: 160,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [9, 62],
		Triadicos: [9, 77, 49],
		Adjacentes: [29, 9, 6],
	},
	{
		Id: 5765,
		CodigoHtml: "C43944",
		Nombre: "Movie Star",
		ColorNum: "1116",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 9,
		colorR: 196,
		colorG: 57,
		colorB: 68,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [9, 62],
		Triadicos: [9, 77, 49],
		Adjacentes: [29, 9, 6],
	},
	{
		Id: 5766,
		CodigoHtml: "A23D40",
		Nombre: "Siren",
		ColorNum: "1117",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 9,
		colorR: 162,
		colorG: 61,
		colorB: 64,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [9, 62],
		Triadicos: [9, 77, 49],
		Adjacentes: [29, 9, 6],
	},
	{
		Id: 5767,
		CodigoHtml: "F7EBEC",
		Nombre: "Cotton Candy",
		ColorNum: "1118",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 10,
		colorR: 247,
		colorG: 235,
		colorB: 236,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [10, 63],
		Triadicos: [10, 78, 50],
		Adjacentes: [30, 10, 7],
	},
	{
		Id: 5768,
		CodigoHtml: "F7DBE3",
		Nombre: "Sweet Pea",
		ColorNum: "1119",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 10,
		colorR: 247,
		colorG: 219,
		colorB: 227,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [10, 63],
		Triadicos: [10, 78, 50],
		Adjacentes: [30, 10, 7],
	},
	{
		Id: 5769,
		CodigoHtml: "F8D1E0",
		Nombre: "Summer Cosmos",
		ColorNum: "1120",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 10,
		colorR: 248,
		colorG: 209,
		colorB: 224,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [10, 63],
		Triadicos: [10, 78, 50],
		Adjacentes: [30, 10, 7],
	},
	{
		Id: 5770,
		CodigoHtml: "F5BDD4",
		Nombre: "Dahlia Delight",
		ColorNum: "1121",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 10,
		colorR: 245,
		colorG: 189,
		colorB: 212,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [10, 63],
		Triadicos: [10, 78, 50],
		Adjacentes: [30, 10, 7],
	},
	{
		Id: 5771,
		CodigoHtml: "F2ADC9",
		Nombre: "Rose Mallow",
		ColorNum: "1122",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 10,
		colorR: 242,
		colorG: 173,
		colorB: 201,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [10, 63],
		Triadicos: [10, 78, 50],
		Adjacentes: [30, 10, 7],
	},
	{
		Id: 5772,
		CodigoHtml: "DD6C96",
		Nombre: "Groovy",
		ColorNum: "1123",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 10,
		colorR: 221,
		colorG: 108,
		colorB: 150,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [10, 63],
		Triadicos: [10, 78, 50],
		Adjacentes: [30, 10, 7],
	},
	{
		Id: 5773,
		CodigoHtml: "9F3949",
		Nombre: "Velvet Cake",
		ColorNum: "1124",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 10,
		colorR: 159,
		colorG: 57,
		colorB: 73,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [10, 63],
		Triadicos: [10, 78, 50],
		Adjacentes: [30, 10, 7],
	},
	{
		Id: 5774,
		CodigoHtml: "F7E2E6",
		Nombre: "Hepatica",
		ColorNum: "1125",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 11,
		colorR: 247,
		colorG: 226,
		colorB: 230,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [11, 64],
		Triadicos: [11, 79, 51],
		Adjacentes: [31, 11, 8],
	},
	{
		Id: 5775,
		CodigoHtml: "F3CCD7",
		Nombre: "Seashell Pink",
		ColorNum: "1126",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 11,
		colorR: 243,
		colorG: 204,
		colorB: 215,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [11, 64],
		Triadicos: [11, 79, 51],
		Adjacentes: [31, 11, 8],
	},
	{
		Id: 5776,
		CodigoHtml: "E5A4BA",
		Nombre: "Pepto",
		ColorNum: "1127",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 11,
		colorR: 229,
		colorG: 164,
		colorB: 186,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [11, 64],
		Triadicos: [11, 79, 51],
		Adjacentes: [31, 11, 8],
	},
	{
		Id: 5777,
		CodigoHtml: "D17C94",
		Nombre: "Minuette",
		ColorNum: "1128",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 11,
		colorR: 209,
		colorG: 124,
		colorB: 148,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [11, 64],
		Triadicos: [11, 79, 51],
		Adjacentes: [31, 11, 8],
	},
	{
		Id: 5778,
		CodigoHtml: "AF5161",
		Nombre: "Punky Pink",
		ColorNum: "1129",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 11,
		colorR: 175,
		colorG: 81,
		colorB: 97,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [11, 64],
		Triadicos: [11, 79, 51],
		Adjacentes: [31, 11, 8],
	},
	{
		Id: 5779,
		CodigoHtml: "A8455E",
		Nombre: "Obsession",
		ColorNum: "1130",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 11,
		colorR: 168,
		colorG: 69,
		colorB: 94,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [11, 64],
		Triadicos: [11, 79, 51],
		Adjacentes: [31, 11, 8],
	},
	{
		Id: 5780,
		CodigoHtml: "AA446A",
		Nombre: "Can Can",
		ColorNum: "1131",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 11,
		colorR: 170,
		colorG: 68,
		colorB: 106,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [11, 64],
		Triadicos: [11, 79, 51],
		Adjacentes: [31, 11, 8],
	},
	{
		Id: 5781,
		CodigoHtml: "F5C6D2",
		Nombre: "Sullivan's Heart",
		ColorNum: "1132",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 12,
		colorR: 245,
		colorG: 198,
		colorB: 210,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [12, 65],
		Triadicos: [12, 80, 52],
		Adjacentes: [32, 12, 9],
	},
	{
		Id: 5782,
		CodigoHtml: "F3BDCB",
		Nombre: "Christy's Smile",
		ColorNum: "1133",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 12,
		colorR: 243,
		colorG: 189,
		colorB: 203,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [12, 65],
		Triadicos: [12, 80, 52],
		Adjacentes: [32, 12, 9],
	},
	{
		Id: 5783,
		CodigoHtml: "E8A1B5",
		Nombre: "Pegeen Peony",
		ColorNum: "1134",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 12,
		colorR: 232,
		colorG: 161,
		colorB: 181,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [12, 65],
		Triadicos: [12, 80, 52],
		Adjacentes: [32, 12, 9],
	},
	{
		Id: 5784,
		CodigoHtml: "CE798F",
		Nombre: "Glam",
		ColorNum: "1135",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 12,
		colorR: 206,
		colorG: 121,
		colorB: 143,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [12, 65],
		Triadicos: [12, 80, 52],
		Adjacentes: [32, 12, 9],
	},
	{
		Id: 5785,
		CodigoHtml: "974851",
		Nombre: "Romp",
		ColorNum: "1136",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 12,
		colorR: 151,
		colorG: 72,
		colorB: 81,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [12, 65],
		Triadicos: [12, 80, 52],
		Adjacentes: [32, 12, 9],
	},
	{
		Id: 5786,
		CodigoHtml: "9E4A52",
		Nombre: "Ode to Joy",
		ColorNum: "1137",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 12,
		colorR: 158,
		colorG: 74,
		colorB: 82,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [12, 65],
		Triadicos: [12, 80, 52],
		Adjacentes: [32, 12, 9],
	},
	{
		Id: 5787,
		CodigoHtml: "984245",
		Nombre: "Romantic Night",
		ColorNum: "1138",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 12,
		colorR: 152,
		colorG: 66,
		colorB: 69,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [12, 65],
		Triadicos: [12, 80, 52],
		Adjacentes: [32, 12, 9],
	},
	{
		Id: 5788,
		CodigoHtml: "FBEBCF",
		Nombre: "Capetown Cream",
		ColorNum: "0964",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 13,
		colorR: 251,
		colorG: 235,
		colorB: 207,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [13, 72],
		Triadicos: [13, 57, 83],
		Adjacentes: [29, 13, 24],
	},
	{
		Id: 5789,
		CodigoHtml: "FCE8C5",
		Nombre: "Gold of Midas",
		ColorNum: "0965",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 13,
		colorR: 252,
		colorG: 232,
		colorB: 197,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [13, 72],
		Triadicos: [13, 57, 83],
		Adjacentes: [29, 13, 24],
	},
	{
		Id: 5790,
		CodigoHtml: "FFDBAA",
		Nombre: "Starburst",
		ColorNum: "0966",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 13,
		colorR: 255,
		colorG: 219,
		colorB: 170,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [13, 72],
		Triadicos: [13, 57, 83],
		Adjacentes: [29, 13, 24],
	},
	{
		Id: 5791,
		CodigoHtml: "FFD49F",
		Nombre: "Endearment",
		ColorNum: "0967",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 13,
		colorR: 255,
		colorG: 212,
		colorB: 159,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [13, 72],
		Triadicos: [13, 57, 83],
		Adjacentes: [29, 13, 24],
	},
	{
		Id: 5792,
		CodigoHtml: "FFB46E",
		Nombre: "Mango Madness",
		ColorNum: "0968",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 13,
		colorR: 255,
		colorG: 180,
		colorB: 110,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [13, 72],
		Triadicos: [13, 57, 83],
		Adjacentes: [29, 13, 24],
	},
	{
		Id: 5793,
		CodigoHtml: "FFA04F",
		Nombre: "Au Gratin",
		ColorNum: "0969",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 13,
		colorR: 255,
		colorG: 160,
		colorB: 79,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [13, 72],
		Triadicos: [13, 57, 83],
		Adjacentes: [29, 13, 24],
	},
	{
		Id: 5794,
		CodigoHtml: "F98433",
		Nombre: "Orange you Happy?",
		ColorNum: "0970",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 13,
		colorR: 249,
		colorG: 132,
		colorB: 51,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [13, 72],
		Triadicos: [13, 57, 83],
		Adjacentes: [29, 13, 24],
	},
	{
		Id: 5795,
		CodigoHtml: "F9E9D0",
		Nombre: "May Sun",
		ColorNum: "0971",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 14,
		colorR: 249,
		colorG: 233,
		colorB: 208,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [14, 73],
		Triadicos: [14, 58, 84],
		Adjacentes: [30, 14, 25],
	},
	{
		Id: 5796,
		CodigoHtml: "FCDDB9",
		Nombre: "Apple Cider",
		ColorNum: "0972",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 14,
		colorR: 252,
		colorG: 221,
		colorB: 185,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [14, 73],
		Triadicos: [14, 58, 84],
		Adjacentes: [30, 14, 25],
	},
	{
		Id: 5797,
		CodigoHtml: "FFCFA1",
		Nombre: "Atoll Sand",
		ColorNum: "0973",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 14,
		colorR: 255,
		colorG: 207,
		colorB: 161,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [14, 73],
		Triadicos: [14, 58, 84],
		Adjacentes: [30, 14, 25],
	},
	{
		Id: 5798,
		CodigoHtml: "FEBB83",
		Nombre: "Goosebill",
		ColorNum: "0974",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 14,
		colorR: 254,
		colorG: 187,
		colorB: 131,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [14, 73],
		Triadicos: [14, 58, 84],
		Adjacentes: [30, 14, 25],
	},
	{
		Id: 5799,
		CodigoHtml: "FAAC6C",
		Nombre: "Downy Feather",
		ColorNum: "0975",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 14,
		colorR: 250,
		colorG: 172,
		colorB: 108,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [14, 73],
		Triadicos: [14, 58, 84],
		Adjacentes: [30, 14, 25],
	},
	{
		Id: 5800,
		CodigoHtml: "F99E59",
		Nombre: "Cocktail Hour",
		ColorNum: "0976",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 14,
		colorR: 249,
		colorG: 158,
		colorB: 89,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [14, 73],
		Triadicos: [14, 58, 84],
		Adjacentes: [30, 14, 25],
	},
	{
		Id: 5801,
		CodigoHtml: "EF8945",
		Nombre: "Cheddar Cheese",
		ColorNum: "0977",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 14,
		colorR: 239,
		colorG: 137,
		colorB: 69,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [14, 73],
		Triadicos: [14, 58, 84],
		Adjacentes: [30, 14, 25],
	},
	{
		Id: 5802,
		CodigoHtml: "F8E5CB",
		Nombre: "City of Diamonds",
		ColorNum: "0978",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 15,
		colorR: 248,
		colorG: 229,
		colorB: 203,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [15, 74],
		Triadicos: [15, 59, 85],
		Adjacentes: [31, 15, 26],
	},
	{
		Id: 5803,
		CodigoHtml: "FDDFBA",
		Nombre: "Liveliness",
		ColorNum: "0979",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 15,
		colorR: 253,
		colorG: 223,
		colorB: 186,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [15, 74],
		Triadicos: [15, 59, 85],
		Adjacentes: [31, 15, 26],
	},
	{
		Id: 5804,
		CodigoHtml: "FDD4A9",
		Nombre: "Southern Breeze",
		ColorNum: "0980",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 15,
		colorR: 253,
		colorG: 212,
		colorB: 169,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [15, 74],
		Triadicos: [15, 59, 85],
		Adjacentes: [31, 15, 26],
	},
	{
		Id: 5805,
		CodigoHtml: "F9C595",
		Nombre: "Tuscan Wall",
		ColorNum: "0981",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 15,
		colorR: 249,
		colorG: 197,
		colorB: 149,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [15, 74],
		Triadicos: [15, 59, 85],
		Adjacentes: [31, 15, 26],
	},
	{
		Id: 5806,
		CodigoHtml: "EFAF78",
		Nombre: "Taste of Summer",
		ColorNum: "0982",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 15,
		colorR: 239,
		colorG: 175,
		colorB: 120,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [15, 74],
		Triadicos: [15, 59, 85],
		Adjacentes: [31, 15, 26],
	},
	{
		Id: 5807,
		CodigoHtml: "E69C5A",
		Nombre: "Japonica",
		ColorNum: "0983",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 15,
		colorR: 230,
		colorG: 156,
		colorB: 90,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [15, 74],
		Triadicos: [15, 59, 85],
		Adjacentes: [31, 15, 26],
	},
	{
		Id: 5808,
		CodigoHtml: "D38643",
		Nombre: "Mexican Spirit",
		ColorNum: "0984",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 15,
		colorR: 211,
		colorG: 134,
		colorB: 67,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [15, 74],
		Triadicos: [15, 59, 85],
		Adjacentes: [31, 15, 26],
	},
	{
		Id: 5809,
		CodigoHtml: "F8F0E1",
		Nombre: "Whiskers",
		ColorNum: "0985",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 16,
		colorR: 248,
		colorG: 240,
		colorB: 225,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [16, 75],
		Triadicos: [16, 60, 86],
		Adjacentes: [32, 16, 27],
	},
	{
		Id: 5810,
		CodigoHtml: "F8E5CC",
		Nombre: "Mineral Glow",
		ColorNum: "0986",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 16,
		colorR: 248,
		colorG: 229,
		colorB: 204,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [16, 75],
		Triadicos: [16, 60, 86],
		Adjacentes: [32, 16, 27],
	},
	{
		Id: 5811,
		CodigoHtml: "F4D4AE",
		Nombre: "Tallow",
		ColorNum: "0987",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 16,
		colorR: 244,
		colorG: 212,
		colorB: 174,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [16, 75],
		Triadicos: [16, 60, 86],
		Adjacentes: [32, 16, 27],
	},
	{
		Id: 5812,
		CodigoHtml: "E8B984",
		Nombre: "Mom's Apple Pie",
		ColorNum: "0988",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 16,
		colorR: 232,
		colorG: 185,
		colorB: 132,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [16, 75],
		Triadicos: [16, 60, 86],
		Adjacentes: [32, 16, 27],
	},
	{
		Id: 5813,
		CodigoHtml: "D2985A",
		Nombre: "Impulse",
		ColorNum: "0989",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 16,
		colorR: 210,
		colorG: 152,
		colorB: 90,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [16, 75],
		Triadicos: [16, 60, 86],
		Adjacentes: [32, 16, 27],
	},
	{
		Id: 5814,
		CodigoHtml: "C4894F",
		Nombre: "Butterscotch Glaze",
		ColorNum: "0990",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 16,
		colorR: 196,
		colorG: 137,
		colorB: 79,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [16, 75],
		Triadicos: [16, 60, 86],
		Adjacentes: [32, 16, 27],
	},
	{
		Id: 5815,
		CodigoHtml: "BF8249",
		Nombre: "Beeswax Candle",
		ColorNum: "0991",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 16,
		colorR: 191,
		colorG: 130,
		colorB: 73,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [16, 75],
		Triadicos: [16, 60, 86],
		Adjacentes: [32, 16, 27],
	},
	{
		Id: 5816,
		CodigoHtml: "F5E3D0",
		Nombre: "Touch of Topaz",
		ColorNum: "0992",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 17,
		colorR: 245,
		colorG: 227,
		colorB: 208,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [17, 76],
		Triadicos: [17, 61, 87],
		Adjacentes: [33, 17, 28],
	},
	{
		Id: 5817,
		CodigoHtml: "F8DDC5",
		Nombre: "Incan Treasure",
		ColorNum: "0993",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 17,
		colorR: 248,
		colorG: 221,
		colorB: 197,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [17, 76],
		Triadicos: [17, 61, 87],
		Adjacentes: [33, 17, 28],
	},
	{
		Id: 5818,
		CodigoHtml: "F4D3B7",
		Nombre: "Sparkle Glow",
		ColorNum: "0994",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 17,
		colorR: 244,
		colorG: 211,
		colorB: 183,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [17, 76],
		Triadicos: [17, 61, 87],
		Adjacentes: [33, 17, 28],
	},
	{
		Id: 5819,
		CodigoHtml: "E9C09A",
		Nombre: "Bread Pudding",
		ColorNum: "0995",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 17,
		colorR: 233,
		colorG: 192,
		colorB: 154,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [17, 76],
		Triadicos: [17, 61, 87],
		Adjacentes: [33, 17, 28],
	},
	{
		Id: 5820,
		CodigoHtml: "D7A070",
		Nombre: "Spice Cookie",
		ColorNum: "0996",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 17,
		colorR: 215,
		colorG: 160,
		colorB: 112,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [17, 76],
		Triadicos: [17, 61, 87],
		Adjacentes: [33, 17, 28],
	},
	{
		Id: 5821,
		CodigoHtml: "CF9561",
		Nombre: "Gates of Gold",
		ColorNum: "0997",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 17,
		colorR: 207,
		colorG: 149,
		colorB: 97,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [17, 76],
		Triadicos: [17, 61, 87],
		Adjacentes: [33, 17, 28],
	},
	{
		Id: 5822,
		CodigoHtml: "B37C4A",
		Nombre: "Gold Metal",
		ColorNum: "0998",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 17,
		colorR: 179,
		colorG: 124,
		colorB: 74,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [17, 76],
		Triadicos: [17, 61, 87],
		Adjacentes: [33, 17, 28],
	},
	{
		Id: 5823,
		CodigoHtml: "F6E8D7",
		Nombre: "Ivory Tassel",
		ColorNum: "0999",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 18,
		colorR: 246,
		colorG: 232,
		colorB: 215,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [18, 77],
		Triadicos: [18, 62, 88],
		Adjacentes: [34, 18, 29],
	},
	{
		Id: 5824,
		CodigoHtml: "F4DDC4",
		Nombre: "Flower of Oahu",
		ColorNum: "1000",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 18,
		colorR: 244,
		colorG: 221,
		colorB: 196,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [18, 77],
		Triadicos: [18, 62, 88],
		Adjacentes: [34, 18, 29],
	},
	{
		Id: 5825,
		CodigoHtml: "F3D3B5",
		Nombre: "Cheddar Corn",
		ColorNum: "1001",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 18,
		colorR: 243,
		colorG: 211,
		colorB: 181,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [18, 77],
		Triadicos: [18, 62, 88],
		Adjacentes: [34, 18, 29],
	},
	{
		Id: 5826,
		CodigoHtml: "DEB899",
		Nombre: "Tea Cookie",
		ColorNum: "1002",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 18,
		colorR: 222,
		colorG: 184,
		colorB: 153,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [18, 77],
		Triadicos: [18, 62, 88],
		Adjacentes: [34, 18, 29],
	},
	{
		Id: 5827,
		CodigoHtml: "D6AB88",
		Nombre: "Sweet Sue",
		ColorNum: "1003",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 18,
		colorR: 214,
		colorG: 171,
		colorB: 136,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [18, 77],
		Triadicos: [18, 62, 88],
		Adjacentes: [34, 18, 29],
	},
	{
		Id: 5828,
		CodigoHtml: "C08A64",
		Nombre: "Peace of Mind",
		ColorNum: "1004",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 18,
		colorR: 192,
		colorG: 138,
		colorB: 100,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [18, 77],
		Triadicos: [18, 62, 88],
		Adjacentes: [34, 18, 29],
	},
	{
		Id: 5829,
		CodigoHtml: "AA724F",
		Nombre: "Flickering Flame",
		ColorNum: "1005",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 18,
		colorR: 170,
		colorG: 114,
		colorB: 79,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [18, 77],
		Triadicos: [18, 62, 88],
		Adjacentes: [34, 18, 29],
	},
	{
		Id: 5830,
		CodigoHtml: "F2D8BF",
		Nombre: "Turkscap",
		ColorNum: "1006",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 19,
		colorR: 242,
		colorG: 216,
		colorB: 191,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [19, 78],
		Triadicos: [19, 63, 89],
		Adjacentes: [35, 19, 30],
	},
	{
		Id: 5831,
		CodigoHtml: "F0CCAF",
		Nombre: "Pastel Peach",
		ColorNum: "1007",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 19,
		colorR: 240,
		colorG: 204,
		colorB: 175,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [19, 78],
		Triadicos: [19, 63, 89],
		Adjacentes: [35, 19, 30],
	},
	{
		Id: 5832,
		CodigoHtml: "ECC6A6",
		Nombre: "Mango Tango",
		ColorNum: "1008",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 19,
		colorR: 236,
		colorG: 198,
		colorB: 166,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [19, 78],
		Triadicos: [19, 63, 89],
		Adjacentes: [35, 19, 30],
	},
	{
		Id: 5833,
		CodigoHtml: "DFB393",
		Nombre: "Bedtime Story",
		ColorNum: "1009",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 19,
		colorR: 223,
		colorG: 179,
		colorB: 147,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [19, 78],
		Triadicos: [19, 63, 89],
		Adjacentes: [35, 19, 30],
	},
	{
		Id: 5834,
		CodigoHtml: "CD9875",
		Nombre: "Peanut Brittle",
		ColorNum: "1010",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 19,
		colorR: 205,
		colorG: 152,
		colorB: 117,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [19, 78],
		Triadicos: [19, 63, 89],
		Adjacentes: [35, 19, 30],
	},
	{
		Id: 5835,
		CodigoHtml: "B77C57",
		Nombre: "Semolina",
		ColorNum: "1011",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 19,
		colorR: 183,
		colorG: 124,
		colorB: 87,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [19, 78],
		Triadicos: [19, 63, 89],
		Adjacentes: [35, 19, 30],
	},
	{
		Id: 5836,
		CodigoHtml: "A06846",
		Nombre: "Hampton Beach",
		ColorNum: "1012",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 19,
		colorR: 160,
		colorG: 104,
		colorB: 70,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [19, 78],
		Triadicos: [19, 63, 89],
		Adjacentes: [35, 19, 30],
	},
	{
		Id: 5837,
		CodigoHtml: "F7E6D3",
		Nombre: "Warm Bread",
		ColorNum: "1013",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 20,
		colorR: 247,
		colorG: 230,
		colorB: 211,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [20, 79],
		Triadicos: [20, 64, 90],
		Adjacentes: [36, 20, 31],
	},
	{
		Id: 5838,
		CodigoHtml: "F8DCC7",
		Nombre: "Muffin Magic",
		ColorNum: "1014",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 20,
		colorR: 248,
		colorG: 220,
		colorB: 199,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [20, 79],
		Triadicos: [20, 64, 90],
		Adjacentes: [36, 20, 31],
	},
	{
		Id: 5839,
		CodigoHtml: "F5D4BD",
		Nombre: "Canter Peach",
		ColorNum: "1015",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 20,
		colorR: 245,
		colorG: 212,
		colorB: 189,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [20, 79],
		Triadicos: [20, 64, 90],
		Adjacentes: [36, 20, 31],
	},
	{
		Id: 5840,
		CodigoHtml: "EEB792",
		Nombre: "November Leaf",
		ColorNum: "1016",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 20,
		colorR: 238,
		colorG: 183,
		colorB: 146,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [20, 79],
		Triadicos: [20, 64, 90],
		Adjacentes: [36, 20, 31],
	},
	{
		Id: 5841,
		CodigoHtml: "DA966C",
		Nombre: "Summer's End",
		ColorNum: "1017",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 20,
		colorR: 218,
		colorG: 150,
		colorB: 108,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [20, 79],
		Triadicos: [20, 64, 90],
		Adjacentes: [36, 20, 31],
	},
	{
		Id: 5842,
		CodigoHtml: "D08B60",
		Nombre: "Sunny Horizon",
		ColorNum: "1018",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 20,
		colorR: 208,
		colorG: 139,
		colorB: 96,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [20, 79],
		Triadicos: [20, 64, 90],
		Adjacentes: [36, 20, 31],
	},
	{
		Id: 5843,
		CodigoHtml: "B57248",
		Nombre: "Orange Ballad",
		ColorNum: "1019",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 20,
		colorR: 181,
		colorG: 114,
		colorB: 72,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [20, 79],
		Triadicos: [20, 64, 90],
		Adjacentes: [36, 20, 31],
	},
	{
		Id: 5844,
		CodigoHtml: "F4F0E4",
		Nombre: "Rapture's Light",
		ColorNum: "1020",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 21,
		colorR: 244,
		colorG: 240,
		colorB: 228,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [21, 80],
		Triadicos: [21, 65, 91],
		Adjacentes: [37, 21, 32],
	},
	{
		Id: 5845,
		CodigoHtml: "F9EBDA",
		Nombre: "Sweet Buttermilk",
		ColorNum: "1021",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 21,
		colorR: 249,
		colorG: 235,
		colorB: 218,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [21, 80],
		Triadicos: [21, 65, 91],
		Adjacentes: [37, 21, 32],
	},
	{
		Id: 5846,
		CodigoHtml: "FCD9BD",
		Nombre: "Delicate Dawn",
		ColorNum: "1022",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 21,
		colorR: 252,
		colorG: 217,
		colorB: 189,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [21, 80],
		Triadicos: [21, 65, 91],
		Adjacentes: [37, 21, 32],
	},
	{
		Id: 5847,
		CodigoHtml: "FECFAF",
		Nombre: "Mellow Glow",
		ColorNum: "1023",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 21,
		colorR: 254,
		colorG: 207,
		colorB: 175,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [21, 80],
		Triadicos: [21, 65, 91],
		Adjacentes: [37, 21, 32],
	},
	{
		Id: 5848,
		CodigoHtml: "F3A67D",
		Nombre: "Pumpkin Hue",
		ColorNum: "1024",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 21,
		colorR: 243,
		colorG: 166,
		colorB: 125,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [21, 80],
		Triadicos: [21, 65, 91],
		Adjacentes: [37, 21, 32],
	},
	{
		Id: 5849,
		CodigoHtml: "E79060",
		Nombre: "Heat of Summer",
		ColorNum: "1025",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 21,
		colorR: 231,
		colorG: 144,
		colorB: 96,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [21, 80],
		Triadicos: [21, 65, 91],
		Adjacentes: [37, 21, 32],
	},
	{
		Id: 5850,
		CodigoHtml: "CD6E44",
		Nombre: "Casa De Oro",
		ColorNum: "1026",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 21,
		colorR: 205,
		colorG: 110,
		colorB: 68,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [21, 80],
		Triadicos: [21, 65, 91],
		Adjacentes: [37, 21, 32],
	},
	{
		Id: 5851,
		CodigoHtml: "F6F0E2",
		Nombre: "Abstract White",
		ColorNum: "1027",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 22,
		colorR: 246,
		colorG: 240,
		colorB: 226,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [22, 81],
		Triadicos: [22, 66, 92],
		Adjacentes: [38, 22, 33],
	},
	{
		Id: 5852,
		CodigoHtml: "FAEAD6",
		Nombre: "Verve",
		ColorNum: "1028",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 22,
		colorR: 250,
		colorG: 234,
		colorB: 214,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [22, 81],
		Triadicos: [22, 66, 92],
		Adjacentes: [38, 22, 33],
	},
	{
		Id: 5853,
		CodigoHtml: "FDE4CB",
		Nombre: "Pale Blossom",
		ColorNum: "1029",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 22,
		colorR: 253,
		colorG: 228,
		colorB: 203,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [22, 81],
		Triadicos: [22, 66, 92],
		Adjacentes: [38, 22, 33],
	},
	{
		Id: 5854,
		CodigoHtml: "FFC299",
		Nombre: "Wispy White",
		ColorNum: "1030",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 22,
		colorR: 255,
		colorG: 194,
		colorB: 153,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [22, 81],
		Triadicos: [22, 66, 92],
		Adjacentes: [38, 22, 33],
	},
	{
		Id: 5855,
		CodigoHtml: "FFA97A",
		Nombre: "Chickadee",
		ColorNum: "1031",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 22,
		colorR: 255,
		colorG: 169,
		colorB: 122,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [22, 81],
		Triadicos: [22, 66, 92],
		Adjacentes: [38, 22, 33],
	},
	{
		Id: 5856,
		CodigoHtml: "FD9158",
		Nombre: "Boredom Buster",
		ColorNum: "1032",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 22,
		colorR: 253,
		colorG: 145,
		colorB: 88,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [22, 81],
		Triadicos: [22, 66, 92],
		Adjacentes: [38, 22, 33],
	},
	{
		Id: 5857,
		CodigoHtml: "E77741",
		Nombre: "Lava Lamp",
		ColorNum: "1033",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 22,
		colorR: 231,
		colorG: 119,
		colorB: 65,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [22, 81],
		Triadicos: [22, 66, 92],
		Adjacentes: [38, 22, 33],
	},
	{
		Id: 5858,
		CodigoHtml: "F7D1B7",
		Nombre: "Autumn Wind",
		ColorNum: "1034",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 23,
		colorR: 247,
		colorG: 209,
		colorB: 183,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [23, 82],
		Triadicos: [23, 68, 93],
		Adjacentes: [39, 23, 34],
	},
	{
		Id: 5859,
		CodigoHtml: "F4C7AB",
		Nombre: "Spiced Cider",
		ColorNum: "1035",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 23,
		colorR: 244,
		colorG: 199,
		colorB: 171,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [23, 82],
		Triadicos: [23, 68, 93],
		Adjacentes: [39, 23, 34],
	},
	{
		Id: 5860,
		CodigoHtml: "EFB597",
		Nombre: "Apricot Spring",
		ColorNum: "1036",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 23,
		colorR: 239,
		colorG: 181,
		colorB: 151,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [23, 82],
		Triadicos: [23, 68, 93],
		Adjacentes: [39, 23, 34],
	},
	{
		Id: 5861,
		CodigoHtml: "E29C7B",
		Nombre: "Trumpet Flower",
		ColorNum: "1037",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 23,
		colorR: 226,
		colorG: 156,
		colorB: 123,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [23, 82],
		Triadicos: [23, 68, 93],
		Adjacentes: [39, 23, 34],
	},
	{
		Id: 5862,
		CodigoHtml: "D17F58",
		Nombre: "Jack-O-Lantern",
		ColorNum: "1038",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 23,
		colorR: 209,
		colorG: 127,
		colorB: 88,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [23, 82],
		Triadicos: [23, 68, 93],
		Adjacentes: [39, 23, 34],
	},
	{
		Id: 5863,
		CodigoHtml: "AD674A",
		Nombre: "Pretty Parasol",
		ColorNum: "1039",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 23,
		colorR: 173,
		colorG: 103,
		colorB: 74,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [23, 82],
		Triadicos: [23, 68, 93],
		Adjacentes: [39, 23, 34],
	},
	{
		Id: 5864,
		CodigoHtml: "9B5244",
		Nombre: "Dark Marmalade",
		ColorNum: "1040",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 23,
		colorR: 155,
		colorG: 82,
		colorB: 68,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [23, 82],
		Triadicos: [23, 68, 93],
		Adjacentes: [39, 23, 34],
	},
	{
		Id: 5865,
		CodigoHtml: "FBE8D8",
		Nombre: "Flowerbed",
		ColorNum: "1041",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 24,
		colorR: 251,
		colorG: 232,
		colorB: 216,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [24, 67],
		Triadicos: [24, 51, 97],
		Adjacentes: [13, 24, 4],
	},
	{
		Id: 5866,
		CodigoHtml: "FFDEC9",
		Nombre: "Peak Season",
		ColorNum: "1042",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 24,
		colorR: 255,
		colorG: 222,
		colorB: 201,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [24, 67],
		Triadicos: [24, 51, 97],
		Adjacentes: [13, 24, 4],
	},
	{
		Id: 5867,
		CodigoHtml: "FFD2BA",
		Nombre: "Mom's Love",
		ColorNum: "1043",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 24,
		colorR: 255,
		colorG: 210,
		colorB: 186,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [24, 67],
		Triadicos: [24, 51, 97],
		Adjacentes: [13, 24, 4],
	},
	{
		Id: 5868,
		CodigoHtml: "FFB99E",
		Nombre: "Shooting Star",
		ColorNum: "1044",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 24,
		colorR: 255,
		colorG: 185,
		colorB: 158,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [24, 67],
		Triadicos: [24, 51, 97],
		Adjacentes: [13, 24, 4],
	},
	{
		Id: 5869,
		CodigoHtml: "FCA080",
		Nombre: "Creamy Orange Blush",
		ColorNum: "1045",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 24,
		colorR: 252,
		colorG: 160,
		colorB: 128,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [24, 67],
		Triadicos: [24, 51, 97],
		Adjacentes: [13, 24, 4],
	},
	{
		Id: 5870,
		CodigoHtml: "F88760",
		Nombre: "Ballet Cream",
		ColorNum: "1046",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 24,
		colorR: 248,
		colorG: 135,
		colorB: 96,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [24, 67],
		Triadicos: [24, 51, 97],
		Adjacentes: [13, 24, 4],
	},
	{
		Id: 5871,
		CodigoHtml: "ED6641",
		Nombre: "Starfish",
		ColorNum: "1047",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 24,
		colorR: 237,
		colorG: 102,
		colorB: 65,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [24, 67],
		Triadicos: [24, 51, 97],
		Adjacentes: [13, 24, 4],
	},
	{
		Id: 5872,
		CodigoHtml: "F9E3D9",
		Nombre: "Pale Shrimp",
		ColorNum: "1048",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 25,
		colorR: 249,
		colorG: 227,
		colorB: 217,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [25, 68],
		Triadicos: [25, 52, 98],
		Adjacentes: [14, 25, 5],
	},
	{
		Id: 5873,
		CodigoHtml: "FAD2C4",
		Nombre: "Salmon Mousse",
		ColorNum: "1049",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 25,
		colorR: 250,
		colorG: 210,
		colorB: 196,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [25, 68],
		Triadicos: [25, 52, 98],
		Adjacentes: [14, 25, 5],
	},
	{
		Id: 5874,
		CodigoHtml: "F7C7B6",
		Nombre: "Salmon Beauty",
		ColorNum: "1050",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 25,
		colorR: 247,
		colorG: 199,
		colorB: 182,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [25, 68],
		Triadicos: [25, 52, 98],
		Adjacentes: [14, 25, 5],
	},
	{
		Id: 5875,
		CodigoHtml: "EFB3A0",
		Nombre: "Stucco Wall",
		ColorNum: "1051",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 25,
		colorR: 239,
		colorG: 179,
		colorB: 160,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [25, 68],
		Triadicos: [25, 52, 98],
		Adjacentes: [14, 25, 5],
	},
	{
		Id: 5876,
		CodigoHtml: "DC8971",
		Nombre: "Summer Memory",
		ColorNum: "1052",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 25,
		colorR: 220,
		colorG: 137,
		colorB: 113,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [25, 68],
		Triadicos: [25, 52, 98],
		Adjacentes: [14, 25, 5],
	},
	{
		Id: 5877,
		CodigoHtml: "AC5844",
		Nombre: "Sun's Rage",
		ColorNum: "1053",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 25,
		colorR: 172,
		colorG: 88,
		colorB: 68,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [25, 68],
		Triadicos: [25, 52, 98],
		Adjacentes: [14, 25, 5],
	},
	{
		Id: 5878,
		CodigoHtml: "A45849",
		Nombre: "Carrot Cake",
		ColorNum: "1054",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 25,
		colorR: 164,
		colorG: 88,
		colorB: 73,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [25, 68],
		Triadicos: [25, 52, 98],
		Adjacentes: [14, 25, 5],
	},
	{
		Id: 5879,
		CodigoHtml: "F1E7DE",
		Nombre: "White Meadow",
		ColorNum: "1055",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 26,
		colorR: 241,
		colorG: 231,
		colorB: 222,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [26, 69],
		Triadicos: [26, 53, 99],
		Adjacentes: [15, 26, 6],
	},
	{
		Id: 5880,
		CodigoHtml: "F8E0D3",
		Nombre: "Elizabeth Rose",
		ColorNum: "1056",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 26,
		colorR: 248,
		colorG: 224,
		colorB: 211,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [26, 69],
		Triadicos: [26, 53, 99],
		Adjacentes: [15, 26, 6],
	},
	{
		Id: 5881,
		CodigoHtml: "F2C8B6",
		Nombre: "Pink Satin",
		ColorNum: "1057",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 26,
		colorR: 242,
		colorG: 200,
		colorB: 182,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [26, 69],
		Triadicos: [26, 53, 99],
		Adjacentes: [15, 26, 6],
	},
	{
		Id: 5882,
		CodigoHtml: "E9AD99",
		Nombre: "Pale Terra",
		ColorNum: "1058",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 26,
		colorR: 233,
		colorG: 173,
		colorB: 153,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [26, 69],
		Triadicos: [26, 53, 99],
		Adjacentes: [15, 26, 6],
	},
	{
		Id: 5883,
		CodigoHtml: "DB9B8A",
		Nombre: "Alexandra Peach",
		ColorNum: "1059",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 26,
		colorR: 219,
		colorG: 155,
		colorB: 138,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [26, 69],
		Triadicos: [26, 53, 99],
		Adjacentes: [15, 26, 6],
	},
	{
		Id: 5884,
		CodigoHtml: "C57F6D",
		Nombre: "First Lady",
		ColorNum: "1060",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 26,
		colorR: 197,
		colorG: 127,
		colorB: 109,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [26, 69],
		Triadicos: [26, 53, 99],
		Adjacentes: [15, 26, 6],
	},
	{
		Id: 5885,
		CodigoHtml: "8E4B3F",
		Nombre: "April Love",
		ColorNum: "1061",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 26,
		colorR: 142,
		colorG: 75,
		colorB: 63,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [26, 69],
		Triadicos: [26, 53, 99],
		Adjacentes: [15, 26, 6],
	},
	{
		Id: 5886,
		CodigoHtml: "F9EBE2",
		Nombre: "Albino",
		ColorNum: "1062",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 27,
		colorR: 249,
		colorG: 235,
		colorB: 226,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [27, 70],
		Triadicos: [27, 54, 100],
		Adjacentes: [16, 27, 7],
	},
	{
		Id: 5887,
		CodigoHtml: "FDD5C7",
		Nombre: "Tiny Pink",
		ColorNum: "1063",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 27,
		colorR: 253,
		colorG: 213,
		colorB: 199,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [27, 70],
		Triadicos: [27, 54, 100],
		Adjacentes: [16, 27, 7],
	},
	{
		Id: 5888,
		CodigoHtml: "F6B4A5",
		Nombre: "Mystic Tulip",
		ColorNum: "1064",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 27,
		colorR: 246,
		colorG: 180,
		colorB: 165,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [27, 70],
		Triadicos: [27, 54, 100],
		Adjacentes: [16, 27, 7],
	},
	{
		Id: 5889,
		CodigoHtml: "EF9E8D",
		Nombre: "Rose Essence",
		ColorNum: "1065",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 27,
		colorR: 239,
		colorG: 158,
		colorB: 141,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [27, 70],
		Triadicos: [27, 54, 100],
		Adjacentes: [16, 27, 7],
	},
	{
		Id: 5890,
		CodigoHtml: "E2816A",
		Nombre: "Sari",
		ColorNum: "1066",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 27,
		colorR: 226,
		colorG: 129,
		colorB: 106,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [27, 70],
		Triadicos: [27, 54, 100],
		Adjacentes: [16, 27, 7],
	},
	{
		Id: 5891,
		CodigoHtml: "C0594B",
		Nombre: "Sweet Baby Rose",
		ColorNum: "1067",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 27,
		colorR: 192,
		colorG: 89,
		colorB: 75,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [27, 70],
		Triadicos: [27, 54, 100],
		Adjacentes: [16, 27, 7],
	},
	{
		Id: 5892,
		CodigoHtml: "BE5D4C",
		Nombre: "Best of the Bunch",
		ColorNum: "1068",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 27,
		colorR: 190,
		colorG: 93,
		colorB: 76,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [27, 70],
		Triadicos: [27, 54, 100],
		Adjacentes: [16, 27, 7],
	},
	{
		Id: 5893,
		CodigoHtml: "FAE2D7",
		Nombre: "Pink Touch",
		ColorNum: "1069",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 28,
		colorR: 250,
		colorG: 226,
		colorB: 215,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [28, 71],
		Triadicos: [28, 55, 101],
		Adjacentes: [17, 28, 8],
	},
	{
		Id: 5894,
		CodigoHtml: "F9DDD5",
		Nombre: "Peony Prize",
		ColorNum: "1070",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 28,
		colorR: 249,
		colorG: 221,
		colorB: 213,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [28, 71],
		Triadicos: [28, 55, 101],
		Adjacentes: [17, 28, 8],
	},
	{
		Id: 5895,
		CodigoHtml: "F7CFC7",
		Nombre: "Cloud Number Nine",
		ColorNum: "1071",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 28,
		colorR: 247,
		colorG: 207,
		colorB: 199,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [28, 71],
		Triadicos: [28, 55, 101],
		Adjacentes: [17, 28, 8],
	},
	{
		Id: 5896,
		CodigoHtml: "EDB1A9",
		Nombre: "Lusty Orange",
		ColorNum: "1072",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 28,
		colorR: 237,
		colorG: 177,
		colorB: 169,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [28, 71],
		Triadicos: [28, 55, 101],
		Adjacentes: [17, 28, 8],
	},
	{
		Id: 5897,
		CodigoHtml: "DA8D80",
		Nombre: "Graceful Ballerina",
		ColorNum: "1073",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 28,
		colorR: 218,
		colorG: 141,
		colorB: 128,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [28, 71],
		Triadicos: [28, 55, 101],
		Adjacentes: [17, 28, 8],
	},
	{
		Id: 5898,
		CodigoHtml: "CC796B",
		Nombre: "It's My Party",
		ColorNum: "1074",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 28,
		colorR: 204,
		colorG: 121,
		colorB: 107,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [28, 71],
		Triadicos: [28, 55, 101],
		Adjacentes: [17, 28, 8],
	},
	{
		Id: 5899,
		CodigoHtml: "AC594B",
		Nombre: "Cherry Blink",
		ColorNum: "1075",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 28,
		colorR: 172,
		colorG: 89,
		colorB: 75,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [28, 71],
		Triadicos: [28, 55, 101],
		Adjacentes: [17, 28, 8],
	},
	{
		Id: 5900,
		CodigoHtml: "F8EFD2",
		Nombre: "Daylilly Yellow",
		ColorNum: "0859",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 29,
		colorR: 248,
		colorG: 239,
		colorB: 210,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [29, 97],
		Triadicos: [29, 67, 1],
		Adjacentes: [44, 29, 13],
	},
	{
		Id: 5901,
		CodigoHtml: "F9E8BA",
		Nombre: "Hawaiian Cream",
		ColorNum: "0860",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 29,
		colorR: 249,
		colorG: 232,
		colorB: 186,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [29, 97],
		Triadicos: [29, 67, 1],
		Adjacentes: [44, 29, 13],
	},
	{
		Id: 5902,
		CodigoHtml: "F9E4A9",
		Nombre: "Lemon Filling",
		ColorNum: "0861",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 29,
		colorR: 249,
		colorG: 228,
		colorB: 169,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [29, 97],
		Triadicos: [29, 67, 1],
		Adjacentes: [44, 29, 13],
	},
	{
		Id: 5903,
		CodigoHtml: "F7D98D",
		Nombre: "Yellow Trumpet",
		ColorNum: "0862",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 29,
		colorR: 247,
		colorG: 217,
		colorB: 141,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [29, 97],
		Triadicos: [29, 67, 1],
		Adjacentes: [44, 29, 13],
	},
	{
		Id: 5904,
		CodigoHtml: "E9C062",
		Nombre: "I'm a Local",
		ColorNum: "0863",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 29,
		colorR: 233,
		colorG: 192,
		colorB: 98,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [29, 97],
		Triadicos: [29, 67, 1],
		Adjacentes: [44, 29, 13],
	},
	{
		Id: 5905,
		CodigoHtml: "DFB24F",
		Nombre: "Lioness",
		ColorNum: "0864",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 29,
		colorR: 223,
		colorG: 178,
		colorB: 79,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [29, 97],
		Triadicos: [29, 67, 1],
		Adjacentes: [44, 29, 13],
	},
	{
		Id: 5906,
		CodigoHtml: "D0A43F",
		Nombre: "Lemon Bar",
		ColorNum: "0865",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 29,
		colorR: 208,
		colorG: 164,
		colorB: 63,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [29, 97],
		Triadicos: [29, 67, 1],
		Adjacentes: [44, 29, 13],
	},
	{
		Id: 5907,
		CodigoHtml: "F3EBD2",
		Nombre: "Apple Sauce",
		ColorNum: "0866",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 30,
		colorR: 243,
		colorG: 235,
		colorB: 210,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [30, 98],
		Triadicos: [30, 68, 2],
		Adjacentes: [45, 30, 14],
	},
	{
		Id: 5908,
		CodigoHtml: "F1E4BF",
		Nombre: "Egg Noodle",
		ColorNum: "0867",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 30,
		colorR: 241,
		colorG: 228,
		colorB: 191,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [30, 98],
		Triadicos: [30, 68, 2],
		Adjacentes: [45, 30, 14],
	},
	{
		Id: 5909,
		CodigoHtml: "EDDAAD",
		Nombre: "Shortcake",
		ColorNum: "0868",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 30,
		colorR: 237,
		colorG: 218,
		colorB: 173,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [30, 98],
		Triadicos: [30, 68, 2],
		Adjacentes: [45, 30, 14],
	},
	{
		Id: 5910,
		CodigoHtml: "E0CB9B",
		Nombre: "Frozen Fruit",
		ColorNum: "0869",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 30,
		colorR: 224,
		colorG: 203,
		colorB: 155,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [30, 98],
		Triadicos: [30, 68, 2],
		Adjacentes: [45, 30, 14],
	},
	{
		Id: 5911,
		CodigoHtml: "C3A767",
		Nombre: "Lemon Slice",
		ColorNum: "0870",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 30,
		colorR: 195,
		colorG: 167,
		colorB: 103,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [30, 98],
		Triadicos: [30, 68, 2],
		Adjacentes: [45, 30, 14],
	},
	{
		Id: 5912,
		CodigoHtml: "BA9D60",
		Nombre: "Gold Finch",
		ColorNum: "0871",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 30,
		colorR: 186,
		colorG: 157,
		colorB: 96,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [30, 98],
		Triadicos: [30, 68, 2],
		Adjacentes: [45, 30, 14],
	},
	{
		Id: 5913,
		CodigoHtml: "A88846",
		Nombre: "Saffron Valley",
		ColorNum: "0872",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 30,
		colorR: 168,
		colorG: 136,
		colorB: 70,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [30, 98],
		Triadicos: [30, 68, 2],
		Adjacentes: [45, 30, 14],
	},
	{
		Id: 5914,
		CodigoHtml: "F8E7C9",
		Nombre: "Washed in Light",
		ColorNum: "0873",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 31,
		colorR: 248,
		colorG: 231,
		colorB: 201,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [31, 99],
		Triadicos: [31, 69, 3],
		Adjacentes: [46, 31, 15],
	},
	{
		Id: 5915,
		CodigoHtml: "F4E1BE",
		Nombre: "Kettle Corn",
		ColorNum: "0874",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 31,
		colorR: 244,
		colorG: 225,
		colorB: 190,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [31, 99],
		Triadicos: [31, 69, 3],
		Adjacentes: [46, 31, 15],
	},
	{
		Id: 5916,
		CodigoHtml: "F0DDBA",
		Nombre: "Nothing Less",
		ColorNum: "0875",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 31,
		colorR: 240,
		colorG: 221,
		colorB: 186,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [31, 99],
		Triadicos: [31, 69, 3],
		Adjacentes: [46, 31, 15],
	},
	{
		Id: 5917,
		CodigoHtml: "DFC595",
		Nombre: "Corn Chowder",
		ColorNum: "0876",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 31,
		colorR: 223,
		colorG: 197,
		colorB: 149,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [31, 99],
		Triadicos: [31, 69, 3],
		Adjacentes: [46, 31, 15],
	},
	{
		Id: 5918,
		CodigoHtml: "D0B179",
		Nombre: "Gold Digger",
		ColorNum: "0877",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 31,
		colorR: 208,
		colorG: 177,
		colorB: 121,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [31, 99],
		Triadicos: [31, 69, 3],
		Adjacentes: [46, 31, 15],
	},
	{
		Id: 5919,
		CodigoHtml: "C2A167",
		Nombre: "Glorious Gold",
		ColorNum: "0878",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 31,
		colorR: 194,
		colorG: 161,
		colorB: 103,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [31, 99],
		Triadicos: [31, 69, 3],
		Adjacentes: [46, 31, 15],
	},
	{
		Id: 5920,
		CodigoHtml: "AC8950",
		Nombre: "Golden Cadillac",
		ColorNum: "0879",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 31,
		colorR: 172,
		colorG: 137,
		colorB: 80,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [31, 99],
		Triadicos: [31, 69, 3],
		Adjacentes: [46, 31, 15],
	},
	{
		Id: 5921,
		CodigoHtml: "F1E4C8",
		Nombre: "Cauliflower Cream",
		ColorNum: "0880",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 32,
		colorR: 241,
		colorG: 228,
		colorB: 200,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [32, 100],
		Triadicos: [32, 70, 4],
		Adjacentes: [47, 32, 16],
	},
	{
		Id: 5922,
		CodigoHtml: "EAD8B4",
		Nombre: "Golden Syrup",
		ColorNum: "0881",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 32,
		colorR: 234,
		colorG: 216,
		colorB: 180,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [32, 100],
		Triadicos: [32, 70, 4],
		Adjacentes: [47, 32, 16],
	},
	{
		Id: 5923,
		CodigoHtml: "E8D4AB",
		Nombre: "Marzipan",
		ColorNum: "0882",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 32,
		colorR: 232,
		colorG: 212,
		colorB: 171,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [32, 100],
		Triadicos: [32, 70, 4],
		Adjacentes: [47, 32, 16],
	},
	{
		Id: 5924,
		CodigoHtml: "DCC699",
		Nombre: "Follow the Leader",
		ColorNum: "0883",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 32,
		colorR: 220,
		colorG: 198,
		colorB: 153,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [32, 100],
		Triadicos: [32, 70, 4],
		Adjacentes: [47, 32, 16],
	},
	{
		Id: 5925,
		CodigoHtml: "CAAE78",
		Nombre: "Gold Tweed",
		ColorNum: "0884",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 32,
		colorR: 202,
		colorG: 174,
		colorB: 120,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [32, 100],
		Triadicos: [32, 70, 4],
		Adjacentes: [47, 32, 16],
	},
	{
		Id: 5926,
		CodigoHtml: "B5975D",
		Nombre: "Jungle Expedition",
		ColorNum: "0885",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 32,
		colorR: 181,
		colorG: 151,
		colorB: 93,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [32, 100],
		Triadicos: [32, 70, 4],
		Adjacentes: [47, 32, 16],
	},
	{
		Id: 5927,
		CodigoHtml: "9A804C",
		Nombre: "Gold Estate",
		ColorNum: "0886",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 32,
		colorR: 154,
		colorG: 128,
		colorB: 76,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [32, 100],
		Triadicos: [32, 70, 4],
		Adjacentes: [47, 32, 16],
	},
	{
		Id: 5928,
		CodigoHtml: "F0E4C9",
		Nombre: "Haystack",
		ColorNum: "0887",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 33,
		colorR: 240,
		colorG: 228,
		colorB: 201,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [33, 101],
		Triadicos: [33, 71, 5],
		Adjacentes: [48, 33, 17],
	},
	{
		Id: 5929,
		CodigoHtml: "E9DBC1",
		Nombre: "Golden Weave",
		ColorNum: "0888",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 33,
		colorR: 233,
		colorG: 219,
		colorB: 193,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [33, 101],
		Triadicos: [33, 71, 5],
		Adjacentes: [48, 33, 17],
	},
	{
		Id: 5930,
		CodigoHtml: "E8D6B0",
		Nombre: "Russeau Gold",
		ColorNum: "0889",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 33,
		colorR: 232,
		colorG: 214,
		colorB: 176,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [33, 101],
		Triadicos: [33, 71, 5],
		Adjacentes: [48, 33, 17],
	},
	{
		Id: 5931,
		CodigoHtml: "D4B88B",
		Nombre: "Dubloon",
		ColorNum: "0890",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 33,
		colorR: 212,
		colorG: 184,
		colorB: 139,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [33, 101],
		Triadicos: [33, 71, 5],
		Adjacentes: [48, 33, 17],
	},
	{
		Id: 5932,
		CodigoHtml: "C1A271",
		Nombre: "Silence is Golden",
		ColorNum: "0891",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 33,
		colorR: 193,
		colorG: 162,
		colorB: 113,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [33, 101],
		Triadicos: [33, 71, 5],
		Adjacentes: [48, 33, 17],
	},
	{
		Id: 5933,
		CodigoHtml: "B39465",
		Nombre: "Gold Ransom",
		ColorNum: "0892",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 33,
		colorR: 179,
		colorG: 148,
		colorB: 101,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [33, 101],
		Triadicos: [33, 71, 5],
		Adjacentes: [48, 33, 17],
	},
	{
		Id: 5934,
		CodigoHtml: "9A7C4D",
		Nombre: "Rich and Rare",
		ColorNum: "0893",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 33,
		colorR: 154,
		colorG: 124,
		colorB: 77,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [33, 101],
		Triadicos: [33, 71, 5],
		Adjacentes: [48, 33, 17],
	},
	{
		Id: 5935,
		CodigoHtml: "F5EAD1",
		Nombre: "Misty Mustard",
		ColorNum: "0894",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 34,
		colorR: 245,
		colorG: 234,
		colorB: 209,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [34, 102],
		Triadicos: [34, 72, 6],
		Adjacentes: [49, 34, 18],
	},
	{
		Id: 5936,
		CodigoHtml: "F4E0BF",
		Nombre: "Rotunda Gold",
		ColorNum: "0895",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 34,
		colorR: 244,
		colorG: 224,
		colorB: 191,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [34, 102],
		Triadicos: [34, 72, 6],
		Adjacentes: [49, 34, 18],
	},
	{
		Id: 5937,
		CodigoHtml: "EDD3AC",
		Nombre: "Coin Purse",
		ColorNum: "0896",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 34,
		colorR: 237,
		colorG: 211,
		colorB: 172,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [34, 102],
		Triadicos: [34, 72, 6],
		Adjacentes: [49, 34, 18],
	},
	{
		Id: 5938,
		CodigoHtml: "DFBE8B",
		Nombre: "Bag of Gold",
		ColorNum: "0897",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 34,
		colorR: 223,
		colorG: 190,
		colorB: 139,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [34, 102],
		Triadicos: [34, 72, 6],
		Adjacentes: [49, 34, 18],
	},
	{
		Id: 5939,
		CodigoHtml: "D3AC73",
		Nombre: "Goby Desert",
		ColorNum: "0898",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 34,
		colorR: 211,
		colorG: 172,
		colorB: 115,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [34, 102],
		Triadicos: [34, 72, 6],
		Adjacentes: [49, 34, 18],
	},
	{
		Id: 5940,
		CodigoHtml: "BC9354",
		Nombre: "Bugle Boy",
		ColorNum: "0899",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 34,
		colorR: 188,
		colorG: 147,
		colorB: 84,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [34, 102],
		Triadicos: [34, 72, 6],
		Adjacentes: [49, 34, 18],
	},
	{
		Id: 5941,
		CodigoHtml: "AF864D",
		Nombre: "Unforgettably Gold",
		ColorNum: "0900",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 34,
		colorR: 175,
		colorG: 134,
		colorB: 77,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [34, 102],
		Triadicos: [34, 72, 6],
		Adjacentes: [49, 34, 18],
	},
	{
		Id: 5942,
		CodigoHtml: "F6EEDA",
		Nombre: "Spinning Wheel",
		ColorNum: "0901",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 35,
		colorR: 246,
		colorG: 238,
		colorB: 218,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [35, 103],
		Triadicos: [35, 73, 7],
		Adjacentes: [50, 35, 19],
	},
	{
		Id: 5943,
		CodigoHtml: "F5DFB9",
		Nombre: "Soleil",
		ColorNum: "0902",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 35,
		colorR: 245,
		colorG: 223,
		colorB: 185,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [35, 103],
		Triadicos: [35, 73, 7],
		Adjacentes: [50, 35, 19],
	},
	{
		Id: 5944,
		CodigoHtml: "F3D5A6",
		Nombre: "Dainty Flower",
		ColorNum: "0903",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 35,
		colorR: 243,
		colorG: 213,
		colorB: 166,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [35, 103],
		Triadicos: [35, 73, 7],
		Adjacentes: [50, 35, 19],
	},
	{
		Id: 5945,
		CodigoHtml: "EFD29D",
		Nombre: "Sunday Afternoon",
		ColorNum: "0904",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 35,
		colorR: 239,
		colorG: 210,
		colorB: 157,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [35, 103],
		Triadicos: [35, 73, 7],
		Adjacentes: [50, 35, 19],
	},
	{
		Id: 5946,
		CodigoHtml: "E7C38A",
		Nombre: "Egyptian Gold",
		ColorNum: "0905",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 35,
		colorR: 231,
		colorG: 195,
		colorB: 138,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [35, 103],
		Triadicos: [35, 73, 7],
		Adjacentes: [50, 35, 19],
	},
	{
		Id: 5947,
		CodigoHtml: "D6AA64",
		Nombre: "Cat's Eye Marble",
		ColorNum: "0906",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 35,
		colorR: 214,
		colorG: 170,
		colorB: 100,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [35, 103],
		Triadicos: [35, 73, 7],
		Adjacentes: [50, 35, 19],
	},
	{
		Id: 5948,
		CodigoHtml: "B78944",
		Nombre: "Millionaire",
		ColorNum: "0907",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 35,
		colorR: 183,
		colorG: 137,
		colorB: 68,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [35, 103],
		Triadicos: [35, 73, 7],
		Adjacentes: [50, 35, 19],
	},
	{
		Id: 5949,
		CodigoHtml: "F7F0DD",
		Nombre: "God-Given",
		ColorNum: "0908",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 36,
		colorR: 247,
		colorG: 240,
		colorB: 221,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [36, 104],
		Triadicos: [36, 74, 8],
		Adjacentes: [51, 36, 20],
	},
	{
		Id: 5950,
		CodigoHtml: "F9E6C1",
		Nombre: "Autumn Child",
		ColorNum: "0909",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 36,
		colorR: 249,
		colorG: 230,
		colorB: 193,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [36, 104],
		Triadicos: [36, 74, 8],
		Adjacentes: [51, 36, 20],
	},
	{
		Id: 5951,
		CodigoHtml: "F8DCA9",
		Nombre: "Western Sky",
		ColorNum: "0910",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 36,
		colorR: 248,
		colorG: 220,
		colorB: 169,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [36, 104],
		Triadicos: [36, 74, 8],
		Adjacentes: [51, 36, 20],
	},
	{
		Id: 5952,
		CodigoHtml: "EFC689",
		Nombre: "Sunset in Italy",
		ColorNum: "0911",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 36,
		colorR: 239,
		colorG: 198,
		colorB: 137,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [36, 104],
		Triadicos: [36, 74, 8],
		Adjacentes: [51, 36, 20],
	},
	{
		Id: 5953,
		CodigoHtml: "E5B875",
		Nombre: "Tropical Dream",
		ColorNum: "0912",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 36,
		colorR: 229,
		colorG: 184,
		colorB: 117,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [36, 104],
		Triadicos: [36, 74, 8],
		Adjacentes: [51, 36, 20],
	},
	{
		Id: 5954,
		CodigoHtml: "D7A458",
		Nombre: "Angel Food Cake",
		ColorNum: "0913",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 36,
		colorR: 215,
		colorG: 164,
		colorB: 88,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [36, 104],
		Triadicos: [36, 74, 8],
		Adjacentes: [51, 36, 20],
	},
	{
		Id: 5955,
		CodigoHtml: "C79244",
		Nombre: "Child of the Moon",
		ColorNum: "0914",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 36,
		colorR: 199,
		colorG: 146,
		colorB: 68,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [36, 104],
		Triadicos: [36, 74, 8],
		Adjacentes: [51, 36, 20],
	},
	{
		Id: 5956,
		CodigoHtml: "F8EBD1",
		Nombre: "Queenly Laugh",
		ColorNum: "0915",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 37,
		colorR: 248,
		colorG: 235,
		colorB: 209,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [37, 105],
		Triadicos: [37, 75, 9],
		Adjacentes: [52, 37, 21],
	},
	{
		Id: 5957,
		CodigoHtml: "F8E7C9",
		Nombre: "Honey Cream",
		ColorNum: "0916",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 37,
		colorR: 248,
		colorG: 231,
		colorB: 201,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [37, 105],
		Triadicos: [37, 75, 9],
		Adjacentes: [52, 37, 21],
	},
	{
		Id: 5958,
		CodigoHtml: "F0D7AC",
		Nombre: "Canyon Sand",
		ColorNum: "0917",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 37,
		colorR: 240,
		colorG: 215,
		colorB: 172,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [37, 105],
		Triadicos: [37, 75, 9],
		Adjacentes: [52, 37, 21],
	},
	{
		Id: 5959,
		CodigoHtml: "EBD2A8",
		Nombre: "Good-Looking",
		ColorNum: "0918",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 37,
		colorR: 235,
		colorG: 210,
		colorB: 168,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [37, 105],
		Triadicos: [37, 75, 9],
		Adjacentes: [52, 37, 21],
	},
	{
		Id: 5960,
		CodigoHtml: "E5C694",
		Nombre: "Venice Square",
		ColorNum: "0919",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 37,
		colorR: 229,
		colorG: 198,
		colorB: 148,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [37, 105],
		Triadicos: [37, 75, 9],
		Adjacentes: [52, 37, 21],
	},
	{
		Id: 5961,
		CodigoHtml: "CAA367",
		Nombre: "Sunrise Heat",
		ColorNum: "0920",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 37,
		colorR: 202,
		colorG: 163,
		colorB: 103,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [37, 105],
		Triadicos: [37, 75, 9],
		Adjacentes: [52, 37, 21],
	},
	{
		Id: 5962,
		CodigoHtml: "B5884D",
		Nombre: "Waxen Moon",
		ColorNum: "0921",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 37,
		colorR: 181,
		colorG: 136,
		colorB: 77,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [37, 105],
		Triadicos: [37, 75, 9],
		Adjacentes: [52, 37, 21],
	},
	{
		Id: 5963,
		CodigoHtml: "F6EED8",
		Nombre: "Cheesecake",
		ColorNum: "0922",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 38,
		colorR: 246,
		colorG: 238,
		colorB: 216,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [38, 106],
		Triadicos: [38, 76, 10],
		Adjacentes: [53, 38, 22],
	},
	{
		Id: 5964,
		CodigoHtml: "FCE4B6",
		Nombre: "Tiffany Light",
		ColorNum: "0923",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 38,
		colorR: 252,
		colorG: 228,
		colorB: 182,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [38, 106],
		Triadicos: [38, 76, 10],
		Adjacentes: [53, 38, 22],
	},
	{
		Id: 5965,
		CodigoHtml: "F9DAA3",
		Nombre: "First Day of School",
		ColorNum: "0924",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 38,
		colorR: 249,
		colorG: 218,
		colorB: 163,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [38, 106],
		Triadicos: [38, 76, 10],
		Adjacentes: [53, 38, 22],
	},
	{
		Id: 5966,
		CodigoHtml: "F6D398",
		Nombre: "Jonquil Trail",
		ColorNum: "0925",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 38,
		colorR: 246,
		colorG: 211,
		colorB: 152,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [38, 106],
		Triadicos: [38, 76, 10],
		Adjacentes: [53, 38, 22],
	},
	{
		Id: 5967,
		CodigoHtml: "F0C985",
		Nombre: "Perky Yellow",
		ColorNum: "0926",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 38,
		colorR: 240,
		colorG: 201,
		colorB: 133,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [38, 106],
		Triadicos: [38, 76, 10],
		Adjacentes: [53, 38, 22],
	},
	{
		Id: 5968,
		CodigoHtml: "E0B05D",
		Nombre: "Lemon Poppy",
		ColorNum: "0927",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 38,
		colorR: 224,
		colorG: 176,
		colorB: 93,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [38, 106],
		Triadicos: [38, 76, 10],
		Adjacentes: [53, 38, 22],
	},
	{
		Id: 5969,
		CodigoHtml: "C59442",
		Nombre: "Yellow Warning",
		ColorNum: "0928",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 38,
		colorR: 197,
		colorG: 148,
		colorB: 66,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [38, 106],
		Triadicos: [38, 76, 10],
		Adjacentes: [53, 38, 22],
	},
	{
		Id: 5970,
		CodigoHtml: "F6F3E5",
		Nombre: "Yellow Bonnet",
		ColorNum: "0929",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 39,
		colorR: 246,
		colorG: 243,
		colorB: 229,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [39, 107],
		Triadicos: [39, 77, 11],
		Adjacentes: [54, 39, 23],
	},
	{
		Id: 5971,
		CodigoHtml: "F6F0D9",
		Nombre: "Marshmallow Fluff",
		ColorNum: "0930",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 39,
		colorR: 246,
		colorG: 240,
		colorB: 217,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [39, 107],
		Triadicos: [39, 77, 11],
		Adjacentes: [54, 39, 23],
	},
	{
		Id: 5972,
		CodigoHtml: "FAEBC0",
		Nombre: "Lemon Bubble",
		ColorNum: "0931",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 39,
		colorR: 250,
		colorG: 235,
		colorB: 192,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [39, 107],
		Triadicos: [39, 77, 11],
		Adjacentes: [54, 39, 23],
	},
	{
		Id: 5973,
		CodigoHtml: "F8D898",
		Nombre: "Sun Splashed",
		ColorNum: "0932",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 39,
		colorR: 248,
		colorG: 216,
		colorB: 152,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [39, 107],
		Triadicos: [39, 77, 11],
		Adjacentes: [54, 39, 23],
	},
	{
		Id: 5974,
		CodigoHtml: "EEC377",
		Nombre: "Sassy Yellow",
		ColorNum: "0933",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 39,
		colorR: 238,
		colorG: 195,
		colorB: 119,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [39, 107],
		Triadicos: [39, 77, 11],
		Adjacentes: [54, 39, 23],
	},
	{
		Id: 5975,
		CodigoHtml: "E0B057",
		Nombre: "Lemon Essence",
		ColorNum: "0934",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 39,
		colorR: 224,
		colorG: 176,
		colorB: 87,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [39, 107],
		Triadicos: [39, 77, 11],
		Adjacentes: [54, 39, 23],
	},
	{
		Id: 5976,
		CodigoHtml: "D09C3C",
		Nombre: "Yellow Shout",
		ColorNum: "0935",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 39,
		colorR: 208,
		colorG: 156,
		colorB: 60,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [39, 107],
		Triadicos: [39, 77, 11],
		Adjacentes: [54, 39, 23],
	},
	{
		Id: 5977,
		CodigoHtml: "F9F2D9",
		Nombre: "Diantha",
		ColorNum: "0936",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 40,
		colorR: 249,
		colorG: 242,
		colorB: 217,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [40, 108],
		Triadicos: [40, 78, 12],
		Adjacentes: [55, 40, 24],
	},
	{
		Id: 5978,
		CodigoHtml: "FBF1D1",
		Nombre: "Egg Nog",
		ColorNum: "0937",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 40,
		colorR: 251,
		colorG: 241,
		colorB: 209,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [40, 108],
		Triadicos: [40, 78, 12],
		Adjacentes: [55, 40, 24],
	},
	{
		Id: 5979,
		CodigoHtml: "FEE2A4",
		Nombre: "Primrose Path",
		ColorNum: "0938",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 40,
		colorR: 254,
		colorG: 226,
		colorB: 164,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [40, 108],
		Triadicos: [40, 78, 12],
		Adjacentes: [55, 40, 24],
	},
	{
		Id: 5980,
		CodigoHtml: "FFD98E",
		Nombre: "Big Bus Yellow",
		ColorNum: "0939",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 40,
		colorR: 255,
		colorG: 217,
		colorB: 142,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [40, 108],
		Triadicos: [40, 78, 12],
		Adjacentes: [55, 40, 24],
	},
	{
		Id: 5981,
		CodigoHtml: "FECA67",
		Nombre: "Grilled Cheese",
		ColorNum: "0940",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 40,
		colorR: 254,
		colorG: 202,
		colorB: 103,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [40, 108],
		Triadicos: [40, 78, 12],
		Adjacentes: [55, 40, 24],
	},
	{
		Id: 5982,
		CodigoHtml: "F9B945",
		Nombre: "Glowing Lantern",
		ColorNum: "0941",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 40,
		colorR: 249,
		colorG: 185,
		colorB: 69,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [40, 108],
		Triadicos: [40, 78, 12],
		Adjacentes: [55, 40, 24],
	},
	{
		Id: 5983,
		CodigoHtml: "ECA72A",
		Nombre: "Lemon Dream",
		ColorNum: "0942",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 40,
		colorR: 236,
		colorG: 167,
		colorB: 42,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [40, 108],
		Triadicos: [40, 78, 12],
		Adjacentes: [55, 40, 24],
	},
	{
		Id: 5984,
		CodigoHtml: "F9F0D0",
		Nombre: "Picture Perfect",
		ColorNum: "0943",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 41,
		colorR: 249,
		colorG: 240,
		colorB: 208,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [41, 109],
		Triadicos: [41, 79, 13],
		Adjacentes: [56, 41, 25],
	},
	{
		Id: 5985,
		CodigoHtml: "FCEDC2",
		Nombre: "Sunrise Glow",
		ColorNum: "0944",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 41,
		colorR: 252,
		colorG: 237,
		colorB: 194,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [41, 109],
		Triadicos: [41, 79, 13],
		Adjacentes: [56, 41, 25],
	},
	{
		Id: 5986,
		CodigoHtml: "FDE9AE",
		Nombre: "Treasure Seeker",
		ColorNum: "0945",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 41,
		colorR: 253,
		colorG: 233,
		colorB: 174,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [41, 109],
		Triadicos: [41, 79, 13],
		Adjacentes: [56, 41, 25],
	},
	{
		Id: 5987,
		CodigoHtml: "FFE298",
		Nombre: "Hot Spot",
		ColorNum: "0946",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 41,
		colorR: 255,
		colorG: 226,
		colorB: 152,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [41, 109],
		Triadicos: [41, 79, 13],
		Adjacentes: [56, 41, 25],
	},
	{
		Id: 5988,
		CodigoHtml: "FFD269",
		Nombre: "Bright Halo",
		ColorNum: "0947",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 41,
		colorR: 255,
		colorG: 210,
		colorB: 105,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [41, 109],
		Triadicos: [41, 79, 13],
		Adjacentes: [56, 41, 25],
	},
	{
		Id: 5989,
		CodigoHtml: "FFC63C",
		Nombre: "Bright Bubble",
		ColorNum: "0948",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 41,
		colorR: 255,
		colorG: 198,
		colorB: 60,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [41, 109],
		Triadicos: [41, 79, 13],
		Adjacentes: [56, 41, 25],
	},
	{
		Id: 5990,
		CodigoHtml: "FFB305",
		Nombre: "Frenzy",
		ColorNum: "0949",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 41,
		colorR: 255,
		colorG: 179,
		colorB: 5,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [41, 109],
		Triadicos: [41, 79, 13],
		Adjacentes: [56, 41, 25],
	},
	{
		Id: 5991,
		CodigoHtml: "F7F2DB",
		Nombre: "Ivory Coast",
		ColorNum: "0950",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 42,
		colorR: 247,
		colorG: 242,
		colorB: 219,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [42, 110],
		Triadicos: [42, 80, 14],
		Adjacentes: [57, 42, 26],
	},
	{
		Id: 5992,
		CodigoHtml: "FAEFC2",
		Nombre: "Pound Cake",
		ColorNum: "0951",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 42,
		colorR: 250,
		colorG: 239,
		colorB: 194,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [42, 110],
		Triadicos: [42, 80, 14],
		Adjacentes: [57, 42, 26],
	},
	{
		Id: 5993,
		CodigoHtml: "FEE59F",
		Nombre: "Rich Glow",
		ColorNum: "0952",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 42,
		colorR: 254,
		colorG: 229,
		colorB: 159,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [42, 110],
		Triadicos: [42, 80, 14],
		Adjacentes: [57, 42, 26],
	},
	{
		Id: 5994,
		CodigoHtml: "FFD368",
		Nombre: "Evening Star",
		ColorNum: "0953",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 42,
		colorR: 255,
		colorG: 211,
		colorB: 104,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [42, 110],
		Triadicos: [42, 80, 14],
		Adjacentes: [57, 42, 26],
	},
	{
		Id: 5995,
		CodigoHtml: "FFC137",
		Nombre: "Mac N Cheese",
		ColorNum: "0954",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 42,
		colorR: 255,
		colorG: 193,
		colorB: 55,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [42, 110],
		Triadicos: [42, 80, 14],
		Adjacentes: [57, 42, 26],
	},
	{
		Id: 5996,
		CodigoHtml: "FFB428",
		Nombre: "Lemon Whisper",
		ColorNum: "0955",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 42,
		colorR: 255,
		colorG: 180,
		colorB: 40,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [42, 110],
		Triadicos: [42, 80, 14],
		Adjacentes: [57, 42, 26],
	},
	{
		Id: 5997,
		CodigoHtml: "FDAB25",
		Nombre: "I Love To Boogie",
		ColorNum: "0956",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 42,
		colorR: 253,
		colorG: 171,
		colorB: 37,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [42, 110],
		Triadicos: [42, 80, 14],
		Adjacentes: [57, 42, 26],
	},
	{
		Id: 5998,
		CodigoHtml: "F8F2DF",
		Nombre: "Pale Narcissus",
		ColorNum: "0957",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 43,
		colorR: 248,
		colorG: 242,
		colorB: 223,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [43, 111],
		Triadicos: [43, 81, 15],
		Adjacentes: [58, 43, 27],
	},
	{
		Id: 5999,
		CodigoHtml: "FBEBC6",
		Nombre: "Holy Grail",
		ColorNum: "0958",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 43,
		colorR: 251,
		colorG: 235,
		colorB: 198,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [43, 111],
		Triadicos: [43, 81, 15],
		Adjacentes: [58, 43, 27],
	},
	{
		Id: 6000,
		CodigoHtml: "FFDEA2",
		Nombre: "Essentially Bright",
		ColorNum: "0959",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 43,
		colorR: 255,
		colorG: 222,
		colorB: 162,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [43, 111],
		Triadicos: [43, 81, 15],
		Adjacentes: [58, 43, 27],
	},
	{
		Id: 6001,
		CodigoHtml: "FFD185",
		Nombre: "Bread 'n Butter",
		ColorNum: "0960",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 43,
		colorR: 255,
		colorG: 209,
		colorB: 133,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [43, 111],
		Triadicos: [43, 81, 15],
		Adjacentes: [58, 43, 27],
	},
	{
		Id: 6002,
		CodigoHtml: "FFC36A",
		Nombre: "Gold's Great Touch",
		ColorNum: "0961",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 43,
		colorR: 255,
		colorG: 195,
		colorB: 106,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [43, 111],
		Triadicos: [43, 81, 15],
		Adjacentes: [58, 43, 27],
	},
	{
		Id: 6003,
		CodigoHtml: "FFB144",
		Nombre: "Pieces of Eight",
		ColorNum: "0962",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 43,
		colorR: 255,
		colorG: 177,
		colorB: 68,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [43, 111],
		Triadicos: [43, 81, 15],
		Adjacentes: [58, 43, 27],
	},
	{
		Id: 6004,
		CodigoHtml: "FF9A29",
		Nombre: "Zambia",
		ColorNum: "0963",
		EsPrincipal: false,
		GroupNum: 4,
		SubGroupNum: 43,
		colorR: 255,
		colorG: 154,
		colorB: 41,
		MonoCromaticos: [
			29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
		],
		Complementarios: [43, 111],
		Triadicos: [43, 81, 15],
		Adjacentes: [58, 43, 27],
	},
	{
		Id: 6005,
		CodigoHtml: "F5EECF",
		Nombre: "Annabel",
		ColorNum: "0810",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 44,
		colorR: 245,
		colorG: 238,
		colorB: 207,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [44, 83],
		Triadicos: [44, 72, 4],
		Adjacentes: [51, 44, 29],
	},
	{
		Id: 6006,
		CodigoHtml: "F3E5B9",
		Nombre: "Martica",
		ColorNum: "0811",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 44,
		colorR: 243,
		colorG: 229,
		colorB: 185,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [44, 83],
		Triadicos: [44, 72, 4],
		Adjacentes: [51, 44, 29],
	},
	{
		Id: 6007,
		CodigoHtml: "F2DFA8",
		Nombre: "Gold Strand",
		ColorNum: "0812",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 44,
		colorR: 242,
		colorG: 223,
		colorB: 168,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [44, 83],
		Triadicos: [44, 72, 4],
		Adjacentes: [51, 44, 29],
	},
	{
		Id: 6008,
		CodigoHtml: "ECD38B",
		Nombre: "Glistening",
		ColorNum: "0813",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 44,
		colorR: 236,
		colorG: 211,
		colorB: 139,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [44, 83],
		Triadicos: [44, 72, 4],
		Adjacentes: [51, 44, 29],
	},
	{
		Id: 6009,
		CodigoHtml: "EBCC76",
		Nombre: "Golden Glow",
		ColorNum: "0814",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 44,
		colorR: 235,
		colorG: 204,
		colorB: 118,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [44, 83],
		Triadicos: [44, 72, 4],
		Adjacentes: [51, 44, 29],
	},
	{
		Id: 6010,
		CodigoHtml: "E0BE62",
		Nombre: "Lemon Surprise",
		ColorNum: "0815",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 44,
		colorR: 224,
		colorG: 190,
		colorB: 98,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [44, 83],
		Triadicos: [44, 72, 4],
		Adjacentes: [51, 44, 29],
	},
	{
		Id: 6011,
		CodigoHtml: "CFA541",
		Nombre: "Sin City",
		ColorNum: "0816",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 44,
		colorR: 207,
		colorG: 165,
		colorB: 65,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [44, 83],
		Triadicos: [44, 72, 4],
		Adjacentes: [51, 44, 29],
	},
	{
		Id: 6012,
		CodigoHtml: "F6F2DA",
		Nombre: "Best of Summer",
		ColorNum: "0817",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 45,
		colorR: 246,
		colorG: 242,
		colorB: 218,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [45, 84],
		Triadicos: [45, 73, 5],
		Adjacentes: [52, 45, 30],
	},
	{
		Id: 6013,
		CodigoHtml: "F7F2D7",
		Nombre: "Day at the Zoo",
		ColorNum: "0818",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 45,
		colorR: 247,
		colorG: 242,
		colorB: 215,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [45, 84],
		Triadicos: [45, 73, 5],
		Adjacentes: [52, 45, 30],
	},
	{
		Id: 6014,
		CodigoHtml: "F9F0C7",
		Nombre: "Lemon Tint",
		ColorNum: "0819",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 45,
		colorR: 249,
		colorG: 240,
		colorB: 199,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [45, 84],
		Triadicos: [45, 73, 5],
		Adjacentes: [52, 45, 30],
	},
	{
		Id: 6015,
		CodigoHtml: "FBE29B",
		Nombre: "Sign of the Crown",
		ColorNum: "0820",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 45,
		colorR: 251,
		colorG: 226,
		colorB: 155,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [45, 84],
		Triadicos: [45, 73, 5],
		Adjacentes: [52, 45, 30],
	},
	{
		Id: 6016,
		CodigoHtml: "F7D67A",
		Nombre: "Sun Touched",
		ColorNum: "0821",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 45,
		colorR: 247,
		colorG: 214,
		colorB: 122,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [45, 84],
		Triadicos: [45, 73, 5],
		Adjacentes: [52, 45, 30],
	},
	{
		Id: 6017,
		CodigoHtml: "F3C854",
		Nombre: "Sunny Mood",
		ColorNum: "0822",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 45,
		colorR: 243,
		colorG: 200,
		colorB: 84,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [45, 84],
		Triadicos: [45, 73, 5],
		Adjacentes: [52, 45, 30],
	},
	{
		Id: 6018,
		CodigoHtml: "E8B229",
		Nombre: "Sun Drops",
		ColorNum: "0823",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 45,
		colorR: 232,
		colorG: 178,
		colorB: 41,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [45, 84],
		Triadicos: [45, 73, 5],
		Adjacentes: [52, 45, 30],
	},
	{
		Id: 6019,
		CodigoHtml: "F6F3D9",
		Nombre: "Hush",
		ColorNum: "0824",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 46,
		colorR: 246,
		colorG: 243,
		colorB: 217,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [46, 85],
		Triadicos: [46, 74, 6],
		Adjacentes: [53, 46, 31],
	},
	{
		Id: 6020,
		CodigoHtml: "F8F2D2",
		Nombre: "Frozen Banana",
		ColorNum: "0825",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 46,
		colorR: 248,
		colorG: 242,
		colorB: 210,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [46, 85],
		Triadicos: [46, 74, 6],
		Adjacentes: [53, 46, 31],
	},
	{
		Id: 6021,
		CodigoHtml: "FAF0C2",
		Nombre: "Banana Custard",
		ColorNum: "0826",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 46,
		colorR: 250,
		colorG: 240,
		colorB: 194,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [46, 85],
		Triadicos: [46, 74, 6],
		Adjacentes: [53, 46, 31],
	},
	{
		Id: 6022,
		CodigoHtml: "FAEFBB",
		Nombre: "Ray of Light",
		ColorNum: "0827",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 46,
		colorR: 250,
		colorG: 239,
		colorB: 187,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [46, 85],
		Triadicos: [46, 74, 6],
		Adjacentes: [53, 46, 31],
	},
	{
		Id: 6023,
		CodigoHtml: "FCE28E",
		Nombre: "Go Go Glow",
		ColorNum: "0828",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 46,
		colorR: 252,
		colorG: 226,
		colorB: 142,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [46, 85],
		Triadicos: [46, 74, 6],
		Adjacentes: [53, 46, 31],
	},
	{
		Id: 6024,
		CodigoHtml: "F7CD58",
		Nombre: "Dandy Lion",
		ColorNum: "0829",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 46,
		colorR: 247,
		colorG: 205,
		colorB: 88,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [46, 85],
		Triadicos: [46, 74, 6],
		Adjacentes: [53, 46, 31],
	},
	{
		Id: 6025,
		CodigoHtml: "F1B837",
		Nombre: "Warm Fuzzies",
		ColorNum: "0830",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 46,
		colorR: 241,
		colorG: 184,
		colorB: 55,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [46, 85],
		Triadicos: [46, 74, 6],
		Adjacentes: [53, 46, 31],
	},
	{
		Id: 6026,
		CodigoHtml: "F8EFCB",
		Nombre: "Lavish Lemon",
		ColorNum: "0831",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 47,
		colorR: 248,
		colorG: 239,
		colorB: 203,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [47, 86],
		Triadicos: [47, 75, 7],
		Adjacentes: [54, 47, 32],
	},
	{
		Id: 6027,
		CodigoHtml: "F8ECB0",
		Nombre: "Lemon Sponge Cake",
		ColorNum: "0832",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 47,
		colorR: 248,
		colorG: 236,
		colorB: 176,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [47, 86],
		Triadicos: [47, 75, 7],
		Adjacentes: [54, 47, 32],
	},
	{
		Id: 6028,
		CodigoHtml: "F9E89E",
		Nombre: "Scene Stealer",
		ColorNum: "0833",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 47,
		colorR: 249,
		colorG: 232,
		colorB: 158,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [47, 86],
		Triadicos: [47, 75, 7],
		Adjacentes: [54, 47, 32],
	},
	{
		Id: 6029,
		CodigoHtml: "FCE589",
		Nombre: "Lemon Drizzle",
		ColorNum: "0834",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 47,
		colorR: 252,
		colorG: 229,
		colorB: 137,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [47, 86],
		Triadicos: [47, 75, 7],
		Adjacentes: [54, 47, 32],
	},
	{
		Id: 6030,
		CodigoHtml: "F6D862",
		Nombre: "Glitter Yellow",
		ColorNum: "0835",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 47,
		colorR: 246,
		colorG: 216,
		colorB: 98,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [47, 86],
		Triadicos: [47, 75, 7],
		Adjacentes: [54, 47, 32],
	},
	{
		Id: 6031,
		CodigoHtml: "F3CD47",
		Nombre: "Basket of Gold",
		ColorNum: "0836",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 47,
		colorR: 243,
		colorG: 205,
		colorB: 71,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [47, 86],
		Triadicos: [47, 75, 7],
		Adjacentes: [54, 47, 32],
	},
	{
		Id: 6032,
		CodigoHtml: "F0C32F",
		Nombre: "Fresh Scent",
		ColorNum: "0837",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 47,
		colorR: 240,
		colorG: 195,
		colorB: 47,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [47, 86],
		Triadicos: [47, 75, 7],
		Adjacentes: [54, 47, 32],
	},
	{
		Id: 6033,
		CodigoHtml: "F8F4DC",
		Nombre: "Fresh Cream",
		ColorNum: "0838",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 48,
		colorR: 248,
		colorG: 244,
		colorB: 220,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [48, 87],
		Triadicos: [48, 76, 8],
		Adjacentes: [55, 48, 33],
	},
	{
		Id: 6034,
		CodigoHtml: "FBF1C4",
		Nombre: "Butterball",
		ColorNum: "0839",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 48,
		colorR: 251,
		colorG: 241,
		colorB: 196,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [48, 87],
		Triadicos: [48, 76, 8],
		Adjacentes: [55, 48, 33],
	},
	{
		Id: 6035,
		CodigoHtml: "FDEEA5",
		Nombre: "Lemon Zest",
		ColorNum: "0840",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 48,
		colorR: 253,
		colorG: 238,
		colorB: 165,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [48, 87],
		Triadicos: [48, 76, 8],
		Adjacentes: [55, 48, 33],
	},
	{
		Id: 6036,
		CodigoHtml: "FAE16A",
		Nombre: "Sun's Glory",
		ColorNum: "0841",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 48,
		colorR: 250,
		colorG: 225,
		colorB: 106,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [48, 87],
		Triadicos: [48, 76, 8],
		Adjacentes: [55, 48, 33],
	},
	{
		Id: 6037,
		CodigoHtml: "F7D22D",
		Nombre: "Sunnyside",
		ColorNum: "0842",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 48,
		colorR: 247,
		colorG: 210,
		colorB: 45,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [48, 87],
		Triadicos: [48, 76, 8],
		Adjacentes: [55, 48, 33],
	},
	{
		Id: 6038,
		CodigoHtml: "F0CA14",
		Nombre: "Buttered Popcorn",
		ColorNum: "0843",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 48,
		colorR: 240,
		colorG: 202,
		colorB: 20,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [48, 87],
		Triadicos: [48, 76, 8],
		Adjacentes: [55, 48, 33],
	},
	{
		Id: 6039,
		CodigoHtml: "EDC80C",
		Nombre: "Yolk",
		ColorNum: "0844",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 48,
		colorR: 237,
		colorG: 200,
		colorB: 12,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [48, 87],
		Triadicos: [48, 76, 8],
		Adjacentes: [55, 48, 33],
	},
	{
		Id: 6040,
		CodigoHtml: "F7F4DD",
		Nombre: "Lemon Stick",
		ColorNum: "0845",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 49,
		colorR: 247,
		colorG: 244,
		colorB: 221,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [49, 88],
		Triadicos: [49, 77, 9],
		Adjacentes: [56, 49, 34],
	},
	{
		Id: 6041,
		CodigoHtml: "F9F2C8",
		Nombre: "Pure Laughter",
		ColorNum: "0846",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 49,
		colorR: 249,
		colorG: 242,
		colorB: 200,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [49, 88],
		Triadicos: [49, 77, 9],
		Adjacentes: [56, 49, 34],
	},
	{
		Id: 6042,
		CodigoHtml: "FAF1B9",
		Nombre: "Yellow Blitz",
		ColorNum: "0847",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 49,
		colorR: 250,
		colorG: 241,
		colorB: 185,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [49, 88],
		Triadicos: [49, 77, 9],
		Adjacentes: [56, 49, 34],
	},
	{
		Id: 6043,
		CodigoHtml: "FEEE9A",
		Nombre: "Yellow Tail",
		ColorNum: "0848",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 49,
		colorR: 254,
		colorG: 238,
		colorB: 154,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [49, 88],
		Triadicos: [49, 77, 9],
		Adjacentes: [56, 49, 34],
	},
	{
		Id: 6044,
		CodigoHtml: "FFEA81",
		Nombre: "Lemon Peel",
		ColorNum: "0849",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 49,
		colorR: 255,
		colorG: 234,
		colorB: 129,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [49, 88],
		Triadicos: [49, 77, 9],
		Adjacentes: [56, 49, 34],
	},
	{
		Id: 6045,
		CodigoHtml: "FFE04D",
		Nombre: "Citron",
		ColorNum: "0850",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 49,
		colorR: 255,
		colorG: 224,
		colorB: 77,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [49, 88],
		Triadicos: [49, 77, 9],
		Adjacentes: [56, 49, 34],
	},
	{
		Id: 6046,
		CodigoHtml: "FFD328",
		Nombre: "Happy Face",
		ColorNum: "0851",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 49,
		colorR: 255,
		colorG: 211,
		colorB: 40,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [49, 88],
		Triadicos: [49, 77, 9],
		Adjacentes: [56, 49, 34],
	},
	{
		Id: 6047,
		CodigoHtml: "F7F5E5",
		Nombre: "Touch of Sun",
		ColorNum: "0852",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 50,
		colorR: 247,
		colorG: 245,
		colorB: 229,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [50, 89],
		Triadicos: [50, 78, 10],
		Adjacentes: [57, 50, 35],
	},
	{
		Id: 6048,
		CodigoHtml: "F8F2D6",
		Nombre: "Lemon Lilly",
		ColorNum: "0853",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 50,
		colorR: 248,
		colorG: 242,
		colorB: 214,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [50, 89],
		Triadicos: [50, 78, 10],
		Adjacentes: [57, 50, 35],
	},
	{
		Id: 6049,
		CodigoHtml: "FBEFC5",
		Nombre: "Crocus Tint",
		ColorNum: "0854",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 50,
		colorR: 251,
		colorG: 239,
		colorB: 197,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [50, 89],
		Triadicos: [50, 78, 10],
		Adjacentes: [57, 50, 35],
	},
	{
		Id: 6050,
		CodigoHtml: "FFE79D",
		Nombre: "Butter Tart",
		ColorNum: "0855",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 50,
		colorR: 255,
		colorG: 231,
		colorB: 157,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [50, 89],
		Triadicos: [50, 78, 10],
		Adjacentes: [57, 50, 35],
	},
	{
		Id: 6051,
		CodigoHtml: "FFDF7B",
		Nombre: "Broadway Lights",
		ColorNum: "0856",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 50,
		colorR: 255,
		colorG: 223,
		colorB: 123,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [50, 89],
		Triadicos: [50, 78, 10],
		Adjacentes: [57, 50, 35],
	},
	{
		Id: 6052,
		CodigoHtml: "FFD046",
		Nombre: "Peeps",
		ColorNum: "0857",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 50,
		colorR: 255,
		colorG: 208,
		colorB: 70,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [50, 89],
		Triadicos: [50, 78, 10],
		Adjacentes: [57, 50, 35],
	},
	{
		Id: 6053,
		CodigoHtml: "FFC100",
		Nombre: "Look at the Bright Side",
		ColorNum: "0858",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 50,
		colorR: 255,
		colorG: 193,
		colorB: 0,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [50, 89],
		Triadicos: [50, 78, 10],
		Adjacentes: [57, 50, 35],
	},
	{
		Id: 6054,
		CodigoHtml: "EFF1DC",
		Nombre: "Restful Rain",
		ColorNum: "0768",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 51,
		colorR: 239,
		colorG: 241,
		colorB: 220,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [51, 1],
		Triadicos: [51, 97, 24],
		Adjacentes: [57, 51, 44],
	},
	{
		Id: 6055,
		CodigoHtml: "E4EEC9",
		Nombre: "Earth Happiness",
		ColorNum: "0769",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 51,
		colorR: 228,
		colorG: 238,
		colorB: 201,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [51, 1],
		Triadicos: [51, 97, 24],
		Adjacentes: [57, 51, 44],
	},
	{
		Id: 6056,
		CodigoHtml: "D6E8B5",
		Nombre: "Woodland Nymph",
		ColorNum: "0770",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 51,
		colorR: 214,
		colorG: 232,
		colorB: 181,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [51, 1],
		Triadicos: [51, 97, 24],
		Adjacentes: [57, 51, 44],
	},
	{
		Id: 6057,
		CodigoHtml: "C4DB9C",
		Nombre: "Green Lime",
		ColorNum: "0771",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 51,
		colorR: 196,
		colorG: 219,
		colorB: 156,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [51, 1],
		Triadicos: [51, 97, 24],
		Adjacentes: [57, 51, 44],
	},
	{
		Id: 6058,
		CodigoHtml: "AFCD7F",
		Nombre: "Wonder Woods",
		ColorNum: "0772",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 51,
		colorR: 175,
		colorG: 205,
		colorB: 127,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [51, 1],
		Triadicos: [51, 97, 24],
		Adjacentes: [57, 51, 44],
	},
	{
		Id: 6059,
		CodigoHtml: "99BD5F",
		Nombre: "Glass Bottle",
		ColorNum: "0773",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 51,
		colorR: 153,
		colorG: 189,
		colorB: 95,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [51, 1],
		Triadicos: [51, 97, 24],
		Adjacentes: [57, 51, 44],
	},
	{
		Id: 6060,
		CodigoHtml: "7AA542",
		Nombre: "Green Gone Wild",
		ColorNum: "0774",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 51,
		colorR: 122,
		colorG: 165,
		colorB: 66,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [51, 1],
		Triadicos: [51, 97, 24],
		Adjacentes: [57, 51, 44],
	},
	{
		Id: 6061,
		CodigoHtml: "EDEECA",
		Nombre: "Elfin Magic",
		ColorNum: "0775",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 52,
		colorR: 237,
		colorG: 238,
		colorB: 202,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [52, 2],
		Triadicos: [52, 98, 25],
		Adjacentes: [58, 52, 45],
	},
	{
		Id: 6062,
		CodigoHtml: "E5EABA",
		Nombre: "Green Mist",
		ColorNum: "0776",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 52,
		colorR: 229,
		colorG: 234,
		colorB: 186,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [52, 2],
		Triadicos: [52, 98, 25],
		Adjacentes: [58, 52, 45],
	},
	{
		Id: 6063,
		CodigoHtml: "E0E6AF",
		Nombre: "Envy",
		ColorNum: "0777",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 52,
		colorR: 224,
		colorG: 230,
		colorB: 175,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [52, 2],
		Triadicos: [52, 98, 25],
		Adjacentes: [58, 52, 45],
	},
	{
		Id: 6064,
		CodigoHtml: "D3DA94",
		Nombre: "Vacation Island",
		ColorNum: "0778",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 52,
		colorR: 211,
		colorG: 218,
		colorB: 148,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [52, 2],
		Triadicos: [52, 98, 25],
		Adjacentes: [58, 52, 45],
	},
	{
		Id: 6065,
		CodigoHtml: "C5CE7F",
		Nombre: "Frog Green",
		ColorNum: "0779",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 52,
		colorR: 197,
		colorG: 206,
		colorB: 127,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [52, 2],
		Triadicos: [52, 98, 25],
		Adjacentes: [58, 52, 45],
	},
	{
		Id: 6066,
		CodigoHtml: "AAB85B",
		Nombre: "Hyper",
		ColorNum: "0780",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 52,
		colorR: 170,
		colorG: 184,
		colorB: 91,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [52, 2],
		Triadicos: [52, 98, 25],
		Adjacentes: [58, 52, 45],
	},
	{
		Id: 6067,
		CodigoHtml: "96A440",
		Nombre: "Science Experiment",
		ColorNum: "0781",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 52,
		colorR: 150,
		colorG: 164,
		colorB: 64,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [52, 2],
		Triadicos: [52, 98, 25],
		Adjacentes: [58, 52, 45],
	},
	{
		Id: 6068,
		CodigoHtml: "E8EACE",
		Nombre: "Tender Shoot",
		ColorNum: "0782",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 53,
		colorR: 232,
		colorG: 234,
		colorB: 206,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [53, 3],
		Triadicos: [53, 99, 26],
		Adjacentes: [59, 53, 46],
	},
	{
		Id: 6069,
		CodigoHtml: "D9DDB7",
		Nombre: "Clean Air",
		ColorNum: "0783",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 53,
		colorR: 217,
		colorG: 221,
		colorB: 183,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [53, 3],
		Triadicos: [53, 99, 26],
		Adjacentes: [59, 53, 46],
	},
	{
		Id: 6070,
		CodigoHtml: "CFD4A6",
		Nombre: "Fresh Start",
		ColorNum: "0784",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 53,
		colorR: 207,
		colorG: 212,
		colorB: 166,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [53, 3],
		Triadicos: [53, 99, 26],
		Adjacentes: [59, 53, 46],
	},
	{
		Id: 6071,
		CodigoHtml: "BEC48C",
		Nombre: "Misty Valley ",
		ColorNum: "0785",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 53,
		colorR: 190,
		colorG: 196,
		colorB: 140,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [53, 3],
		Triadicos: [53, 99, 26],
		Adjacentes: [59, 53, 46],
	},
	{
		Id: 6072,
		CodigoHtml: "A7AB6B",
		Nombre: "Peter Pan",
		ColorNum: "0786",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 53,
		colorR: 167,
		colorG: 171,
		colorB: 107,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [53, 3],
		Triadicos: [53, 99, 26],
		Adjacentes: [59, 53, 46],
	},
	{
		Id: 6073,
		CodigoHtml: "949B59",
		Nombre: "Totally Cool",
		ColorNum: "0787",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 53,
		colorR: 148,
		colorG: 155,
		colorB: 89,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [53, 3],
		Triadicos: [53, 99, 26],
		Adjacentes: [59, 53, 46],
	},
	{
		Id: 6074,
		CodigoHtml: "7D8447",
		Nombre: "Green Glow",
		ColorNum: "0788",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 53,
		colorR: 125,
		colorG: 132,
		colorB: 71,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [53, 3],
		Triadicos: [53, 99, 26],
		Adjacentes: [59, 53, 46],
	},
	{
		Id: 6075,
		CodigoHtml: "EAE6C8",
		Nombre: "Garden Seat",
		ColorNum: "0789",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 54,
		colorR: 234,
		colorG: 230,
		colorB: 200,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [54, 4],
		Triadicos: [54, 100, 27],
		Adjacentes: [60, 54, 47],
	},
	{
		Id: 6076,
		CodigoHtml: "E0DFBB",
		Nombre: "Forest Found",
		ColorNum: "0790",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 54,
		colorR: 224,
		colorG: 223,
		colorB: 187,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [54, 4],
		Triadicos: [54, 100, 27],
		Adjacentes: [60, 54, 47],
	},
	{
		Id: 6077,
		CodigoHtml: "D8D5A9",
		Nombre: "Origin",
		ColorNum: "0791",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 54,
		colorR: 216,
		colorG: 213,
		colorB: 169,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [54, 4],
		Triadicos: [54, 100, 27],
		Adjacentes: [60, 54, 47],
	},
	{
		Id: 6078,
		CodigoHtml: "CAC68C",
		Nombre: "Footie Pajamas",
		ColorNum: "0792",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 54,
		colorR: 202,
		colorG: 198,
		colorB: 140,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [54, 4],
		Triadicos: [54, 100, 27],
		Adjacentes: [60, 54, 47],
	},
	{
		Id: 6079,
		CodigoHtml: "BDB86C",
		Nombre: "Motherland",
		ColorNum: "0793",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 54,
		colorR: 189,
		colorG: 184,
		colorB: 108,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [54, 4],
		Triadicos: [54, 100, 27],
		Adjacentes: [60, 54, 47],
	},
	{
		Id: 6080,
		CodigoHtml: "ABA857",
		Nombre: "The Goods",
		ColorNum: "0794",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 54,
		colorR: 171,
		colorG: 168,
		colorB: 87,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [54, 4],
		Triadicos: [54, 100, 27],
		Adjacentes: [60, 54, 47],
	},
	{
		Id: 6081,
		CodigoHtml: "8F9046",
		Nombre: "Martina Olive",
		ColorNum: "0795",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 54,
		colorR: 143,
		colorG: 144,
		colorB: 70,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [54, 4],
		Triadicos: [54, 100, 27],
		Adjacentes: [60, 54, 47],
	},
	{
		Id: 6082,
		CodigoHtml: "F4EFD3",
		Nombre: "Spotlight",
		ColorNum: "0796",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 55,
		colorR: 244,
		colorG: 239,
		colorB: 211,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [55, 5],
		Triadicos: [55, 101, 28],
		Adjacentes: [17, 55, 48],
	},
	{
		Id: 6083,
		CodigoHtml: "EFE9C0",
		Nombre: "Bermuda Son",
		ColorNum: "0797",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 55,
		colorR: 239,
		colorG: 233,
		colorB: 192,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [55, 5],
		Triadicos: [55, 101, 28],
		Adjacentes: [17, 55, 48],
	},
	{
		Id: 6084,
		CodigoHtml: "EDE3AF",
		Nombre: "Lemon Appeal",
		ColorNum: "0798",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 55,
		colorR: 237,
		colorG: 227,
		colorB: 175,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [55, 5],
		Triadicos: [55, 101, 28],
		Adjacentes: [17, 55, 48],
	},
	{
		Id: 6085,
		CodigoHtml: "E1D593",
		Nombre: "Fire Dance",
		ColorNum: "0799",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 55,
		colorR: 225,
		colorG: 213,
		colorB: 147,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [55, 5],
		Triadicos: [55, 101, 28],
		Adjacentes: [17, 55, 48],
	},
	{
		Id: 6086,
		CodigoHtml: "CCBD68",
		Nombre: "Yellow Umbrella",
		ColorNum: "0800",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 55,
		colorR: 204,
		colorG: 189,
		colorB: 104,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [55, 5],
		Triadicos: [55, 101, 28],
		Adjacentes: [17, 55, 48],
	},
	{
		Id: 6087,
		CodigoHtml: "BCAB51",
		Nombre: "Rain Slicker",
		ColorNum: "0801",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 55,
		colorR: 188,
		colorG: 171,
		colorB: 81,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [55, 5],
		Triadicos: [55, 101, 28],
		Adjacentes: [17, 55, 48],
	},
	{
		Id: 6088,
		CodigoHtml: "A79A45",
		Nombre: "Rain Boots",
		ColorNum: "0802",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 55,
		colorR: 167,
		colorG: 154,
		colorB: 69,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [55, 5],
		Triadicos: [55, 101, 28],
		Adjacentes: [17, 55, 48],
	},
	{
		Id: 6089,
		CodigoHtml: "F8F2D3",
		Nombre: "Perky Tint",
		ColorNum: "0803",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 56,
		colorR: 248,
		colorG: 242,
		colorB: 211,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [56, 6],
		Triadicos: [56, 102, 29],
		Adjacentes: [62, 56, 49],
	},
	{
		Id: 6090,
		CodigoHtml: "F4E5B7",
		Nombre: "Sweetie Pie",
		ColorNum: "0804",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 56,
		colorR: 244,
		colorG: 229,
		colorB: 183,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [56, 6],
		Triadicos: [56, 102, 29],
		Adjacentes: [62, 56, 49],
	},
	{
		Id: 6091,
		CodigoHtml: "EFDEA4",
		Nombre: "Mella Yella",
		ColorNum: "0805",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 56,
		colorR: 239,
		colorG: 222,
		colorB: 164,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [56, 6],
		Triadicos: [56, 102, 29],
		Adjacentes: [62, 56, 49],
	},
	{
		Id: 6092,
		CodigoHtml: "E7D190",
		Nombre: "Sweet Angelica",
		ColorNum: "0806",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 56,
		colorR: 231,
		colorG: 209,
		colorB: 144,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [56, 6],
		Triadicos: [56, 102, 29],
		Adjacentes: [62, 56, 49],
	},
	{
		Id: 6093,
		CodigoHtml: "D8B966",
		Nombre: "Bright Idea",
		ColorNum: "0807",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 56,
		colorR: 216,
		colorG: 185,
		colorB: 102,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [56, 6],
		Triadicos: [56, 102, 29],
		Adjacentes: [62, 56, 49],
	},
	{
		Id: 6094,
		CodigoHtml: "CCAD54",
		Nombre: "Yellow Lupine",
		ColorNum: "0808",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 56,
		colorR: 204,
		colorG: 173,
		colorB: 84,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [56, 6],
		Triadicos: [56, 102, 29],
		Adjacentes: [62, 56, 49],
	},
	{
		Id: 6095,
		CodigoHtml: "BE9E43",
		Nombre: "Hair Ribbon",
		ColorNum: "0809",
		EsPrincipal: false,
		GroupNum: 6,
		SubGroupNum: 56,
		colorR: 190,
		colorG: 158,
		colorB: 67,
		MonoCromaticos: [51, 52, 53, 54, 55, 56],
		Complementarios: [56, 6],
		Triadicos: [56, 102, 29],
		Adjacentes: [62, 56, 49],
	},
	{
		Id: 6096,
		CodigoHtml: "E4ECE1",
		Nombre: "Calm Thoughts",
		ColorNum: "0698",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 57,
		colorR: 228,
		colorG: 236,
		colorB: 225,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [57, 4],
		Triadicos: [57, 83, 13],
		Adjacentes: [67, 57, 51],
	},
	{
		Id: 6097,
		CodigoHtml: "C5D9D1",
		Nombre: "Lickety Split",
		ColorNum: "0699",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 57,
		colorR: 197,
		colorG: 217,
		colorB: 209,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [57, 4],
		Triadicos: [57, 83, 13],
		Adjacentes: [67, 57, 51],
	},
	{
		Id: 6098,
		CodigoHtml: "B8D1C7",
		Nombre: "Uninhibited",
		ColorNum: "0700",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 57,
		colorR: 184,
		colorG: 209,
		colorB: 199,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [57, 4],
		Triadicos: [57, 83, 13],
		Adjacentes: [67, 57, 51],
	},
	{
		Id: 6099,
		CodigoHtml: "A8C3B9",
		Nombre: "Plunge",
		ColorNum: "0701",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 57,
		colorR: 168,
		colorG: 195,
		colorB: 185,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [57, 4],
		Triadicos: [57, 83, 13],
		Adjacentes: [67, 57, 51],
	},
	{
		Id: 6100,
		CodigoHtml: "91AFA4",
		Nombre: "Peg's Promise",
		ColorNum: "0702",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 57,
		colorR: 145,
		colorG: 175,
		colorB: 164,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [57, 4],
		Triadicos: [57, 83, 13],
		Adjacentes: [67, 57, 51],
	},
	{
		Id: 6101,
		CodigoHtml: "6D8C80",
		Nombre: "Mountain Meadow",
		ColorNum: "0703",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 57,
		colorR: 109,
		colorG: 140,
		colorB: 128,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [57, 4],
		Triadicos: [57, 83, 13],
		Adjacentes: [67, 57, 51],
	},
	{
		Id: 6102,
		CodigoHtml: "546D61",
		Nombre: "Plateau",
		ColorNum: "0704",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 57,
		colorR: 84,
		colorG: 109,
		colorB: 97,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [57, 4],
		Triadicos: [57, 83, 13],
		Adjacentes: [67, 57, 51],
	},
	{
		Id: 6103,
		CodigoHtml: "D5EBE0",
		Nombre: "Bay Green",
		ColorNum: "0705",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 58,
		colorR: 213,
		colorG: 235,
		colorB: 224,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [58, 5],
		Triadicos: [58, 84, 14],
		Adjacentes: [68, 58, 52],
	},
	{
		Id: 6104,
		CodigoHtml: "BAE1D2",
		Nombre: "Filtered Forest",
		ColorNum: "0706",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 58,
		colorR: 186,
		colorG: 225,
		colorB: 210,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [58, 5],
		Triadicos: [58, 84, 14],
		Adjacentes: [68, 58, 52],
	},
	{
		Id: 6105,
		CodigoHtml: "A8D7C6",
		Nombre: "Shimmering Glade",
		ColorNum: "0707",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 58,
		colorR: 168,
		colorG: 215,
		colorB: 198,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [58, 5],
		Triadicos: [58, 84, 14],
		Adjacentes: [68, 58, 52],
	},
	{
		Id: 6106,
		CodigoHtml: "96C6B6",
		Nombre: "Catarina Green",
		ColorNum: "0708",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 58,
		colorR: 150,
		colorG: 198,
		colorB: 182,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [58, 5],
		Triadicos: [58, 84, 14],
		Adjacentes: [68, 58, 52],
	},
	{
		Id: 6107,
		CodigoHtml: "72AC98",
		Nombre: "Calmness",
		ColorNum: "0709",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 58,
		colorR: 114,
		colorG: 172,
		colorB: 152,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [58, 5],
		Triadicos: [58, 84, 14],
		Adjacentes: [68, 58, 52],
	},
	{
		Id: 6108,
		CodigoHtml: "55927A",
		Nombre: "On the Nile",
		ColorNum: "0710",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 58,
		colorR: 85,
		colorG: 146,
		colorB: 122,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [58, 5],
		Triadicos: [58, 84, 14],
		Adjacentes: [68, 58, 52],
	},
	{
		Id: 6109,
		CodigoHtml: "2D5C4F",
		Nombre: "Cute Pixie",
		ColorNum: "0711",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 58,
		colorR: 45,
		colorG: 92,
		colorB: 79,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [58, 5],
		Triadicos: [58, 84, 14],
		Adjacentes: [68, 58, 52],
	},
	{
		Id: 6110,
		CodigoHtml: "DAF1E2",
		Nombre: "Teal Treat",
		ColorNum: "0712",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 59,
		colorR: 218,
		colorG: 241,
		colorB: 226,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [59, 6],
		Triadicos: [59, 85, 15],
		Adjacentes: [69, 59, 53],
	},
	{
		Id: 6111,
		CodigoHtml: "C0E9D7",
		Nombre: "Mediterranean Mist",
		ColorNum: "0713",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 59,
		colorR: 192,
		colorG: 233,
		colorB: 215,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [59, 6],
		Triadicos: [59, 85, 15],
		Adjacentes: [69, 59, 53],
	},
	{
		Id: 6112,
		CodigoHtml: "AFE4CC",
		Nombre: "Turquoise Tower",
		ColorNum: "0714",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 59,
		colorR: 175,
		colorG: 228,
		colorB: 204,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [59, 6],
		Triadicos: [59, 85, 15],
		Adjacentes: [69, 59, 53],
	},
	{
		Id: 6113,
		CodigoHtml: "88CFAF",
		Nombre: "Aquamarine Ocean",
		ColorNum: "0715",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 59,
		colorR: 136,
		colorG: 207,
		colorB: 175,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [59, 6],
		Triadicos: [59, 85, 15],
		Adjacentes: [69, 59, 53],
	},
	{
		Id: 6114,
		CodigoHtml: "62B992",
		Nombre: "Blue Green Scene",
		ColorNum: "0716",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 59,
		colorR: 98,
		colorG: 185,
		colorB: 146,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [59, 6],
		Triadicos: [59, 85, 15],
		Adjacentes: [69, 59, 53],
	},
	{
		Id: 6115,
		CodigoHtml: "40A374",
		Nombre: "Tropical Tale",
		ColorNum: "0717",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 59,
		colorR: 64,
		colorG: 163,
		colorB: 116,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [59, 6],
		Triadicos: [59, 85, 15],
		Adjacentes: [69, 59, 53],
	},
	{
		Id: 6116,
		CodigoHtml: "227654",
		Nombre: "Aquadazzle",
		ColorNum: "0718",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 59,
		colorR: 34,
		colorG: 118,
		colorB: 84,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [59, 6],
		Triadicos: [59, 85, 15],
		Adjacentes: [69, 59, 53],
	},
	{
		Id: 6117,
		CodigoHtml: "E2EFDF",
		Nombre: "Mint Grasshopper",
		ColorNum: "0719",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 60,
		colorR: 226,
		colorG: 239,
		colorB: 223,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [60, 7],
		Triadicos: [60, 86, 16],
		Adjacentes: [70, 60, 54],
	},
	{
		Id: 6118,
		CodigoHtml: "D8EBD8",
		Nombre: "Silky Mint",
		ColorNum: "0720",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 60,
		colorR: 216,
		colorG: 235,
		colorB: 216,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [60, 7],
		Triadicos: [60, 86, 16],
		Adjacentes: [70, 60, 54],
	},
	{
		Id: 6119,
		CodigoHtml: "C8E5CD",
		Nombre: "Leaves of Spring",
		ColorNum: "0721",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 60,
		colorR: 200,
		colorG: 229,
		colorB: 205,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [60, 7],
		Triadicos: [60, 86, 16],
		Adjacentes: [70, 60, 54],
	},
	{
		Id: 6120,
		CodigoHtml: "ABD6B4",
		Nombre: "Liliana",
		ColorNum: "0722",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 60,
		colorR: 171,
		colorG: 214,
		colorB: 180,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [60, 7],
		Triadicos: [60, 86, 16],
		Adjacentes: [70, 60, 54],
	},
	{
		Id: 6121,
		CodigoHtml: "7EB086",
		Nombre: "Goddess Green",
		ColorNum: "0723",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 60,
		colorR: 126,
		colorG: 176,
		colorB: 134,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [60, 7],
		Triadicos: [60, 86, 16],
		Adjacentes: [70, 60, 54],
	},
	{
		Id: 6122,
		CodigoHtml: "689B6C",
		Nombre: "Wildness Mint",
		ColorNum: "0724",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 60,
		colorR: 104,
		colorG: 155,
		colorB: 108,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [60, 7],
		Triadicos: [60, 86, 16],
		Adjacentes: [70, 60, 54],
	},
	{
		Id: 6123,
		CodigoHtml: "578153",
		Nombre: "Melbourne",
		ColorNum: "0725",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 60,
		colorR: 87,
		colorG: 129,
		colorB: 83,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [60, 7],
		Triadicos: [60, 86, 16],
		Adjacentes: [70, 60, 54],
	},
	{
		Id: 6124,
		CodigoHtml: "EBF1E3",
		Nombre: "Hidden Jade",
		ColorNum: "0726",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 61,
		colorR: 235,
		colorG: 241,
		colorB: 227,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [61, 8],
		Triadicos: [61, 87, 17],
		Adjacentes: [71, 61, 55],
	},
	{
		Id: 6125,
		CodigoHtml: "DAEED6",
		Nombre: "Graceful Mint",
		ColorNum: "0727",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 61,
		colorR: 218,
		colorG: 238,
		colorB: 214,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [61, 8],
		Triadicos: [61, 87, 17],
		Adjacentes: [71, 61, 55],
	},
	{
		Id: 6126,
		CodigoHtml: "ADDEB3",
		Nombre: "Celtic Spring",
		ColorNum: "0728",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 61,
		colorR: 173,
		colorG: 222,
		colorB: 179,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [61, 8],
		Triadicos: [61, 87, 17],
		Adjacentes: [71, 61, 55],
	},
	{
		Id: 6127,
		CodigoHtml: "9ECFA2",
		Nombre: "Garden Goddess",
		ColorNum: "0729",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 61,
		colorR: 158,
		colorG: 207,
		colorB: 162,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [61, 8],
		Triadicos: [61, 87, 17],
		Adjacentes: [71, 61, 55],
	},
	{
		Id: 6128,
		CodigoHtml: "7FBB81",
		Nombre: "Lucerne",
		ColorNum: "0730",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 61,
		colorR: 127,
		colorG: 187,
		colorB: 129,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [61, 8],
		Triadicos: [61, 87, 17],
		Adjacentes: [71, 61, 55],
	},
	{
		Id: 6129,
		CodigoHtml: "61A462",
		Nombre: "Aqueous ",
		ColorNum: "0731",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 61,
		colorR: 97,
		colorG: 164,
		colorB: 98,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [61, 8],
		Triadicos: [61, 87, 17],
		Adjacentes: [71, 61, 55],
	},
	{
		Id: 6130,
		CodigoHtml: "3D8145",
		Nombre: "Tingle",
		ColorNum: "0732",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 61,
		colorR: 61,
		colorG: 129,
		colorB: 69,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [61, 8],
		Triadicos: [61, 87, 17],
		Adjacentes: [71, 61, 55],
	},
	{
		Id: 6131,
		CodigoHtml: "DFECD9",
		Nombre: "Leaf Print",
		ColorNum: "0733",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 62,
		colorR: 223,
		colorG: 236,
		colorB: 217,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [62, 9],
		Triadicos: [62, 88, 18],
		Adjacentes: [72, 62, 56],
	},
	{
		Id: 6132,
		CodigoHtml: "C4DCC9",
		Nombre: "Vineyard Green",
		ColorNum: "0734",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 62,
		colorR: 196,
		colorG: 220,
		colorB: 201,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [62, 9],
		Triadicos: [62, 88, 18],
		Adjacentes: [72, 62, 56],
	},
	{
		Id: 6133,
		CodigoHtml: "BED9C3",
		Nombre: "Cool Spring",
		ColorNum: "0735",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 62,
		colorR: 190,
		colorG: 217,
		colorB: 195,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [62, 9],
		Triadicos: [62, 88, 18],
		Adjacentes: [72, 62, 56],
	},
	{
		Id: 6134,
		CodigoHtml: "A3C6AC",
		Nombre: "Mini Green",
		ColorNum: "0736",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 62,
		colorR: 163,
		colorG: 198,
		colorB: 172,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [62, 9],
		Triadicos: [62, 88, 18],
		Adjacentes: [72, 62, 56],
	},
	{
		Id: 6135,
		CodigoHtml: "8EB597",
		Nombre: "Buenos Aires",
		ColorNum: "0737",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 62,
		colorR: 142,
		colorG: 181,
		colorB: 151,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [62, 9],
		Triadicos: [62, 88, 18],
		Adjacentes: [72, 62, 56],
	},
	{
		Id: 6136,
		CodigoHtml: "6E9675",
		Nombre: "Spring Forest",
		ColorNum: "0738",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 62,
		colorR: 110,
		colorG: 150,
		colorB: 117,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [62, 9],
		Triadicos: [62, 88, 18],
		Adjacentes: [72, 62, 56],
	},
	{
		Id: 6137,
		CodigoHtml: "4F6A4E",
		Nombre: "Jungle Adventure",
		ColorNum: "0739",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 62,
		colorR: 79,
		colorG: 106,
		colorB: 78,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [62, 9],
		Triadicos: [62, 88, 18],
		Adjacentes: [72, 62, 56],
	},
	{
		Id: 6138,
		CodigoHtml: "E1E6D3",
		Nombre: "Just About Green",
		ColorNum: "0740",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 63,
		colorR: 225,
		colorG: 230,
		colorB: 211,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [63, 10],
		Triadicos: [63, 89, 19],
		Adjacentes: [73, 63, 57],
	},
	{
		Id: 6139,
		CodigoHtml: "CFD9C4",
		Nombre: "Turning Leaf",
		ColorNum: "0741",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 63,
		colorR: 207,
		colorG: 217,
		colorB: 196,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [63, 10],
		Triadicos: [63, 89, 19],
		Adjacentes: [73, 63, 57],
	},
	{
		Id: 6140,
		CodigoHtml: "BECDB6",
		Nombre: "Isle of Dreams",
		ColorNum: "0742",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 63,
		colorR: 190,
		colorG: 205,
		colorB: 182,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [63, 10],
		Triadicos: [63, 89, 19],
		Adjacentes: [73, 63, 57],
	},
	{
		Id: 6141,
		CodigoHtml: "B0C4A9",
		Nombre: "Belladonna's Leaf",
		ColorNum: "0743",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 63,
		colorR: 176,
		colorG: 196,
		colorB: 169,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [63, 10],
		Triadicos: [63, 89, 19],
		Adjacentes: [73, 63, 57],
	},
	{
		Id: 6142,
		CodigoHtml: "9BAF91",
		Nombre: "Hidden Glade",
		ColorNum: "0744",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 63,
		colorR: 155,
		colorG: 175,
		colorB: 145,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [63, 10],
		Triadicos: [63, 89, 19],
		Adjacentes: [73, 63, 57],
	},
	{
		Id: 6143,
		CodigoHtml: "809675",
		Nombre: "Chic Shade",
		ColorNum: "0745",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 63,
		colorR: 128,
		colorG: 150,
		colorB: 117,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [63, 10],
		Triadicos: [63, 89, 19],
		Adjacentes: [73, 63, 57],
	},
	{
		Id: 6144,
		CodigoHtml: "5D6C50",
		Nombre: "Mother Nature",
		ColorNum: "0746",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 63,
		colorR: 93,
		colorG: 108,
		colorB: 80,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [63, 10],
		Triadicos: [63, 89, 19],
		Adjacentes: [73, 63, 57],
	},
	{
		Id: 6145,
		CodigoHtml: "E9EBD8",
		Nombre: "Whitewash",
		ColorNum: "0747",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 64,
		colorR: 233,
		colorG: 235,
		colorB: 216,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [64, 11],
		Triadicos: [64, 90, 20],
		Adjacentes: [74, 64, 58],
	},
	{
		Id: 6146,
		CodigoHtml: "D3DCC3",
		Nombre: "Balance",
		ColorNum: "0748",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 64,
		colorR: 211,
		colorG: 220,
		colorB: 195,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [64, 11],
		Triadicos: [64, 90, 20],
		Adjacentes: [74, 64, 58],
	},
	{
		Id: 6147,
		CodigoHtml: "C7D1B5",
		Nombre: "Perspective",
		ColorNum: "0749",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 64,
		colorR: 199,
		colorG: 209,
		colorB: 181,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [64, 11],
		Triadicos: [64, 90, 20],
		Adjacentes: [74, 64, 58],
	},
	{
		Id: 6148,
		CodigoHtml: "BEC8A6",
		Nombre: "Wispy Mint",
		ColorNum: "0750",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 64,
		colorR: 190,
		colorG: 200,
		colorB: 166,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [64, 11],
		Triadicos: [64, 90, 20],
		Adjacentes: [74, 64, 58],
	},
	{
		Id: 6149,
		CodigoHtml: "AEBA95",
		Nombre: "Green Glass",
		ColorNum: "0751",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 64,
		colorR: 174,
		colorG: 186,
		colorB: 149,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [64, 11],
		Triadicos: [64, 90, 20],
		Adjacentes: [74, 64, 58],
	},
	{
		Id: 6150,
		CodigoHtml: "8C9A70",
		Nombre: "Cactus Valley",
		ColorNum: "0752",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 64,
		colorR: 140,
		colorG: 154,
		colorB: 112,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [64, 11],
		Triadicos: [64, 90, 20],
		Adjacentes: [74, 64, 58],
	},
	{
		Id: 6151,
		CodigoHtml: "65704D",
		Nombre: "Baby Vegetable",
		ColorNum: "0753",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 64,
		colorR: 101,
		colorG: 112,
		colorB: 77,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [64, 11],
		Triadicos: [64, 90, 20],
		Adjacentes: [74, 64, 58],
	},
	{
		Id: 6152,
		CodigoHtml: "E9EDD9",
		Nombre: "Colleen Green",
		ColorNum: "0754",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 65,
		colorR: 233,
		colorG: 237,
		colorB: 217,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [65, 12],
		Triadicos: [65, 91, 21],
		Adjacentes: [75, 65, 59],
	},
	{
		Id: 6153,
		CodigoHtml: "CEDDBC",
		Nombre: "Creamy Mint",
		ColorNum: "0755",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 65,
		colorR: 206,
		colorG: 221,
		colorB: 188,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [65, 12],
		Triadicos: [65, 91, 21],
		Adjacentes: [75, 65, 59],
	},
	{
		Id: 6154,
		CodigoHtml: "C3D6B2",
		Nombre: "Meadow Grass",
		ColorNum: "0756",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 65,
		colorR: 195,
		colorG: 214,
		colorB: 178,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [65, 12],
		Triadicos: [65, 91, 21],
		Adjacentes: [75, 65, 59],
	},
	{
		Id: 6155,
		CodigoHtml: "BCD0A8",
		Nombre: "Garland Pine",
		ColorNum: "0757",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 65,
		colorR: 188,
		colorG: 208,
		colorB: 168,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [65, 12],
		Triadicos: [65, 91, 21],
		Adjacentes: [75, 65, 59],
	},
	{
		Id: 6156,
		CodigoHtml: "A5BD8E",
		Nombre: "Glendale",
		ColorNum: "0758",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 65,
		colorR: 165,
		colorG: 189,
		colorB: 142,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [65, 12],
		Triadicos: [65, 91, 21],
		Adjacentes: [75, 65, 59],
	},
	{
		Id: 6157,
		CodigoHtml: "8BA874",
		Nombre: "Perennial Garden",
		ColorNum: "0759",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 65,
		colorR: 139,
		colorG: 168,
		colorB: 116,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [65, 12],
		Triadicos: [65, 91, 21],
		Adjacentes: [75, 65, 59],
	},
	{
		Id: 6158,
		CodigoHtml: "6D8553",
		Nombre: "Green Knoll",
		ColorNum: "0760",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 65,
		colorR: 109,
		colorG: 133,
		colorB: 83,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [65, 12],
		Triadicos: [65, 91, 21],
		Adjacentes: [75, 65, 59],
	},
	{
		Id: 6159,
		CodigoHtml: "E4EFD8",
		Nombre: "Maison Verte",
		ColorNum: "0761",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 66,
		colorR: 228,
		colorG: 239,
		colorB: 216,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [66, 13],
		Triadicos: [66, 92, 22],
		Adjacentes: [76, 66, 60],
	},
	{
		Id: 6160,
		CodigoHtml: "D1E8C4",
		Nombre: "Green Song",
		ColorNum: "0762",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 66,
		colorR: 209,
		colorG: 232,
		colorB: 196,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [66, 13],
		Triadicos: [66, 92, 22],
		Adjacentes: [76, 66, 60],
	},
	{
		Id: 6161,
		CodigoHtml: "C0E0B3",
		Nombre: "Graceful Flower",
		ColorNum: "0763",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 66,
		colorR: 192,
		colorG: 224,
		colorB: 179,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [66, 13],
		Triadicos: [66, 92, 22],
		Adjacentes: [76, 66, 60],
	},
	{
		Id: 6162,
		CodigoHtml: "B7D6A4",
		Nombre: "Green Sheen",
		ColorNum: "0764",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 66,
		colorR: 183,
		colorG: 214,
		colorB: 164,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [66, 13],
		Triadicos: [66, 92, 22],
		Adjacentes: [76, 66, 60],
	},
	{
		Id: 6163,
		CodigoHtml: "96C184",
		Nombre: "Sweetness",
		ColorNum: "0765",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 66,
		colorR: 150,
		colorG: 193,
		colorB: 132,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [66, 13],
		Triadicos: [66, 92, 22],
		Adjacentes: [76, 66, 60],
	},
	{
		Id: 6164,
		CodigoHtml: "81AE66",
		Nombre: "Gentle Caress",
		ColorNum: "0766",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 66,
		colorR: 129,
		colorG: 174,
		colorB: 102,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [66, 13],
		Triadicos: [66, 92, 22],
		Adjacentes: [76, 66, 60],
	},
	{
		Id: 6165,
		CodigoHtml: "639049",
		Nombre: "Golf Day",
		ColorNum: "0767",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 66,
		colorR: 99,
		colorG: 144,
		colorB: 73,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [66, 13],
		Triadicos: [66, 92, 22],
		Adjacentes: [76, 66, 60],
	},
	{
		Id: 6166,
		CodigoHtml: "E8EEEA",
		Nombre: "Falling Tears",
		ColorNum: "0663",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 67,
		colorR: 232,
		colorG: 238,
		colorB: 234,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [67, 24],
		Triadicos: [67, 29, 1],
		Adjacentes: [57, 67, 72],
	},
	{
		Id: 6167,
		CodigoHtml: "CFE3E4",
		Nombre: "Zircon Ice",
		ColorNum: "0664",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 67,
		colorR: 207,
		colorG: 227,
		colorB: 228,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [67, 24],
		Triadicos: [67, 29, 1],
		Adjacentes: [57, 67, 72],
	},
	{
		Id: 6168,
		CodigoHtml: "BDD9DE",
		Nombre: "Monet Magic",
		ColorNum: "0665",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 67,
		colorR: 189,
		colorG: 217,
		colorB: 222,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [67, 24],
		Triadicos: [67, 29, 1],
		Adjacentes: [57, 67, 72],
	},
	{
		Id: 6169,
		CodigoHtml: "92B9C4",
		Nombre: "Trisha's Eyes",
		ColorNum: "0666",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 67,
		colorR: 146,
		colorG: 185,
		colorB: 196,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [67, 24],
		Triadicos: [67, 29, 1],
		Adjacentes: [57, 67, 72],
	},
	{
		Id: 6170,
		CodigoHtml: "6B9CA8",
		Nombre: "Blessed Blue",
		ColorNum: "0667",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 67,
		colorR: 107,
		colorG: 156,
		colorB: 168,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [67, 24],
		Triadicos: [67, 29, 1],
		Adjacentes: [57, 67, 72],
	},
	{
		Id: 6171,
		CodigoHtml: "4E828D",
		Nombre: "Wish Upon a Star",
		ColorNum: "0668",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 67,
		colorR: 78,
		colorG: 130,
		colorB: 141,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [67, 24],
		Triadicos: [67, 29, 1],
		Adjacentes: [57, 67, 72],
	},
	{
		Id: 6172,
		CodigoHtml: "3E5C64",
		Nombre: "Frozen Stream",
		ColorNum: "0669",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 67,
		colorR: 62,
		colorG: 92,
		colorB: 100,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [67, 24],
		Triadicos: [67, 29, 1],
		Adjacentes: [57, 67, 72],
	},
	{
		Id: 6173,
		CodigoHtml: "DFEEEB",
		Nombre: "Drifting Tide",
		ColorNum: "0670",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 68,
		colorR: 223,
		colorG: 238,
		colorB: 235,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [68, 25],
		Triadicos: [68, 30, 2],
		Adjacentes: [58, 68, 73],
	},
	{
		Id: 6174,
		CodigoHtml: "C3E7EA",
		Nombre: "Egg Blue",
		ColorNum: "0671",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 68,
		colorR: 195,
		colorG: 231,
		colorB: 234,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [68, 25],
		Triadicos: [68, 30, 2],
		Adjacentes: [58, 68, 73],
	},
	{
		Id: 6175,
		CodigoHtml: "9AD9E2",
		Nombre: "Quiet Pond",
		ColorNum: "0672",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 68,
		colorR: 154,
		colorG: 217,
		colorB: 226,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [68, 25],
		Triadicos: [68, 30, 2],
		Adjacentes: [58, 68, 73],
	},
	{
		Id: 6176,
		CodigoHtml: "6CC4D3",
		Nombre: "Enchanted Evening",
		ColorNum: "0673",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 68,
		colorR: 108,
		colorG: 196,
		colorB: 211,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [68, 25],
		Triadicos: [68, 30, 2],
		Adjacentes: [58, 68, 73],
	},
	{
		Id: 6177,
		CodigoHtml: "2EADC2",
		Nombre: "Sarah's Garden",
		ColorNum: "0674",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 68,
		colorR: 46,
		colorG: 173,
		colorB: 194,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [68, 25],
		Triadicos: [68, 30, 2],
		Adjacentes: [58, 68, 73],
	},
	{
		Id: 6178,
		CodigoHtml: "0096AB",
		Nombre: "Blue Bliss",
		ColorNum: "0675",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 68,
		colorR: 0,
		colorG: 150,
		colorB: 171,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [68, 25],
		Triadicos: [68, 30, 2],
		Adjacentes: [58, 68, 73],
	},
	{
		Id: 6179,
		CodigoHtml: "006381",
		Nombre: "Job's Tears",
		ColorNum: "0676",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 68,
		colorR: 0,
		colorG: 99,
		colorB: 129,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [68, 25],
		Triadicos: [68, 30, 2],
		Adjacentes: [58, 68, 73],
	},
	{
		Id: 6180,
		CodigoHtml: "CFEEE8",
		Nombre: "Eastern Wind",
		ColorNum: "0677",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 69,
		colorR: 207,
		colorG: 238,
		colorB: 232,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [69, 26],
		Triadicos: [69, 31, 3],
		Adjacentes: [59, 69, 74],
	},
	{
		Id: 6181,
		CodigoHtml: "BFE9E4",
		Nombre: "Luxor Blue",
		ColorNum: "0678",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 69,
		colorR: 191,
		colorG: 233,
		colorB: 228,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [69, 26],
		Triadicos: [69, 31, 3],
		Adjacentes: [59, 69, 74],
	},
	{
		Id: 6182,
		CodigoHtml: "A5E3E0",
		Nombre: "Everest",
		ColorNum: "0679",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 69,
		colorR: 165,
		colorG: 227,
		colorB: 224,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [69, 26],
		Triadicos: [69, 31, 3],
		Adjacentes: [59, 69, 74],
	},
	{
		Id: 6183,
		CodigoHtml: "7ED8D7",
		Nombre: "Andes Sky",
		ColorNum: "0680",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 69,
		colorR: 126,
		colorG: 216,
		colorB: 215,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [69, 26],
		Triadicos: [69, 31, 3],
		Adjacentes: [59, 69, 74],
	},
	{
		Id: 6184,
		CodigoHtml: "28B7B8",
		Nombre: "Cyan Sky",
		ColorNum: "0681",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 69,
		colorR: 40,
		colorG: 183,
		colorB: 184,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [69, 26],
		Triadicos: [69, 31, 3],
		Adjacentes: [59, 69, 74],
	},
	{
		Id: 6185,
		CodigoHtml: "00A4A3",
		Nombre: "Pleasant Stream",
		ColorNum: "0682",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 69,
		colorR: 0,
		colorG: 164,
		colorB: 163,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [69, 26],
		Triadicos: [69, 31, 3],
		Adjacentes: [59, 69, 74],
	},
	{
		Id: 6186,
		CodigoHtml: "006D6E",
		Nombre: "Joyful Tears",
		ColorNum: "0683",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 69,
		colorR: 0,
		colorG: 109,
		colorB: 110,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [69, 26],
		Triadicos: [69, 31, 3],
		Adjacentes: [59, 69, 74],
	},
	{
		Id: 6187,
		CodigoHtml: "E1EEE7",
		Nombre: "Snow Drift",
		ColorNum: "0684",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 70,
		colorR: 225,
		colorG: 238,
		colorB: 231,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [70, 27],
		Triadicos: [70, 32, 4],
		Adjacentes: [60, 70, 75],
	},
	{
		Id: 6188,
		CodigoHtml: "D7E9E4",
		Nombre: "Dillard's Blue",
		ColorNum: "0685",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 70,
		colorR: 215,
		colorG: 233,
		colorB: 228,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [70, 27],
		Triadicos: [70, 32, 4],
		Adjacentes: [60, 70, 75],
	},
	{
		Id: 6189,
		CodigoHtml: "B8DDDA",
		Nombre: "Outerspace",
		ColorNum: "0686",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 70,
		colorR: 184,
		colorG: 221,
		colorB: 218,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [70, 27],
		Triadicos: [70, 32, 4],
		Adjacentes: [60, 70, 75],
	},
	{
		Id: 6190,
		CodigoHtml: "91C2C3",
		Nombre: "Stillwater",
		ColorNum: "0687",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 70,
		colorR: 145,
		colorG: 194,
		colorB: 195,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [70, 27],
		Triadicos: [70, 32, 4],
		Adjacentes: [60, 70, 75],
	},
	{
		Id: 6191,
		CodigoHtml: "5F9E9D",
		Nombre: "Cold North",
		ColorNum: "0688",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 70,
		colorR: 95,
		colorG: 158,
		colorB: 157,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [70, 27],
		Triadicos: [70, 32, 4],
		Adjacentes: [60, 70, 75],
	},
	{
		Id: 6192,
		CodigoHtml: "488786",
		Nombre: "Atmosphere",
		ColorNum: "0689",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 70,
		colorR: 72,
		colorG: 135,
		colorB: 134,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [70, 27],
		Triadicos: [70, 32, 4],
		Adjacentes: [60, 70, 75],
	},
	{
		Id: 6193,
		CodigoHtml: "2D5C62",
		Nombre: "Blue Period",
		ColorNum: "0690",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 70,
		colorR: 45,
		colorG: 92,
		colorB: 98,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [70, 27],
		Triadicos: [70, 32, 4],
		Adjacentes: [60, 70, 75],
	},
	{
		Id: 6194,
		CodigoHtml: "DEF1E9",
		Nombre: "Paradise",
		ColorNum: "0691",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 71,
		colorR: 222,
		colorG: 241,
		colorB: 233,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [71, 28],
		Triadicos: [71, 33, 5],
		Adjacentes: [61, 71, 76],
	},
	{
		Id: 6195,
		CodigoHtml: "BEE9E0",
		Nombre: "Barely Aqua",
		ColorNum: "0692",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 71,
		colorR: 190,
		colorG: 233,
		colorB: 224,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [71, 28],
		Triadicos: [71, 33, 5],
		Adjacentes: [61, 71, 76],
	},
	{
		Id: 6196,
		CodigoHtml: "90DACF",
		Nombre: "Island Breeze",
		ColorNum: "0693",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 71,
		colorR: 144,
		colorG: 218,
		colorB: 207,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [71, 28],
		Triadicos: [71, 33, 5],
		Adjacentes: [61, 71, 76],
	},
	{
		Id: 6197,
		CodigoHtml: "7BCBC1",
		Nombre: "Turkish Teal",
		ColorNum: "0694",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 71,
		colorR: 123,
		colorG: 203,
		colorB: 193,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [71, 28],
		Triadicos: [71, 33, 5],
		Adjacentes: [61, 71, 76],
	},
	{
		Id: 6198,
		CodigoHtml: "50B5AA",
		Nombre: "Island Moment",
		ColorNum: "0695",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 71,
		colorR: 80,
		colorG: 181,
		colorB: 170,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [71, 28],
		Triadicos: [71, 33, 5],
		Adjacentes: [61, 71, 76],
	},
	{
		Id: 6199,
		CodigoHtml: "289F90",
		Nombre: "Meringue",
		ColorNum: "0696",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 71,
		colorR: 40,
		colorG: 159,
		colorB: 144,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [71, 28],
		Triadicos: [71, 33, 5],
		Adjacentes: [61, 71, 76],
	},
	{
		Id: 6200,
		CodigoHtml: "206D63",
		Nombre: "Arizona Stone",
		ColorNum: "0697",
		EsPrincipal: false,
		GroupNum: 8,
		SubGroupNum: 71,
		colorR: 32,
		colorG: 109,
		colorB: 99,
		MonoCromaticos: [67, 68, 69, 70, 71],
		Complementarios: [71, 28],
		Triadicos: [71, 33, 5],
		Adjacentes: [61, 71, 76],
	},
	{
		Id: 6201,
		CodigoHtml: "DDE2E6",
		Nombre: "Wind Blown",
		ColorNum: "0586",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 72,
		colorR: 221,
		colorG: 226,
		colorB: 230,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [72, 13],
		Triadicos: [72, 44, 4],
		Adjacentes: [67, 72, 97],
	},
	{
		Id: 6202,
		CodigoHtml: "C4D1E0",
		Nombre: "Atmospheric Pressure",
		ColorNum: "0587",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 72,
		colorR: 196,
		colorG: 209,
		colorB: 224,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [72, 13],
		Triadicos: [72, 44, 4],
		Adjacentes: [67, 72, 97],
	},
	{
		Id: 6203,
		CodigoHtml: "AABBD3",
		Nombre: "Babbling Creek",
		ColorNum: "0588",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 72,
		colorR: 170,
		colorG: 187,
		colorB: 211,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [72, 13],
		Triadicos: [72, 44, 4],
		Adjacentes: [67, 72, 97],
	},
	{
		Id: 6204,
		CodigoHtml: "8196B4",
		Nombre: "Celestial Horizon",
		ColorNum: "0589",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 72,
		colorR: 129,
		colorG: 150,
		colorB: 180,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [72, 13],
		Triadicos: [72, 44, 4],
		Adjacentes: [67, 72, 97],
	},
	{
		Id: 6205,
		CodigoHtml: "677B9B",
		Nombre: "Madonna Blue",
		ColorNum: "0590",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 72,
		colorR: 103,
		colorG: 123,
		colorB: 155,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [72, 13],
		Triadicos: [72, 44, 4],
		Adjacentes: [67, 72, 97],
	},
	{
		Id: 6206,
		CodigoHtml: "617696",
		Nombre: "Blue Jacket",
		ColorNum: "0591",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 72,
		colorR: 97,
		colorG: 118,
		colorB: 150,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [72, 13],
		Triadicos: [72, 44, 4],
		Adjacentes: [67, 72, 97],
	},
	{
		Id: 6207,
		CodigoHtml: "49536B",
		Nombre: "Quiet Peace",
		ColorNum: "0592",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 72,
		colorR: 73,
		colorG: 83,
		colorB: 107,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [72, 13],
		Triadicos: [72, 44, 4],
		Adjacentes: [67, 72, 97],
	},
	{
		Id: 6208,
		CodigoHtml: "C6D3E5",
		Nombre: "Surf's Surprise",
		ColorNum: "0593",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 73,
		colorR: 198,
		colorG: 211,
		colorB: 229,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [73, 14],
		Triadicos: [73, 45, 5],
		Adjacentes: [68, 73, 98],
	},
	{
		Id: 6209,
		CodigoHtml: "B3C6E0",
		Nombre: "Simply Sparkling",
		ColorNum: "0594",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 73,
		colorR: 179,
		colorG: 198,
		colorB: 224,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [73, 14],
		Triadicos: [73, 45, 5],
		Adjacentes: [68, 73, 98],
	},
	{
		Id: 6210,
		CodigoHtml: "A7BCDC",
		Nombre: "Sail into the Horizon",
		ColorNum: "0595",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 73,
		colorR: 167,
		colorG: 188,
		colorB: 220,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [73, 14],
		Triadicos: [73, 45, 5],
		Adjacentes: [68, 73, 98],
	},
	{
		Id: 6211,
		CodigoHtml: "99AFD2",
		Nombre: "Lazy Day",
		ColorNum: "0596",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 73,
		colorR: 153,
		colorG: 175,
		colorB: 210,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [73, 14],
		Triadicos: [73, 45, 5],
		Adjacentes: [68, 73, 98],
	},
	{
		Id: 6212,
		CodigoHtml: "7C98C3",
		Nombre: "Himalaya Sky",
		ColorNum: "0597",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 73,
		colorR: 124,
		colorG: 152,
		colorB: 195,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [73, 14],
		Triadicos: [73, 45, 5],
		Adjacentes: [68, 73, 98],
	},
	{
		Id: 6213,
		CodigoHtml: "607CAB",
		Nombre: "Caribbean Current",
		ColorNum: "0598",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 73,
		colorR: 96,
		colorG: 124,
		colorB: 171,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [73, 14],
		Triadicos: [73, 45, 5],
		Adjacentes: [68, 73, 98],
	},
	{
		Id: 6214,
		CodigoHtml: "344C84",
		Nombre: "Florida Waters",
		ColorNum: "0599",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 73,
		colorR: 52,
		colorG: 76,
		colorB: 132,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [73, 14],
		Triadicos: [73, 45, 5],
		Adjacentes: [68, 73, 98],
	},
	{
		Id: 6215,
		CodigoHtml: "EBECEB",
		Nombre: "Melting Glacier",
		ColorNum: "0600",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 74,
		colorR: 235,
		colorG: 236,
		colorB: 235,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [74, 15],
		Triadicos: [74, 46, 6],
		Adjacentes: [69, 74, 99],
	},
	{
		Id: 6216,
		CodigoHtml: "DBE3EC",
		Nombre: "Cool Sky",
		ColorNum: "0601",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 74,
		colorR: 219,
		colorG: 227,
		colorB: 236,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [74, 15],
		Triadicos: [74, 46, 6],
		Adjacentes: [69, 74, 99],
	},
	{
		Id: 6217,
		CodigoHtml: "C6D5EA",
		Nombre: "Fluid Blue",
		ColorNum: "0602",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 74,
		colorR: 198,
		colorG: 213,
		colorB: 234,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [74, 15],
		Triadicos: [74, 46, 6],
		Adjacentes: [69, 74, 99],
	},
	{
		Id: 6218,
		CodigoHtml: "AAC0E3",
		Nombre: "Angela Bay",
		ColorNum: "0603",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 74,
		colorR: 170,
		colorG: 192,
		colorB: 227,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [74, 15],
		Triadicos: [74, 46, 6],
		Adjacentes: [69, 74, 99],
	},
	{
		Id: 6219,
		CodigoHtml: "98AEDB",
		Nombre: "Blue Skies Today",
		ColorNum: "0604",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 74,
		colorR: 152,
		colorG: 174,
		colorB: 219,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [74, 15],
		Triadicos: [74, 46, 6],
		Adjacentes: [69, 74, 99],
	},
	{
		Id: 6220,
		CodigoHtml: "5874B4",
		Nombre: "Capri Isle",
		ColorNum: "0605",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 74,
		colorR: 88,
		colorG: 116,
		colorB: 180,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [74, 15],
		Triadicos: [74, 46, 6],
		Adjacentes: [69, 74, 99],
	},
	{
		Id: 6221,
		CodigoHtml: "43487D",
		Nombre: "Frozen Blue",
		ColorNum: "0606",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 74,
		colorR: 67,
		colorG: 72,
		colorB: 125,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [74, 15],
		Triadicos: [74, 46, 6],
		Adjacentes: [69, 74, 99],
	},
	{
		Id: 6222,
		CodigoHtml: "D5E1ED",
		Nombre: "Brush Blue",
		ColorNum: "0607",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 75,
		colorR: 213,
		colorG: 225,
		colorB: 237,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [75, 16],
		Triadicos: [75, 7, 47],
		Adjacentes: [70, 75, 100],
	},
	{
		Id: 6223,
		CodigoHtml: "BDD2EA",
		Nombre: "Modern Blue",
		ColorNum: "0608",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 75,
		colorR: 189,
		colorG: 210,
		colorB: 234,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [75, 16],
		Triadicos: [75, 7, 47],
		Adjacentes: [70, 75, 100],
	},
	{
		Id: 6224,
		CodigoHtml: "A0BDE4",
		Nombre: "Innuendo",
		ColorNum: "0609",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 75,
		colorR: 160,
		colorG: 189,
		colorB: 228,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [75, 16],
		Triadicos: [75, 7, 47],
		Adjacentes: [70, 75, 100],
	},
	{
		Id: 6225,
		CodigoHtml: "8FAEDC",
		Nombre: "Blue Parlor",
		ColorNum: "0610",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 75,
		colorR: 143,
		colorG: 174,
		colorB: 220,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [75, 16],
		Triadicos: [75, 7, 47],
		Adjacentes: [70, 75, 100],
	},
	{
		Id: 6226,
		CodigoHtml: "7296CF",
		Nombre: "Bashful Blue",
		ColorNum: "0611",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 75,
		colorR: 114,
		colorG: 150,
		colorB: 207,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [75, 16],
		Triadicos: [75, 7, 47],
		Adjacentes: [70, 75, 100],
	},
	{
		Id: 6227,
		CodigoHtml: "547DBD",
		Nombre: "Parkwater",
		ColorNum: "0612",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 75,
		colorR: 84,
		colorG: 125,
		colorB: 189,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [75, 16],
		Triadicos: [75, 7, 47],
		Adjacentes: [70, 75, 100],
	},
	{
		Id: 6228,
		CodigoHtml: "41518E",
		Nombre: "Blue Highlight",
		ColorNum: "0613",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 75,
		colorR: 65,
		colorG: 81,
		colorB: 142,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [75, 16],
		Triadicos: [75, 7, 47],
		Adjacentes: [70, 75, 100],
	},
	{
		Id: 6229,
		CodigoHtml: "C8D8E4",
		Nombre: "Simple Serenity",
		ColorNum: "0614",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 76,
		colorR: 200,
		colorG: 216,
		colorB: 228,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [76, 61],
		Triadicos: [76, 8, 48],
		Adjacentes: [71, 76, 101],
	},
	{
		Id: 6230,
		CodigoHtml: "B6CBDE",
		Nombre: "Blue Bayou",
		ColorNum: "0615",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 76,
		colorR: 182,
		colorG: 203,
		colorB: 222,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [76, 61],
		Triadicos: [76, 8, 48],
		Adjacentes: [71, 76, 101],
	},
	{
		Id: 6231,
		CodigoHtml: "A1BDD7",
		Nombre: "Bluette",
		ColorNum: "0616",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 76,
		colorR: 161,
		colorG: 189,
		colorB: 215,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [76, 61],
		Triadicos: [76, 8, 48],
		Adjacentes: [71, 76, 101],
	},
	{
		Id: 6232,
		CodigoHtml: "87A4C0",
		Nombre: "John's Blue",
		ColorNum: "0617",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 76,
		colorR: 135,
		colorG: 164,
		colorB: 192,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [76, 61],
		Triadicos: [76, 8, 48],
		Adjacentes: [71, 76, 101],
	},
	{
		Id: 6233,
		CodigoHtml: "7192B4",
		Nombre: "Heavenly Sky",
		ColorNum: "0618",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 76,
		colorR: 113,
		colorG: 146,
		colorB: 180,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [76, 61],
		Triadicos: [76, 8, 48],
		Adjacentes: [71, 76, 101],
	},
	{
		Id: 6234,
		CodigoHtml: "4F6E90",
		Nombre: "Honky Tonk Blue",
		ColorNum: "0619",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 76,
		colorR: 79,
		colorG: 110,
		colorB: 144,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [76, 61],
		Triadicos: [76, 8, 48],
		Adjacentes: [71, 76, 101],
	},
	{
		Id: 6235,
		CodigoHtml: "414B61",
		Nombre: "Star-Studded",
		ColorNum: "0620",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 76,
		colorR: 65,
		colorG: 75,
		colorB: 97,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [76, 61],
		Triadicos: [76, 8, 48],
		Adjacentes: [71, 76, 101],
	},
	{
		Id: 6236,
		CodigoHtml: "D3DDDF",
		Nombre: "Nuance",
		ColorNum: "0621",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 77,
		colorR: 211,
		colorG: 221,
		colorB: 223,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [77, 18],
		Triadicos: [77, 9, 49],
		Adjacentes: [72, 77, 102],
	},
	{
		Id: 6237,
		CodigoHtml: "C7D3DA",
		Nombre: "Serene Setting",
		ColorNum: "0622",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 77,
		colorR: 199,
		colorG: 211,
		colorB: 218,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [77, 18],
		Triadicos: [77, 9, 49],
		Adjacentes: [72, 77, 102],
	},
	{
		Id: 6238,
		CodigoHtml: "B7C8D3",
		Nombre: "Pompeii Ruins",
		ColorNum: "0623",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 77,
		colorR: 183,
		colorG: 200,
		colorB: 211,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [77, 18],
		Triadicos: [77, 9, 49],
		Adjacentes: [72, 77, 102],
	},
	{
		Id: 6239,
		CodigoHtml: "9EB0C0",
		Nombre: "Blue Pot",
		ColorNum: "0624",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 77,
		colorR: 158,
		colorG: 176,
		colorB: 192,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [77, 18],
		Triadicos: [77, 9, 49],
		Adjacentes: [72, 77, 102],
	},
	{
		Id: 6240,
		CodigoHtml: "8B9EB3",
		Nombre: "North Sea",
		ColorNum: "0625",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 77,
		colorR: 139,
		colorG: 158,
		colorB: 179,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [77, 18],
		Triadicos: [77, 9, 49],
		Adjacentes: [72, 77, 102],
	},
	{
		Id: 6241,
		CodigoHtml: "64768B",
		Nombre: "Blue Depths",
		ColorNum: "0626",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 77,
		colorR: 100,
		colorG: 118,
		colorB: 139,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [77, 18],
		Triadicos: [77, 9, 49],
		Adjacentes: [72, 77, 102],
	},
	{
		Id: 6242,
		CodigoHtml: "4F5B6E",
		Nombre: "Into the Stratosphere",
		ColorNum: "0627",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 77,
		colorR: 79,
		colorG: 91,
		colorB: 110,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [77, 18],
		Triadicos: [77, 9, 49],
		Adjacentes: [72, 77, 102],
	},
	{
		Id: 6243,
		CodigoHtml: "DEE3E0",
		Nombre: "Illuminating Experience",
		ColorNum: "0628",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 78,
		colorR: 222,
		colorG: 227,
		colorB: 224,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [78, 19],
		Triadicos: [78, 10, 50],
		Adjacentes: [73, 78, 103],
	},
	{
		Id: 6244,
		CodigoHtml: "C8D4D8",
		Nombre: "Polished Cotton",
		ColorNum: "0629",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 78,
		colorR: 200,
		colorG: 212,
		colorB: 216,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [78, 19],
		Triadicos: [78, 10, 50],
		Adjacentes: [73, 78, 103],
	},
	{
		Id: 6245,
		CodigoHtml: "B5C6CF",
		Nombre: "Star Mist",
		ColorNum: "0630",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 78,
		colorR: 181,
		colorG: 198,
		colorB: 207,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [78, 19],
		Triadicos: [78, 10, 50],
		Adjacentes: [73, 78, 103],
	},
	{
		Id: 6246,
		CodigoHtml: "99AFBC",
		Nombre: "Chicago Skyline",
		ColorNum: "0631",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 78,
		colorR: 153,
		colorG: 175,
		colorB: 188,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [78, 19],
		Triadicos: [78, 10, 50],
		Adjacentes: [73, 78, 103],
	},
	{
		Id: 6247,
		CodigoHtml: "859DAD",
		Nombre: "Breath-Taking View",
		ColorNum: "0632",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 78,
		colorR: 133,
		colorG: 157,
		colorB: 173,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [78, 19],
		Triadicos: [78, 10, 50],
		Adjacentes: [73, 78, 103],
	},
	{
		Id: 6248,
		CodigoHtml: "5D7585",
		Nombre: "Cape Cod Bay",
		ColorNum: "0633",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 78,
		colorR: 93,
		colorG: 117,
		colorB: 133,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [78, 19],
		Triadicos: [78, 10, 50],
		Adjacentes: [73, 78, 103],
	},
	{
		Id: 6249,
		CodigoHtml: "4B5C6A",
		Nombre: "Day Spa",
		ColorNum: "0634",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 78,
		colorR: 75,
		colorG: 92,
		colorB: 106,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [78, 19],
		Triadicos: [78, 10, 50],
		Adjacentes: [73, 78, 103],
	},
	{
		Id: 6250,
		CodigoHtml: "E1EBEA",
		Nombre: "Aquafir",
		ColorNum: "0635",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 79,
		colorR: 225,
		colorG: 235,
		colorB: 234,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [79, 20],
		Triadicos: [79, 11, 51],
		Adjacentes: [74, 79, 104],
	},
	{
		Id: 6251,
		CodigoHtml: "C6DEEC",
		Nombre: "Empress Lila",
		ColorNum: "0636",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 79,
		colorR: 198,
		colorG: 222,
		colorB: 236,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [79, 20],
		Triadicos: [79, 11, 51],
		Adjacentes: [74, 79, 104],
	},
	{
		Id: 6252,
		CodigoHtml: "AACFEB",
		Nombre: "Marzena Dream",
		ColorNum: "0637",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 79,
		colorR: 170,
		colorG: 207,
		colorB: 235,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [79, 20],
		Triadicos: [79, 11, 51],
		Adjacentes: [74, 79, 104],
	},
	{
		Id: 6253,
		CodigoHtml: "8DBFE6",
		Nombre: "Watery Sea",
		ColorNum: "0638",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 79,
		colorR: 141,
		colorG: 191,
		colorB: 230,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [79, 20],
		Triadicos: [79, 11, 51],
		Adjacentes: [74, 79, 104],
	},
	{
		Id: 6254,
		CodigoHtml: "6EA7DB",
		Nombre: "Acapulco Dive",
		ColorNum: "0639",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 79,
		colorR: 110,
		colorG: 167,
		colorB: 219,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [79, 20],
		Triadicos: [79, 11, 51],
		Adjacentes: [74, 79, 104],
	},
	{
		Id: 6255,
		CodigoHtml: "387EC2",
		Nombre: "Skylla",
		ColorNum: "0640",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 79,
		colorR: 56,
		colorG: 126,
		colorB: 194,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [79, 20],
		Triadicos: [79, 11, 51],
		Adjacentes: [74, 79, 104],
	},
	{
		Id: 6256,
		CodigoHtml: "335392",
		Nombre: "Dancing Sea",
		ColorNum: "0641",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 79,
		colorR: 51,
		colorG: 83,
		colorB: 146,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [79, 20],
		Triadicos: [79, 11, 51],
		Adjacentes: [74, 79, 104],
	},
	{
		Id: 6257,
		CodigoHtml: "CCDCE1",
		Nombre: "Sea Foam Mist",
		ColorNum: "0642",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 80,
		colorR: 204,
		colorG: 220,
		colorB: 225,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [80, 21],
		Triadicos: [80, 12, 52],
		Adjacentes: [75, 80, 105],
	},
	{
		Id: 6258,
		CodigoHtml: "BCD2DD",
		Nombre: "Abstract Idea",
		ColorNum: "0643",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 80,
		colorR: 188,
		colorG: 210,
		colorB: 221,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [80, 21],
		Triadicos: [80, 12, 52],
		Adjacentes: [75, 80, 105],
	},
	{
		Id: 6259,
		CodigoHtml: "ADC6D6",
		Nombre: "Dancing in the Rain",
		ColorNum: "0644",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 80,
		colorR: 173,
		colorG: 198,
		colorB: 214,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [80, 21],
		Triadicos: [80, 12, 52],
		Adjacentes: [75, 80, 105],
	},
	{
		Id: 6260,
		CodigoHtml: "91B3CE",
		Nombre: "Calm Tint",
		ColorNum: "0645",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 80,
		colorR: 145,
		colorG: 179,
		colorB: 206,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [80, 21],
		Triadicos: [80, 12, 52],
		Adjacentes: [75, 80, 105],
	},
	{
		Id: 6261,
		CodigoHtml: "7BA0C1",
		Nombre: "Key West Zenith",
		ColorNum: "0646",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 80,
		colorR: 123,
		colorG: 160,
		colorB: 193,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [80, 21],
		Triadicos: [80, 12, 52],
		Adjacentes: [75, 80, 105],
	},
	{
		Id: 6262,
		CodigoHtml: "557EA2",
		Nombre: "Zenith",
		ColorNum: "0647",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 80,
		colorR: 85,
		colorG: 126,
		colorB: 162,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [80, 21],
		Triadicos: [80, 12, 52],
		Adjacentes: [75, 80, 105],
	},
	{
		Id: 6263,
		CodigoHtml: "425E79",
		Nombre: "Happy Tune",
		ColorNum: "0648",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 80,
		colorR: 66,
		colorG: 94,
		colorB: 121,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [80, 21],
		Triadicos: [80, 12, 52],
		Adjacentes: [75, 80, 105],
	},
	{
		Id: 6264,
		CodigoHtml: "D9E3E2",
		Nombre: "Divine Inspiration",
		ColorNum: "0649",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 81,
		colorR: 217,
		colorG: 227,
		colorB: 226,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [81, 22],
		Triadicos: [81, 13, 53],
		Adjacentes: [76, 81, 106],
	},
	{
		Id: 6265,
		CodigoHtml: "BFD7E1",
		Nombre: "Bridgewater Bay",
		ColorNum: "0650",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 81,
		colorR: 191,
		colorG: 215,
		colorB: 225,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [81, 22],
		Triadicos: [81, 13, 53],
		Adjacentes: [76, 81, 106],
	},
	{
		Id: 6266,
		CodigoHtml: "ADCEDD",
		Nombre: "Soft Blue",
		ColorNum: "0651",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 81,
		colorR: 173,
		colorG: 206,
		colorB: 221,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [81, 22],
		Triadicos: [81, 13, 53],
		Adjacentes: [76, 81, 106],
	},
	{
		Id: 6267,
		CodigoHtml: "92BAD1",
		Nombre: "Mountain Main",
		ColorNum: "0652",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 81,
		colorR: 146,
		colorG: 186,
		colorB: 209,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [81, 22],
		Triadicos: [81, 13, 53],
		Adjacentes: [76, 81, 106],
	},
	{
		Id: 6268,
		CodigoHtml: "73A3C2",
		Nombre: "Sea of Atlantis",
		ColorNum: "0653",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 81,
		colorR: 115,
		colorG: 163,
		colorB: 194,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [81, 22],
		Triadicos: [81, 13, 53],
		Adjacentes: [76, 81, 106],
	},
	{
		Id: 6269,
		CodigoHtml: "4A7FA0",
		Nombre: "Peninsula",
		ColorNum: "0654",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 81,
		colorR: 74,
		colorG: 127,
		colorB: 160,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [81, 22],
		Triadicos: [81, 13, 53],
		Adjacentes: [76, 81, 106],
	},
	{
		Id: 6270,
		CodigoHtml: "395E7A",
		Nombre: "Queen of the Night",
		ColorNum: "0655",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 81,
		colorR: 57,
		colorG: 94,
		colorB: 122,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [81, 22],
		Triadicos: [81, 13, 53],
		Adjacentes: [76, 81, 106],
	},
	{
		Id: 6271,
		CodigoHtml: "DFECEB",
		Nombre: "Roman White",
		ColorNum: "0656",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 82,
		colorR: 223,
		colorG: 236,
		colorB: 235,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [82, 23],
		Triadicos: [82, 14, 54],
		Adjacentes: [77, 82, 107],
	},
	{
		Id: 6272,
		CodigoHtml: "C3E4EB",
		Nombre: "Kyoto",
		ColorNum: "0657",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 82,
		colorR: 195,
		colorG: 228,
		colorB: 235,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [82, 23],
		Triadicos: [82, 14, 54],
		Adjacentes: [77, 82, 107],
	},
	{
		Id: 6273,
		CodigoHtml: "9ED6E7",
		Nombre: "Orleans Tune",
		ColorNum: "0658",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 82,
		colorR: 158,
		colorG: 214,
		colorB: 231,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [82, 23],
		Triadicos: [82, 14, 54],
		Adjacentes: [77, 82, 107],
	},
	{
		Id: 6274,
		CodigoHtml: "7BC8E2",
		Nombre: "Lasting Thoughts",
		ColorNum: "0659",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 82,
		colorR: 123,
		colorG: 200,
		colorB: 226,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [82, 23],
		Triadicos: [82, 14, 54],
		Adjacentes: [77, 82, 107],
	},
	{
		Id: 6275,
		CodigoHtml: "5BB4D5",
		Nombre: "Epimethius",
		ColorNum: "0660",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 82,
		colorR: 91,
		colorG: 180,
		colorB: 213,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [82, 23],
		Triadicos: [82, 14, 54],
		Adjacentes: [77, 82, 107],
	},
	{
		Id: 6276,
		CodigoHtml: "37A5CB",
		Nombre: "Charybdis",
		ColorNum: "0661",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 82,
		colorR: 55,
		colorG: 165,
		colorB: 203,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [82, 23],
		Triadicos: [82, 14, 54],
		Adjacentes: [77, 82, 107],
	},
	{
		Id: 6277,
		CodigoHtml: "0A5B7E",
		Nombre: "Ocean Spray",
		ColorNum: "0662",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 82,
		colorR: 10,
		colorG: 91,
		colorB: 126,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [82, 23],
		Triadicos: [82, 14, 54],
		Adjacentes: [77, 82, 107],
	},
	{
		Id: 6278,
		CodigoHtml: "F4E1EB",
		Nombre: "Petal Poise",
		ColorNum: "1160",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 83,
		colorR: 244,
		colorG: 225,
		colorB: 235,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [83, 44],
		Triadicos: [83, 57, 13],
		Adjacentes: [1, 83, 97],
	},
	{
		Id: 6279,
		CodigoHtml: "F2D8E8",
		Nombre: "Nursery Pink",
		ColorNum: "1161",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 83,
		colorR: 242,
		colorG: 216,
		colorB: 232,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [83, 44],
		Triadicos: [83, 57, 13],
		Adjacentes: [1, 83, 97],
	},
	{
		Id: 6280,
		CodigoHtml: "EDC7E1",
		Nombre: "Venetian Rose",
		ColorNum: "1162",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 83,
		colorR: 237,
		colorG: 199,
		colorB: 225,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [83, 44],
		Triadicos: [83, 57, 13],
		Adjacentes: [1, 83, 97],
	},
	{
		Id: 6281,
		CodigoHtml: "D8A6CE",
		Nombre: "Tiara Pink",
		ColorNum: "1163",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 83,
		colorR: 216,
		colorG: 166,
		colorB: 206,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [83, 44],
		Triadicos: [83, 57, 13],
		Adjacentes: [1, 83, 97],
	},
	{
		Id: 6282,
		CodigoHtml: "CC94C1",
		Nombre: "Carnation Rose",
		ColorNum: "1164",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 83,
		colorR: 204,
		colorG: 148,
		colorB: 193,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [83, 44],
		Triadicos: [83, 57, 13],
		Adjacentes: [1, 83, 97],
	},
	{
		Id: 6283,
		CodigoHtml: "B471A5",
		Nombre: "Pink Parade",
		ColorNum: "1165",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 83,
		colorR: 180,
		colorG: 113,
		colorB: 165,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [83, 44],
		Triadicos: [83, 57, 13],
		Adjacentes: [1, 83, 97],
	},
	{
		Id: 6284,
		CodigoHtml: "70435A",
		Nombre: "Noble Honor",
		ColorNum: "1166",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 83,
		colorR: 112,
		colorG: 67,
		colorB: 90,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [83, 44],
		Triadicos: [83, 57, 13],
		Adjacentes: [1, 83, 97],
	},
	{
		Id: 6285,
		CodigoHtml: "EDDCE0",
		Nombre: "Dream State",
		ColorNum: "1167",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 84,
		colorR: 237,
		colorG: 220,
		colorB: 224,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [84, 45],
		Triadicos: [84, 58, 14],
		Adjacentes: [2, 84, 98],
	},
	{
		Id: 6286,
		CodigoHtml: "E0C6D6",
		Nombre: "Marvelous Magic",
		ColorNum: "1168",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 84,
		colorR: 224,
		colorG: 198,
		colorB: 214,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [84, 45],
		Triadicos: [84, 58, 14],
		Adjacentes: [2, 84, 98],
	},
	{
		Id: 6287,
		CodigoHtml: "DBB6CD",
		Nombre: "Baby Girl",
		ColorNum: "1169",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 84,
		colorR: 219,
		colorG: 182,
		colorB: 205,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [84, 45],
		Triadicos: [84, 58, 14],
		Adjacentes: [2, 84, 98],
	},
	{
		Id: 6288,
		CodigoHtml: "BD8CAD",
		Nombre: "Lavender Quartz",
		ColorNum: "1170",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 84,
		colorR: 189,
		colorG: 140,
		colorB: 173,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [84, 45],
		Triadicos: [84, 58, 14],
		Adjacentes: [2, 84, 98],
	},
	{
		Id: 6289,
		CodigoHtml: "A8789C",
		Nombre: "Teen Queen",
		ColorNum: "1171",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 84,
		colorR: 168,
		colorG: 120,
		colorB: 156,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [84, 45],
		Triadicos: [84, 58, 14],
		Adjacentes: [2, 84, 98],
	},
	{
		Id: 6290,
		CodigoHtml: "966284",
		Nombre: "Immortality",
		ColorNum: "1172",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 84,
		colorR: 150,
		colorG: 98,
		colorB: 132,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [84, 45],
		Triadicos: [84, 58, 14],
		Adjacentes: [2, 84, 98],
	},
	{
		Id: 6291,
		CodigoHtml: "684B5B",
		Nombre: "Purple Stiletto",
		ColorNum: "1173",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 84,
		colorR: 104,
		colorG: 75,
		colorB: 91,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [84, 45],
		Triadicos: [84, 58, 14],
		Adjacentes: [2, 84, 98],
	},
	{
		Id: 6292,
		CodigoHtml: "F3ECEA",
		Nombre: "Little Pinky",
		ColorNum: "1174",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 85,
		colorR: 243,
		colorG: 236,
		colorB: 234,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [85, 46],
		Triadicos: [85, 59, 15],
		Adjacentes: [3, 85, 99],
	},
	{
		Id: 6293,
		CodigoHtml: "F2DFE9",
		Nombre: "Taffeta Tint",
		ColorNum: "1175",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 85,
		colorR: 242,
		colorG: 223,
		colorB: 233,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [85, 46],
		Triadicos: [85, 59, 15],
		Adjacentes: [3, 85, 99],
	},
	{
		Id: 6294,
		CodigoHtml: "EDCEE5",
		Nombre: "Poodle Pink",
		ColorNum: "1176",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 85,
		colorR: 237,
		colorG: 206,
		colorB: 229,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [85, 46],
		Triadicos: [85, 59, 15],
		Adjacentes: [3, 85, 99],
	},
	{
		Id: 6295,
		CodigoHtml: "E6BEE0",
		Nombre: "Rosy Cheeks",
		ColorNum: "1177",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 85,
		colorR: 230,
		colorG: 190,
		colorB: 224,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [85, 46],
		Triadicos: [85, 59, 15],
		Adjacentes: [3, 85, 99],
	},
	{
		Id: 6296,
		CodigoHtml: "C393C7",
		Nombre: "Rose Souvenir",
		ColorNum: "1178",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 85,
		colorR: 195,
		colorG: 147,
		colorB: 199,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [85, 46],
		Triadicos: [85, 59, 15],
		Adjacentes: [3, 85, 99],
	},
	{
		Id: 6297,
		CodigoHtml: "B078B1",
		Nombre: "Moonlight Melody",
		ColorNum: "1179",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 85,
		colorR: 176,
		colorG: 120,
		colorB: 177,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [85, 46],
		Triadicos: [85, 59, 15],
		Adjacentes: [3, 85, 99],
	},
	{
		Id: 6298,
		CodigoHtml: "6E496E",
		Nombre: "Berry Light",
		ColorNum: "1180",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 85,
		colorR: 110,
		colorG: 73,
		colorB: 110,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [85, 46],
		Triadicos: [85, 59, 15],
		Adjacentes: [3, 85, 99],
	},
	{
		Id: 6299,
		CodigoHtml: "EEDBE7",
		Nombre: "Violet Ash",
		ColorNum: "1181",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 86,
		colorR: 238,
		colorG: 219,
		colorB: 231,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [86, 47],
		Triadicos: [86, 60, 16],
		Adjacentes: [4, 86, 100],
	},
	{
		Id: 6300,
		CodigoHtml: "E7CFE4",
		Nombre: "Lilacs in Spring",
		ColorNum: "1182",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 86,
		colorR: 231,
		colorG: 207,
		colorB: 228,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [86, 47],
		Triadicos: [86, 60, 16],
		Adjacentes: [4, 86, 100],
	},
	{
		Id: 6301,
		CodigoHtml: "DEBEDD",
		Nombre: "Romantic Ballad",
		ColorNum: "1183",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 86,
		colorR: 222,
		colorG: 190,
		colorB: 221,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [86, 47],
		Triadicos: [86, 60, 16],
		Adjacentes: [4, 86, 100],
	},
	{
		Id: 6302,
		CodigoHtml: "CBAAD0",
		Nombre: "Graceful Garden",
		ColorNum: "1184",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 86,
		colorR: 203,
		colorG: 170,
		colorB: 208,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [86, 47],
		Triadicos: [86, 60, 16],
		Adjacentes: [4, 86, 100],
	},
	{
		Id: 6303,
		CodigoHtml: "B692BE",
		Nombre: "Satin Flower",
		ColorNum: "1185",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 86,
		colorR: 182,
		colorG: 146,
		colorB: 190,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [86, 47],
		Triadicos: [86, 60, 16],
		Adjacentes: [4, 86, 100],
	},
	{
		Id: 6304,
		CodigoHtml: "A47DAC",
		Nombre: "Pleasant Dream",
		ColorNum: "1186",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 86,
		colorR: 164,
		colorG: 125,
		colorB: 172,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [86, 47],
		Triadicos: [86, 60, 16],
		Adjacentes: [4, 86, 100],
	},
	{
		Id: 6305,
		CodigoHtml: "644466",
		Nombre: "Joyous Song",
		ColorNum: "1187",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 86,
		colorR: 100,
		colorG: 68,
		colorB: 102,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [86, 47],
		Triadicos: [86, 60, 16],
		Adjacentes: [4, 86, 100],
	},
	{
		Id: 6306,
		CodigoHtml: "EDDCEA",
		Nombre: "Maiden of the Mist",
		ColorNum: "1188",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 87,
		colorR: 237,
		colorG: 220,
		colorB: 234,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [87, 48],
		Triadicos: [87, 61, 17],
		Adjacentes: [5, 87, 101],
	},
	{
		Id: 6307,
		CodigoHtml: "E6D0E7",
		Nombre: "Little Touch ",
		ColorNum: "1189",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 87,
		colorR: 230,
		colorG: 208,
		colorB: 231,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [87, 48],
		Triadicos: [87, 61, 17],
		Adjacentes: [5, 87, 101],
	},
	{
		Id: 6308,
		CodigoHtml: "DDC1E2",
		Nombre: "Glittering Gemstone",
		ColorNum: "1190",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 87,
		colorR: 221,
		colorG: 193,
		colorB: 226,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [87, 48],
		Triadicos: [87, 61, 17],
		Adjacentes: [5, 87, 101],
	},
	{
		Id: 6309,
		CodigoHtml: "CBAED7",
		Nombre: "Cherish the Moment",
		ColorNum: "1191",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 87,
		colorR: 203,
		colorG: 174,
		colorB: 215,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [87, 48],
		Triadicos: [87, 61, 17],
		Adjacentes: [5, 87, 101],
	},
	{
		Id: 6310,
		CodigoHtml: "B493C8",
		Nombre: "Cut Velvet",
		ColorNum: "1192",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 87,
		colorR: 180,
		colorG: 147,
		colorB: 200,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [87, 48],
		Triadicos: [87, 61, 17],
		Adjacentes: [5, 87, 101],
	},
	{
		Id: 6311,
		CodigoHtml: "9872AF",
		Nombre: "Crown Jewels",
		ColorNum: "1193",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 87,
		colorR: 152,
		colorG: 114,
		colorB: 175,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [87, 48],
		Triadicos: [87, 61, 17],
		Adjacentes: [5, 87, 101],
	},
	{
		Id: 6312,
		CodigoHtml: "694A73",
		Nombre: "Magenta Red Lips",
		ColorNum: "1194",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 87,
		colorR: 105,
		colorG: 74,
		colorB: 115,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [87, 48],
		Triadicos: [87, 61, 17],
		Adjacentes: [5, 87, 101],
	},
	{
		Id: 6313,
		CodigoHtml: "EDE3E7",
		Nombre: "Rosie Posie",
		ColorNum: "1195",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 88,
		colorR: 237,
		colorG: 227,
		colorB: 231,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [88, 49],
		Triadicos: [88, 62, 18],
		Adjacentes: [6, 88, 102],
	},
	{
		Id: 6314,
		CodigoHtml: "E2CDDC",
		Nombre: "Naughty Marietta",
		ColorNum: "1196",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 88,
		colorR: 226,
		colorG: 205,
		colorB: 220,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [88, 49],
		Triadicos: [88, 62, 18],
		Adjacentes: [6, 88, 102],
	},
	{
		Id: 6315,
		CodigoHtml: "D7BBD1",
		Nombre: "Lavender Veil",
		ColorNum: "1197",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 88,
		colorR: 215,
		colorG: 187,
		colorB: 209,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [88, 49],
		Triadicos: [88, 62, 18],
		Adjacentes: [6, 88, 102],
	},
	{
		Id: 6316,
		CodigoHtml: "C3AFC5",
		Nombre: "Heather Feather",
		ColorNum: "1198",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 88,
		colorR: 195,
		colorG: 175,
		colorB: 197,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [88, 49],
		Triadicos: [88, 62, 18],
		Adjacentes: [6, 88, 102],
	},
	{
		Id: 6317,
		CodigoHtml: "AE95B1",
		Nombre: "Enthroned Above",
		ColorNum: "1199",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 88,
		colorR: 174,
		colorG: 149,
		colorB: 177,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [88, 49],
		Triadicos: [88, 62, 18],
		Adjacentes: [6, 88, 102],
	},
	{
		Id: 6318,
		CodigoHtml: "947795",
		Nombre: "Felicia",
		ColorNum: "1200",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 88,
		colorR: 148,
		colorG: 119,
		colorB: 149,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [88, 49],
		Triadicos: [88, 62, 18],
		Adjacentes: [6, 88, 102],
	},
	{
		Id: 6319,
		CodigoHtml: "614C5F",
		Nombre: "Dreamy Heaven",
		ColorNum: "1201",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 88,
		colorR: 97,
		colorG: 76,
		colorB: 95,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [88, 49],
		Triadicos: [88, 62, 18],
		Adjacentes: [6, 88, 102],
	},
	{
		Id: 6320,
		CodigoHtml: "EFDEDF",
		Nombre: "Daring Deception",
		ColorNum: "1202",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 89,
		colorR: 239,
		colorG: 222,
		colorB: 223,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [89, 50],
		Triadicos: [89, 63, 19],
		Adjacentes: [7, 89, 103],
	},
	{
		Id: 6321,
		CodigoHtml: "E0C8D5",
		Nombre: "Etcetera",
		ColorNum: "1203",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 89,
		colorR: 224,
		colorG: 200,
		colorB: 213,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [89, 50],
		Triadicos: [89, 63, 19],
		Adjacentes: [7, 89, 103],
	},
	{
		Id: 6322,
		CodigoHtml: "D8BBCD",
		Nombre: "Noteworthy",
		ColorNum: "1204",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 89,
		colorR: 216,
		colorG: 187,
		colorB: 205,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [89, 50],
		Triadicos: [89, 63, 19],
		Adjacentes: [7, 89, 103],
	},
	{
		Id: 6323,
		CodigoHtml: "B38FAB",
		Nombre: "Fashionably Plum ",
		ColorNum: "1205",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 89,
		colorR: 179,
		colorG: 143,
		colorB: 171,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [89, 50],
		Triadicos: [89, 63, 19],
		Adjacentes: [7, 89, 103],
	},
	{
		Id: 6324,
		CodigoHtml: "9F7B9B",
		Nombre: "Screen Gem",
		ColorNum: "1206",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 89,
		colorR: 159,
		colorG: 123,
		colorB: 155,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [89, 50],
		Triadicos: [89, 63, 19],
		Adjacentes: [7, 89, 103],
	},
	{
		Id: 6325,
		CodigoHtml: "8B6885",
		Nombre: "Orchid Orchestra",
		ColorNum: "1207",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 89,
		colorR: 139,
		colorG: 104,
		colorB: 133,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [89, 50],
		Triadicos: [89, 63, 19],
		Adjacentes: [7, 89, 103],
	},
	{
		Id: 6326,
		CodigoHtml: "634E60",
		Nombre: "Crowd Pleaser",
		ColorNum: "1208",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 89,
		colorR: 99,
		colorG: 78,
		colorB: 96,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [89, 50],
		Triadicos: [89, 63, 19],
		Adjacentes: [7, 89, 103],
	},
	{
		Id: 6327,
		CodigoHtml: "EADADE",
		Nombre: "Princess Irene",
		ColorNum: "1209",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 90,
		colorR: 234,
		colorG: 218,
		colorB: 222,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [90, 51],
		Triadicos: [90, 64, 20],
		Adjacentes: [8, 90, 104],
	},
	{
		Id: 6328,
		CodigoHtml: "DEC9D1",
		Nombre: "French Lavender",
		ColorNum: "1210",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 90,
		colorR: 222,
		colorG: 201,
		colorB: 209,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [90, 51],
		Triadicos: [90, 64, 20],
		Adjacentes: [8, 90, 104],
	},
	{
		Id: 6329,
		CodigoHtml: "D3B9C5",
		Nombre: "Lavender Blessing",
		ColorNum: "1211",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 90,
		colorR: 211,
		colorG: 185,
		colorB: 197,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [90, 51],
		Triadicos: [90, 64, 20],
		Adjacentes: [8, 90, 104],
	},
	{
		Id: 6330,
		CodigoHtml: "BEA5B5",
		Nombre: "Pansy Posie",
		ColorNum: "1212",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 90,
		colorR: 190,
		colorG: 165,
		colorB: 181,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [90, 51],
		Triadicos: [90, 64, 20],
		Adjacentes: [8, 90, 104],
	},
	{
		Id: 6331,
		CodigoHtml: "A28698",
		Nombre: "Berry Bright",
		ColorNum: "1213",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 90,
		colorR: 162,
		colorG: 134,
		colorB: 152,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [90, 51],
		Triadicos: [90, 64, 20],
		Adjacentes: [8, 90, 104],
	},
	{
		Id: 6332,
		CodigoHtml: "846778",
		Nombre: "Majestic Plum",
		ColorNum: "1214",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 90,
		colorR: 132,
		colorG: 103,
		colorB: 120,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [90, 51],
		Triadicos: [90, 64, 20],
		Adjacentes: [8, 90, 104],
	},
	{
		Id: 6333,
		CodigoHtml: "54454F",
		Nombre: "Purple Shadow",
		ColorNum: "1215",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 90,
		colorR: 84,
		colorG: 69,
		colorB: 79,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [90, 51],
		Triadicos: [90, 64, 20],
		Adjacentes: [8, 90, 104],
	},
	{
		Id: 6334,
		CodigoHtml: "EDE3DF",
		Nombre: "Aimee",
		ColorNum: "1216",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 91,
		colorR: 237,
		colorG: 227,
		colorB: 223,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [91, 52],
		Triadicos: [91, 65, 21],
		Adjacentes: [9, 91, 105],
	},
	{
		Id: 6335,
		CodigoHtml: "E2D4D6",
		Nombre: "Velvet Blush",
		ColorNum: "1217",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 91,
		colorR: 226,
		colorG: 212,
		colorB: 214,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [91, 52],
		Triadicos: [91, 65, 21],
		Adjacentes: [9, 91, 105],
	},
	{
		Id: 6336,
		CodigoHtml: "D6C7CC",
		Nombre: "Odyssey Lilac",
		ColorNum: "1218",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 91,
		colorR: 214,
		colorG: 199,
		colorB: 204,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [91, 52],
		Triadicos: [91, 65, 21],
		Adjacentes: [9, 91, 105],
	},
	{
		Id: 6337,
		CodigoHtml: "C5AFBA",
		Nombre: "Desireé",
		ColorNum: "1219",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 91,
		colorR: 197,
		colorG: 175,
		colorB: 186,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [91, 52],
		Triadicos: [91, 65, 21],
		Adjacentes: [9, 91, 105],
	},
	{
		Id: 6338,
		CodigoHtml: "B69EAB",
		Nombre: "Play Time",
		ColorNum: "1220",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 91,
		colorR: 182,
		colorG: 158,
		colorB: 171,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [91, 52],
		Triadicos: [91, 65, 21],
		Adjacentes: [9, 91, 105],
	},
	{
		Id: 6339,
		CodigoHtml: "8F7683",
		Nombre: "Charming Violet",
		ColorNum: "1221",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 91,
		colorR: 143,
		colorG: 118,
		colorB: 131,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [91, 52],
		Triadicos: [91, 65, 21],
		Adjacentes: [9, 91, 105],
	},
	{
		Id: 6340,
		CodigoHtml: "5E4A52",
		Nombre: "Grape Soda",
		ColorNum: "1222",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 91,
		colorR: 94,
		colorG: 74,
		colorB: 82,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [91, 52],
		Triadicos: [91, 65, 21],
		Adjacentes: [9, 91, 105],
	},
	{
		Id: 6341,
		CodigoHtml: "E9DFE5",
		Nombre: "Ostrich Tail",
		ColorNum: "1223",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 92,
		colorR: 233,
		colorG: 223,
		colorB: 229,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [92, 53],
		Triadicos: [92, 66, 22],
		Adjacentes: [10, 92, 106],
	},
	{
		Id: 6342,
		CodigoHtml: "D6C7D5",
		Nombre: "Fragrant Snowbell",
		ColorNum: "1224",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 92,
		colorR: 214,
		colorG: 199,
		colorB: 213,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [92, 53],
		Triadicos: [92, 66, 22],
		Adjacentes: [10, 92, 106],
	},
	{
		Id: 6343,
		CodigoHtml: "BCA9C0",
		Nombre: "Velour",
		ColorNum: "1225",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 92,
		colorR: 188,
		colorG: 169,
		colorB: 192,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [92, 53],
		Triadicos: [92, 66, 22],
		Adjacentes: [10, 92, 106],
	},
	{
		Id: 6344,
		CodigoHtml: "97819B",
		Nombre: "Imagine That",
		ColorNum: "1226",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 92,
		colorR: 151,
		colorG: 129,
		colorB: 155,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [92, 53],
		Triadicos: [92, 66, 22],
		Adjacentes: [10, 92, 106],
	},
	{
		Id: 6345,
		CodigoHtml: "675569",
		Nombre: "Vivid Vision",
		ColorNum: "1227",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 92,
		colorR: 103,
		colorG: 85,
		colorB: 105,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [92, 53],
		Triadicos: [92, 66, 22],
		Adjacentes: [10, 92, 106],
	},
	{
		Id: 6346,
		CodigoHtml: "564954",
		Nombre: "Petunia Patty",
		ColorNum: "1228",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 92,
		colorR: 86,
		colorG: 73,
		colorB: 84,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [92, 53],
		Triadicos: [92, 66, 22],
		Adjacentes: [10, 92, 106],
	},
	{
		Id: 6347,
		CodigoHtml: "4D4449",
		Nombre: "Eleanor Ann",
		ColorNum: "1229",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 92,
		colorR: 77,
		colorG: 68,
		colorB: 73,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [92, 53],
		Triadicos: [92, 66, 22],
		Adjacentes: [10, 92, 106],
	},
	{
		Id: 6348,
		CodigoHtml: "EEE7E8",
		Nombre: "Crescent Moon",
		ColorNum: "1230",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 93,
		colorR: 238,
		colorG: 231,
		colorB: 232,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [93, 54],
		Triadicos: [93, 67, 23],
		Adjacentes: [11, 93, 107],
	},
	{
		Id: 6349,
		CodigoHtml: "EAE0E7",
		Nombre: "Hosta Flower",
		ColorNum: "1231",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 93,
		colorR: 234,
		colorG: 224,
		colorB: 231,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [93, 54],
		Triadicos: [93, 67, 23],
		Adjacentes: [11, 93, 107],
	},
	{
		Id: 6350,
		CodigoHtml: "E2D6E4",
		Nombre: "Flowers of May",
		ColorNum: "1232",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 93,
		colorR: 226,
		colorG: 214,
		colorB: 228,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [93, 54],
		Triadicos: [93, 67, 23],
		Adjacentes: [11, 93, 107],
	},
	{
		Id: 6351,
		CodigoHtml: "D1C0DC",
		Nombre: "Plum Cake",
		ColorNum: "1233",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 93,
		colorR: 209,
		colorG: 192,
		colorB: 220,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [93, 54],
		Triadicos: [93, 67, 23],
		Adjacentes: [11, 93, 107],
	},
	{
		Id: 6352,
		CodigoHtml: "C0B0CF",
		Nombre: "Grape's Treasure",
		ColorNum: "1234",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 93,
		colorR: 192,
		colorG: 176,
		colorB: 207,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [93, 54],
		Triadicos: [93, 67, 23],
		Adjacentes: [11, 93, 107],
	},
	{
		Id: 6353,
		CodigoHtml: "A694BC",
		Nombre: "Yolanda",
		ColorNum: "1235",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 93,
		colorR: 166,
		colorG: 148,
		colorB: 188,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [93, 54],
		Triadicos: [93, 67, 23],
		Adjacentes: [11, 93, 107],
	},
	{
		Id: 6354,
		CodigoHtml: "624D6C",
		Nombre: "Sunset Serenade",
		ColorNum: "1236",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 93,
		colorR: 98,
		colorG: 77,
		colorB: 108,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [93, 54],
		Triadicos: [93, 67, 23],
		Adjacentes: [11, 93, 107],
	},
	{
		Id: 6355,
		CodigoHtml: "EBE5EB",
		Nombre: "Easter Bunny",
		ColorNum: "1237",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 94,
		colorR: 235,
		colorG: 229,
		colorB: 235,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [94, 55],
		Triadicos: [94, 68, 24],
		Adjacentes: [12, 94, 108],
	},
	{
		Id: 6356,
		CodigoHtml: "E4DBEA",
		Nombre: "Fairytale",
		ColorNum: "1238",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 94,
		colorR: 228,
		colorG: 219,
		colorB: 234,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [94, 55],
		Triadicos: [94, 68, 24],
		Adjacentes: [12, 94, 108],
	},
	{
		Id: 6357,
		CodigoHtml: "DACEE7",
		Nombre: "Plum's the Word",
		ColorNum: "1239",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 94,
		colorR: 218,
		colorG: 206,
		colorB: 231,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [94, 55],
		Triadicos: [94, 68, 24],
		Adjacentes: [12, 94, 108],
	},
	{
		Id: 6358,
		CodigoHtml: "CDBDE2",
		Nombre: "Drifting Dream",
		ColorNum: "1240",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 94,
		colorR: 205,
		colorG: 189,
		colorB: 226,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [94, 55],
		Triadicos: [94, 68, 24],
		Adjacentes: [12, 94, 108],
	},
	{
		Id: 6359,
		CodigoHtml: "B6A8D5",
		Nombre: "Grape Illusion",
		ColorNum: "1241",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 94,
		colorR: 182,
		colorG: 168,
		colorB: 213,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [94, 55],
		Triadicos: [94, 68, 24],
		Adjacentes: [12, 94, 108],
	},
	{
		Id: 6360,
		CodigoHtml: "A797CB",
		Nombre: "Gorgeous Hydrangea",
		ColorNum: "1242",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 94,
		colorR: 167,
		colorG: 151,
		colorB: 203,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [94, 55],
		Triadicos: [94, 68, 24],
		Adjacentes: [12, 94, 108],
	},
	{
		Id: 6361,
		CodigoHtml: "604D7A",
		Nombre: "When Red Met Blue",
		ColorNum: "1243",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 94,
		colorR: 96,
		colorG: 77,
		colorB: 122,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [94, 55],
		Triadicos: [94, 68, 24],
		Adjacentes: [12, 94, 108],
	},
	{
		Id: 6362,
		CodigoHtml: "E2DFE7",
		Nombre: "White Bud",
		ColorNum: "1244",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 95,
		colorR: 226,
		colorG: 223,
		colorB: 231,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [95, 56],
		Triadicos: [95, 69, 25],
		Adjacentes: [13, 95, 109],
	},
	{
		Id: 6363,
		CodigoHtml: "D9D5E4",
		Nombre: "Perfection",
		ColorNum: "1245",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 95,
		colorR: 217,
		colorG: 213,
		colorB: 228,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [95, 56],
		Triadicos: [95, 69, 25],
		Adjacentes: [13, 95, 109],
	},
	{
		Id: 6364,
		CodigoHtml: "CAC6DF",
		Nombre: "Purple Purity",
		ColorNum: "1246",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 95,
		colorR: 202,
		colorG: 198,
		colorB: 223,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [95, 56],
		Triadicos: [95, 69, 25],
		Adjacentes: [13, 95, 109],
	},
	{
		Id: 6365,
		CodigoHtml: "A3A0C8",
		Nombre: "May Mist",
		ColorNum: "1247",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 95,
		colorR: 163,
		colorG: 160,
		colorB: 200,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [95, 56],
		Triadicos: [95, 69, 25],
		Adjacentes: [13, 95, 109],
	},
	{
		Id: 6366,
		CodigoHtml: "9593BD",
		Nombre: "Velvet Crest",
		ColorNum: "1248",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 95,
		colorR: 149,
		colorG: 147,
		colorB: 189,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [95, 56],
		Triadicos: [95, 69, 25],
		Adjacentes: [13, 95, 109],
	},
	{
		Id: 6367,
		CodigoHtml: "78739F",
		Nombre: "Purple Spire",
		ColorNum: "1249",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 95,
		colorR: 120,
		colorG: 115,
		colorB: 159,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [95, 56],
		Triadicos: [95, 69, 25],
		Adjacentes: [13, 95, 109],
	},
	{
		Id: 6368,
		CodigoHtml: "574E70",
		Nombre: "True Romance",
		ColorNum: "1250",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 95,
		colorR: 87,
		colorG: 78,
		colorB: 112,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [95, 56],
		Triadicos: [95, 69, 25],
		Adjacentes: [13, 95, 109],
	},
	{
		Id: 6369,
		CodigoHtml: "D9D0E2",
		Nombre: "Mountain's Majesty",
		ColorNum: "1251",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 96,
		colorR: 217,
		colorG: 208,
		colorB: 226,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [96, 57],
		Triadicos: [96, 70, 26],
		Adjacentes: [14, 96, 110],
	},
	{
		Id: 6370,
		CodigoHtml: "CFC3DC",
		Nombre: "Lavender Bliss",
		ColorNum: "1252",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 96,
		colorR: 207,
		colorG: 195,
		colorB: 220,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [96, 57],
		Triadicos: [96, 70, 26],
		Adjacentes: [14, 96, 110],
	},
	{
		Id: 6371,
		CodigoHtml: "B9B1CE",
		Nombre: "Petunia Trail",
		ColorNum: "1253",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 96,
		colorR: 185,
		colorG: 177,
		colorB: 206,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [96, 57],
		Triadicos: [96, 70, 26],
		Adjacentes: [14, 96, 110],
	},
	{
		Id: 6372,
		CodigoHtml: "A49EC8",
		Nombre: "Purple Vision",
		ColorNum: "1254",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 96,
		colorR: 164,
		colorG: 158,
		colorB: 200,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [96, 57],
		Triadicos: [96, 70, 26],
		Adjacentes: [14, 96, 110],
	},
	{
		Id: 6373,
		CodigoHtml: "A098C5",
		Nombre: "Purple Haze",
		ColorNum: "1255",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 96,
		colorR: 160,
		colorG: 152,
		colorB: 197,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [96, 57],
		Triadicos: [96, 70, 26],
		Adjacentes: [14, 96, 110],
	},
	{
		Id: 6374,
		CodigoHtml: "8275AB",
		Nombre: "Plum Preserve",
		ColorNum: "1256",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 96,
		colorR: 130,
		colorG: 117,
		colorB: 171,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [96, 57],
		Triadicos: [96, 70, 26],
		Adjacentes: [14, 96, 110],
	},
	{
		Id: 6375,
		CodigoHtml: "574C71",
		Nombre: "Voldemort",
		ColorNum: "1257",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 96,
		colorR: 87,
		colorG: 76,
		colorB: 113,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [96, 57],
		Triadicos: [96, 70, 26],
		Adjacentes: [14, 96, 110],
	},
	{
		Id: 6376,
		CodigoHtml: "E3E3EA",
		Nombre: "Sweet Flower",
		ColorNum: "1258",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 97,
		colorR: 227,
		colorG: 227,
		colorB: 234,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [97, 29],
		Triadicos: [97, 51, 24],
		Adjacentes: [67, 97, 72],
	},
	{
		Id: 6377,
		CodigoHtml: "DADAEA",
		Nombre: "Eyeshadow",
		ColorNum: "1259",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 97,
		colorR: 218,
		colorG: 218,
		colorB: 234,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [97, 29],
		Triadicos: [97, 51, 24],
		Adjacentes: [67, 97, 72],
	},
	{
		Id: 6378,
		CodigoHtml: "BFBFE4",
		Nombre: "Pretty Posie",
		ColorNum: "1260",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 97,
		colorR: 191,
		colorG: 191,
		colorB: 228,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [97, 29],
		Triadicos: [97, 51, 24],
		Adjacentes: [67, 97, 72],
	},
	{
		Id: 6379,
		CodigoHtml: "A9AAD7",
		Nombre: "Childish Wonder",
		ColorNum: "1261",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 97,
		colorR: 169,
		colorG: 170,
		colorB: 215,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [97, 29],
		Triadicos: [97, 51, 24],
		Adjacentes: [67, 97, 72],
	},
	{
		Id: 6380,
		CodigoHtml: "9092CA",
		Nombre: "Bailey Bells",
		ColorNum: "1262",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 97,
		colorR: 144,
		colorG: 146,
		colorB: 202,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [97, 29],
		Triadicos: [97, 51, 24],
		Adjacentes: [67, 97, 72],
	},
	{
		Id: 6381,
		CodigoHtml: "7173B3",
		Nombre: "Medieval",
		ColorNum: "1263",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 97,
		colorR: 113,
		colorG: 115,
		colorB: 179,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [97, 29],
		Triadicos: [97, 51, 24],
		Adjacentes: [67, 97, 72],
	},
	{
		Id: 6382,
		CodigoHtml: "555283",
		Nombre: "Altar of Heaven",
		ColorNum: "1264",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 97,
		colorR: 85,
		colorG: 82,
		colorB: 131,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [97, 29],
		Triadicos: [97, 51, 24],
		Adjacentes: [67, 97, 72],
	},
	{
		Id: 6383,
		CodigoHtml: "F1F0ED",
		Nombre: "White Shoulders",
		ColorNum: "1265",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 98,
		colorR: 241,
		colorG: 240,
		colorB: 237,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [98, 30],
		Triadicos: [98, 52, 25],
		Adjacentes: [68, 98, 73],
	},
	{
		Id: 6384,
		CodigoHtml: "E4E3EB",
		Nombre: "Penelope",
		ColorNum: "1266",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 98,
		colorR: 228,
		colorG: 227,
		colorB: 235,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [98, 30],
		Triadicos: [98, 52, 25],
		Adjacentes: [68, 98, 73],
	},
	{
		Id: 6385,
		CodigoHtml: "CCD0E6",
		Nombre: "Casa del Mar",
		ColorNum: "1267",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 98,
		colorR: 204,
		colorG: 208,
		colorB: 230,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [98, 30],
		Triadicos: [98, 52, 25],
		Adjacentes: [68, 98, 73],
	},
	{
		Id: 6386,
		CodigoHtml: "BAC0E4",
		Nombre: "Spoiled Rotten",
		ColorNum: "1268",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 98,
		colorR: 186,
		colorG: 192,
		colorB: 228,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [98, 30],
		Triadicos: [98, 52, 25],
		Adjacentes: [68, 98, 73],
	},
	{
		Id: 6387,
		CodigoHtml: "8792CB",
		Nombre: "Mood Mode",
		ColorNum: "1269",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 98,
		colorR: 135,
		colorG: 146,
		colorB: 203,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [98, 30],
		Triadicos: [98, 52, 25],
		Adjacentes: [68, 98, 73],
	},
	{
		Id: 6388,
		CodigoHtml: "7179B8",
		Nombre: "Wind Star",
		ColorNum: "1270",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 98,
		colorR: 113,
		colorG: 121,
		colorB: 184,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [98, 30],
		Triadicos: [98, 52, 25],
		Adjacentes: [68, 98, 73],
	},
	{
		Id: 6389,
		CodigoHtml: "4A4779",
		Nombre: "Hot Sauna",
		ColorNum: "1271",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 98,
		colorR: 74,
		colorG: 71,
		colorB: 121,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [98, 30],
		Triadicos: [98, 52, 25],
		Adjacentes: [68, 98, 73],
	},
	{
		Id: 6390,
		CodigoHtml: "E9E8E5",
		Nombre: "Angel Kiss",
		ColorNum: "1272",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 99,
		colorR: 233,
		colorG: 232,
		colorB: 229,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [99, 31],
		Triadicos: [99, 53, 26],
		Adjacentes: [69, 99, 74],
	},
	{
		Id: 6391,
		CodigoHtml: "CCD0E2",
		Nombre: "Wildflower Prairie",
		ColorNum: "1273",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 99,
		colorR: 204,
		colorG: 208,
		colorB: 226,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [99, 31],
		Triadicos: [99, 53, 26],
		Adjacentes: [69, 99, 74],
	},
	{
		Id: 6392,
		CodigoHtml: "BEC5DE",
		Nombre: "Genevieve",
		ColorNum: "1274",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 99,
		colorR: 190,
		colorG: 197,
		colorB: 222,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [99, 31],
		Triadicos: [99, 53, 26],
		Adjacentes: [69, 99, 74],
	},
	{
		Id: 6393,
		CodigoHtml: "ABB2D7",
		Nombre: "High Style",
		ColorNum: "1275",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 99,
		colorR: 171,
		colorG: 178,
		colorB: 215,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [99, 31],
		Triadicos: [99, 53, 26],
		Adjacentes: [69, 99, 74],
	},
	{
		Id: 6394,
		CodigoHtml: "98A0CC",
		Nombre: "Velvet Robe",
		ColorNum: "1276",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 99,
		colorR: 152,
		colorG: 160,
		colorB: 204,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [99, 31],
		Triadicos: [99, 53, 26],
		Adjacentes: [69, 99, 74],
	},
	{
		Id: 6395,
		CodigoHtml: "717AB0",
		Nombre: "Regal Azure",
		ColorNum: "1277",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 99,
		colorR: 113,
		colorG: 122,
		colorB: 176,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [99, 31],
		Triadicos: [99, 53, 26],
		Adjacentes: [69, 99, 74],
	},
	{
		Id: 6396,
		CodigoHtml: "5B6196",
		Nombre: "Fresh Take",
		ColorNum: "1278",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 99,
		colorR: 91,
		colorG: 97,
		colorB: 150,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [99, 31],
		Triadicos: [99, 53, 26],
		Adjacentes: [69, 99, 74],
	},
	{
		Id: 6397,
		CodigoHtml: "E1DCE3",
		Nombre: "He Loves Me",
		ColorNum: "1279",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 100,
		colorR: 225,
		colorG: 220,
		colorB: 227,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [100, 32],
		Triadicos: [100, 54, 27],
		Adjacentes: [86, 100, 75],
	},
	{
		Id: 6398,
		CodigoHtml: "D4CDDB",
		Nombre: "Lilac Luster",
		ColorNum: "1280",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 100,
		colorR: 212,
		colorG: 205,
		colorB: 219,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [100, 32],
		Triadicos: [100, 54, 27],
		Adjacentes: [86, 100, 75],
	},
	{
		Id: 6399,
		CodigoHtml: "BFB9CD",
		Nombre: "Collensia",
		ColorNum: "1281",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 100,
		colorR: 191,
		colorG: 185,
		colorB: 205,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [100, 32],
		Triadicos: [100, 54, 27],
		Adjacentes: [86, 100, 75],
	},
	{
		Id: 6400,
		CodigoHtml: "A6A4BA",
		Nombre: "Butterfly Bush",
		ColorNum: "1282",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 100,
		colorR: 166,
		colorG: 164,
		colorB: 186,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [100, 32],
		Triadicos: [100, 54, 27],
		Adjacentes: [86, 100, 75],
	},
	{
		Id: 6401,
		CodigoHtml: "9896AE",
		Nombre: "Lavender Spectacle",
		ColorNum: "1283",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 100,
		colorR: 152,
		colorG: 150,
		colorB: 174,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [100, 32],
		Triadicos: [100, 54, 27],
		Adjacentes: [86, 100, 75],
	},
	{
		Id: 6402,
		CodigoHtml: "6D6983",
		Nombre: "Grapes of Wrath",
		ColorNum: "1284",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 100,
		colorR: 109,
		colorG: 105,
		colorB: 131,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [100, 32],
		Triadicos: [100, 54, 27],
		Adjacentes: [86, 100, 75],
	},
	{
		Id: 6403,
		CodigoHtml: "514C5B",
		Nombre: "Jazlyn",
		ColorNum: "1285",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 100,
		colorR: 81,
		colorG: 76,
		colorB: 91,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [100, 32],
		Triadicos: [100, 54, 27],
		Adjacentes: [86, 100, 75],
	},
	{
		Id: 6404,
		CodigoHtml: "E8E2E5",
		Nombre: "Lavender Pizzazz",
		ColorNum: "1286",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 101,
		colorR: 232,
		colorG: 226,
		colorB: 229,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [101, 33],
		Triadicos: [101, 55, 28],
		Adjacentes: [87, 101, 76],
	},
	{
		Id: 6405,
		CodigoHtml: "CAC2D0",
		Nombre: "Orchid Fragrance",
		ColorNum: "1287",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 101,
		colorR: 202,
		colorG: 194,
		colorB: 208,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [101, 33],
		Triadicos: [101, 55, 28],
		Adjacentes: [87, 101, 76],
	},
	{
		Id: 6406,
		CodigoHtml: "B7B0C3",
		Nombre: "Violet Crush",
		ColorNum: "1288",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 101,
		colorR: 183,
		colorG: 176,
		colorB: 195,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [101, 33],
		Triadicos: [101, 55, 28],
		Adjacentes: [87, 101, 76],
	},
	{
		Id: 6407,
		CodigoHtml: "9E96AB",
		Nombre: "Lilac Blossom",
		ColorNum: "1289",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 101,
		colorR: 158,
		colorG: 150,
		colorB: 171,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [101, 33],
		Triadicos: [101, 55, 28],
		Adjacentes: [87, 101, 76],
	},
	{
		Id: 6408,
		CodigoHtml: "8E859B",
		Nombre: "Violet Vibes",
		ColorNum: "1290",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 101,
		colorR: 142,
		colorG: 133,
		colorB: 155,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [101, 33],
		Triadicos: [101, 55, 28],
		Adjacentes: [87, 101, 76],
	},
	{
		Id: 6409,
		CodigoHtml: "81788D",
		Nombre: "Dancing in the Spring",
		ColorNum: "1291",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 101,
		colorR: 129,
		colorG: 120,
		colorB: 141,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [101, 33],
		Triadicos: [101, 55, 28],
		Adjacentes: [87, 101, 76],
	},
	{
		Id: 6410,
		CodigoHtml: "5A505E",
		Nombre: "Plum Perfect",
		ColorNum: "1292",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 101,
		colorR: 90,
		colorG: 80,
		colorB: 94,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [101, 33],
		Triadicos: [101, 55, 28],
		Adjacentes: [87, 101, 76],
	},
	{
		Id: 6411,
		CodigoHtml: "E1D9E3",
		Nombre: "Violet Pearl",
		ColorNum: "1293",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 102,
		colorR: 225,
		colorG: 217,
		colorB: 227,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [102, 34],
		Triadicos: [102, 56, 29],
		Adjacentes: [88, 102, 77],
	},
	{
		Id: 6412,
		CodigoHtml: "DBD5DF",
		Nombre: "Lavender Scent",
		ColorNum: "1294",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 102,
		colorR: 219,
		colorG: 213,
		colorB: 223,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [102, 34],
		Triadicos: [102, 56, 29],
		Adjacentes: [88, 102, 77],
	},
	{
		Id: 6413,
		CodigoHtml: "D1C9D7",
		Nombre: "Gypsy Caravan",
		ColorNum: "1295",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 102,
		colorR: 209,
		colorG: 201,
		colorB: 215,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [102, 34],
		Triadicos: [102, 56, 29],
		Adjacentes: [88, 102, 77],
	},
	{
		Id: 6414,
		CodigoHtml: "BCB4CA",
		Nombre: "Violet Beauty",
		ColorNum: "1296",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 102,
		colorR: 188,
		colorG: 180,
		colorB: 202,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [102, 34],
		Triadicos: [102, 56, 29],
		Adjacentes: [88, 102, 77],
	},
	{
		Id: 6415,
		CodigoHtml: "ACA2BD",
		Nombre: "Royal Proclamation",
		ColorNum: "1297",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 102,
		colorR: 172,
		colorG: 162,
		colorB: 189,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [102, 34],
		Triadicos: [102, 56, 29],
		Adjacentes: [88, 102, 77],
	},
	{
		Id: 6416,
		CodigoHtml: "857A9B",
		Nombre: "Queen of Sheba",
		ColorNum: "1298",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 102,
		colorR: 133,
		colorG: 122,
		colorB: 155,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [102, 34],
		Triadicos: [102, 56, 29],
		Adjacentes: [88, 102, 77],
	},
	{
		Id: 6417,
		CodigoHtml: "625165",
		Nombre: "Soul Train",
		ColorNum: "1299",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 102,
		colorR: 98,
		colorG: 81,
		colorB: 101,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [102, 34],
		Triadicos: [102, 56, 29],
		Adjacentes: [88, 102, 77],
	},
	{
		Id: 6418,
		CodigoHtml: "ECE3E2",
		Nombre: "Winged Victory",
		ColorNum: "1300",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 103,
		colorR: 236,
		colorG: 227,
		colorB: 226,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [103, 35],
		Triadicos: [103, 57, 30],
		Adjacentes: [89, 103, 78],
	},
	{
		Id: 6419,
		CodigoHtml: "E5DADA",
		Nombre: "Lavender Bikini",
		ColorNum: "1301",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 103,
		colorR: 229,
		colorG: 218,
		colorB: 218,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [103, 35],
		Triadicos: [103, 57, 30],
		Adjacentes: [89, 103, 78],
	},
	{
		Id: 6420,
		CodigoHtml: "BDB1BC",
		Nombre: "Heather Hill",
		ColorNum: "1302",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 103,
		colorR: 189,
		colorG: 177,
		colorB: 188,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [103, 35],
		Triadicos: [103, 57, 30],
		Adjacentes: [89, 103, 78],
	},
	{
		Id: 6421,
		CodigoHtml: "A194A1",
		Nombre: "Cut Heather",
		ColorNum: "1303",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 103,
		colorR: 161,
		colorG: 148,
		colorB: 161,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [103, 35],
		Triadicos: [103, 57, 30],
		Adjacentes: [89, 103, 78],
	},
	{
		Id: 6422,
		CodigoHtml: "92838E",
		Nombre: "Iced Orchid",
		ColorNum: "1304",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 103,
		colorR: 146,
		colorG: 131,
		colorB: 142,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [103, 35],
		Triadicos: [103, 57, 30],
		Adjacentes: [89, 103, 78],
	},
	{
		Id: 6423,
		CodigoHtml: "80717C",
		Nombre: "Victorian Violet",
		ColorNum: "1305",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 103,
		colorR: 128,
		colorG: 113,
		colorB: 124,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [103, 35],
		Triadicos: [103, 57, 30],
		Adjacentes: [89, 103, 78],
	},
	{
		Id: 6424,
		CodigoHtml: "5B4A57",
		Nombre: "Royal Velvet",
		ColorNum: "1306",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 103,
		colorR: 91,
		colorG: 74,
		colorB: 87,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [103, 35],
		Triadicos: [103, 57, 30],
		Adjacentes: [89, 103, 78],
	},
	{
		Id: 6425,
		CodigoHtml: "E6E1DE",
		Nombre: "Clytemnestra",
		ColorNum: "1307",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 104,
		colorR: 230,
		colorG: 225,
		colorB: 222,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [104, 36],
		Triadicos: [104, 58, 31],
		Adjacentes: [90, 104, 79],
	},
	{
		Id: 6426,
		CodigoHtml: "DACED4",
		Nombre: "Stardust Ballroom",
		ColorNum: "1308",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 104,
		colorR: 218,
		colorG: 206,
		colorB: 212,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [104, 36],
		Triadicos: [104, 58, 31],
		Adjacentes: [90, 104, 79],
	},
	{
		Id: 6427,
		CodigoHtml: "CDC0C9",
		Nombre: "So Dainty",
		ColorNum: "1309",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 104,
		colorR: 205,
		colorG: 192,
		colorB: 201,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [104, 36],
		Triadicos: [104, 58, 31],
		Adjacentes: [90, 104, 79],
	},
	{
		Id: 6428,
		CodigoHtml: "BFB1BD",
		Nombre: "Velvet Dawn",
		ColorNum: "1310",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 104,
		colorR: 191,
		colorG: 177,
		colorB: 189,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [104, 36],
		Triadicos: [104, 58, 31],
		Adjacentes: [90, 104, 79],
	},
	{
		Id: 6429,
		CodigoHtml: "A89AAA",
		Nombre: "Gypsy's Gown",
		ColorNum: "1311",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 104,
		colorR: 168,
		colorG: 154,
		colorB: 170,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [104, 36],
		Triadicos: [104, 58, 31],
		Adjacentes: [90, 104, 79],
	},
	{
		Id: 6430,
		CodigoHtml: "817180",
		Nombre: "Southern Belle",
		ColorNum: "1312",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 104,
		colorR: 129,
		colorG: 113,
		colorB: 128,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [104, 36],
		Triadicos: [104, 58, 31],
		Adjacentes: [90, 104, 79],
	},
	{
		Id: 6431,
		CodigoHtml: "594F58",
		Nombre: "My Place or Yours?",
		ColorNum: "1313",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 104,
		colorR: 89,
		colorG: 79,
		colorB: 88,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [104, 36],
		Triadicos: [104, 58, 31],
		Adjacentes: [90, 104, 79],
	},
	{
		Id: 6432,
		CodigoHtml: "D9D8DA",
		Nombre: "Desired Dawn",
		ColorNum: "1314",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 105,
		colorR: 217,
		colorG: 216,
		colorB: 218,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [105, 37],
		Triadicos: [105, 59, 32],
		Adjacentes: [91, 105, 80],
	},
	{
		Id: 6433,
		CodigoHtml: "CFCED3",
		Nombre: "Calamities",
		ColorNum: "1315",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 105,
		colorR: 207,
		colorG: 206,
		colorB: 211,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [105, 37],
		Triadicos: [105, 59, 32],
		Adjacentes: [91, 105, 80],
	},
	{
		Id: 6434,
		CodigoHtml: "C0BFC7",
		Nombre: "Savoy",
		ColorNum: "1316",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 105,
		colorR: 192,
		colorG: 191,
		colorB: 199,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [105, 37],
		Triadicos: [105, 59, 32],
		Adjacentes: [91, 105, 80],
	},
	{
		Id: 6435,
		CodigoHtml: "B1B1BB",
		Nombre: "Buffed Plum",
		ColorNum: "1317",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 105,
		colorR: 177,
		colorG: 177,
		colorB: 187,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [105, 37],
		Triadicos: [105, 59, 32],
		Adjacentes: [91, 105, 80],
	},
	{
		Id: 6436,
		CodigoHtml: "9B9DAA",
		Nombre: "Dusky Mood",
		ColorNum: "1318",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 105,
		colorR: 155,
		colorG: 157,
		colorB: 170,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [105, 37],
		Triadicos: [105, 59, 32],
		Adjacentes: [91, 105, 80],
	},
	{
		Id: 6437,
		CodigoHtml: "6F7280",
		Nombre: "Overlook",
		ColorNum: "1319",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 105,
		colorR: 111,
		colorG: 114,
		colorB: 128,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [105, 37],
		Triadicos: [105, 59, 32],
		Adjacentes: [91, 105, 80],
	},
	{
		Id: 6438,
		CodigoHtml: "5D5E6A",
		Nombre: "Blackwater",
		ColorNum: "1320",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 105,
		colorR: 93,
		colorG: 94,
		colorB: 106,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [105, 37],
		Triadicos: [105, 59, 32],
		Adjacentes: [91, 105, 80],
	},
	{
		Id: 6439,
		CodigoHtml: "F4E0CF",
		Nombre: "Chafed Wheat",
		ColorNum: "0033",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 106,
		colorR: 244,
		colorG: 224,
		colorB: 207,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [106, 156],
		Triadicos: [106],
		Adjacentes: [107, 106, 108],
	},
	{
		Id: 6440,
		CodigoHtml: "EFD1BF",
		Nombre: "Here Comes the Sun",
		ColorNum: "0034",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 106,
		colorR: 239,
		colorG: 209,
		colorB: 191,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [106, 156],
		Triadicos: [106],
		Adjacentes: [107, 106, 108],
	},
	{
		Id: 6441,
		CodigoHtml: "E8C4AF",
		Nombre: "Weekend Retreat",
		ColorNum: "0035",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 106,
		colorR: 232,
		colorG: 196,
		colorB: 175,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [106, 156],
		Triadicos: [106],
		Adjacentes: [107, 106, 108],
	},
	{
		Id: 6442,
		CodigoHtml: "D9AC97",
		Nombre: "Fortune's Prize",
		ColorNum: "0036",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 106,
		colorR: 217,
		colorG: 172,
		colorB: 151,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [106, 156],
		Triadicos: [106],
		Adjacentes: [107, 106, 108],
	},
	{
		Id: 6443,
		CodigoHtml: "C99780",
		Nombre: "Money Tree",
		ColorNum: "0037",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 106,
		colorR: 201,
		colorG: 151,
		colorB: 128,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [106, 156],
		Triadicos: [106],
		Adjacentes: [107, 106, 108],
	},
	{
		Id: 6444,
		CodigoHtml: "BC8069",
		Nombre: "Autumn's Hill",
		ColorNum: "0038",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 106,
		colorR: 188,
		colorG: 128,
		colorB: 105,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [106, 156],
		Triadicos: [106],
		Adjacentes: [107, 106, 108],
	},
	{
		Id: 6445,
		CodigoHtml: "A36952",
		Nombre: "Mandalay Road",
		ColorNum: "0039",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 106,
		colorR: 163,
		colorG: 105,
		colorB: 82,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [106, 156],
		Triadicos: [106],
		Adjacentes: [107, 106, 108],
	},
	{
		Id: 6446,
		CodigoHtml: "F3E2D7",
		Nombre: "Antique Candle Light",
		ColorNum: "0040",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 107,
		colorR: 243,
		colorG: 226,
		colorB: 215,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [107, 157],
		Triadicos: [107],
		Adjacentes: [106, 107, 108],
	},
	{
		Id: 6447,
		CodigoHtml: "F1D8CD",
		Nombre: "Chinese Cherry",
		ColorNum: "0041",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 107,
		colorR: 241,
		colorG: 216,
		colorB: 205,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [107, 157],
		Triadicos: [107],
		Adjacentes: [106, 107, 108],
	},
	{
		Id: 6448,
		CodigoHtml: "E6C4B6",
		Nombre: "Antoinette Pink",
		ColorNum: "0042",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 107,
		colorR: 230,
		colorG: 196,
		colorB: 182,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [107, 157],
		Triadicos: [107],
		Adjacentes: [106, 107, 108],
	},
	{
		Id: 6449,
		CodigoHtml: "DCB2A2",
		Nombre: "Angel Breath",
		ColorNum: "0043",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 107,
		colorR: 220,
		colorG: 178,
		colorB: 162,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [107, 157],
		Triadicos: [107],
		Adjacentes: [106, 107, 108],
	},
	{
		Id: 6450,
		CodigoHtml: "C99D91",
		Nombre: "Calliope",
		ColorNum: "0044",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 107,
		colorR: 201,
		colorG: 157,
		colorB: 145,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [107, 157],
		Triadicos: [107],
		Adjacentes: [106, 107, 108],
	},
	{
		Id: 6451,
		CodigoHtml: "A57160",
		Nombre: "Sizzling Hot",
		ColorNum: "0045",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 107,
		colorR: 165,
		colorG: 113,
		colorB: 96,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [107, 157],
		Triadicos: [107],
		Adjacentes: [106, 107, 108],
	},
	{
		Id: 6452,
		CodigoHtml: "835246",
		Nombre: "Valentino",
		ColorNum: "0046",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 107,
		colorR: 131,
		colorG: 82,
		colorB: 70,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [107, 157],
		Triadicos: [107],
		Adjacentes: [106, 107, 108],
	},
	{
		Id: 6453,
		CodigoHtml: "F2D2C3",
		Nombre: "Sand Island",
		ColorNum: "0047",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 108,
		colorR: 242,
		colorG: 210,
		colorB: 195,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [108, 158],
		Triadicos: [108],
		Adjacentes: [107, 108, 109],
	},
	{
		Id: 6454,
		CodigoHtml: "E6BAA9",
		Nombre: "Embrace",
		ColorNum: "0048",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 108,
		colorR: 230,
		colorG: 186,
		colorB: 169,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [108, 158],
		Triadicos: [108],
		Adjacentes: [107, 108, 109],
	},
	{
		Id: 6455,
		CodigoHtml: "D7A697",
		Nombre: "Auburn Wave",
		ColorNum: "0049",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 108,
		colorR: 215,
		colorG: 166,
		colorB: 151,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [108, 158],
		Triadicos: [108],
		Adjacentes: [107, 108, 109],
	},
	{
		Id: 6456,
		CodigoHtml: "CB9686",
		Nombre: "Simmering Ridge",
		ColorNum: "0050",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 108,
		colorR: 203,
		colorG: 150,
		colorB: 134,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [108, 158],
		Triadicos: [108],
		Adjacentes: [107, 108, 109],
	},
	{
		Id: 6457,
		CodigoHtml: "B47865",
		Nombre: "Caramel Candy",
		ColorNum: "0051",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 108,
		colorR: 180,
		colorG: 120,
		colorB: 101,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [108, 158],
		Triadicos: [108],
		Adjacentes: [107, 108, 109],
	},
	{
		Id: 6458,
		CodigoHtml: "A76A58",
		Nombre: "Spiced Carrot",
		ColorNum: "0052",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 108,
		colorR: 167,
		colorG: 106,
		colorB: 88,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [108, 158],
		Triadicos: [108],
		Adjacentes: [107, 108, 109],
	},
	{
		Id: 6459,
		CodigoHtml: "8D5644",
		Nombre: "Remaining Embers",
		ColorNum: "0053",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 108,
		colorR: 141,
		colorG: 86,
		colorB: 68,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [108, 158],
		Triadicos: [108],
		Adjacentes: [107, 108, 109],
	},
	{
		Id: 6460,
		CodigoHtml: "F7E8DF",
		Nombre: "Wink Pink",
		ColorNum: "0054",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 109,
		colorR: 247,
		colorG: 232,
		colorB: 223,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [109, 159],
		Triadicos: [109],
		Adjacentes: [108, 109, 110],
	},
	{
		Id: 6461,
		CodigoHtml: "F7D7CD",
		Nombre: "Strawberry Whip",
		ColorNum: "0055",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 109,
		colorR: 247,
		colorG: 215,
		colorB: 205,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [109, 159],
		Triadicos: [109],
		Adjacentes: [108, 109, 110],
	},
	{
		Id: 6462,
		CodigoHtml: "F2BEB4",
		Nombre: "Dainty Debutante",
		ColorNum: "0056",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 109,
		colorR: 242,
		colorG: 190,
		colorB: 180,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [109, 159],
		Triadicos: [109],
		Adjacentes: [108, 109, 110],
	},
	{
		Id: 6463,
		CodigoHtml: "D1867E",
		Nombre: "Marble Pink",
		ColorNum: "0057",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 109,
		colorR: 209,
		colorG: 134,
		colorB: 126,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [109, 159],
		Triadicos: [109],
		Adjacentes: [108, 109, 110],
	},
	{
		Id: 6464,
		CodigoHtml: "B86D64",
		Nombre: "Angelic Choir",
		ColorNum: "0058",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 109,
		colorR: 184,
		colorG: 109,
		colorB: 100,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [109, 159],
		Triadicos: [109],
		Adjacentes: [108, 109, 110],
	},
	{
		Id: 6465,
		CodigoHtml: "8F584E",
		Nombre: "Quintessential",
		ColorNum: "0059",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 109,
		colorR: 143,
		colorG: 88,
		colorB: 78,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [109, 159],
		Triadicos: [109],
		Adjacentes: [108, 109, 110],
	},
	{
		Id: 6466,
		CodigoHtml: "8C4944",
		Nombre: "Parlor Rose",
		ColorNum: "0060",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 109,
		colorR: 140,
		colorG: 73,
		colorB: 68,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [109, 159],
		Triadicos: [109],
		Adjacentes: [108, 109, 110],
	},
	{
		Id: 6467,
		CodigoHtml: "EEBEBB",
		Nombre: "Pinkathon",
		ColorNum: "0061",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 110,
		colorR: 238,
		colorG: 190,
		colorB: 187,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [110, 160],
		Triadicos: [110],
		Adjacentes: [109, 110, 111],
	},
	{
		Id: 6468,
		CodigoHtml: "D79A99",
		Nombre: "Blooming Perfect",
		ColorNum: "0062",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 110,
		colorR: 215,
		colorG: 154,
		colorB: 153,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [110, 160],
		Triadicos: [110],
		Adjacentes: [109, 110, 111],
	},
	{
		Id: 6469,
		CodigoHtml: "C48281",
		Nombre: "Impatient Heart",
		ColorNum: "0063",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 110,
		colorR: 196,
		colorG: 130,
		colorB: 129,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [110, 160],
		Triadicos: [110],
		Adjacentes: [109, 110, 111],
	},
	{
		Id: 6470,
		CodigoHtml: "B76D69",
		Nombre: "Lord Baltimore",
		ColorNum: "0064",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 110,
		colorR: 183,
		colorG: 109,
		colorB: 105,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [110, 160],
		Triadicos: [110],
		Adjacentes: [109, 110, 111],
	},
	{
		Id: 6471,
		CodigoHtml: "AE6660",
		Nombre: "Poppy Prose",
		ColorNum: "0065",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 110,
		colorR: 174,
		colorG: 102,
		colorB: 96,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [110, 160],
		Triadicos: [110],
		Adjacentes: [109, 110, 111],
	},
	{
		Id: 6472,
		CodigoHtml: "924145",
		Nombre: "Wild Rose",
		ColorNum: "0066",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 110,
		colorR: 146,
		colorG: 65,
		colorB: 69,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [110, 160],
		Triadicos: [110],
		Adjacentes: [109, 110, 111],
	},
	{
		Id: 6473,
		CodigoHtml: "914849",
		Nombre: "Lover's Kiss",
		ColorNum: "0067",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 110,
		colorR: 145,
		colorG: 72,
		colorB: 73,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [110, 160],
		Triadicos: [110],
		Adjacentes: [109, 110, 111],
	},
	{
		Id: 6474,
		CodigoHtml: "F7DFD8",
		Nombre: "Summer Blush",
		ColorNum: "0068",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 111,
		colorR: 247,
		colorG: 223,
		colorB: 216,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [111, 161],
		Triadicos: [111],
		Adjacentes: [110, 111, 112],
	},
	{
		Id: 6475,
		CodigoHtml: "E6C6C3",
		Nombre: "Empire Rose",
		ColorNum: "0069",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 111,
		colorR: 230,
		colorG: 198,
		colorB: 195,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [111, 161],
		Triadicos: [111],
		Adjacentes: [110, 111, 112],
	},
	{
		Id: 6476,
		CodigoHtml: "DFBAB7",
		Nombre: "O Fortuna",
		ColorNum: "0070",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 111,
		colorR: 223,
		colorG: 186,
		colorB: 183,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [111, 161],
		Triadicos: [111],
		Adjacentes: [110, 111, 112],
	},
	{
		Id: 6477,
		CodigoHtml: "D0A4A3",
		Nombre: "Ruby Eye",
		ColorNum: "0071",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 111,
		colorR: 208,
		colorG: 164,
		colorB: 163,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [111, 161],
		Triadicos: [111],
		Adjacentes: [110, 111, 112],
	},
	{
		Id: 6478,
		CodigoHtml: "BB8A8A",
		Nombre: "Notice Me",
		ColorNum: "0072",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 111,
		colorR: 187,
		colorG: 138,
		colorB: 138,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [111, 161],
		Triadicos: [111],
		Adjacentes: [110, 111, 112],
	},
	{
		Id: 6479,
		CodigoHtml: "9F6C69",
		Nombre: "Corazon",
		ColorNum: "0073",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 111,
		colorR: 159,
		colorG: 108,
		colorB: 105,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [111, 161],
		Triadicos: [111],
		Adjacentes: [110, 111, 112],
	},
	{
		Id: 6480,
		CodigoHtml: "7F534B",
		Nombre: "Emperor's Robe",
		ColorNum: "0074",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 111,
		colorR: 127,
		colorG: 83,
		colorB: 75,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [111, 161],
		Triadicos: [111],
		Adjacentes: [110, 111, 112],
	},
	{
		Id: 6481,
		CodigoHtml: "F7E7E4",
		Nombre: "Pink Duet",
		ColorNum: "0075",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 112,
		colorR: 247,
		colorG: 231,
		colorB: 228,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [112, 162],
		Triadicos: [112],
		Adjacentes: [111, 112, 113],
	},
	{
		Id: 6482,
		CodigoHtml: "E5C9C9",
		Nombre: "Fantastic Pink",
		ColorNum: "0076",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 112,
		colorR: 229,
		colorG: 201,
		colorB: 201,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [112, 162],
		Triadicos: [112],
		Adjacentes: [111, 112, 113],
	},
	{
		Id: 6483,
		CodigoHtml: "DDB9BD",
		Nombre: "Pink Softness",
		ColorNum: "0077",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 112,
		colorR: 221,
		colorG: 185,
		colorB: 189,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [112, 162],
		Triadicos: [112],
		Adjacentes: [111, 112, 113],
	},
	{
		Id: 6484,
		CodigoHtml: "CAA1A6",
		Nombre: "Be Mine",
		ColorNum: "0078",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 112,
		colorR: 202,
		colorG: 161,
		colorB: 166,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [112, 162],
		Triadicos: [112],
		Adjacentes: [111, 112, 113],
	},
	{
		Id: 6485,
		CodigoHtml: "BF8E92",
		Nombre: "Prophetess",
		ColorNum: "0079",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 112,
		colorR: 191,
		colorG: 142,
		colorB: 146,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [112, 162],
		Triadicos: [112],
		Adjacentes: [111, 112, 113],
	},
	{
		Id: 6486,
		CodigoHtml: "99686B",
		Nombre: "Abra Cadabra",
		ColorNum: "0080",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 112,
		colorR: 153,
		colorG: 104,
		colorB: 107,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [112, 162],
		Triadicos: [112],
		Adjacentes: [111, 112, 113],
	},
	{
		Id: 6487,
		CodigoHtml: "784F4B",
		Nombre: "Berry Crush",
		ColorNum: "0081",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 112,
		colorR: 120,
		colorG: 79,
		colorB: 75,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [112, 162],
		Triadicos: [112],
		Adjacentes: [111, 112, 113],
	},
	{
		Id: 6488,
		CodigoHtml: "F2D6D6",
		Nombre: "Rose Shadow",
		ColorNum: "0082",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 113,
		colorR: 242,
		colorG: 214,
		colorB: 214,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [113, 163],
		Triadicos: [113],
		Adjacentes: [112, 113, 114],
	},
	{
		Id: 6489,
		CodigoHtml: "EBCBCE",
		Nombre: "Pink Coral",
		ColorNum: "0083",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 113,
		colorR: 235,
		colorG: 203,
		colorB: 206,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [113, 163],
		Triadicos: [113],
		Adjacentes: [112, 113, 114],
	},
	{
		Id: 6490,
		CodigoHtml: "E4BDC2",
		Nombre: "Quiet Pink",
		ColorNum: "0084",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 113,
		colorR: 228,
		colorG: 189,
		colorB: 194,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [113, 163],
		Triadicos: [113],
		Adjacentes: [112, 113, 114],
	},
	{
		Id: 6491,
		CodigoHtml: "D7A5AD",
		Nombre: "Fragrant Satchel",
		ColorNum: "0085",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 113,
		colorR: 215,
		colorG: 165,
		colorB: 173,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [113, 163],
		Triadicos: [113],
		Adjacentes: [112, 113, 114],
	},
	{
		Id: 6492,
		CodigoHtml: "C48A96",
		Nombre: "King's Cloak",
		ColorNum: "0086",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 113,
		colorR: 196,
		colorG: 138,
		colorB: 150,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [113, 163],
		Triadicos: [113],
		Adjacentes: [112, 113, 114],
	},
	{
		Id: 6493,
		CodigoHtml: "A76C76",
		Nombre: "Cinnapink",
		ColorNum: "0087",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 113,
		colorR: 167,
		colorG: 108,
		colorB: 118,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [113, 163],
		Triadicos: [113],
		Adjacentes: [112, 113, 114],
	},
	{
		Id: 6494,
		CodigoHtml: "824F52",
		Nombre: "Casandra ",
		ColorNum: "0088",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 113,
		colorR: 130,
		colorG: 79,
		colorB: 82,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [113, 163],
		Triadicos: [113],
		Adjacentes: [112, 113, 114],
	},
	{
		Id: 6495,
		CodigoHtml: "EABCBF",
		Nombre: "Star of Morning",
		ColorNum: "0089",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 114,
		colorR: 234,
		colorG: 188,
		colorB: 191,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [114, 164],
		Triadicos: [114],
		Adjacentes: [113, 114, 115],
	},
	{
		Id: 6496,
		CodigoHtml: "D7A9B0",
		Nombre: "Moon Goddess",
		ColorNum: "0090",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 114,
		colorR: 215,
		colorG: 169,
		colorB: 176,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [114, 164],
		Triadicos: [114],
		Adjacentes: [113, 114, 115],
	},
	{
		Id: 6497,
		CodigoHtml: "C89499",
		Nombre: "Garnet Shadow",
		ColorNum: "0091",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 114,
		colorR: 200,
		colorG: 148,
		colorB: 153,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [114, 164],
		Triadicos: [114],
		Adjacentes: [113, 114, 115],
	},
	{
		Id: 6498,
		CodigoHtml: "B07579",
		Nombre: "Fresh Day",
		ColorNum: "0092",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 114,
		colorR: 176,
		colorG: 117,
		colorB: 121,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [114, 164],
		Triadicos: [114],
		Adjacentes: [113, 114, 115],
	},
	{
		Id: 6499,
		CodigoHtml: "A66A6C",
		Nombre: "Herald of Spring",
		ColorNum: "0093",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 114,
		colorR: 166,
		colorG: 106,
		colorB: 108,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [114, 164],
		Triadicos: [114],
		Adjacentes: [113, 114, 115],
	},
	{
		Id: 6500,
		CodigoHtml: "88585A",
		Nombre: "Starlet",
		ColorNum: "0094",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 114,
		colorR: 136,
		colorG: 88,
		colorB: 90,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [114, 164],
		Triadicos: [114],
		Adjacentes: [113, 114, 115],
	},
	{
		Id: 6501,
		CodigoHtml: "7C464A",
		Nombre: "Queen's Rose",
		ColorNum: "0095",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 114,
		colorR: 124,
		colorG: 70,
		colorB: 74,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [114, 164],
		Triadicos: [114],
		Adjacentes: [113, 114, 115],
	},
	{
		Id: 6502,
		CodigoHtml: "F2E1DE",
		Nombre: "Morning Side",
		ColorNum: "0096",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 115,
		colorR: 242,
		colorG: 225,
		colorB: 222,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [115, 165],
		Triadicos: [115],
		Adjacentes: [114, 115, 116],
	},
	{
		Id: 6503,
		CodigoHtml: "E1C4C6",
		Nombre: "Odyssey Plum",
		ColorNum: "0097",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 115,
		colorR: 225,
		colorG: 196,
		colorB: 198,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [115, 165],
		Triadicos: [115],
		Adjacentes: [114, 115, 116],
	},
	{
		Id: 6504,
		CodigoHtml: "BE9CA4",
		Nombre: "Lockhart",
		ColorNum: "0098",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 115,
		colorR: 190,
		colorG: 156,
		colorB: 164,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [115, 165],
		Triadicos: [115],
		Adjacentes: [114, 115, 116],
	},
	{
		Id: 6505,
		CodigoHtml: "A68088",
		Nombre: "Lover's Tryst",
		ColorNum: "0099",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 115,
		colorR: 166,
		colorG: 128,
		colorB: 136,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [115, 165],
		Triadicos: [115],
		Adjacentes: [114, 115, 116],
	},
	{
		Id: 6506,
		CodigoHtml: "8E666A",
		Nombre: "Whimsy",
		ColorNum: "0100",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 115,
		colorR: 142,
		colorG: 102,
		colorB: 106,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [115, 165],
		Triadicos: [115],
		Adjacentes: [114, 115, 116],
	},
	{
		Id: 6507,
		CodigoHtml: "7C484B",
		Nombre: "Fruitful Orchard",
		ColorNum: "0101",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 115,
		colorR: 124,
		colorG: 72,
		colorB: 75,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [115, 165],
		Triadicos: [115],
		Adjacentes: [114, 115, 116],
	},
	{
		Id: 6508,
		CodigoHtml: "6D4643",
		Nombre: "Earthly Pleasure",
		ColorNum: "0102",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 115,
		colorR: 109,
		colorG: 70,
		colorB: 67,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [115, 165],
		Triadicos: [115],
		Adjacentes: [114, 115, 116],
	},
	{
		Id: 6509,
		CodigoHtml: "DBBAC1",
		Nombre: "Hosanna",
		ColorNum: "0103",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 116,
		colorR: 219,
		colorG: 186,
		colorB: 193,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [116, 166],
		Triadicos: [116],
		Adjacentes: [115, 116, 117],
	},
	{
		Id: 6510,
		CodigoHtml: "D4B0B7",
		Nombre: "Victorian Cottage",
		ColorNum: "0104",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 116,
		colorR: 212,
		colorG: 176,
		colorB: 183,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [116, 166],
		Triadicos: [116],
		Adjacentes: [115, 116, 117],
	},
	{
		Id: 6511,
		CodigoHtml: "C49EA8",
		Nombre: "Harvest Blessing",
		ColorNum: "0105",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 116,
		colorR: 196,
		colorG: 158,
		colorB: 168,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [116, 166],
		Triadicos: [116],
		Adjacentes: [115, 116, 117],
	},
	{
		Id: 6512,
		CodigoHtml: "AE828D",
		Nombre: "Rare Find",
		ColorNum: "0106",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 116,
		colorR: 174,
		colorG: 130,
		colorB: 141,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [116, 166],
		Triadicos: [116],
		Adjacentes: [115, 116, 117],
	},
	{
		Id: 6513,
		CodigoHtml: "93656B",
		Nombre: "Prosperity",
		ColorNum: "0107",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 116,
		colorR: 147,
		colorG: 101,
		colorB: 107,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [116, 166],
		Triadicos: [116],
		Adjacentes: [115, 116, 117],
	},
	{
		Id: 6514,
		CodigoHtml: "7F4E59",
		Nombre: "Exotica",
		ColorNum: "0108",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 116,
		colorR: 127,
		colorG: 78,
		colorB: 89,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [116, 166],
		Triadicos: [116],
		Adjacentes: [115, 116, 117],
	},
	{
		Id: 6515,
		CodigoHtml: "67454A",
		Nombre: "Kung Fu",
		ColorNum: "0109",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 116,
		colorR: 103,
		colorG: 69,
		colorB: 74,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [116, 166],
		Triadicos: [116],
		Adjacentes: [115, 116, 117],
	},
	{
		Id: 6516,
		CodigoHtml: "EBC9CE",
		Nombre: "Baby Bunting",
		ColorNum: "0110",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 117,
		colorR: 235,
		colorG: 201,
		colorB: 206,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [117, 167],
		Triadicos: [117],
		Adjacentes: [116, 117, 118],
	},
	{
		Id: 6517,
		CodigoHtml: "E3B9C0",
		Nombre: "Acadia Bloom",
		ColorNum: "0111",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 117,
		colorR: 227,
		colorG: 185,
		colorB: 192,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [117, 167],
		Triadicos: [117],
		Adjacentes: [116, 117, 118],
	},
	{
		Id: 6518,
		CodigoHtml: "CFA6B0",
		Nombre: "Lady Flower",
		ColorNum: "0112",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 117,
		colorR: 207,
		colorG: 166,
		colorB: 176,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [117, 167],
		Triadicos: [117],
		Adjacentes: [116, 117, 118],
	},
	{
		Id: 6519,
		CodigoHtml: "B78996",
		Nombre: "Punch of Pink",
		ColorNum: "0113",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 117,
		colorR: 183,
		colorG: 137,
		colorB: 150,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [117, 167],
		Triadicos: [117],
		Adjacentes: [116, 117, 118],
	},
	{
		Id: 6520,
		CodigoHtml: "9F6F78",
		Nombre: "Montrose Rose",
		ColorNum: "0114",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 117,
		colorR: 159,
		colorG: 111,
		colorB: 120,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [117, 167],
		Triadicos: [117],
		Adjacentes: [116, 117, 118],
	},
	{
		Id: 6521,
		CodigoHtml: "784552",
		Nombre: "Sophistication",
		ColorNum: "0115",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 117,
		colorR: 120,
		colorG: 69,
		colorB: 82,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [117, 167],
		Triadicos: [117],
		Adjacentes: [116, 117, 118],
	},
	{
		Id: 6522,
		CodigoHtml: "7F444F",
		Nombre: "Cordova Burgundy",
		ColorNum: "0116",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 117,
		colorR: 127,
		colorG: 68,
		colorB: 79,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [117, 167],
		Triadicos: [117],
		Adjacentes: [116, 117, 118],
	},
	{
		Id: 6523,
		CodigoHtml: "F0D7D2",
		Nombre: "Satin Slipper",
		ColorNum: "0117",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 118,
		colorR: 240,
		colorG: 215,
		colorB: 210,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [118, 168],
		Triadicos: [118],
		Adjacentes: [117, 118, 119],
	},
	{
		Id: 6524,
		CodigoHtml: "E3C0BC",
		Nombre: "Miniature Posey",
		ColorNum: "0118",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 118,
		colorR: 227,
		colorG: 192,
		colorB: 188,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [118, 168],
		Triadicos: [118],
		Adjacentes: [117, 118, 119],
	},
	{
		Id: 6525,
		CodigoHtml: "C99C99",
		Nombre: "Angela Canyon",
		ColorNum: "0119",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 118,
		colorR: 201,
		colorG: 156,
		colorB: 153,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [118, 168],
		Triadicos: [118],
		Adjacentes: [117, 118, 119],
	},
	{
		Id: 6526,
		CodigoHtml: "AA756F",
		Nombre: "Summer's Eve",
		ColorNum: "0120",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 118,
		colorR: 170,
		colorG: 117,
		colorB: 111,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [118, 168],
		Triadicos: [118],
		Adjacentes: [117, 118, 119],
	},
	{
		Id: 6527,
		CodigoHtml: "8B564F",
		Nombre: "Savanna",
		ColorNum: "0121",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 118,
		colorR: 139,
		colorG: 86,
		colorB: 79,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [118, 168],
		Triadicos: [118],
		Adjacentes: [117, 118, 119],
	},
	{
		Id: 6528,
		CodigoHtml: "865045",
		Nombre: "Outrageous",
		ColorNum: "0122",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 118,
		colorR: 134,
		colorG: 80,
		colorB: 69,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [118, 168],
		Triadicos: [118],
		Adjacentes: [117, 118, 119],
	},
	{
		Id: 6529,
		CodigoHtml: "814D42",
		Nombre: "Monterey Chestnut",
		ColorNum: "0123",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 118,
		colorR: 129,
		colorG: 77,
		colorB: 66,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [118, 168],
		Triadicos: [118],
		Adjacentes: [117, 118, 119],
	},
	{
		Id: 6530,
		CodigoHtml: "EDE1D8",
		Nombre: "Merlins Beard",
		ColorNum: "0124",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 119,
		colorR: 237,
		colorG: 225,
		colorB: 216,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [119, 169],
		Triadicos: [119],
		Adjacentes: [118, 119, 120],
	},
	{
		Id: 6531,
		CodigoHtml: "E1CBC3",
		Nombre: "Pout",
		ColorNum: "0125",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 119,
		colorR: 225,
		colorG: 203,
		colorB: 195,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [119, 169],
		Triadicos: [119],
		Adjacentes: [118, 119, 120],
	},
	{
		Id: 6532,
		CodigoHtml: "D6BCB4",
		Nombre: "Sauterne",
		ColorNum: "0126",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 119,
		colorR: 214,
		colorG: 188,
		colorB: 180,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [119, 169],
		Triadicos: [119],
		Adjacentes: [118, 119, 120],
	},
	{
		Id: 6533,
		CodigoHtml: "C1A199",
		Nombre: "Andes Ash",
		ColorNum: "0127",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 119,
		colorR: 193,
		colorG: 161,
		colorB: 153,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [119, 169],
		Triadicos: [119],
		Adjacentes: [118, 119, 120],
	},
	{
		Id: 6534,
		CodigoHtml: "AB8A82",
		Nombre: "Rocky Mountain",
		ColorNum: "0128",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 119,
		colorR: 171,
		colorG: 138,
		colorB: 130,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [119, 169],
		Triadicos: [119],
		Adjacentes: [118, 119, 120],
	},
	{
		Id: 6535,
		CodigoHtml: "926E64",
		Nombre: "Arrowhead",
		ColorNum: "0129",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 119,
		colorR: 146,
		colorG: 110,
		colorB: 100,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [119, 169],
		Triadicos: [119],
		Adjacentes: [118, 119, 120],
	},
	{
		Id: 6536,
		CodigoHtml: "76554A",
		Nombre: "Evolution",
		ColorNum: "0130",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 119,
		colorR: 118,
		colorG: 85,
		colorB: 74,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [119, 169],
		Triadicos: [119],
		Adjacentes: [118, 119, 120],
	},
	{
		Id: 6537,
		CodigoHtml: "EADDD4",
		Nombre: "Cliffswallow",
		ColorNum: "0131",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 120,
		colorR: 234,
		colorG: 221,
		colorB: 212,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [120, 170],
		Triadicos: [120],
		Adjacentes: [119, 120, 121],
	},
	{
		Id: 6538,
		CodigoHtml: "DAC4BA",
		Nombre: "Summer Beige",
		ColorNum: "0132",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 120,
		colorR: 218,
		colorG: 196,
		colorB: 186,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [120, 170],
		Triadicos: [120],
		Adjacentes: [119, 120, 121],
	},
	{
		Id: 6539,
		CodigoHtml: "BAA198",
		Nombre: "Natchez",
		ColorNum: "0133",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 120,
		colorR: 186,
		colorG: 161,
		colorB: 152,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [120, 170],
		Triadicos: [120],
		Adjacentes: [119, 120, 121],
	},
	{
		Id: 6540,
		CodigoHtml: "A88F85",
		Nombre: "Baby Sprout",
		ColorNum: "0134",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 120,
		colorR: 168,
		colorG: 143,
		colorB: 133,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [120, 170],
		Triadicos: [120],
		Adjacentes: [119, 120, 121],
	},
	{
		Id: 6541,
		CodigoHtml: "9A7E73",
		Nombre: "Emerging Leaf",
		ColorNum: "0135",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 120,
		colorR: 154,
		colorG: 126,
		colorB: 115,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [120, 170],
		Triadicos: [120],
		Adjacentes: [119, 120, 121],
	},
	{
		Id: 6542,
		CodigoHtml: "83675B",
		Nombre: "Amazon Mist",
		ColorNum: "0136",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 120,
		colorR: 131,
		colorG: 103,
		colorB: 91,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [120, 170],
		Triadicos: [120],
		Adjacentes: [119, 120, 121],
	},
	{
		Id: 6543,
		CodigoHtml: "6B5145",
		Nombre: "Hideaway",
		ColorNum: "0137",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 120,
		colorR: 107,
		colorG: 81,
		colorB: 69,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [120, 170],
		Triadicos: [120],
		Adjacentes: [119, 120, 121],
	},
	{
		Id: 6544,
		CodigoHtml: "D9C9C0",
		Nombre: "Coconut Macaroon",
		ColorNum: "0138",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 121,
		colorR: 217,
		colorG: 201,
		colorB: 192,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [121, 171],
		Triadicos: [121],
		Adjacentes: [120, 121, 122],
	},
	{
		Id: 6545,
		CodigoHtml: "CDBCB4",
		Nombre: "Soufflé",
		ColorNum: "0139",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 121,
		colorR: 205,
		colorG: 188,
		colorB: 180,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [121, 171],
		Triadicos: [121],
		Adjacentes: [120, 121, 122],
	},
	{
		Id: 6546,
		CodigoHtml: "B8A39A",
		Nombre: "Cozy Cover",
		ColorNum: "0140",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 121,
		colorR: 184,
		colorG: 163,
		colorB: 154,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [121, 171],
		Triadicos: [121],
		Adjacentes: [120, 121, 122],
	},
	{
		Id: 6547,
		CodigoHtml: "A69086",
		Nombre: "Bush Buck",
		ColorNum: "0141",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 121,
		colorR: 166,
		colorG: 144,
		colorB: 134,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [121, 171],
		Triadicos: [121],
		Adjacentes: [120, 121, 122],
	},
	{
		Id: 6548,
		CodigoHtml: "7F6A60",
		Nombre: "Ranch House",
		ColorNum: "0142",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 121,
		colorR: 127,
		colorG: 106,
		colorB: 96,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [121, 171],
		Triadicos: [121],
		Adjacentes: [120, 121, 122],
	},
	{
		Id: 6549,
		CodigoHtml: "6B584F",
		Nombre: "Connoisseur",
		ColorNum: "0143",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 121,
		colorR: 107,
		colorG: 88,
		colorB: 79,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [121, 171],
		Triadicos: [121],
		Adjacentes: [120, 121, 122],
	},
	{
		Id: 6550,
		CodigoHtml: "524641",
		Nombre: "Film Noir",
		ColorNum: "0144",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 121,
		colorR: 82,
		colorG: 70,
		colorB: 65,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [121, 171],
		Triadicos: [121],
		Adjacentes: [120, 121, 122],
	},
	{
		Id: 6551,
		CodigoHtml: "EEDFD5",
		Nombre: "Flax Flower",
		ColorNum: "0145",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 122,
		colorR: 238,
		colorG: 223,
		colorB: 213,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [122, 172],
		Triadicos: [122],
		Adjacentes: [121, 122, 123],
	},
	{
		Id: 6552,
		CodigoHtml: "E7D6CC",
		Nombre: "Truffles",
		ColorNum: "0146",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 122,
		colorR: 231,
		colorG: 214,
		colorB: 204,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [122, 172],
		Triadicos: [122],
		Adjacentes: [121, 122, 123],
	},
	{
		Id: 6553,
		CodigoHtml: "D2BBAE",
		Nombre: "Cave Painting",
		ColorNum: "0147",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 122,
		colorR: 210,
		colorG: 187,
		colorB: 174,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [122, 172],
		Triadicos: [122],
		Adjacentes: [121, 122, 123],
	},
	{
		Id: 6554,
		CodigoHtml: "C8AB9B",
		Nombre: "Oak Plank",
		ColorNum: "0148",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 122,
		colorR: 200,
		colorG: 171,
		colorB: 155,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [122, 172],
		Triadicos: [122],
		Adjacentes: [121, 122, 123],
	},
	{
		Id: 6555,
		CodigoHtml: "B39383",
		Nombre: "Pavilion Tan",
		ColorNum: "0149",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 122,
		colorR: 179,
		colorG: 147,
		colorB: 131,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [122, 172],
		Triadicos: [122],
		Adjacentes: [121, 122, 123],
	},
	{
		Id: 6556,
		CodigoHtml: "977464",
		Nombre: "Temptation",
		ColorNum: "0150",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 122,
		colorR: 151,
		colorG: 116,
		colorB: 100,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [122, 172],
		Triadicos: [122],
		Adjacentes: [121, 122, 123],
	},
	{
		Id: 6557,
		CodigoHtml: "77584B",
		Nombre: "Coffee Shop",
		ColorNum: "0151",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 122,
		colorR: 119,
		colorG: 88,
		colorB: 75,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [122, 172],
		Triadicos: [122],
		Adjacentes: [121, 122, 123],
	},
	{
		Id: 6558,
		CodigoHtml: "ECD9C6",
		Nombre: "Flirt",
		ColorNum: "0152",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 123,
		colorR: 236,
		colorG: 217,
		colorB: 198,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [123, 173],
		Triadicos: [123],
		Adjacentes: [122, 123, 124],
	},
	{
		Id: 6559,
		CodigoHtml: "E3CDB8",
		Nombre: "Take-Out",
		ColorNum: "0153",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 123,
		colorR: 227,
		colorG: 205,
		colorB: 184,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [123, 173],
		Triadicos: [123],
		Adjacentes: [122, 123, 124],
	},
	{
		Id: 6560,
		CodigoHtml: "DCC2A9",
		Nombre: "Romulus ",
		ColorNum: "0154",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 123,
		colorR: 220,
		colorG: 194,
		colorB: 169,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [123, 173],
		Triadicos: [123],
		Adjacentes: [122, 123, 124],
	},
	{
		Id: 6561,
		CodigoHtml: "CCA88C",
		Nombre: "Cowhide",
		ColorNum: "0155",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 123,
		colorR: 204,
		colorG: 168,
		colorB: 140,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [123, 173],
		Triadicos: [123],
		Adjacentes: [122, 123, 124],
	},
	{
		Id: 6562,
		CodigoHtml: "B58E73",
		Nombre: "Skinny Dip",
		ColorNum: "0156",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 123,
		colorR: 181,
		colorG: 142,
		colorB: 115,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [123, 173],
		Triadicos: [123],
		Adjacentes: [122, 123, 124],
	},
	{
		Id: 6563,
		CodigoHtml: "A4785B",
		Nombre: "Dodge Pole",
		ColorNum: "0157",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 123,
		colorR: 164,
		colorG: 120,
		colorB: 91,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [123, 173],
		Triadicos: [123],
		Adjacentes: [122, 123, 124],
	},
	{
		Id: 6564,
		CodigoHtml: "89644C",
		Nombre: "Mover and Shaker",
		ColorNum: "0158",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 123,
		colorR: 137,
		colorG: 100,
		colorB: 76,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [123, 173],
		Triadicos: [123],
		Adjacentes: [122, 123, 124],
	},
	{
		Id: 6565,
		CodigoHtml: "EFE0D0",
		Nombre: "Senior Moment",
		ColorNum: "0159",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 124,
		colorR: 239,
		colorG: 224,
		colorB: 208,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [124, 174],
		Triadicos: [124],
		Adjacentes: [123, 124, 125],
	},
	{
		Id: 6566,
		CodigoHtml: "E8D0B9",
		Nombre: "Kingdom's Keys",
		ColorNum: "0160",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 124,
		colorR: 232,
		colorG: 208,
		colorB: 185,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [124, 174],
		Triadicos: [124],
		Adjacentes: [123, 124, 125],
	},
	{
		Id: 6567,
		CodigoHtml: "DEC0A8",
		Nombre: "Tiny Calf",
		ColorNum: "0161",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 124,
		colorR: 222,
		colorG: 192,
		colorB: 168,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [124, 174],
		Triadicos: [124],
		Adjacentes: [123, 124, 125],
	},
	{
		Id: 6568,
		CodigoHtml: "CBAE97",
		Nombre: "Apple Pie",
		ColorNum: "0162",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 124,
		colorR: 203,
		colorG: 174,
		colorB: 151,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [124, 174],
		Triadicos: [124],
		Adjacentes: [123, 124, 125],
	},
	{
		Id: 6569,
		CodigoHtml: "B0927A",
		Nombre: "Camel Train",
		ColorNum: "0163",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 124,
		colorR: 176,
		colorG: 146,
		colorB: 122,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [124, 174],
		Triadicos: [124],
		Adjacentes: [123, 124, 125],
	},
	{
		Id: 6570,
		CodigoHtml: "A48268",
		Nombre: "Veldrift",
		ColorNum: "0164",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 124,
		colorR: 164,
		colorG: 130,
		colorB: 104,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [124, 174],
		Triadicos: [124],
		Adjacentes: [123, 124, 125],
	},
	{
		Id: 6571,
		CodigoHtml: "98765B",
		Nombre: "Cavern Sand",
		ColorNum: "0165",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 124,
		colorR: 152,
		colorG: 118,
		colorB: 91,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [124, 174],
		Triadicos: [124],
		Adjacentes: [123, 124, 125],
	},
	{
		Id: 6572,
		CodigoHtml: "F5E5D1",
		Nombre: "Pastel Day",
		ColorNum: "0166",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 125,
		colorR: 245,
		colorG: 229,
		colorB: 209,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [125, 175],
		Triadicos: [125],
		Adjacentes: [124, 125, 126],
	},
	{
		Id: 6573,
		CodigoHtml: "E5D0B8",
		Nombre: "First Date",
		ColorNum: "0167",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 125,
		colorR: 229,
		colorG: 208,
		colorB: 184,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [125, 175],
		Triadicos: [125],
		Adjacentes: [124, 125, 126],
	},
	{
		Id: 6574,
		CodigoHtml: "DBC1A7",
		Nombre: "Candle Light",
		ColorNum: "0168",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 125,
		colorR: 219,
		colorG: 193,
		colorB: 167,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [125, 175],
		Triadicos: [125],
		Adjacentes: [124, 125, 126],
	},
	{
		Id: 6575,
		CodigoHtml: "D0B092",
		Nombre: "Fossil Tan",
		ColorNum: "0169",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 125,
		colorR: 208,
		colorG: 176,
		colorB: 146,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [125, 175],
		Triadicos: [125],
		Adjacentes: [124, 125, 126],
	},
	{
		Id: 6576,
		CodigoHtml: "BD9876",
		Nombre: "Blonde Shell",
		ColorNum: "0170",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 125,
		colorR: 189,
		colorG: 152,
		colorB: 118,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [125, 175],
		Triadicos: [125],
		Adjacentes: [124, 125, 126],
	},
	{
		Id: 6577,
		CodigoHtml: "A17A5A",
		Nombre: "Golden Glove",
		ColorNum: "0171",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 125,
		colorR: 161,
		colorG: 122,
		colorB: 90,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [125, 175],
		Triadicos: [125],
		Adjacentes: [124, 125, 126],
	},
	{
		Id: 6578,
		CodigoHtml: "8A674C",
		Nombre: "Chasm",
		ColorNum: "0172",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 125,
		colorR: 138,
		colorG: 103,
		colorB: 76,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [125, 175],
		Triadicos: [125],
		Adjacentes: [124, 125, 126],
	},
	{
		Id: 6579,
		CodigoHtml: "E9DDCC",
		Nombre: "Pale Gingersnap",
		ColorNum: "0173",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 126,
		colorR: 233,
		colorG: 221,
		colorB: 204,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [126, 176],
		Triadicos: [126],
		Adjacentes: [125, 126, 127],
	},
	{
		Id: 6580,
		CodigoHtml: "E1D1BC",
		Nombre: "Maple Pecan",
		ColorNum: "0174",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 126,
		colorR: 225,
		colorG: 209,
		colorB: 188,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [126, 176],
		Triadicos: [126],
		Adjacentes: [125, 126, 127],
	},
	{
		Id: 6581,
		CodigoHtml: "C1AC92",
		Nombre: "3am Latte",
		ColorNum: "0175",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 126,
		colorR: 193,
		colorG: 172,
		colorB: 146,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [126, 176],
		Triadicos: [126],
		Adjacentes: [125, 126, 127],
	},
	{
		Id: 6582,
		CodigoHtml: "B19A80",
		Nombre: "Country Dweller",
		ColorNum: "0176",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 126,
		colorR: 177,
		colorG: 154,
		colorB: 128,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [126, 176],
		Triadicos: [126],
		Adjacentes: [125, 126, 127],
	},
	{
		Id: 6583,
		CodigoHtml: "9F8468",
		Nombre: "Weaver's Tool",
		ColorNum: "0177",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 126,
		colorR: 159,
		colorG: 132,
		colorB: 104,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [126, 176],
		Triadicos: [126],
		Adjacentes: [125, 126, 127],
	},
	{
		Id: 6584,
		CodigoHtml: "8E755B",
		Nombre: "Cupcake",
		ColorNum: "0178",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 126,
		colorR: 142,
		colorG: 117,
		colorB: 91,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [126, 176],
		Triadicos: [126],
		Adjacentes: [125, 126, 127],
	},
	{
		Id: 6585,
		CodigoHtml: "83684E",
		Nombre: "Fall in Season",
		ColorNum: "0179",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 126,
		colorR: 131,
		colorG: 104,
		colorB: 78,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [126, 176],
		Triadicos: [126],
		Adjacentes: [125, 126, 127],
	},
	{
		Id: 6586,
		CodigoHtml: "EBE0D3",
		Nombre: "Fresh Linen",
		ColorNum: "0180",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 127,
		colorR: 235,
		colorG: 224,
		colorB: 211,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [127, 177],
		Triadicos: [127],
		Adjacentes: [126, 127, 128],
	},
	{
		Id: 6587,
		CodigoHtml: "DFD2C2",
		Nombre: "Ageless",
		ColorNum: "0181",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 127,
		colorR: 223,
		colorG: 210,
		colorB: 194,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [127, 177],
		Triadicos: [127],
		Adjacentes: [126, 127, 128],
	},
	{
		Id: 6588,
		CodigoHtml: "D8CAB8",
		Nombre: "Ivory Ridge",
		ColorNum: "0182",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 127,
		colorR: 216,
		colorG: 202,
		colorB: 184,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [127, 177],
		Triadicos: [127],
		Adjacentes: [126, 127, 128],
	},
	{
		Id: 6589,
		CodigoHtml: "CABCA9",
		Nombre: "Moth Wing",
		ColorNum: "0183",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 127,
		colorR: 202,
		colorG: 188,
		colorB: 169,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [127, 177],
		Triadicos: [127],
		Adjacentes: [126, 127, 128],
	},
	{
		Id: 6590,
		CodigoHtml: "BAA893",
		Nombre: "Macadamia Brown",
		ColorNum: "0184",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 127,
		colorR: 186,
		colorG: 168,
		colorB: 147,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [127, 177],
		Triadicos: [127],
		Adjacentes: [126, 127, 128],
	},
	{
		Id: 6591,
		CodigoHtml: "8E7760",
		Nombre: "Noble Crown",
		ColorNum: "0185",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 127,
		colorR: 142,
		colorG: 119,
		colorB: 96,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [127, 177],
		Triadicos: [127],
		Adjacentes: [126, 127, 128],
	},
	{
		Id: 6592,
		CodigoHtml: "715F4E",
		Nombre: "Christmas Ornament",
		ColorNum: "0186",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 127,
		colorR: 113,
		colorG: 95,
		colorB: 78,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [127, 177],
		Triadicos: [127],
		Adjacentes: [126, 127, 128],
	},
	{
		Id: 6593,
		CodigoHtml: "EDE2D4",
		Nombre: "White Lightning ",
		ColorNum: "0187",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 128,
		colorR: 237,
		colorG: 226,
		colorB: 212,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [128, 178],
		Triadicos: [128],
		Adjacentes: [127, 128, 129],
	},
	{
		Id: 6594,
		CodigoHtml: "CCBDAD",
		Nombre: "Baguette",
		ColorNum: "0188",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 128,
		colorR: 204,
		colorG: 189,
		colorB: 173,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [128, 178],
		Triadicos: [128],
		Adjacentes: [127, 128, 129],
	},
	{
		Id: 6595,
		CodigoHtml: "BDAD9A",
		Nombre: "Delicate Honeysweet",
		ColorNum: "0189",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 128,
		colorR: 189,
		colorG: 173,
		colorB: 154,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [128, 178],
		Triadicos: [128],
		Adjacentes: [127, 128, 129],
	},
	{
		Id: 6596,
		CodigoHtml: "AE9B88",
		Nombre: "Village Crier",
		ColorNum: "0190",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 128,
		colorR: 174,
		colorG: 155,
		colorB: 136,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [128, 178],
		Triadicos: [128],
		Adjacentes: [127, 128, 129],
	},
	{
		Id: 6597,
		CodigoHtml: "907B68",
		Nombre: "Dusty Path",
		ColorNum: "0191",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 128,
		colorR: 144,
		colorG: 123,
		colorB: 104,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [128, 178],
		Triadicos: [128],
		Adjacentes: [127, 128, 129],
	},
	{
		Id: 6598,
		CodigoHtml: "776353",
		Nombre: "Dapper",
		ColorNum: "0192",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 128,
		colorR: 119,
		colorG: 99,
		colorB: 83,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [128, 178],
		Triadicos: [128],
		Adjacentes: [127, 128, 129],
	},
	{
		Id: 6599,
		CodigoHtml: "776350",
		Nombre: "Alpha Male",
		ColorNum: "0193",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 128,
		colorR: 119,
		colorG: 99,
		colorB: 80,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [128, 178],
		Triadicos: [128],
		Adjacentes: [127, 128, 129],
	},
	{
		Id: 6600,
		CodigoHtml: "E5E0D5",
		Nombre: "August Moon",
		ColorNum: "0194",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 129,
		colorR: 229,
		colorG: 224,
		colorB: 213,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [129, 179],
		Triadicos: [129],
		Adjacentes: [128, 129, 130],
	},
	{
		Id: 6601,
		CodigoHtml: "DAD3C8",
		Nombre: "Mossy Shade",
		ColorNum: "0195",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 129,
		colorR: 218,
		colorG: 211,
		colorB: 200,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [129, 179],
		Triadicos: [129],
		Adjacentes: [128, 129, 130],
	},
	{
		Id: 6602,
		CodigoHtml: "CFC7B9",
		Nombre: "Overgrown",
		ColorNum: "0196",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 129,
		colorR: 207,
		colorG: 199,
		colorB: 185,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [129, 179],
		Triadicos: [129],
		Adjacentes: [128, 129, 130],
	},
	{
		Id: 6603,
		CodigoHtml: "C1B8AA",
		Nombre: "Thistle Gray",
		ColorNum: "0197",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 129,
		colorR: 193,
		colorG: 184,
		colorB: 170,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [129, 179],
		Triadicos: [129],
		Adjacentes: [128, 129, 130],
	},
	{
		Id: 6604,
		CodigoHtml: "AA9F8F",
		Nombre: "Young Colt",
		ColorNum: "0198",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 129,
		colorR: 170,
		colorG: 159,
		colorB: 143,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [129, 179],
		Triadicos: [129],
		Adjacentes: [128, 129, 130],
	},
	{
		Id: 6605,
		CodigoHtml: "877A69",
		Nombre: "Sandy Shoes",
		ColorNum: "0199",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 129,
		colorR: 135,
		colorG: 122,
		colorB: 105,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [129, 179],
		Triadicos: [129],
		Adjacentes: [128, 129, 130],
	},
	{
		Id: 6606,
		CodigoHtml: "665C4F",
		Nombre: "Stony Field",
		ColorNum: "0200",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 129,
		colorR: 102,
		colorG: 92,
		colorB: 79,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [129, 179],
		Triadicos: [129],
		Adjacentes: [128, 129, 130],
	},
	{
		Id: 6607,
		CodigoHtml: "EBE8DC",
		Nombre: "Sparkling Champagne",
		ColorNum: "0201",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 130,
		colorR: 235,
		colorG: 232,
		colorB: 220,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [130, 180],
		Triadicos: [130],
		Adjacentes: [129, 130, 131],
	},
	{
		Id: 6608,
		CodigoHtml: "DED6C9",
		Nombre: "Powder Cake",
		ColorNum: "0202",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 130,
		colorR: 222,
		colorG: 214,
		colorB: 201,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [130, 180],
		Triadicos: [130],
		Adjacentes: [129, 130, 131],
	},
	{
		Id: 6609,
		CodigoHtml: "D0C9BB",
		Nombre: "Whale Bone",
		ColorNum: "0203",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 130,
		colorR: 208,
		colorG: 201,
		colorB: 187,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [130, 180],
		Triadicos: [130],
		Adjacentes: [129, 130, 131],
	},
	{
		Id: 6610,
		CodigoHtml: "B9B0A3",
		Nombre: "Marshy Habitat",
		ColorNum: "0204",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 130,
		colorR: 185,
		colorG: 176,
		colorB: 163,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [130, 180],
		Triadicos: [130],
		Adjacentes: [129, 130, 131],
	},
	{
		Id: 6611,
		CodigoHtml: "A9A092",
		Nombre: "Wood Shadow",
		ColorNum: "0205",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 130,
		colorR: 169,
		colorG: 160,
		colorB: 146,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [130, 180],
		Triadicos: [130],
		Adjacentes: [129, 130, 131],
	},
	{
		Id: 6612,
		CodigoHtml: "867C6C",
		Nombre: "Thatched Cottage",
		ColorNum: "0206",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 130,
		colorR: 134,
		colorG: 124,
		colorB: 108,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [130, 180],
		Triadicos: [130],
		Adjacentes: [129, 130, 131],
	},
	{
		Id: 6613,
		CodigoHtml: "675F53",
		Nombre: "Ares Shadow",
		ColorNum: "0207",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 130,
		colorR: 103,
		colorG: 95,
		colorB: 83,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [130, 180],
		Triadicos: [130],
		Adjacentes: [129, 130, 131],
	},
	{
		Id: 6614,
		CodigoHtml: "EBE7DD",
		Nombre: "Umber Style",
		ColorNum: "0208",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 131,
		colorR: 235,
		colorG: 231,
		colorB: 221,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [131, 181],
		Triadicos: [131],
		Adjacentes: [130, 131, 132],
	},
	{
		Id: 6615,
		CodigoHtml: "DDD6CD",
		Nombre: "Polished",
		ColorNum: "0209",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 131,
		colorR: 221,
		colorG: 214,
		colorB: 205,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [131, 181],
		Triadicos: [131],
		Adjacentes: [130, 131, 132],
	},
	{
		Id: 6616,
		CodigoHtml: "CEC8BE",
		Nombre: "Hidden Cove",
		ColorNum: "0210",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 131,
		colorR: 206,
		colorG: 200,
		colorB: 190,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [131, 181],
		Triadicos: [131],
		Adjacentes: [130, 131, 132],
	},
	{
		Id: 6617,
		CodigoHtml: "C0B8AB",
		Nombre: "Light Lichen",
		ColorNum: "0211",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 131,
		colorR: 192,
		colorG: 184,
		colorB: 171,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [131, 181],
		Triadicos: [131],
		Adjacentes: [130, 131, 132],
	},
	{
		Id: 6618,
		CodigoHtml: "AA9F91",
		Nombre: "Big Spender",
		ColorNum: "0212",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 131,
		colorR: 170,
		colorG: 159,
		colorB: 145,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [131, 181],
		Triadicos: [131],
		Adjacentes: [130, 131, 132],
	},
	{
		Id: 6619,
		CodigoHtml: "83796C",
		Nombre: "Coastal Fog",
		ColorNum: "0213",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 131,
		colorR: 131,
		colorG: 121,
		colorB: 108,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [131, 181],
		Triadicos: [131],
		Adjacentes: [130, 131, 132],
	},
	{
		Id: 6620,
		CodigoHtml: "685E55",
		Nombre: "Wildwood",
		ColorNum: "0214",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 131,
		colorR: 104,
		colorG: 94,
		colorB: 85,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [131, 181],
		Triadicos: [131],
		Adjacentes: [130, 131, 132],
	},
	{
		Id: 6621,
		CodigoHtml: "E6DFD3",
		Nombre: "Herare White",
		ColorNum: "0215",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 132,
		colorR: 230,
		colorG: 223,
		colorB: 211,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [132, 182],
		Triadicos: [132],
		Adjacentes: [131, 132, 133],
	},
	{
		Id: 6622,
		CodigoHtml: "DDD5C7",
		Nombre: "Earthling",
		ColorNum: "0216",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 132,
		colorR: 221,
		colorG: 213,
		colorB: 199,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [132, 182],
		Triadicos: [132],
		Adjacentes: [131, 132, 133],
	},
	{
		Id: 6623,
		CodigoHtml: "D0C7B7",
		Nombre: "Oak Tone",
		ColorNum: "0217",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 132,
		colorR: 208,
		colorG: 199,
		colorB: 183,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [132, 182],
		Triadicos: [132],
		Adjacentes: [131, 132, 133],
	},
	{
		Id: 6624,
		CodigoHtml: "AAA195",
		Nombre: "Drifting Sand",
		ColorNum: "0218",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 132,
		colorR: 170,
		colorG: 161,
		colorB: 149,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [132, 182],
		Triadicos: [132],
		Adjacentes: [131, 132, 133],
	},
	{
		Id: 6625,
		CodigoHtml: "857B6D",
		Nombre: "Camel's Hump",
		ColorNum: "0219",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 132,
		colorR: 133,
		colorG: 123,
		colorB: 109,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [132, 182],
		Triadicos: [132],
		Adjacentes: [131, 132, 133],
	},
	{
		Id: 6626,
		CodigoHtml: "746A5C",
		Nombre: "Monk's Cloth ",
		ColorNum: "0220",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 132,
		colorR: 116,
		colorG: 106,
		colorB: 92,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [132, 182],
		Triadicos: [132],
		Adjacentes: [131, 132, 133],
	},
	{
		Id: 6627,
		CodigoHtml: "62584C",
		Nombre: "Brown Suede",
		ColorNum: "0221",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 132,
		colorR: 98,
		colorG: 88,
		colorB: 76,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [132, 182],
		Triadicos: [132],
		Adjacentes: [131, 132, 133],
	},
	{
		Id: 6628,
		CodigoHtml: "E4E0CE",
		Nombre: "Au Natural",
		ColorNum: "0222",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 133,
		colorR: 228,
		colorG: 224,
		colorB: 206,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [133, 183],
		Triadicos: [133],
		Adjacentes: [132, 133, 134],
	},
	{
		Id: 6629,
		CodigoHtml: "E2DBC9",
		Nombre: "White Elephant",
		ColorNum: "0223",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 133,
		colorR: 226,
		colorG: 219,
		colorB: 201,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [133, 183],
		Triadicos: [133],
		Adjacentes: [132, 133, 134],
	},
	{
		Id: 6630,
		CodigoHtml: "D8D0BC",
		Nombre: "Pebblebrook",
		ColorNum: "0224",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 133,
		colorR: 216,
		colorG: 208,
		colorB: 188,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [133, 183],
		Triadicos: [133],
		Adjacentes: [132, 133, 134],
	},
	{
		Id: 6631,
		CodigoHtml: "C0B6A0",
		Nombre: "Roman Ruins",
		ColorNum: "0225",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 133,
		colorR: 192,
		colorG: 182,
		colorB: 160,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [133, 183],
		Triadicos: [133],
		Adjacentes: [132, 133, 134],
	},
	{
		Id: 6632,
		CodigoHtml: "B1A793",
		Nombre: "Sedge",
		ColorNum: "0226",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 133,
		colorR: 177,
		colorG: 167,
		colorB: 147,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [133, 183],
		Triadicos: [133],
		Adjacentes: [132, 133, 134],
	},
	{
		Id: 6633,
		CodigoHtml: "8E826E",
		Nombre: "Moonscape",
		ColorNum: "0227",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 133,
		colorR: 142,
		colorG: 130,
		colorB: 110,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [133, 183],
		Triadicos: [133],
		Adjacentes: [132, 133, 134],
	},
	{
		Id: 6634,
		CodigoHtml: "6E6352",
		Nombre: "Hannover Hills",
		ColorNum: "0228",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 133,
		colorR: 110,
		colorG: 99,
		colorB: 82,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [133, 183],
		Triadicos: [133],
		Adjacentes: [132, 133, 134],
	},
	{
		Id: 6635,
		CodigoHtml: "E3DED3",
		Nombre: "Feather Stone",
		ColorNum: "0229",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 134,
		colorR: 227,
		colorG: 222,
		colorB: 211,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [134, 184],
		Triadicos: [134],
		Adjacentes: [133, 134, 132],
	},
	{
		Id: 6636,
		CodigoHtml: "DAD4C7",
		Nombre: "Harrow Gate",
		ColorNum: "0230",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 134,
		colorR: 218,
		colorG: 212,
		colorB: 199,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [134, 184],
		Triadicos: [134],
		Adjacentes: [133, 134, 132],
	},
	{
		Id: 6637,
		CodigoHtml: "D1CABC",
		Nombre: "Desert Mirage",
		ColorNum: "0231",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 134,
		colorR: 209,
		colorG: 202,
		colorB: 188,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [134, 184],
		Triadicos: [134],
		Adjacentes: [133, 134, 132],
	},
	{
		Id: 6638,
		CodigoHtml: "C2BBAC",
		Nombre: "Frond",
		ColorNum: "0232",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 134,
		colorR: 194,
		colorG: 187,
		colorB: 172,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [134, 184],
		Triadicos: [134],
		Adjacentes: [133, 134, 132],
	},
	{
		Id: 6639,
		CodigoHtml: "AEA391",
		Nombre: "Deep Marsh",
		ColorNum: "0233",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 134,
		colorR: 174,
		colorG: 163,
		colorB: 145,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [134, 184],
		Triadicos: [134],
		Adjacentes: [133, 134, 132],
	},
	{
		Id: 6640,
		CodigoHtml: "8B7E6A",
		Nombre: "Industrial Strength",
		ColorNum: "0234",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 134,
		colorR: 139,
		colorG: 126,
		colorB: 106,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [134, 184],
		Triadicos: [134],
		Adjacentes: [133, 134, 132],
	},
	{
		Id: 6641,
		CodigoHtml: "6C6150",
		Nombre: "Black Heath",
		ColorNum: "0235",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 134,
		colorR: 108,
		colorG: 97,
		colorB: 80,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [134, 184],
		Triadicos: [134],
		Adjacentes: [133, 134, 132],
	},
	{
		Id: 6642,
		CodigoHtml: "E8D5BB",
		Nombre: "Sunday Gloves",
		ColorNum: "0236",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 135,
		colorR: 232,
		colorG: 213,
		colorB: 187,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [135],
		Triadicos: [135],
		Adjacentes: [135],
	},
	{
		Id: 6643,
		CodigoHtml: "E1C9AC",
		Nombre: "Flan",
		ColorNum: "0237",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 135,
		colorR: 225,
		colorG: 201,
		colorB: 172,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [135],
		Triadicos: [135],
		Adjacentes: [135],
	},
	{
		Id: 6644,
		CodigoHtml: "D4B799",
		Nombre: "Crowned One",
		ColorNum: "0238",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 135,
		colorR: 212,
		colorG: 183,
		colorB: 153,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [135],
		Triadicos: [135],
		Adjacentes: [135],
	},
	{
		Id: 6645,
		CodigoHtml: "BEA182",
		Nombre: "Tailored Tan",
		ColorNum: "0239",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 135,
		colorR: 190,
		colorG: 161,
		colorB: 130,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [135],
		Triadicos: [135],
		Adjacentes: [135],
	},
	{
		Id: 6646,
		CodigoHtml: "AF8F6D",
		Nombre: "Spiced Rum",
		ColorNum: "0240",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 135,
		colorR: 175,
		colorG: 143,
		colorB: 109,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [135],
		Triadicos: [135],
		Adjacentes: [135],
	},
	{
		Id: 6647,
		CodigoHtml: "A07E5D",
		Nombre: "Prince Paris",
		ColorNum: "0241",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 135,
		colorR: 160,
		colorG: 126,
		colorB: 93,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [135],
		Triadicos: [135],
		Adjacentes: [135],
	},
	{
		Id: 6648,
		CodigoHtml: "90704F",
		Nombre: "Rare Happening",
		ColorNum: "0242",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 135,
		colorR: 144,
		colorG: 112,
		colorB: 79,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [135],
		Triadicos: [135],
		Adjacentes: [135],
	},
	{
		Id: 6649,
		CodigoHtml: "EAD1AE",
		Nombre: "Frozen Custard",
		ColorNum: "0243",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 136,
		colorR: 234,
		colorG: 209,
		colorB: 174,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [136],
		Triadicos: [136],
		Adjacentes: [136],
	},
	{
		Id: 6650,
		CodigoHtml: "E3C59E",
		Nombre: "Mulled Cider",
		ColorNum: "0244",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 136,
		colorR: 227,
		colorG: 197,
		colorB: 158,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [136],
		Triadicos: [136],
		Adjacentes: [136],
	},
	{
		Id: 6651,
		CodigoHtml: "D3B188",
		Nombre: "Caramel Cloud",
		ColorNum: "0245",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 136,
		colorR: 211,
		colorG: 177,
		colorB: 136,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [136],
		Triadicos: [136],
		Adjacentes: [136],
	},
	{
		Id: 6652,
		CodigoHtml: "CAA577",
		Nombre: "Cinnamon Toast",
		ColorNum: "0246",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 136,
		colorR: 202,
		colorG: 165,
		colorB: 119,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [136],
		Triadicos: [136],
		Adjacentes: [136],
	},
	{
		Id: 6653,
		CodigoHtml: "BB9163",
		Nombre: "Apple Crisp ",
		ColorNum: "0247",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 136,
		colorR: 187,
		colorG: 145,
		colorB: 99,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [136],
		Triadicos: [136],
		Adjacentes: [136],
	},
	{
		Id: 6654,
		CodigoHtml: "B68C63",
		Nombre: "Safari Sun",
		ColorNum: "0248",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 136,
		colorR: 182,
		colorG: 140,
		colorB: 99,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [136],
		Triadicos: [136],
		Adjacentes: [136],
	},
	{
		Id: 6655,
		CodigoHtml: "AA815A",
		Nombre: "Butterscotch Mousse",
		ColorNum: "0249",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 136,
		colorR: 170,
		colorG: 129,
		colorB: 90,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [136],
		Triadicos: [136],
		Adjacentes: [136],
	},
	{
		Id: 6656,
		CodigoHtml: "F4E7D0",
		Nombre: "Pumpkin Spice",
		ColorNum: "0250",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 137,
		colorR: 244,
		colorG: 231,
		colorB: 208,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [137],
		Triadicos: [137],
		Adjacentes: [137],
	},
	{
		Id: 6657,
		CodigoHtml: "EFD8BA",
		Nombre: "Alchemy",
		ColorNum: "0251",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 137,
		colorR: 239,
		colorG: 216,
		colorB: 186,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [137],
		Triadicos: [137],
		Adjacentes: [137],
	},
	{
		Id: 6658,
		CodigoHtml: "E2C8A6",
		Nombre: "October Bounty",
		ColorNum: "0252",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 137,
		colorR: 226,
		colorG: 200,
		colorB: 166,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [137],
		Triadicos: [137],
		Adjacentes: [137],
	},
	{
		Id: 6659,
		CodigoHtml: "DEC19C",
		Nombre: "Honey Glow",
		ColorNum: "0253",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 137,
		colorR: 222,
		colorG: 193,
		colorB: 156,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [137],
		Triadicos: [137],
		Adjacentes: [137],
	},
	{
		Id: 6660,
		CodigoHtml: "D6B68B",
		Nombre: "Living Large",
		ColorNum: "0254",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 137,
		colorR: 214,
		colorG: 182,
		colorB: 139,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [137],
		Triadicos: [137],
		Adjacentes: [137],
	},
	{
		Id: 6661,
		CodigoHtml: "C59E6F",
		Nombre: "Back to Basics",
		ColorNum: "0255",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 137,
		colorR: 197,
		colorG: 158,
		colorB: 111,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [137],
		Triadicos: [137],
		Adjacentes: [137],
	},
	{
		Id: 6662,
		CodigoHtml: "A77C50",
		Nombre: "Crazy Horse",
		ColorNum: "0256",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 137,
		colorR: 167,
		colorG: 124,
		colorB: 80,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [137],
		Triadicos: [137],
		Adjacentes: [137],
	},
	{
		Id: 6663,
		CodigoHtml: "EADABF",
		Nombre: "Shortbread",
		ColorNum: "0257",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 138,
		colorR: 234,
		colorG: 218,
		colorB: 191,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [138],
		Triadicos: [138],
		Adjacentes: [138],
	},
	{
		Id: 6664,
		CodigoHtml: "E3CFB0",
		Nombre: "Classic Terra",
		ColorNum: "0258",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 138,
		colorR: 227,
		colorG: 207,
		colorB: 176,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [138],
		Triadicos: [138],
		Adjacentes: [138],
	},
	{
		Id: 6665,
		CodigoHtml: "D2BC9F",
		Nombre: "Persian Fable",
		ColorNum: "0259",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 138,
		colorR: 210,
		colorG: 188,
		colorB: 159,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [138],
		Triadicos: [138],
		Adjacentes: [138],
	},
	{
		Id: 6666,
		CodigoHtml: "BDA281",
		Nombre: "Cocoa Nib",
		ColorNum: "0260",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 138,
		colorR: 189,
		colorG: 162,
		colorB: 129,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [138],
		Triadicos: [138],
		Adjacentes: [138],
	},
	{
		Id: 6667,
		CodigoHtml: "B19671",
		Nombre: "Tan Hide",
		ColorNum: "0261",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 138,
		colorR: 177,
		colorG: 150,
		colorB: 113,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [138],
		Triadicos: [138],
		Adjacentes: [138],
	},
	{
		Id: 6668,
		CodigoHtml: "A18560",
		Nombre: "Log Cabin",
		ColorNum: "0262",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 138,
		colorR: 161,
		colorG: 133,
		colorB: 96,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [138],
		Triadicos: [138],
		Adjacentes: [138],
	},
	{
		Id: 6669,
		CodigoHtml: "90724E",
		Nombre: "Country Charm",
		ColorNum: "0263",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 138,
		colorR: 144,
		colorG: 114,
		colorB: 78,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [138],
		Triadicos: [138],
		Adjacentes: [138],
	},
	{
		Id: 6670,
		CodigoHtml: "E5D6BD",
		Nombre: "Palest of Lemon",
		ColorNum: "0264",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 139,
		colorR: 229,
		colorG: 214,
		colorB: 189,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [139],
		Triadicos: [139],
		Adjacentes: [139],
	},
	{
		Id: 6671,
		CodigoHtml: "DFCCAD",
		Nombre: "Marshmallow Mist",
		ColorNum: "0265",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 139,
		colorR: 223,
		colorG: 204,
		colorB: 173,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [139],
		Triadicos: [139],
		Adjacentes: [139],
	},
	{
		Id: 6672,
		CodigoHtml: "D2BD9C",
		Nombre: "October Harvest",
		ColorNum: "0266",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 139,
		colorR: 210,
		colorG: 189,
		colorB: 156,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [139],
		Triadicos: [139],
		Adjacentes: [139],
	},
	{
		Id: 6673,
		CodigoHtml: "BFA57F",
		Nombre: "Season Finale",
		ColorNum: "0267",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 139,
		colorR: 191,
		colorG: 165,
		colorB: 127,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [139],
		Triadicos: [139],
		Adjacentes: [139],
	},
	{
		Id: 6674,
		CodigoHtml: "A98960",
		Nombre: "Rich Oak",
		ColorNum: "0268",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 139,
		colorR: 169,
		colorG: 137,
		colorB: 96,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [139],
		Triadicos: [139],
		Adjacentes: [139],
	},
	{
		Id: 6675,
		CodigoHtml: "8F714A",
		Nombre: "Sahara Wind",
		ColorNum: "0269",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 139,
		colorR: 143,
		colorG: 113,
		colorB: 74,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [139],
		Triadicos: [139],
		Adjacentes: [139],
	},
	{
		Id: 6676,
		CodigoHtml: "856A4B",
		Nombre: "Graham Crust",
		ColorNum: "0270",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 139,
		colorR: 133,
		colorG: 106,
		colorB: 75,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [139],
		Triadicos: [139],
		Adjacentes: [139],
	},
	{
		Id: 6677,
		CodigoHtml: "E9DFD0",
		Nombre: "Clover Honey",
		ColorNum: "0271",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 140,
		colorR: 233,
		colorG: 223,
		colorB: 208,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [140],
		Triadicos: [140],
		Adjacentes: [140],
	},
	{
		Id: 6678,
		CodigoHtml: "DDD2BE",
		Nombre: "Mineral Water",
		ColorNum: "0272",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 140,
		colorR: 221,
		colorG: 210,
		colorB: 190,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [140],
		Triadicos: [140],
		Adjacentes: [140],
	},
	{
		Id: 6679,
		CodigoHtml: "D8CCB7",
		Nombre: "Sandstone Palette",
		ColorNum: "0273",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 140,
		colorR: 216,
		colorG: 204,
		colorB: 183,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [140],
		Triadicos: [140],
		Adjacentes: [140],
	},
	{
		Id: 6680,
		CodigoHtml: "D1BEA0",
		Nombre: "Polished Marble",
		ColorNum: "0274",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 140,
		colorR: 209,
		colorG: 190,
		colorB: 160,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [140],
		Triadicos: [140],
		Adjacentes: [140],
	},
	{
		Id: 6681,
		CodigoHtml: "BBA787",
		Nombre: "Highlight",
		ColorNum: "0275",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 140,
		colorR: 187,
		colorG: 167,
		colorB: 135,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [140],
		Triadicos: [140],
		Adjacentes: [140],
	},
	{
		Id: 6682,
		CodigoHtml: "A58E6C",
		Nombre: "Gold Taffeta",
		ColorNum: "0276",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 140,
		colorR: 165,
		colorG: 142,
		colorB: 108,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [140],
		Triadicos: [140],
		Adjacentes: [140],
	},
	{
		Id: 6683,
		CodigoHtml: "8A7454",
		Nombre: "Golden Freesia",
		ColorNum: "0277",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 140,
		colorR: 138,
		colorG: 116,
		colorB: 84,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [140],
		Triadicos: [140],
		Adjacentes: [140],
	},
	{
		Id: 6684,
		CodigoHtml: "E1D4BD",
		Nombre: "Barberry Sand",
		ColorNum: "0278",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 141,
		colorR: 225,
		colorG: 212,
		colorB: 189,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [141],
		Triadicos: [141],
		Adjacentes: [141],
	},
	{
		Id: 6685,
		CodigoHtml: "D5C6AB",
		Nombre: "Garden Country",
		ColorNum: "0279",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 141,
		colorR: 213,
		colorG: 198,
		colorB: 171,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [141],
		Triadicos: [141],
		Adjacentes: [141],
	},
	{
		Id: 6686,
		CodigoHtml: "C6B698",
		Nombre: "Wicker Basket",
		ColorNum: "0280",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 141,
		colorR: 198,
		colorG: 182,
		colorB: 152,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [141],
		Triadicos: [141],
		Adjacentes: [141],
	},
	{
		Id: 6687,
		CodigoHtml: "BEAD8D",
		Nombre: "Olive Gold",
		ColorNum: "0281",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 141,
		colorR: 190,
		colorG: 173,
		colorB: 141,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [141],
		Triadicos: [141],
		Adjacentes: [141],
	},
	{
		Id: 6688,
		CodigoHtml: "B6A17C",
		Nombre: "Vintage Gold",
		ColorNum: "0282",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 141,
		colorR: 182,
		colorG: 161,
		colorB: 124,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [141],
		Triadicos: [141],
		Adjacentes: [141],
	},
	{
		Id: 6689,
		CodigoHtml: "A28D69",
		Nombre: "Historic Town",
		ColorNum: "0283",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 141,
		colorR: 162,
		colorG: 141,
		colorB: 105,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [141],
		Triadicos: [141],
		Adjacentes: [141],
	},
	{
		Id: 6690,
		CodigoHtml: "7A6848",
		Nombre: "Queen Lioness",
		ColorNum: "0284",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 141,
		colorR: 122,
		colorG: 104,
		colorB: 72,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [141],
		Triadicos: [141],
		Adjacentes: [141],
	},
	{
		Id: 6691,
		CodigoHtml: "F0E6D4",
		Nombre: "Eugenia",
		ColorNum: "0285",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 142,
		colorR: 240,
		colorG: 230,
		colorB: 212,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [142],
		Triadicos: [142],
		Adjacentes: [142],
	},
	{
		Id: 6692,
		CodigoHtml: "E9DCC6",
		Nombre: "Yellow Page",
		ColorNum: "0286",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 142,
		colorR: 233,
		colorG: 220,
		colorB: 198,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [142],
		Triadicos: [142],
		Adjacentes: [142],
	},
	{
		Id: 6693,
		CodigoHtml: "DFCEB3",
		Nombre: "Muslin Tint",
		ColorNum: "0287",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 142,
		colorR: 223,
		colorG: 206,
		colorB: 179,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [142],
		Triadicos: [142],
		Adjacentes: [142],
	},
	{
		Id: 6694,
		CodigoHtml: "DCC9A5",
		Nombre: "Golden Buff",
		ColorNum: "0288",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 142,
		colorR: 220,
		colorG: 201,
		colorB: 165,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [142],
		Triadicos: [142],
		Adjacentes: [142],
	},
	{
		Id: 6695,
		CodigoHtml: "C9B28D",
		Nombre: "Prairie Sand",
		ColorNum: "0289",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 142,
		colorR: 201,
		colorG: 178,
		colorB: 141,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [142],
		Triadicos: [142],
		Adjacentes: [142],
	},
	{
		Id: 6696,
		CodigoHtml: "A58D66",
		Nombre: "Key Keeper",
		ColorNum: "0290",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 142,
		colorR: 165,
		colorG: 141,
		colorB: 102,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [142],
		Triadicos: [142],
		Adjacentes: [142],
	},
	{
		Id: 6697,
		CodigoHtml: "907855",
		Nombre: "Rutherford",
		ColorNum: "0291",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 142,
		colorR: 144,
		colorG: 120,
		colorB: 85,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [142],
		Triadicos: [142],
		Adjacentes: [142],
	},
	{
		Id: 6698,
		CodigoHtml: "F2EAD7",
		Nombre: "Private White",
		ColorNum: "0292",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 143,
		colorR: 242,
		colorG: 234,
		colorB: 215,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [143],
		Triadicos: [143],
		Adjacentes: [143],
	},
	{
		Id: 6699,
		CodigoHtml: "E8D8BD",
		Nombre: "What Inheritance?",
		ColorNum: "0293",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 143,
		colorR: 232,
		colorG: 216,
		colorB: 189,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [143],
		Triadicos: [143],
		Adjacentes: [143],
	},
	{
		Id: 6700,
		CodigoHtml: "E1D0B2",
		Nombre: "Rub Elbows",
		ColorNum: "0294",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 143,
		colorR: 225,
		colorG: 208,
		colorB: 178,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [143],
		Triadicos: [143],
		Adjacentes: [143],
	},
	{
		Id: 6701,
		CodigoHtml: "D6C4A2",
		Nombre: "Spanish Cream",
		ColorNum: "0295",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 143,
		colorR: 214,
		colorG: 196,
		colorB: 162,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [143],
		Triadicos: [143],
		Adjacentes: [143],
	},
	{
		Id: 6702,
		CodigoHtml: "C9B38D",
		Nombre: "Only Yesterday",
		ColorNum: "0296",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 143,
		colorR: 201,
		colorG: 179,
		colorB: 141,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [143],
		Triadicos: [143],
		Adjacentes: [143],
	},
	{
		Id: 6703,
		CodigoHtml: "B29C75",
		Nombre: "Gold Season",
		ColorNum: "0297",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 143,
		colorR: 178,
		colorG: 156,
		colorB: 117,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [143],
		Triadicos: [143],
		Adjacentes: [143],
	},
	{
		Id: 6704,
		CodigoHtml: "8E764E",
		Nombre: "Brocade",
		ColorNum: "0298",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 143,
		colorR: 142,
		colorG: 118,
		colorB: 78,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [143],
		Triadicos: [143],
		Adjacentes: [143],
	},
	{
		Id: 6705,
		CodigoHtml: "F2E9D6",
		Nombre: "Allison Lace",
		ColorNum: "0299",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 144,
		colorR: 242,
		colorG: 233,
		colorB: 214,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [144],
		Triadicos: [144],
		Adjacentes: [144],
	},
	{
		Id: 6706,
		CodigoHtml: "EEE3CB",
		Nombre: "Ivory Parchment",
		ColorNum: "0300",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 144,
		colorR: 238,
		colorG: 227,
		colorB: 203,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [144],
		Triadicos: [144],
		Adjacentes: [144],
	},
	{
		Id: 6707,
		CodigoHtml: "E1D2B2",
		Nombre: "Corinthian Pillar",
		ColorNum: "0301",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 144,
		colorR: 225,
		colorG: 210,
		colorB: 178,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [144],
		Triadicos: [144],
		Adjacentes: [144],
	},
	{
		Id: 6708,
		CodigoHtml: "BBA783",
		Nombre: "Rich Reward",
		ColorNum: "0302",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 144,
		colorR: 187,
		colorG: 167,
		colorB: 131,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [144],
		Triadicos: [144],
		Adjacentes: [144],
	},
	{
		Id: 6709,
		CodigoHtml: "B29C73",
		Nombre: "Bauhaus Gold",
		ColorNum: "0303",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 144,
		colorR: 178,
		colorG: 156,
		colorB: 115,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [144],
		Triadicos: [144],
		Adjacentes: [144],
	},
	{
		Id: 6710,
		CodigoHtml: "A08A63",
		Nombre: "Gold Tangiers",
		ColorNum: "0304",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 144,
		colorR: 160,
		colorG: 138,
		colorB: 99,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [144],
		Triadicos: [144],
		Adjacentes: [144],
	},
	{
		Id: 6711,
		CodigoHtml: "846F4B",
		Nombre: "Golden Summer",
		ColorNum: "0305",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 144,
		colorR: 132,
		colorG: 111,
		colorB: 75,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [144],
		Triadicos: [144],
		Adjacentes: [144],
	},
	{
		Id: 6712,
		CodigoHtml: "F2EAD6",
		Nombre: "Saffron Tint",
		ColorNum: "0306",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 145,
		colorR: 242,
		colorG: 234,
		colorB: 214,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [145],
		Triadicos: [145],
		Adjacentes: [145],
	},
	{
		Id: 6713,
		CodigoHtml: "F0E4CA",
		Nombre: "Putting Bench",
		ColorNum: "0307",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 145,
		colorR: 240,
		colorG: 228,
		colorB: 202,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [145],
		Triadicos: [145],
		Adjacentes: [145],
	},
	{
		Id: 6714,
		CodigoHtml: "E0CFA8",
		Nombre: "Coconut Crumble",
		ColorNum: "0308",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 145,
		colorR: 224,
		colorG: 207,
		colorB: 168,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [145],
		Triadicos: [145],
		Adjacentes: [145],
	},
	{
		Id: 6715,
		CodigoHtml: "CEBC96",
		Nombre: "Palmetto Bluff",
		ColorNum: "0309",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 145,
		colorR: 206,
		colorG: 188,
		colorB: 150,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [145],
		Triadicos: [145],
		Adjacentes: [145],
	},
	{
		Id: 6716,
		CodigoHtml: "BBA87E",
		Nombre: "Sassafras",
		ColorNum: "0310",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 145,
		colorR: 187,
		colorG: 168,
		colorB: 126,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [145],
		Triadicos: [145],
		Adjacentes: [145],
	},
	{
		Id: 6717,
		CodigoHtml: "AF986B",
		Nombre: "Life Exotic",
		ColorNum: "0311",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 145,
		colorR: 175,
		colorG: 152,
		colorB: 107,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [145],
		Triadicos: [145],
		Adjacentes: [145],
	},
	{
		Id: 6718,
		CodigoHtml: "907A50",
		Nombre: "September Gold",
		ColorNum: "0312",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 145,
		colorR: 144,
		colorG: 122,
		colorB: 80,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [145],
		Triadicos: [145],
		Adjacentes: [145],
	},
	{
		Id: 6719,
		CodigoHtml: "EBE0C8",
		Nombre: "Chic Magnet",
		ColorNum: "0313",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 146,
		colorR: 235,
		colorG: 224,
		colorB: 200,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [146],
		Triadicos: [146],
		Adjacentes: [146],
	},
	{
		Id: 6720,
		CodigoHtml: "E4D8BD",
		Nombre: "Trade Secret",
		ColorNum: "0314",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 146,
		colorR: 228,
		colorG: 216,
		colorB: 189,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [146],
		Triadicos: [146],
		Adjacentes: [146],
	},
	{
		Id: 6721,
		CodigoHtml: "DCCDAE",
		Nombre: "Peaceable Kingdom",
		ColorNum: "0315",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 146,
		colorR: 220,
		colorG: 205,
		colorB: 174,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [146],
		Triadicos: [146],
		Adjacentes: [146],
	},
	{
		Id: 6722,
		CodigoHtml: "D6C7A3",
		Nombre: "Golden Lake",
		ColorNum: "0316",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 146,
		colorR: 214,
		colorG: 199,
		colorB: 163,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [146],
		Triadicos: [146],
		Adjacentes: [146],
	},
	{
		Id: 6723,
		CodigoHtml: "C4B18A",
		Nombre: "Green Gold",
		ColorNum: "0317",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 146,
		colorR: 196,
		colorG: 177,
		colorB: 138,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [146],
		Triadicos: [146],
		Adjacentes: [146],
	},
	{
		Id: 6724,
		CodigoHtml: "AD976C",
		Nombre: "There's No Place Like Home",
		ColorNum: "0318",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 146,
		colorR: 173,
		colorG: 151,
		colorB: 108,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [146],
		Triadicos: [146],
		Adjacentes: [146],
	},
	{
		Id: 6725,
		CodigoHtml: "8C7952",
		Nombre: "Herald's Trumpet",
		ColorNum: "0319",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 146,
		colorR: 140,
		colorG: 121,
		colorB: 82,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [146],
		Triadicos: [146],
		Adjacentes: [146],
	},
	{
		Id: 6726,
		CodigoHtml: "EDE5D1",
		Nombre: "Floating Island",
		ColorNum: "0320",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 147,
		colorR: 237,
		colorG: 229,
		colorB: 209,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [147],
		Triadicos: [147],
		Adjacentes: [147],
	},
	{
		Id: 6727,
		CodigoHtml: "E2D6B8",
		Nombre: "Hephaestus",
		ColorNum: "0321",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 147,
		colorR: 226,
		colorG: 214,
		colorB: 184,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [147],
		Triadicos: [147],
		Adjacentes: [147],
	},
	{
		Id: 6728,
		CodigoHtml: "D9CCA9",
		Nombre: "Gabriel's Light",
		ColorNum: "0322",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 147,
		colorR: 217,
		colorG: 204,
		colorB: 169,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [147],
		Triadicos: [147],
		Adjacentes: [147],
	},
	{
		Id: 6729,
		CodigoHtml: "BBAB82",
		Nombre: "Star of Gold",
		ColorNum: "0323",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 147,
		colorR: 187,
		colorG: 171,
		colorB: 130,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [147],
		Triadicos: [147],
		Adjacentes: [147],
	},
	{
		Id: 6730,
		CodigoHtml: "AE9B6F",
		Nombre: "Upon Reflection",
		ColorNum: "0324",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 147,
		colorR: 174,
		colorG: 155,
		colorB: 111,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [147],
		Triadicos: [147],
		Adjacentes: [147],
	},
	{
		Id: 6731,
		CodigoHtml: "928159",
		Nombre: "Welcome Home",
		ColorNum: "0325",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 147,
		colorR: 146,
		colorG: 129,
		colorB: 89,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [147],
		Triadicos: [147],
		Adjacentes: [147],
	},
	{
		Id: 6732,
		CodigoHtml: "82724E",
		Nombre: "Bambino",
		ColorNum: "0326",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 147,
		colorR: 130,
		colorG: 114,
		colorB: 78,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [147],
		Triadicos: [147],
		Adjacentes: [147],
	},
	{
		Id: 6733,
		CodigoHtml: "EDE3D0",
		Nombre: "Tawny Daylilly",
		ColorNum: "0327",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 148,
		colorR: 237,
		colorG: 227,
		colorB: 208,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [148],
		Triadicos: [148],
		Adjacentes: [148],
	},
	{
		Id: 6734,
		CodigoHtml: "DFD5BA",
		Nombre: "Land of Trees",
		ColorNum: "0328",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 148,
		colorR: 223,
		colorG: 213,
		colorB: 186,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [148],
		Triadicos: [148],
		Adjacentes: [148],
	},
	{
		Id: 6735,
		CodigoHtml: "D6C9AA",
		Nombre: "Knit Cardigan",
		ColorNum: "0329",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 148,
		colorR: 214,
		colorG: 201,
		colorB: 170,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [148],
		Triadicos: [148],
		Adjacentes: [148],
	},
	{
		Id: 6736,
		CodigoHtml: "CEC19F",
		Nombre: "Castaway Beach",
		ColorNum: "0330",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 148,
		colorR: 206,
		colorG: 193,
		colorB: 159,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [148],
		Triadicos: [148],
		Adjacentes: [148],
	},
	{
		Id: 6737,
		CodigoHtml: "C1B58F",
		Nombre: "Refined",
		ColorNum: "0331",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 148,
		colorR: 193,
		colorG: 181,
		colorB: 143,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [148],
		Triadicos: [148],
		Adjacentes: [148],
	},
	{
		Id: 6738,
		CodigoHtml: "9F916B",
		Nombre: "All Nighter",
		ColorNum: "0332",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 148,
		colorR: 159,
		colorG: 145,
		colorB: 107,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [148],
		Triadicos: [148],
		Adjacentes: [148],
	},
	{
		Id: 6739,
		CodigoHtml: "7D704F",
		Nombre: "Grasslands",
		ColorNum: "0333",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 148,
		colorR: 125,
		colorG: 112,
		colorB: 79,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [148],
		Triadicos: [148],
		Adjacentes: [148],
	},
	{
		Id: 6740,
		CodigoHtml: "E6DFCF",
		Nombre: "Sedona",
		ColorNum: "0334",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 149,
		colorR: 230,
		colorG: 223,
		colorB: 207,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [149],
		Triadicos: [149],
		Adjacentes: [149],
	},
	{
		Id: 6741,
		CodigoHtml: "E1D6C3",
		Nombre: "Illusive Dream",
		ColorNum: "0335",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 149,
		colorR: 225,
		colorG: 214,
		colorB: 195,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [149],
		Triadicos: [149],
		Adjacentes: [149],
	},
	{
		Id: 6742,
		CodigoHtml: "C5BBA9",
		Nombre: "Soft Leather",
		ColorNum: "0336",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 149,
		colorR: 197,
		colorG: 187,
		colorB: 169,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [149],
		Triadicos: [149],
		Adjacentes: [149],
	},
	{
		Id: 6743,
		CodigoHtml: "AEA38E",
		Nombre: "Urban Charm",
		ColorNum: "0337",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 149,
		colorR: 174,
		colorG: 163,
		colorB: 142,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [149],
		Triadicos: [149],
		Adjacentes: [149],
	},
	{
		Id: 6744,
		CodigoHtml: "A1937A",
		Nombre: "Whipcord",
		ColorNum: "0338",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 149,
		colorR: 161,
		colorG: 147,
		colorB: 122,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [149],
		Triadicos: [149],
		Adjacentes: [149],
	},
	{
		Id: 6745,
		CodigoHtml: "92856C",
		Nombre: "Ceramic Pot",
		ColorNum: "0339",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 149,
		colorR: 146,
		colorG: 133,
		colorB: 108,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [149],
		Triadicos: [149],
		Adjacentes: [149],
	},
	{
		Id: 6746,
		CodigoHtml: "655A48",
		Nombre: "Nightfall",
		ColorNum: "0340",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 149,
		colorR: 101,
		colorG: 90,
		colorB: 72,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [149],
		Triadicos: [149],
		Adjacentes: [149],
	},
	{
		Id: 6747,
		CodigoHtml: "E6E1CE",
		Nombre: "Loch Ness",
		ColorNum: "0341",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 150,
		colorR: 230,
		colorG: 225,
		colorB: 206,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [150],
		Triadicos: [150],
		Adjacentes: [150],
	},
	{
		Id: 6748,
		CodigoHtml: "D6CEBA",
		Nombre: "Velum Smoke",
		ColorNum: "0342",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 150,
		colorR: 214,
		colorG: 206,
		colorB: 186,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [150],
		Triadicos: [150],
		Adjacentes: [150],
	},
	{
		Id: 6749,
		CodigoHtml: "CEC6B1",
		Nombre: "Cantera",
		ColorNum: "0343",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 150,
		colorR: 206,
		colorG: 198,
		colorB: 177,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [150],
		Triadicos: [150],
		Adjacentes: [150],
	},
	{
		Id: 6750,
		CodigoHtml: "C7BEA5",
		Nombre: "Courtyard",
		ColorNum: "0344",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 150,
		colorR: 199,
		colorG: 190,
		colorB: 165,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [150],
		Triadicos: [150],
		Adjacentes: [150],
	},
	{
		Id: 6751,
		CodigoHtml: "B6AC91",
		Nombre: "Travertine Path",
		ColorNum: "0345",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 150,
		colorR: 182,
		colorG: 172,
		colorB: 145,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [150],
		Triadicos: [150],
		Adjacentes: [150],
	},
	{
		Id: 6752,
		CodigoHtml: "978A6C",
		Nombre: "Northeast Trail",
		ColorNum: "0346",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 150,
		colorR: 151,
		colorG: 138,
		colorB: 108,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [150],
		Triadicos: [150],
		Adjacentes: [150],
	},
	{
		Id: 6753,
		CodigoHtml: "706750",
		Nombre: "Jungle Cover",
		ColorNum: "0347",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 150,
		colorR: 112,
		colorG: 103,
		colorB: 80,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [150],
		Triadicos: [150],
		Adjacentes: [150],
	},
	{
		Id: 6754,
		CodigoHtml: "DCD6C1",
		Nombre: "Dried Grass",
		ColorNum: "0348",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 151,
		colorR: 220,
		colorG: 214,
		colorB: 193,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [151],
		Triadicos: [151],
		Adjacentes: [151],
	},
	{
		Id: 6755,
		CodigoHtml: "D0C8B1",
		Nombre: "Reseda",
		ColorNum: "0349",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 151,
		colorR: 208,
		colorG: 200,
		colorB: 177,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [151],
		Triadicos: [151],
		Adjacentes: [151],
	},
	{
		Id: 6756,
		CodigoHtml: "C3BAA2",
		Nombre: "Helen of Troy",
		ColorNum: "0350",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 151,
		colorR: 195,
		colorG: 186,
		colorB: 162,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [151],
		Triadicos: [151],
		Adjacentes: [151],
	},
	{
		Id: 6757,
		CodigoHtml: "A99D82",
		Nombre: "Mohalla",
		ColorNum: "0351",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 151,
		colorR: 169,
		colorG: 157,
		colorB: 130,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [151],
		Triadicos: [151],
		Adjacentes: [151],
	},
	{
		Id: 6758,
		CodigoHtml: "958C71",
		Nombre: "Shagbark Olive",
		ColorNum: "0352",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 151,
		colorR: 149,
		colorG: 140,
		colorB: 113,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [151],
		Triadicos: [151],
		Adjacentes: [151],
	},
	{
		Id: 6759,
		CodigoHtml: "7E7254",
		Nombre: "Becker Gold",
		ColorNum: "0353",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 151,
		colorR: 126,
		colorG: 114,
		colorB: 84,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [151],
		Triadicos: [151],
		Adjacentes: [151],
	},
	{
		Id: 6760,
		CodigoHtml: "786F51",
		Nombre: "Austere",
		ColorNum: "0354",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 151,
		colorR: 120,
		colorG: 111,
		colorB: 81,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [151],
		Triadicos: [151],
		Adjacentes: [151],
	},
	{
		Id: 6761,
		CodigoHtml: "F4F2E9",
		Nombre: "Twinkle Twinkle",
		ColorNum: "0355",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 152,
		colorR: 244,
		colorG: 242,
		colorB: 233,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [152],
		Triadicos: [152],
		Adjacentes: [152],
	},
	{
		Id: 6762,
		CodigoHtml: "EDEADE",
		Nombre: "Moth Mist",
		ColorNum: "0356",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 152,
		colorR: 237,
		colorG: 234,
		colorB: 222,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [152],
		Triadicos: [152],
		Adjacentes: [152],
	},
	{
		Id: 6763,
		CodigoHtml: "DEDCCD",
		Nombre: "Moss Island",
		ColorNum: "0357",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 152,
		colorR: 222,
		colorG: 220,
		colorB: 205,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [152],
		Triadicos: [152],
		Adjacentes: [152],
	},
	{
		Id: 6764,
		CodigoHtml: "C8C2AC",
		Nombre: "Silky Green",
		ColorNum: "0358",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 152,
		colorR: 200,
		colorG: 194,
		colorB: 172,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [152],
		Triadicos: [152],
		Adjacentes: [152],
	},
	{
		Id: 6765,
		CodigoHtml: "A39A7A",
		Nombre: "Green Sleeves",
		ColorNum: "0359",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 152,
		colorR: 163,
		colorG: 154,
		colorB: 122,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [152],
		Triadicos: [152],
		Adjacentes: [152],
	},
	{
		Id: 6766,
		CodigoHtml: "8E8363",
		Nombre: "Land Before Time",
		ColorNum: "0360",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 152,
		colorR: 142,
		colorG: 131,
		colorB: 99,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [152],
		Triadicos: [152],
		Adjacentes: [152],
	},
	{
		Id: 6767,
		CodigoHtml: "786F52",
		Nombre: "Georgia on My Mind",
		ColorNum: "0361",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 152,
		colorR: 120,
		colorG: 111,
		colorB: 82,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [152],
		Triadicos: [152],
		Adjacentes: [152],
	},
	{
		Id: 6768,
		CodigoHtml: "EEE9DA",
		Nombre: "Pale Quartz ",
		ColorNum: "0362",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 153,
		colorR: 238,
		colorG: 233,
		colorB: 218,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [153],
		Triadicos: [153],
		Adjacentes: [153],
	},
	{
		Id: 6769,
		CodigoHtml: "E1DAC5",
		Nombre: "Mission Hills",
		ColorNum: "0363",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 153,
		colorR: 225,
		colorG: 218,
		colorB: 197,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [153],
		Triadicos: [153],
		Adjacentes: [153],
	},
	{
		Id: 6770,
		CodigoHtml: "CFC7AB",
		Nombre: "Stone Hearth",
		ColorNum: "0364",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 153,
		colorR: 207,
		colorG: 199,
		colorB: 171,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [153],
		Triadicos: [153],
		Adjacentes: [153],
	},
	{
		Id: 6771,
		CodigoHtml: "A3997B",
		Nombre: "Abbey Stone",
		ColorNum: "0365",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 153,
		colorR: 163,
		colorG: 153,
		colorB: 123,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [153],
		Triadicos: [153],
		Adjacentes: [153],
	},
	{
		Id: 6772,
		CodigoHtml: "938865",
		Nombre: "Owlet",
		ColorNum: "0366",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 153,
		colorR: 147,
		colorG: 136,
		colorB: 101,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [153],
		Triadicos: [153],
		Adjacentes: [153],
	},
	{
		Id: 6773,
		CodigoHtml: "837A5B",
		Nombre: "Chocolate Velvet",
		ColorNum: "0367",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 153,
		colorR: 131,
		colorG: 122,
		colorB: 91,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [153],
		Triadicos: [153],
		Adjacentes: [153],
	},
	{
		Id: 6774,
		CodigoHtml: "7A7050",
		Nombre: "Folk Tales",
		ColorNum: "0368",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 153,
		colorR: 122,
		colorG: 112,
		colorB: 80,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [153],
		Triadicos: [153],
		Adjacentes: [153],
	},
	{
		Id: 6775,
		CodigoHtml: "DCDACD",
		Nombre: "Saturnia",
		ColorNum: "0369",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 154,
		colorR: 220,
		colorG: 218,
		colorB: 205,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [154],
		Triadicos: [154],
		Adjacentes: [154],
	},
	{
		Id: 6776,
		CodigoHtml: "CAC7B7",
		Nombre: "Palatine",
		ColorNum: "0370",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 154,
		colorR: 202,
		colorG: 199,
		colorB: 183,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [154],
		Triadicos: [154],
		Adjacentes: [154],
	},
	{
		Id: 6777,
		CodigoHtml: "C1C1B1",
		Nombre: "Fiorito",
		ColorNum: "0371",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 154,
		colorR: 193,
		colorG: 193,
		colorB: 177,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [154],
		Triadicos: [154],
		Adjacentes: [154],
	},
	{
		Id: 6778,
		CodigoHtml: "AEA994",
		Nombre: "Historic Shade",
		ColorNum: "0372",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 154,
		colorR: 174,
		colorG: 169,
		colorB: 148,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [154],
		Triadicos: [154],
		Adjacentes: [154],
	},
	{
		Id: 6779,
		CodigoHtml: "938F77",
		Nombre: "Burning Idea",
		ColorNum: "0373",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 154,
		colorR: 147,
		colorG: 143,
		colorB: 119,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [154],
		Triadicos: [154],
		Adjacentes: [154],
	},
	{
		Id: 6780,
		CodigoHtml: "74705A",
		Nombre: "Candle Wax",
		ColorNum: "0374",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 154,
		colorR: 116,
		colorG: 112,
		colorB: 90,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [154],
		Triadicos: [154],
		Adjacentes: [154],
	},
	{
		Id: 6781,
		CodigoHtml: "6A654B",
		Nombre: "African Queen",
		ColorNum: "0375",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 154,
		colorR: 106,
		colorG: 101,
		colorB: 75,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [154],
		Triadicos: [154],
		Adjacentes: [154],
	},
	{
		Id: 6782,
		CodigoHtml: "E9E4D4",
		Nombre: "Bleached Meadow",
		ColorNum: "0376",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 155,
		colorR: 233,
		colorG: 228,
		colorB: 212,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [155],
		Triadicos: [155],
		Adjacentes: [155],
	},
	{
		Id: 6783,
		CodigoHtml: "D3CEB8",
		Nombre: "Montezuma",
		ColorNum: "0377",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 155,
		colorR: 211,
		colorG: 206,
		colorB: 184,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [155],
		Triadicos: [155],
		Adjacentes: [155],
	},
	{
		Id: 6784,
		CodigoHtml: "C9C3AB",
		Nombre: "Garden Hedge",
		ColorNum: "0378",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 155,
		colorR: 201,
		colorG: 195,
		colorB: 171,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [155],
		Triadicos: [155],
		Adjacentes: [155],
	},
	{
		Id: 6785,
		CodigoHtml: "A29E87",
		Nombre: "Orestes",
		ColorNum: "0379",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 155,
		colorR: 162,
		colorG: 158,
		colorB: 135,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [155],
		Triadicos: [155],
		Adjacentes: [155],
	},
	{
		Id: 6786,
		CodigoHtml: "918C72",
		Nombre: "Mirrored Willow",
		ColorNum: "0380",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 155,
		colorR: 145,
		colorG: 140,
		colorB: 114,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [155],
		Triadicos: [155],
		Adjacentes: [155],
	},
	{
		Id: 6787,
		CodigoHtml: "847E64",
		Nombre: "Sonata",
		ColorNum: "0381",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 155,
		colorR: 132,
		colorG: 126,
		colorB: 100,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [155],
		Triadicos: [155],
		Adjacentes: [155],
	},
	{
		Id: 6788,
		CodigoHtml: "756C4E",
		Nombre: "Mount Olive",
		ColorNum: "0382",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 155,
		colorR: 117,
		colorG: 108,
		colorB: 78,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [155],
		Triadicos: [155],
		Adjacentes: [155],
	},
	{
		Id: 6789,
		CodigoHtml: "EEE7CF",
		Nombre: "Natural Whisper",
		ColorNum: "0383",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 156,
		colorR: 238,
		colorG: 231,
		colorB: 207,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [156, 106],
		Triadicos: [156],
		Adjacentes: [157, 156, 158],
	},
	{
		Id: 6790,
		CodigoHtml: "ECE4CA",
		Nombre: "Cheese Please",
		ColorNum: "0384",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 156,
		colorR: 236,
		colorG: 228,
		colorB: 202,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [156, 106],
		Triadicos: [156],
		Adjacentes: [157, 156, 158],
	},
	{
		Id: 6791,
		CodigoHtml: "DDD2AC",
		Nombre: "Howdy Neighbor",
		ColorNum: "0385",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 156,
		colorR: 221,
		colorG: 210,
		colorB: 172,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [156, 106],
		Triadicos: [156],
		Adjacentes: [157, 156, 158],
	},
	{
		Id: 6792,
		CodigoHtml: "D0C497",
		Nombre: "Hippolita",
		ColorNum: "0386",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 156,
		colorR: 208,
		colorG: 196,
		colorB: 151,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [156, 106],
		Triadicos: [156],
		Adjacentes: [157, 156, 158],
	},
	{
		Id: 6793,
		CodigoHtml: "BCAF7A",
		Nombre: "Goldie Oldie",
		ColorNum: "0387",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 156,
		colorR: 188,
		colorG: 175,
		colorB: 122,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [156, 106],
		Triadicos: [156],
		Adjacentes: [157, 156, 158],
	},
	{
		Id: 6794,
		CodigoHtml: "AFA16B",
		Nombre: "Shiny Gold",
		ColorNum: "0388",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 156,
		colorR: 175,
		colorG: 161,
		colorB: 107,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [156, 106],
		Triadicos: [156],
		Adjacentes: [157, 156, 158],
	},
	{
		Id: 6795,
		CodigoHtml: "7C7147",
		Nombre: "Gold Sparkle",
		ColorNum: "0389",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 156,
		colorR: 124,
		colorG: 113,
		colorB: 71,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [156, 106],
		Triadicos: [156],
		Adjacentes: [157, 156, 158],
	},
	{
		Id: 6796,
		CodigoHtml: "EEEAD7",
		Nombre: "Olive Tint",
		ColorNum: "0390",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 157,
		colorR: 238,
		colorG: 234,
		colorB: 215,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [157, 107],
		Triadicos: [157],
		Adjacentes: [156, 157, 158],
	},
	{
		Id: 6797,
		CodigoHtml: "E7DFC2",
		Nombre: "Crispa",
		ColorNum: "0391",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 157,
		colorR: 231,
		colorG: 223,
		colorB: 194,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [157, 107],
		Triadicos: [157],
		Adjacentes: [156, 157, 158],
	},
	{
		Id: 6798,
		CodigoHtml: "DED6B3",
		Nombre: "Alsot Olive",
		ColorNum: "0392",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 157,
		colorR: 222,
		colorG: 214,
		colorB: 179,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [157, 107],
		Triadicos: [157],
		Adjacentes: [156, 157, 158],
	},
	{
		Id: 6799,
		CodigoHtml: "CDC4A0",
		Nombre: "Inviting Gesture",
		ColorNum: "0393",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 157,
		colorR: 205,
		colorG: 196,
		colorB: 160,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [157, 107],
		Triadicos: [157],
		Adjacentes: [156, 157, 158],
	},
	{
		Id: 6800,
		CodigoHtml: "BEB58C",
		Nombre: "Highway",
		ColorNum: "0394",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 157,
		colorR: 190,
		colorG: 181,
		colorB: 140,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [157, 107],
		Triadicos: [157],
		Adjacentes: [156, 157, 158],
	},
	{
		Id: 6801,
		CodigoHtml: "B2A775",
		Nombre: "Crack Willow",
		ColorNum: "0395",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 157,
		colorR: 178,
		colorG: 167,
		colorB: 117,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [157, 107],
		Triadicos: [157],
		Adjacentes: [156, 157, 158],
	},
	{
		Id: 6802,
		CodigoHtml: "7E7546",
		Nombre: "Wing Man",
		ColorNum: "0396",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 157,
		colorR: 126,
		colorG: 117,
		colorB: 70,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [157, 107],
		Triadicos: [157],
		Adjacentes: [156, 157, 158],
	},
	{
		Id: 6803,
		CodigoHtml: "EDEBD5",
		Nombre: "Hidden Paradise ",
		ColorNum: "0397",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 158,
		colorR: 237,
		colorG: 235,
		colorB: 213,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [158, 108],
		Triadicos: [158],
		Adjacentes: [157, 158, 159],
	},
	{
		Id: 6804,
		CodigoHtml: "DFDAB6",
		Nombre: "Island Embrace",
		ColorNum: "0398",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 158,
		colorR: 223,
		colorG: 218,
		colorB: 182,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [158, 108],
		Triadicos: [158],
		Adjacentes: [157, 158, 159],
	},
	{
		Id: 6805,
		CodigoHtml: "D3CDA3",
		Nombre: "Sawgrass Cottage",
		ColorNum: "0399",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 158,
		colorR: 211,
		colorG: 205,
		colorB: 163,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [158, 108],
		Triadicos: [158],
		Adjacentes: [157, 158, 159],
	},
	{
		Id: 6806,
		CodigoHtml: "C4BD92",
		Nombre: "New Foliage",
		ColorNum: "0400",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 158,
		colorR: 196,
		colorG: 189,
		colorB: 146,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [158, 108],
		Triadicos: [158],
		Adjacentes: [157, 158, 159],
	},
	{
		Id: 6807,
		CodigoHtml: "B2AA7C",
		Nombre: "Bamboo Forest",
		ColorNum: "0401",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 158,
		colorR: 178,
		colorG: 170,
		colorB: 124,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [158, 108],
		Triadicos: [158],
		Adjacentes: [157, 158, 159],
	},
	{
		Id: 6808,
		CodigoHtml: "99915F",
		Nombre: "Sabo Garden",
		ColorNum: "0402",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 158,
		colorR: 153,
		colorG: 145,
		colorB: 95,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [158, 108],
		Triadicos: [158],
		Adjacentes: [157, 158, 159],
	},
	{
		Id: 6809,
		CodigoHtml: "877E4E",
		Nombre: "Tropical Twist",
		ColorNum: "0403",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 158,
		colorR: 135,
		colorG: 126,
		colorB: 78,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [158, 108],
		Triadicos: [158],
		Adjacentes: [157, 158, 159],
	},
	{
		Id: 6810,
		CodigoHtml: "E4E5D0",
		Nombre: "Translucent Vision",
		ColorNum: "0404",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 159,
		colorR: 228,
		colorG: 229,
		colorB: 208,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [159, 109],
		Triadicos: [159],
		Adjacentes: [158, 159, 160],
	},
	{
		Id: 6811,
		CodigoHtml: "DBDDC4",
		Nombre: "Planet Earth",
		ColorNum: "0405",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 159,
		colorR: 219,
		colorG: 221,
		colorB: 196,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [159, 109],
		Triadicos: [159],
		Adjacentes: [158, 159, 160],
	},
	{
		Id: 6812,
		CodigoHtml: "D0D3B6",
		Nombre: "Home Body",
		ColorNum: "0406",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 159,
		colorR: 208,
		colorG: 211,
		colorB: 182,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [159, 109],
		Triadicos: [159],
		Adjacentes: [158, 159, 160],
	},
	{
		Id: 6813,
		CodigoHtml: "C2C5A4",
		Nombre: "Vapor",
		ColorNum: "0407",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 159,
		colorR: 194,
		colorG: 197,
		colorB: 164,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [159, 109],
		Triadicos: [159],
		Adjacentes: [158, 159, 160],
	},
	{
		Id: 6814,
		CodigoHtml: "B6B993",
		Nombre: "Rediscover",
		ColorNum: "0408",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 159,
		colorR: 182,
		colorG: 185,
		colorB: 147,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [159, 109],
		Triadicos: [159],
		Adjacentes: [158, 159, 160],
	},
	{
		Id: 6815,
		CodigoHtml: "949569",
		Nombre: "Green Cast",
		ColorNum: "0409",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 159,
		colorR: 148,
		colorG: 149,
		colorB: 105,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [159, 109],
		Triadicos: [159],
		Adjacentes: [158, 159, 160],
	},
	{
		Id: 6816,
		CodigoHtml: "6E6F4D",
		Nombre: "Old School",
		ColorNum: "0410",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 159,
		colorR: 110,
		colorG: 111,
		colorB: 77,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [159, 109],
		Triadicos: [159],
		Adjacentes: [158, 159, 160],
	},
	{
		Id: 6817,
		CodigoHtml: "F3F2E9",
		Nombre: "Cyprus Spring",
		ColorNum: "0411",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 160,
		colorR: 243,
		colorG: 242,
		colorB: 233,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [160, 110],
		Triadicos: [160],
		Adjacentes: [159, 160, 161],
	},
	{
		Id: 6818,
		CodigoHtml: "EEEDE1",
		Nombre: "Sublime",
		ColorNum: "0412",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 160,
		colorR: 238,
		colorG: 237,
		colorB: 225,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [160, 110],
		Triadicos: [160],
		Adjacentes: [159, 160, 161],
	},
	{
		Id: 6819,
		CodigoHtml: "DFDFD1",
		Nombre: "Speak to Me ",
		ColorNum: "0413",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 160,
		colorR: 223,
		colorG: 223,
		colorB: 209,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [160, 110],
		Triadicos: [160],
		Adjacentes: [159, 160, 161],
	},
	{
		Id: 6820,
		CodigoHtml: "C6C8B1",
		Nombre: "Plume Grass",
		ColorNum: "0414",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 160,
		colorR: 198,
		colorG: 200,
		colorB: 177,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [160, 110],
		Triadicos: [160],
		Adjacentes: [159, 160, 161],
	},
	{
		Id: 6821,
		CodigoHtml: "A1A282",
		Nombre: "French Pear",
		ColorNum: "0415",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 160,
		colorR: 161,
		colorG: 162,
		colorB: 130,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [160, 110],
		Triadicos: [160],
		Adjacentes: [159, 160, 161],
	},
	{
		Id: 6822,
		CodigoHtml: "858665",
		Nombre: "Taffeta Sheen",
		ColorNum: "0416",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 160,
		colorR: 133,
		colorG: 134,
		colorB: 101,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [160, 110],
		Triadicos: [160],
		Adjacentes: [159, 160, 161],
	},
	{
		Id: 6823,
		CodigoHtml: "686B4B",
		Nombre: "Aloe Leaf",
		ColorNum: "0417",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 160,
		colorR: 104,
		colorG: 107,
		colorB: 75,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [160, 110],
		Triadicos: [160],
		Adjacentes: [159, 160, 161],
	},
	{
		Id: 6824,
		CodigoHtml: "EBE8DD",
		Nombre: "Onion Skin",
		ColorNum: "0418",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 161,
		colorR: 235,
		colorG: 232,
		colorB: 221,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [161, 111],
		Triadicos: [161],
		Adjacentes: [160, 161, 162],
	},
	{
		Id: 6825,
		CodigoHtml: "DAD7C9",
		Nombre: "Poseidon's Beard",
		ColorNum: "0419",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 161,
		colorR: 218,
		colorG: 215,
		colorB: 201,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [161, 111],
		Triadicos: [161],
		Adjacentes: [160, 161, 162],
	},
	{
		Id: 6826,
		CodigoHtml: "C4C0B0",
		Nombre: "Dave's Den",
		ColorNum: "0420",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 161,
		colorR: 196,
		colorG: 192,
		colorB: 176,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [161, 111],
		Triadicos: [161],
		Adjacentes: [160, 161, 162],
	},
	{
		Id: 6827,
		CodigoHtml: "AAAB9E",
		Nombre: "Silverado Ranch",
		ColorNum: "0421",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 161,
		colorR: 170,
		colorG: 171,
		colorB: 158,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [161, 111],
		Triadicos: [161],
		Adjacentes: [160, 161, 162],
	},
	{
		Id: 6828,
		CodigoHtml: "97998B",
		Nombre: "Everlasting Sage",
		ColorNum: "0422",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 161,
		colorR: 151,
		colorG: 153,
		colorB: 139,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [161, 111],
		Triadicos: [161],
		Adjacentes: [160, 161, 162],
	},
	{
		Id: 6829,
		CodigoHtml: "707061",
		Nombre: "Eye of the Storm",
		ColorNum: "0423",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 161,
		colorR: 112,
		colorG: 112,
		colorB: 97,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [161, 111],
		Triadicos: [161],
		Adjacentes: [160, 161, 162],
	},
	{
		Id: 6830,
		CodigoHtml: "605E50",
		Nombre: "Monogram",
		ColorNum: "0424",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 161,
		colorR: 96,
		colorG: 94,
		colorB: 80,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [161, 111],
		Triadicos: [161],
		Adjacentes: [160, 161, 162],
	},
	{
		Id: 6831,
		CodigoHtml: "E1E2D2",
		Nombre: "Pale Green Tea",
		ColorNum: "0425",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 162,
		colorR: 225,
		colorG: 226,
		colorB: 210,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [162, 112],
		Triadicos: [162],
		Adjacentes: [161, 162, 163],
	},
	{
		Id: 6832,
		CodigoHtml: "D5D8C8",
		Nombre: "September Song",
		ColorNum: "0426",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 162,
		colorR: 213,
		colorG: 216,
		colorB: 200,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [162, 112],
		Triadicos: [162],
		Adjacentes: [161, 162, 163],
	},
	{
		Id: 6833,
		CodigoHtml: "BBC3AD",
		Nombre: "Arbor Vitae",
		ColorNum: "0427",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 162,
		colorR: 187,
		colorG: 195,
		colorB: 173,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [162, 112],
		Triadicos: [162],
		Adjacentes: [161, 162, 163],
	},
	{
		Id: 6834,
		CodigoHtml: "959C81",
		Nombre: "Lucky Day",
		ColorNum: "0428",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 162,
		colorR: 149,
		colorG: 156,
		colorB: 129,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [162, 112],
		Triadicos: [162],
		Adjacentes: [161, 162, 163],
	},
	{
		Id: 6835,
		CodigoHtml: "7E856A",
		Nombre: "Pendula Garden",
		ColorNum: "0429",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 162,
		colorR: 126,
		colorG: 133,
		colorB: 106,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [162, 112],
		Triadicos: [162],
		Adjacentes: [161, 162, 163],
	},
	{
		Id: 6836,
		CodigoHtml: "5F634F",
		Nombre: "Vegetarian",
		ColorNum: "0430",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 162,
		colorR: 95,
		colorG: 99,
		colorB: 79,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [162, 112],
		Triadicos: [162],
		Adjacentes: [161, 162, 163],
	},
	{
		Id: 6837,
		CodigoHtml: "4F5245",
		Nombre: "Clover Patch",
		ColorNum: "0431",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 162,
		colorR: 79,
		colorG: 82,
		colorB: 69,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [162, 112],
		Triadicos: [162],
		Adjacentes: [161, 162, 163],
	},
	{
		Id: 6838,
		CodigoHtml: "EDEDDF",
		Nombre: "Sweet Spring",
		ColorNum: "0432",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 163,
		colorR: 237,
		colorG: 237,
		colorB: 223,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [163, 113],
		Triadicos: [163],
		Adjacentes: [162, 163, 164],
	},
	{
		Id: 6839,
		CodigoHtml: "D9DECC",
		Nombre: "Grassy Glade",
		ColorNum: "0433",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 163,
		colorR: 217,
		colorG: 222,
		colorB: 204,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [163, 113],
		Triadicos: [163],
		Adjacentes: [162, 163, 164],
	},
	{
		Id: 6840,
		CodigoHtml: "C2CBB3",
		Nombre: "Lush Meadow",
		ColorNum: "0434",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 163,
		colorR: 194,
		colorG: 203,
		colorB: 179,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [163, 113],
		Triadicos: [163],
		Adjacentes: [162, 163, 164],
	},
	{
		Id: 6841,
		CodigoHtml: "9DA691",
		Nombre: "Big Fish",
		ColorNum: "0435",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 163,
		colorR: 157,
		colorG: 166,
		colorB: 145,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [163, 113],
		Triadicos: [163],
		Adjacentes: [162, 163, 164],
	},
	{
		Id: 6842,
		CodigoHtml: "878F77",
		Nombre: "Fern Gully",
		ColorNum: "0436",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 163,
		colorR: 135,
		colorG: 143,
		colorB: 119,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [163, 113],
		Triadicos: [163],
		Adjacentes: [162, 163, 164],
	},
	{
		Id: 6843,
		CodigoHtml: "767F67",
		Nombre: "Bowling Green",
		ColorNum: "0437",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 163,
		colorR: 118,
		colorG: 127,
		colorB: 103,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [163, 113],
		Triadicos: [163],
		Adjacentes: [162, 163, 164],
	},
	{
		Id: 6844,
		CodigoHtml: "616650",
		Nombre: "Delicious",
		ColorNum: "0438",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 163,
		colorR: 97,
		colorG: 102,
		colorB: 80,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [163, 113],
		Triadicos: [163],
		Adjacentes: [162, 163, 164],
	},
	{
		Id: 6845,
		CodigoHtml: "E2E5DE",
		Nombre: "Numero Uno",
		ColorNum: "0439",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 164,
		colorR: 226,
		colorG: 229,
		colorB: 222,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [164, 114],
		Triadicos: [164],
		Adjacentes: [163, 164, 165],
	},
	{
		Id: 6846,
		CodigoHtml: "CED2CB",
		Nombre: "Pale Loden",
		ColorNum: "0440",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 164,
		colorR: 206,
		colorG: 210,
		colorB: 203,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [164, 114],
		Triadicos: [164],
		Adjacentes: [163, 164, 165],
	},
	{
		Id: 6847,
		CodigoHtml: "AEB5AD",
		Nombre: "Slate Stone",
		ColorNum: "0441",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 164,
		colorR: 174,
		colorG: 181,
		colorB: 173,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [164, 114],
		Triadicos: [164],
		Adjacentes: [163, 164, 165],
	},
	{
		Id: 6848,
		CodigoHtml: "888F85",
		Nombre: "Dowager",
		ColorNum: "0442",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 164,
		colorR: 136,
		colorG: 143,
		colorB: 133,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [164, 114],
		Triadicos: [164],
		Adjacentes: [163, 164, 165],
	},
	{
		Id: 6849,
		CodigoHtml: "666559",
		Nombre: "Serpentine",
		ColorNum: "0443",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 164,
		colorR: 102,
		colorG: 101,
		colorB: 89,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [164, 114],
		Triadicos: [164],
		Adjacentes: [163, 164, 165],
	},
	{
		Id: 6850,
		CodigoHtml: "5D594D",
		Nombre: "Grime",
		ColorNum: "0444",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 164,
		colorR: 93,
		colorG: 89,
		colorB: 77,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [164, 114],
		Triadicos: [164],
		Adjacentes: [163, 164, 165],
	},
	{
		Id: 6851,
		CodigoHtml: "575146",
		Nombre: "Deep Shadow",
		ColorNum: "0445",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 164,
		colorR: 87,
		colorG: 81,
		colorB: 70,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [164, 114],
		Triadicos: [164],
		Adjacentes: [163, 164, 165],
	},
	{
		Id: 6852,
		CodigoHtml: "E9ECE5",
		Nombre: "Sealskin Shadow",
		ColorNum: "0446",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 165,
		colorR: 233,
		colorG: 236,
		colorB: 229,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [165, 115],
		Triadicos: [165],
		Adjacentes: [164, 165, 166],
	},
	{
		Id: 6853,
		CodigoHtml: "D9DCD6",
		Nombre: "Metal Flake",
		ColorNum: "0447",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 165,
		colorR: 217,
		colorG: 220,
		colorB: 214,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [165, 115],
		Triadicos: [165],
		Adjacentes: [164, 165, 166],
	},
	{
		Id: 6854,
		CodigoHtml: "BEC4BE",
		Nombre: "Ice Flow",
		ColorNum: "0448",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 165,
		colorR: 190,
		colorG: 196,
		colorB: 190,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [165, 115],
		Triadicos: [165],
		Adjacentes: [164, 165, 166],
	},
	{
		Id: 6855,
		CodigoHtml: "9CA299",
		Nombre: "Cannon Ball",
		ColorNum: "0449",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 165,
		colorR: 156,
		colorG: 162,
		colorB: 153,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [165, 115],
		Triadicos: [165],
		Adjacentes: [164, 165, 166],
	},
	{
		Id: 6856,
		CodigoHtml: "737367",
		Nombre: "River God",
		ColorNum: "0450",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 165,
		colorR: 115,
		colorG: 115,
		colorB: 103,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [165, 115],
		Triadicos: [165],
		Adjacentes: [164, 165, 166],
	},
	{
		Id: 6857,
		CodigoHtml: "5E5C50",
		Nombre: "Evening Dove",
		ColorNum: "0451",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 165,
		colorR: 94,
		colorG: 92,
		colorB: 80,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [165, 115],
		Triadicos: [165],
		Adjacentes: [164, 165, 166],
	},
	{
		Id: 6858,
		CodigoHtml: "514D41",
		Nombre: "Ruggero Grey",
		ColorNum: "0452",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 165,
		colorR: 81,
		colorG: 77,
		colorB: 65,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [165, 115],
		Triadicos: [165],
		Adjacentes: [164, 165, 166],
	},
	{
		Id: 6859,
		CodigoHtml: "D6DCD1",
		Nombre: "Feather Fern",
		ColorNum: "0453",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 166,
		colorR: 214,
		colorG: 220,
		colorB: 209,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [166, 116],
		Triadicos: [166],
		Adjacentes: [165, 166, 167],
	},
	{
		Id: 6860,
		CodigoHtml: "CCD4C9",
		Nombre: "Marble Green",
		ColorNum: "0454",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 166,
		colorR: 204,
		colorG: 212,
		colorB: 201,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [166, 116],
		Triadicos: [166],
		Adjacentes: [165, 166, 167],
	},
	{
		Id: 6861,
		CodigoHtml: "C2CCBF",
		Nombre: "Favored One",
		ColorNum: "0455",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 166,
		colorR: 194,
		colorG: 204,
		colorB: 191,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [166, 116],
		Triadicos: [166],
		Adjacentes: [165, 166, 167],
	},
	{
		Id: 6862,
		CodigoHtml: "A9B6A8",
		Nombre: "Fair Maiden",
		ColorNum: "0456",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 166,
		colorR: 169,
		colorG: 182,
		colorB: 168,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [166, 116],
		Triadicos: [166],
		Adjacentes: [165, 166, 167],
	},
	{
		Id: 6863,
		CodigoHtml: "97A697",
		Nombre: "Heavenly Garden",
		ColorNum: "0457",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 166,
		colorR: 151,
		colorG: 166,
		colorB: 151,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [166, 116],
		Triadicos: [166],
		Adjacentes: [165, 166, 167],
	},
	{
		Id: 6864,
		CodigoHtml: "758374",
		Nombre: "Malarca",
		ColorNum: "0458",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 166,
		colorR: 117,
		colorG: 131,
		colorB: 116,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [166, 116],
		Triadicos: [166],
		Adjacentes: [165, 166, 167],
	},
	{
		Id: 6865,
		CodigoHtml: "576256",
		Nombre: "Pleasant Hill",
		ColorNum: "0459",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 166,
		colorR: 87,
		colorG: 98,
		colorB: 86,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [166, 116],
		Triadicos: [166],
		Adjacentes: [165, 166, 167],
	},
	{
		Id: 6866,
		CodigoHtml: "DCDFD6",
		Nombre: "Just a Little",
		ColorNum: "0460",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 167,
		colorR: 220,
		colorG: 223,
		colorB: 214,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [167, 117],
		Triadicos: [167],
		Adjacentes: [166, 167, 168],
	},
	{
		Id: 6867,
		CodigoHtml: "CED6CD",
		Nombre: "Calm Breeze",
		ColorNum: "0461",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 167,
		colorR: 206,
		colorG: 214,
		colorB: 205,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [167, 117],
		Triadicos: [167],
		Adjacentes: [166, 167, 168],
	},
	{
		Id: 6868,
		CodigoHtml: "BEC9BF",
		Nombre: "Resting Place",
		ColorNum: "0462",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 167,
		colorR: 190,
		colorG: 201,
		colorB: 191,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [167, 117],
		Triadicos: [167],
		Adjacentes: [166, 167, 168],
	},
	{
		Id: 6869,
		CodigoHtml: "A8B5AC",
		Nombre: "Gaelic Garden",
		ColorNum: "0463",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 167,
		colorR: 168,
		colorG: 181,
		colorB: 172,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [167, 117],
		Triadicos: [167],
		Adjacentes: [166, 167, 168],
	},
	{
		Id: 6870,
		CodigoHtml: "93A39B",
		Nombre: "Kathleen's Garden",
		ColorNum: "0464",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 167,
		colorR: 147,
		colorG: 163,
		colorB: 155,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [167, 117],
		Triadicos: [167],
		Adjacentes: [166, 167, 168],
	},
	{
		Id: 6871,
		CodigoHtml: "6D7E75",
		Nombre: "Earthen Cheer",
		ColorNum: "0465",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 167,
		colorR: 109,
		colorG: 126,
		colorB: 117,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [167, 117],
		Triadicos: [167],
		Adjacentes: [166, 167, 168],
	},
	{
		Id: 6872,
		CodigoHtml: "525A53",
		Nombre: "Green Column",
		ColorNum: "0466",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 167,
		colorR: 82,
		colorG: 90,
		colorB: 83,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [167, 117],
		Triadicos: [167],
		Adjacentes: [166, 167, 168],
	},
	{
		Id: 6873,
		CodigoHtml: "E6EAE3",
		Nombre: "Sweet Ariel",
		ColorNum: "0467",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 168,
		colorR: 230,
		colorG: 234,
		colorB: 227,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [168, 118],
		Triadicos: [168],
		Adjacentes: [167, 168, 169],
	},
	{
		Id: 6874,
		CodigoHtml: "D9E3DD",
		Nombre: "Mellow Blue",
		ColorNum: "0468",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 168,
		colorR: 217,
		colorG: 227,
		colorB: 221,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [168, 118],
		Triadicos: [168],
		Adjacentes: [167, 168, 169],
	},
	{
		Id: 6875,
		CodigoHtml: "C1D3CD",
		Nombre: "Déjà Vu",
		ColorNum: "0469",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 168,
		colorR: 193,
		colorG: 211,
		colorB: 205,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [168, 118],
		Triadicos: [168],
		Adjacentes: [167, 168, 169],
	},
	{
		Id: 6876,
		CodigoHtml: "AFC3BF",
		Nombre: "Dreaming of the Day",
		ColorNum: "0470",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 168,
		colorR: 175,
		colorG: 195,
		colorB: 191,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [168, 118],
		Triadicos: [168],
		Adjacentes: [167, 168, 169],
	},
	{
		Id: 6877,
		CodigoHtml: "809696",
		Nombre: "Miracle Bay",
		ColorNum: "0471",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 168,
		colorR: 128,
		colorG: 150,
		colorB: 150,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [168, 118],
		Triadicos: [168],
		Adjacentes: [167, 168, 169],
	},
	{
		Id: 6878,
		CodigoHtml: "5F7472",
		Nombre: "Micropolis",
		ColorNum: "0472",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 168,
		colorR: 95,
		colorG: 116,
		colorB: 114,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [168, 118],
		Triadicos: [168],
		Adjacentes: [167, 168, 169],
	},
	{
		Id: 6879,
		CodigoHtml: "3C5052",
		Nombre: "Atlantic Waves",
		ColorNum: "0473",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 168,
		colorR: 60,
		colorG: 80,
		colorB: 82,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [168, 118],
		Triadicos: [168],
		Adjacentes: [167, 168, 169],
	},
	{
		Id: 6880,
		CodigoHtml: "D6DDD5",
		Nombre: "Raindance",
		ColorNum: "0474",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 169,
		colorR: 214,
		colorG: 221,
		colorB: 213,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [169, 119],
		Triadicos: [169],
		Adjacentes: [168, 169, 170],
	},
	{
		Id: 6881,
		CodigoHtml: "CAD2CD",
		Nombre: "Foggy Mist",
		ColorNum: "0475",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 169,
		colorR: 202,
		colorG: 210,
		colorB: 205,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [169, 119],
		Triadicos: [169],
		Adjacentes: [168, 169, 170],
	},
	{
		Id: 6882,
		CodigoHtml: "BBC6BF",
		Nombre: "Lighthouse View",
		ColorNum: "0476",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 169,
		colorR: 187,
		colorG: 198,
		colorB: 191,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [169, 119],
		Triadicos: [169],
		Adjacentes: [168, 169, 170],
	},
	{
		Id: 6883,
		CodigoHtml: "AEB8B3",
		Nombre: "Ocean Cruise",
		ColorNum: "0477",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 169,
		colorR: 174,
		colorG: 184,
		colorB: 179,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [169, 119],
		Triadicos: [169],
		Adjacentes: [168, 169, 170],
	},
	{
		Id: 6884,
		CodigoHtml: "929E9A",
		Nombre: "Singing in the Rain",
		ColorNum: "0478",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 169,
		colorR: 146,
		colorG: 158,
		colorB: 154,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [169, 119],
		Triadicos: [169],
		Adjacentes: [168, 169, 170],
	},
	{
		Id: 6885,
		CodigoHtml: "6E7673",
		Nombre: "Stairway to Heaven",
		ColorNum: "0479",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 169,
		colorR: 110,
		colorG: 118,
		colorB: 115,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [169, 119],
		Triadicos: [169],
		Adjacentes: [168, 169, 170],
	},
	{
		Id: 6886,
		CodigoHtml: "59615E",
		Nombre: "Deep Sea Shadow",
		ColorNum: "0480",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 169,
		colorR: 89,
		colorG: 97,
		colorB: 94,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [169, 119],
		Triadicos: [169],
		Adjacentes: [168, 169, 170],
	},
	{
		Id: 6887,
		CodigoHtml: "DBE4DC",
		Nombre: "Dream Catcher",
		ColorNum: "0481",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 170,
		colorR: 219,
		colorG: 228,
		colorB: 220,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [170, 120],
		Triadicos: [170],
		Adjacentes: [169, 170, 171],
	},
	{
		Id: 6888,
		CodigoHtml: "C9D6D1",
		Nombre: "Powdered Pool",
		ColorNum: "0482",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 170,
		colorR: 201,
		colorG: 214,
		colorB: 209,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [170, 120],
		Triadicos: [170],
		Adjacentes: [169, 170, 171],
	},
	{
		Id: 6889,
		CodigoHtml: "B2C3C2",
		Nombre: "Indulgence",
		ColorNum: "0483",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 170,
		colorR: 178,
		colorG: 195,
		colorB: 194,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [170, 120],
		Triadicos: [170],
		Adjacentes: [169, 170, 171],
	},
	{
		Id: 6890,
		CodigoHtml: "9EB1B0",
		Nombre: "Stormy Bay",
		ColorNum: "0484",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 170,
		colorR: 158,
		colorG: 177,
		colorB: 176,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [170, 120],
		Triadicos: [170],
		Adjacentes: [169, 170, 171],
	},
	{
		Id: 6891,
		CodigoHtml: "899F9F",
		Nombre: "North Beach Blue",
		ColorNum: "0485",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 170,
		colorR: 137,
		colorG: 159,
		colorB: 159,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [170, 120],
		Triadicos: [170],
		Adjacentes: [169, 170, 171],
	},
	{
		Id: 6892,
		CodigoHtml: "667879",
		Nombre: "Paradise City",
		ColorNum: "0486",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 170,
		colorR: 102,
		colorG: 120,
		colorB: 121,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [170, 120],
		Triadicos: [170],
		Adjacentes: [169, 170, 171],
	},
	{
		Id: 6893,
		CodigoHtml: "4F5D5D",
		Nombre: "Deep Space",
		ColorNum: "0487",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 170,
		colorR: 79,
		colorG: 93,
		colorB: 93,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [170, 120],
		Triadicos: [170],
		Adjacentes: [169, 170, 171],
	},
	{
		Id: 6894,
		CodigoHtml: "C5CECB",
		Nombre: "Luna Light",
		ColorNum: "0488",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 171,
		colorR: 197,
		colorG: 206,
		colorB: 203,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [171, 121],
		Triadicos: [171],
		Adjacentes: [170, 171, 172],
	},
	{
		Id: 6895,
		CodigoHtml: "B9C5C2",
		Nombre: "Whirlwind",
		ColorNum: "0489",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 171,
		colorR: 185,
		colorG: 197,
		colorB: 194,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [171, 121],
		Triadicos: [171],
		Adjacentes: [170, 171, 172],
	},
	{
		Id: 6896,
		CodigoHtml: "9FACAD",
		Nombre: "Beacon Fog",
		ColorNum: "0490",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 171,
		colorR: 159,
		colorG: 172,
		colorB: 173,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [171, 121],
		Triadicos: [171],
		Adjacentes: [170, 171, 172],
	},
	{
		Id: 6897,
		CodigoHtml: "839294",
		Nombre: "Smoky Day",
		ColorNum: "0491",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 171,
		colorR: 131,
		colorG: 146,
		colorB: 148,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [171, 121],
		Triadicos: [171],
		Adjacentes: [170, 171, 172],
	},
	{
		Id: 6898,
		CodigoHtml: "707E7D",
		Nombre: "Polar Pond",
		ColorNum: "0492",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 171,
		colorR: 112,
		colorG: 126,
		colorB: 125,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [171, 121],
		Triadicos: [171],
		Adjacentes: [170, 171, 172],
	},
	{
		Id: 6899,
		CodigoHtml: "626F70",
		Nombre: "Spacebox",
		ColorNum: "0493",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 171,
		colorR: 98,
		colorG: 111,
		colorB: 112,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [171, 121],
		Triadicos: [171],
		Adjacentes: [170, 171, 172],
	},
	{
		Id: 6900,
		CodigoHtml: "464B4B",
		Nombre: "Dark River",
		ColorNum: "0494",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 171,
		colorR: 70,
		colorG: 75,
		colorB: 75,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [171, 121],
		Triadicos: [171],
		Adjacentes: [170, 171, 172],
	},
	{
		Id: 6901,
		CodigoHtml: "D9E4DF",
		Nombre: "Breakwaters",
		ColorNum: "0495",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 172,
		colorR: 217,
		colorG: 228,
		colorB: 223,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [172, 122],
		Triadicos: [172],
		Adjacentes: [171, 172, 173],
	},
	{
		Id: 6902,
		CodigoHtml: "C1D2D1",
		Nombre: "Cape Hope",
		ColorNum: "0496",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 172,
		colorR: 193,
		colorG: 210,
		colorB: 209,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [172, 122],
		Triadicos: [172],
		Adjacentes: [171, 172, 173],
	},
	{
		Id: 6903,
		CodigoHtml: "B3C7C8",
		Nombre: "Restful Retreat",
		ColorNum: "0497",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 172,
		colorR: 179,
		colorG: 199,
		colorB: 200,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [172, 122],
		Triadicos: [172],
		Adjacentes: [171, 172, 173],
	},
	{
		Id: 6904,
		CodigoHtml: "96AEB2",
		Nombre: "Wonder Land",
		ColorNum: "0498",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 172,
		colorR: 150,
		colorG: 174,
		colorB: 178,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [172, 122],
		Triadicos: [172],
		Adjacentes: [171, 172, 173],
	},
	{
		Id: 6905,
		CodigoHtml: "839CA2",
		Nombre: "Ocean Melody",
		ColorNum: "0499",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 172,
		colorR: 131,
		colorG: 156,
		colorB: 162,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [172, 122],
		Triadicos: [172],
		Adjacentes: [171, 172, 173],
	},
	{
		Id: 6906,
		CodigoHtml: "60757A",
		Nombre: "Bowman Blue",
		ColorNum: "0500",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 172,
		colorR: 96,
		colorG: 117,
		colorB: 122,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [172, 122],
		Triadicos: [172],
		Adjacentes: [171, 172, 173],
	},
	{
		Id: 6907,
		CodigoHtml: "485559",
		Nombre: "Calm Interlude",
		ColorNum: "0501",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 172,
		colorR: 72,
		colorG: 85,
		colorB: 89,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [172, 122],
		Triadicos: [172],
		Adjacentes: [171, 172, 173],
	},
	{
		Id: 6908,
		CodigoHtml: "D7DCDD",
		Nombre: "Ocean Crest",
		ColorNum: "0502",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 173,
		colorR: 215,
		colorG: 220,
		colorB: 221,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [173, 123],
		Triadicos: [173],
		Adjacentes: [172, 173, 174],
	},
	{
		Id: 6909,
		CodigoHtml: "BEC9CE",
		Nombre: "Water Droplet",
		ColorNum: "0503",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 173,
		colorR: 190,
		colorG: 201,
		colorB: 206,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [173, 123],
		Triadicos: [173],
		Adjacentes: [172, 173, 174],
	},
	{
		Id: 6910,
		CodigoHtml: "A0B2BC",
		Nombre: "In the Blue",
		ColorNum: "0504",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 173,
		colorR: 160,
		colorG: 178,
		colorB: 188,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [173, 123],
		Triadicos: [173],
		Adjacentes: [172, 173, 174],
	},
	{
		Id: 6911,
		CodigoHtml: "8E9CA7",
		Nombre: "In Good Taste",
		ColorNum: "0505",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 173,
		colorR: 142,
		colorG: 156,
		colorB: 167,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [173, 123],
		Triadicos: [173],
		Adjacentes: [172, 173, 174],
	},
	{
		Id: 6912,
		CodigoHtml: "768691",
		Nombre: "Ocean Storms",
		ColorNum: "0506",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 173,
		colorR: 118,
		colorG: 134,
		colorB: 145,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [173, 123],
		Triadicos: [173],
		Adjacentes: [172, 173, 174],
	},
	{
		Id: 6913,
		CodigoHtml: "67737C",
		Nombre: "Deep Lagoon",
		ColorNum: "0507",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 173,
		colorR: 103,
		colorG: 115,
		colorB: 124,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [173, 123],
		Triadicos: [173],
		Adjacentes: [172, 173, 174],
	},
	{
		Id: 6914,
		CodigoHtml: "525356",
		Nombre: "Midnight Magic ",
		ColorNum: "0508",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 173,
		colorR: 82,
		colorG: 83,
		colorB: 86,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [173, 123],
		Triadicos: [173],
		Adjacentes: [172, 173, 174],
	},
	{
		Id: 6915,
		CodigoHtml: "E2E3E0",
		Nombre: "Beryl Pearl",
		ColorNum: "0509",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 174,
		colorR: 226,
		colorG: 227,
		colorB: 224,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [174, 124],
		Triadicos: [174],
		Adjacentes: [173, 174, 175],
	},
	{
		Id: 6916,
		CodigoHtml: "C9CCCE",
		Nombre: "Sacred Spring ",
		ColorNum: "0510",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 174,
		colorR: 201,
		colorG: 204,
		colorB: 206,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [174, 124],
		Triadicos: [174],
		Adjacentes: [173, 174, 175],
	},
	{
		Id: 6917,
		CodigoHtml: "ACB2B7",
		Nombre: "Cystern",
		ColorNum: "0511",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 174,
		colorR: 172,
		colorG: 178,
		colorB: 183,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [174, 124],
		Triadicos: [174],
		Adjacentes: [173, 174, 175],
	},
	{
		Id: 6918,
		CodigoHtml: "939BA4",
		Nombre: "Overcast Day",
		ColorNum: "0512",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 174,
		colorR: 147,
		colorG: 155,
		colorB: 164,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [174, 124],
		Triadicos: [174],
		Adjacentes: [173, 174, 175],
	},
	{
		Id: 6919,
		CodigoHtml: "6F767D",
		Nombre: "Backwater",
		ColorNum: "0513",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 174,
		colorR: 111,
		colorG: 118,
		colorB: 125,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [174, 124],
		Triadicos: [174],
		Adjacentes: [173, 174, 175],
	},
	{
		Id: 6920,
		CodigoHtml: "5D636B",
		Nombre: "Thunderstorm",
		ColorNum: "0514",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 174,
		colorR: 93,
		colorG: 99,
		colorB: 107,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [174, 124],
		Triadicos: [174],
		Adjacentes: [173, 174, 175],
	},
	{
		Id: 6921,
		CodigoHtml: "3E3F40",
		Nombre: "Silent Sea",
		ColorNum: "0515",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 174,
		colorR: 62,
		colorG: 63,
		colorB: 64,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [174, 124],
		Triadicos: [174],
		Adjacentes: [173, 174, 175],
	},
	{
		Id: 6922,
		CodigoHtml: "DDDCDB",
		Nombre: "Diamond Stud",
		ColorNum: "0516",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 175,
		colorR: 221,
		colorG: 220,
		colorB: 219,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [175, 125],
		Triadicos: [175],
		Adjacentes: [174, 175, 176],
	},
	{
		Id: 6923,
		CodigoHtml: "D1D0D1",
		Nombre: "Cool Elegance",
		ColorNum: "0517",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 175,
		colorR: 209,
		colorG: 208,
		colorB: 209,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [175, 125],
		Triadicos: [175],
		Adjacentes: [174, 175, 176],
	},
	{
		Id: 6924,
		CodigoHtml: "B9B9BE",
		Nombre: "Let It Rain",
		ColorNum: "0518",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 175,
		colorR: 185,
		colorG: 185,
		colorB: 190,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [175, 125],
		Triadicos: [175],
		Adjacentes: [174, 175, 176],
	},
	{
		Id: 6925,
		CodigoHtml: "A1A5AD",
		Nombre: "Baby Seal",
		ColorNum: "0519",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 175,
		colorR: 161,
		colorG: 165,
		colorB: 173,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [175, 125],
		Triadicos: [175],
		Adjacentes: [174, 175, 176],
	},
	{
		Id: 6926,
		CodigoHtml: "7F848F",
		Nombre: "Slate Tint",
		ColorNum: "0520",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 175,
		colorR: 127,
		colorG: 132,
		colorB: 143,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [175, 125],
		Triadicos: [175],
		Adjacentes: [174, 175, 176],
	},
	{
		Id: 6927,
		CodigoHtml: "71737C",
		Nombre: "Dolphin Dream",
		ColorNum: "0521",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 175,
		colorR: 113,
		colorG: 115,
		colorB: 124,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [175, 125],
		Triadicos: [175],
		Adjacentes: [174, 175, 176],
	},
	{
		Id: 6928,
		CodigoHtml: "48474B",
		Nombre: "Octavius",
		ColorNum: "0522",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 175,
		colorR: 72,
		colorG: 71,
		colorB: 75,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [175, 125],
		Triadicos: [175],
		Adjacentes: [174, 175, 176],
	},
	{
		Id: 6929,
		CodigoHtml: "E5E4DF",
		Nombre: "Felicity",
		ColorNum: "0523",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 176,
		colorR: 229,
		colorG: 228,
		colorB: 223,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [176, 126],
		Triadicos: [176],
		Adjacentes: [175, 176, 177],
	},
	{
		Id: 6930,
		CodigoHtml: "CCCECD",
		Nombre: "Nomadic Travels",
		ColorNum: "0524",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 176,
		colorR: 204,
		colorG: 206,
		colorB: 205,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [176, 126],
		Triadicos: [176],
		Adjacentes: [175, 176, 177],
	},
	{
		Id: 6931,
		CodigoHtml: "B8BCBC",
		Nombre: "Marseilles",
		ColorNum: "0525",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 176,
		colorR: 184,
		colorG: 188,
		colorB: 188,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [176, 126],
		Triadicos: [176],
		Adjacentes: [175, 176, 177],
	},
	{
		Id: 6932,
		CodigoHtml: "9DA3A7",
		Nombre: "Metropolis Mood",
		ColorNum: "0526",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 176,
		colorR: 157,
		colorG: 163,
		colorB: 167,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [176, 126],
		Triadicos: [176],
		Adjacentes: [175, 176, 177],
	},
	{
		Id: 6933,
		CodigoHtml: "848A8D",
		Nombre: "London Road",
		ColorNum: "0527",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 176,
		colorR: 132,
		colorG: 138,
		colorB: 141,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [176, 126],
		Triadicos: [176],
		Adjacentes: [175, 176, 177],
	},
	{
		Id: 6934,
		CodigoHtml: "696D6F",
		Nombre: "Greybeard",
		ColorNum: "0528",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 176,
		colorR: 105,
		colorG: 109,
		colorB: 111,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [176, 126],
		Triadicos: [176],
		Adjacentes: [175, 176, 177],
	},
	{
		Id: 6935,
		CodigoHtml: "474848",
		Nombre: "Black Licorice",
		ColorNum: "0529",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 176,
		colorR: 71,
		colorG: 72,
		colorB: 72,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [176, 126],
		Triadicos: [176],
		Adjacentes: [175, 176, 177],
	},
	{
		Id: 6936,
		CodigoHtml: "D5D4D0",
		Nombre: "Metro",
		ColorNum: "0530",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 177,
		colorR: 213,
		colorG: 212,
		colorB: 208,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [177, 127],
		Triadicos: [177],
		Adjacentes: [176, 177, 178],
	},
	{
		Id: 6937,
		CodigoHtml: "C9C8C4",
		Nombre: "Snowglory",
		ColorNum: "0531",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 177,
		colorR: 201,
		colorG: 200,
		colorB: 196,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [177, 127],
		Triadicos: [177],
		Adjacentes: [176, 177, 178],
	},
	{
		Id: 6938,
		CodigoHtml: "B9B8B5",
		Nombre: "Rand Moon",
		ColorNum: "0532",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 177,
		colorR: 185,
		colorG: 184,
		colorB: 181,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [177, 127],
		Triadicos: [177],
		Adjacentes: [176, 177, 178],
	},
	{
		Id: 6939,
		CodigoHtml: "A2A3A2",
		Nombre: "Techile",
		ColorNum: "0533",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 177,
		colorR: 162,
		colorG: 163,
		colorB: 162,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [177, 127],
		Triadicos: [177],
		Adjacentes: [176, 177, 178],
	},
	{
		Id: 6940,
		CodigoHtml: "848586",
		Nombre: "Subtle Shadow",
		ColorNum: "0534",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 177,
		colorR: 132,
		colorG: 133,
		colorB: 134,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [177, 127],
		Triadicos: [177],
		Adjacentes: [176, 177, 178],
	},
	{
		Id: 6941,
		CodigoHtml: "636463",
		Nombre: "Zen Retreat",
		ColorNum: "0535",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 177,
		colorR: 99,
		colorG: 100,
		colorB: 99,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [177, 127],
		Triadicos: [177],
		Adjacentes: [176, 177, 178],
	},
	{
		Id: 6942,
		CodigoHtml: "4F4E4C",
		Nombre: "Subway",
		ColorNum: "0536",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 177,
		colorR: 79,
		colorG: 78,
		colorB: 76,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [177, 127],
		Triadicos: [177],
		Adjacentes: [176, 177, 178],
	},
	{
		Id: 6943,
		CodigoHtml: "E1DFD9",
		Nombre: "Dove's Wing",
		ColorNum: "0537",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 178,
		colorR: 225,
		colorG: 223,
		colorB: 217,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [178, 128],
		Triadicos: [178],
		Adjacentes: [177, 178, 179],
	},
	{
		Id: 6944,
		CodigoHtml: "D7D3CE",
		Nombre: "Santo",
		ColorNum: "0538",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 178,
		colorR: 215,
		colorG: 211,
		colorB: 206,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [178, 128],
		Triadicos: [178],
		Adjacentes: [177, 178, 179],
	},
	{
		Id: 6945,
		CodigoHtml: "C7C5C1",
		Nombre: "Place of Dust",
		ColorNum: "0539",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 178,
		colorR: 199,
		colorG: 197,
		colorB: 193,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [178, 128],
		Triadicos: [178],
		Adjacentes: [177, 178, 179],
	},
	{
		Id: 6946,
		CodigoHtml: "B5B3B0",
		Nombre: "Praise Giving",
		ColorNum: "0540",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 178,
		colorR: 181,
		colorG: 179,
		colorB: 176,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [178, 128],
		Triadicos: [178],
		Adjacentes: [177, 178, 179],
	},
	{
		Id: 6947,
		CodigoHtml: "A0A09F",
		Nombre: "Smoky Tone",
		ColorNum: "0541",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 178,
		colorR: 160,
		colorG: 160,
		colorB: 159,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [178, 128],
		Triadicos: [178],
		Adjacentes: [177, 178, 179],
	},
	{
		Id: 6948,
		CodigoHtml: "868585",
		Nombre: "Captain Nemo",
		ColorNum: "0542",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 178,
		colorR: 134,
		colorG: 133,
		colorB: 133,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [178, 128],
		Triadicos: [178],
		Adjacentes: [177, 178, 179],
	},
	{
		Id: 6949,
		CodigoHtml: "4C4946",
		Nombre: "November Storms",
		ColorNum: "0543",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 178,
		colorR: 76,
		colorG: 73,
		colorB: 70,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [178, 128],
		Triadicos: [178],
		Adjacentes: [177, 178, 179],
	},
	{
		Id: 6950,
		CodigoHtml: "E2DCD6",
		Nombre: "Venetian Wall",
		ColorNum: "0544",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 179,
		colorR: 226,
		colorG: 220,
		colorB: 214,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [179, 129],
		Triadicos: [179],
		Adjacentes: [178, 179, 180],
	},
	{
		Id: 6951,
		CodigoHtml: "D9D6D3",
		Nombre: "Old Grey Mare",
		ColorNum: "0545",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 179,
		colorR: 217,
		colorG: 214,
		colorB: 211,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [179, 129],
		Triadicos: [179],
		Adjacentes: [178, 179, 180],
	},
	{
		Id: 6952,
		CodigoHtml: "C8C8C6",
		Nombre: "Paternoster",
		ColorNum: "0546",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 179,
		colorR: 200,
		colorG: 200,
		colorB: 198,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [179, 129],
		Triadicos: [179],
		Adjacentes: [178, 179, 180],
	},
	{
		Id: 6953,
		CodigoHtml: "B1AEAE",
		Nombre: "Tin Man",
		ColorNum: "0547",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 179,
		colorR: 177,
		colorG: 174,
		colorB: 174,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [179, 129],
		Triadicos: [179],
		Adjacentes: [178, 179, 180],
	},
	{
		Id: 6954,
		CodigoHtml: "9F9C9C",
		Nombre: "Jet Gray",
		ColorNum: "0548",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 179,
		colorR: 159,
		colorG: 156,
		colorB: 156,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [179, 129],
		Triadicos: [179],
		Adjacentes: [178, 179, 180],
	},
	{
		Id: 6955,
		CodigoHtml: "7A7473",
		Nombre: "Emu",
		ColorNum: "0549",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 179,
		colorR: 122,
		colorG: 116,
		colorB: 115,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [179, 129],
		Triadicos: [179],
		Adjacentes: [178, 179, 180],
	},
	{
		Id: 6956,
		CodigoHtml: "655A56",
		Nombre: "Gargoyle",
		ColorNum: "0550",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 179,
		colorR: 101,
		colorG: 90,
		colorB: 86,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [179, 129],
		Triadicos: [179],
		Adjacentes: [178, 179, 180],
	},
	{
		Id: 6957,
		CodigoHtml: "CBC6C3",
		Nombre: "Orchid Shadow",
		ColorNum: "0551",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 180,
		colorR: 203,
		colorG: 198,
		colorB: 195,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [180, 130],
		Triadicos: [180],
		Adjacentes: [179, 180, 181],
	},
	{
		Id: 6958,
		CodigoHtml: "BEB8B6",
		Nombre: "North Island",
		ColorNum: "0552",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 180,
		colorR: 190,
		colorG: 184,
		colorB: 182,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [180, 130],
		Triadicos: [180],
		Adjacentes: [179, 180, 181],
	},
	{
		Id: 6959,
		CodigoHtml: "B5AFAB",
		Nombre: "Smoky Wings",
		ColorNum: "0553",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 180,
		colorR: 181,
		colorG: 175,
		colorB: 171,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [180, 130],
		Triadicos: [180],
		Adjacentes: [179, 180, 181],
	},
	{
		Id: 6960,
		CodigoHtml: "9C9898",
		Nombre: "Sparrow",
		ColorNum: "0554",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 180,
		colorR: 156,
		colorG: 152,
		colorB: 152,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [180, 130],
		Triadicos: [180],
		Adjacentes: [179, 180, 181],
	},
	{
		Id: 6961,
		CodigoHtml: "807C7D",
		Nombre: "Ocean Frigate",
		ColorNum: "0555",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 180,
		colorR: 128,
		colorG: 124,
		colorB: 125,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [180, 130],
		Triadicos: [180],
		Adjacentes: [179, 180, 181],
	},
	{
		Id: 6962,
		CodigoHtml: "67615F",
		Nombre: "Smokescreen",
		ColorNum: "0556",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 180,
		colorR: 103,
		colorG: 97,
		colorB: 95,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [180, 130],
		Triadicos: [180],
		Adjacentes: [179, 180, 181],
	},
	{
		Id: 6963,
		CodigoHtml: "524B48",
		Nombre: "Evermore",
		ColorNum: "0557",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 180,
		colorR: 82,
		colorG: 75,
		colorB: 72,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [180, 130],
		Triadicos: [180],
		Adjacentes: [179, 180, 181],
	},
	{
		Id: 6964,
		CodigoHtml: "EFEBE1",
		Nombre: "Queen Anne's Lace",
		ColorNum: "0558",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 181,
		colorR: 239,
		colorG: 235,
		colorB: 225,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [181, 131],
		Triadicos: [181],
		Adjacentes: [180, 181, 182],
	},
	{
		Id: 6965,
		CodigoHtml: "DDD6CB",
		Nombre: "Always Neutral",
		ColorNum: "0559",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 181,
		colorR: 221,
		colorG: 214,
		colorB: 203,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [181, 131],
		Triadicos: [181],
		Adjacentes: [180, 181, 182],
	},
	{
		Id: 6966,
		CodigoHtml: "D4CABE",
		Nombre: "Awakening",
		ColorNum: "0560",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 181,
		colorR: 212,
		colorG: 202,
		colorB: 190,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [181, 131],
		Triadicos: [181],
		Adjacentes: [180, 181, 182],
	},
	{
		Id: 6967,
		CodigoHtml: "B0A89E",
		Nombre: "In the Hills",
		ColorNum: "0561",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 181,
		colorR: 176,
		colorG: 168,
		colorB: 158,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [181, 131],
		Triadicos: [181],
		Adjacentes: [180, 181, 182],
	},
	{
		Id: 6968,
		CodigoHtml: "969088",
		Nombre: "Sultry Castle",
		ColorNum: "0562",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 181,
		colorR: 150,
		colorG: 144,
		colorB: 136,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [181, 131],
		Triadicos: [181],
		Adjacentes: [180, 181, 182],
	},
	{
		Id: 6969,
		CodigoHtml: "857E74",
		Nombre: "Vigilant",
		ColorNum: "0563",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 181,
		colorR: 133,
		colorG: 126,
		colorB: 116,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [181, 131],
		Triadicos: [181],
		Adjacentes: [180, 181, 182],
	},
	{
		Id: 6970,
		CodigoHtml: "645B4E",
		Nombre: "Bad Hair Day",
		ColorNum: "0564",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 181,
		colorR: 100,
		colorG: 91,
		colorB: 78,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [181, 131],
		Triadicos: [181],
		Adjacentes: [180, 181, 182],
	},
	{
		Id: 6971,
		CodigoHtml: "E9E3DA",
		Nombre: "Elk Skin",
		ColorNum: "0565",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 182,
		colorR: 233,
		colorG: 227,
		colorB: 218,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [182, 132],
		Triadicos: [182],
		Adjacentes: [181, 182, 183],
	},
	{
		Id: 6972,
		CodigoHtml: "CFC7BD",
		Nombre: "Sheepskin",
		ColorNum: "0566",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 182,
		colorR: 207,
		colorG: 199,
		colorB: 189,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [182, 132],
		Triadicos: [182],
		Adjacentes: [181, 182, 183],
	},
	{
		Id: 6973,
		CodigoHtml: "C6BEB2",
		Nombre: "Hearthstone",
		ColorNum: "0567",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 182,
		colorR: 198,
		colorG: 190,
		colorB: 178,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [182, 132],
		Triadicos: [182],
		Adjacentes: [181, 182, 183],
	},
	{
		Id: 6974,
		CodigoHtml: "A7A198",
		Nombre: "Cloudy Today",
		ColorNum: "0568",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 182,
		colorR: 167,
		colorG: 161,
		colorB: 152,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [182, 132],
		Triadicos: [182],
		Adjacentes: [181, 182, 183],
	},
	{
		Id: 6975,
		CodigoHtml: "867F73",
		Nombre: "Fireplace Mantel",
		ColorNum: "0569",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 182,
		colorR: 134,
		colorG: 127,
		colorB: 115,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [182, 132],
		Triadicos: [182],
		Adjacentes: [181, 182, 183],
	},
	{
		Id: 6976,
		CodigoHtml: "756D63",
		Nombre: "Grey Locks",
		ColorNum: "0570",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 182,
		colorR: 117,
		colorG: 109,
		colorB: 99,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [182, 132],
		Triadicos: [182],
		Adjacentes: [181, 182, 183],
	},
	{
		Id: 6977,
		CodigoHtml: "5A554A",
		Nombre: "Terra Pin",
		ColorNum: "0571",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 182,
		colorR: 90,
		colorG: 85,
		colorB: 74,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [182, 132],
		Triadicos: [182],
		Adjacentes: [181, 182, 183],
	},
	{
		Id: 6978,
		CodigoHtml: "D5D2C8",
		Nombre: "Power Lunch",
		ColorNum: "0572",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 183,
		colorR: 213,
		colorG: 210,
		colorB: 200,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [183, 133],
		Triadicos: [183],
		Adjacentes: [182, 183, 184],
	},
	{
		Id: 6979,
		CodigoHtml: "C2BCB0",
		Nombre: "Chintz",
		ColorNum: "0573",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 183,
		colorR: 194,
		colorG: 188,
		colorB: 176,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [183, 133],
		Triadicos: [183],
		Adjacentes: [182, 183, 184],
	},
	{
		Id: 6980,
		CodigoHtml: "A7A59D",
		Nombre: "Shark Fin",
		ColorNum: "0574",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 183,
		colorR: 167,
		colorG: 165,
		colorB: 157,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [183, 133],
		Triadicos: [183],
		Adjacentes: [182, 183, 184],
	},
	{
		Id: 6981,
		CodigoHtml: "8B8981",
		Nombre: "Greystoke",
		ColorNum: "0575",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 183,
		colorR: 139,
		colorG: 137,
		colorB: 129,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [183, 133],
		Triadicos: [183],
		Adjacentes: [182, 183, 184],
	},
	{
		Id: 6982,
		CodigoHtml: "7A776C",
		Nombre: "Stormy Weather",
		ColorNum: "0576",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 183,
		colorR: 122,
		colorG: 119,
		colorB: 108,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [183, 133],
		Triadicos: [183],
		Adjacentes: [182, 183, 184],
	},
	{
		Id: 6983,
		CodigoHtml: "737065",
		Nombre: "King Fischer",
		ColorNum: "0577",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 183,
		colorR: 115,
		colorG: 112,
		colorB: 101,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [183, 133],
		Triadicos: [183],
		Adjacentes: [182, 183, 184],
	},
	{
		Id: 6984,
		CodigoHtml: "565148",
		Nombre: "Philosophically Speaking",
		ColorNum: "0578",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 183,
		colorR: 86,
		colorG: 81,
		colorB: 72,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [183, 133],
		Triadicos: [183],
		Adjacentes: [182, 183, 184],
	},
	{
		Id: 6985,
		CodigoHtml: "D9D9D2",
		Nombre: "Turkish Tower",
		ColorNum: "0579",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 184,
		colorR: 217,
		colorG: 217,
		colorB: 210,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [184, 134],
		Triadicos: [184],
		Adjacentes: [183, 184, 182],
	},
	{
		Id: 6986,
		CodigoHtml: "C4C5BC",
		Nombre: "Rippled Rock",
		ColorNum: "0580",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 184,
		colorR: 196,
		colorG: 197,
		colorB: 188,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [184, 134],
		Triadicos: [184],
		Adjacentes: [183, 184, 182],
	},
	{
		Id: 6987,
		CodigoHtml: "B6B9B0",
		Nombre: "Fossilized",
		ColorNum: "0581",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 184,
		colorR: 182,
		colorG: 185,
		colorB: 176,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [184, 134],
		Triadicos: [184],
		Adjacentes: [183, 184, 182],
	},
	{
		Id: 6988,
		CodigoHtml: "858984",
		Nombre: "Paved Path",
		ColorNum: "0582",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 184,
		colorR: 133,
		colorG: 137,
		colorB: 132,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [184, 134],
		Triadicos: [184],
		Adjacentes: [183, 184, 182],
	},
	{
		Id: 6989,
		CodigoHtml: "73766F",
		Nombre: "Billowing Smoke",
		ColorNum: "0583",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 184,
		colorR: 115,
		colorG: 118,
		colorB: 111,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [184, 134],
		Triadicos: [184],
		Adjacentes: [183, 184, 182],
	},
	{
		Id: 6990,
		CodigoHtml: "666963",
		Nombre: "Tornado Wind",
		ColorNum: "0584",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 184,
		colorR: 102,
		colorG: 105,
		colorB: 99,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [184, 134],
		Triadicos: [184],
		Adjacentes: [183, 184, 182],
	},
	{
		Id: 6991,
		CodigoHtml: "54544E",
		Nombre: "Oyster Catch",
		ColorNum: "0585",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 184,
		colorR: 84,
		colorG: 84,
		colorB: 78,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [184, 134],
		Triadicos: [184],
		Adjacentes: [183, 184, 182],
	},
	{
		Id: 6992,
		CodigoHtml: "EEE8DD",
		Nombre: "Hint of Vanilla",
		ColorNum: "0001",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 185,
		colorR: 238,
		colorG: 232,
		colorB: 221,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [185],
		Triadicos: [185],
		Adjacentes: [185],
	},
	{
		Id: 6993,
		CodigoHtml: "E9E5D8",
		Nombre: "Elusive White",
		ColorNum: "0002",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 185,
		colorR: 233,
		colorG: 229,
		colorB: 216,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [185],
		Triadicos: [185],
		Adjacentes: [185],
	},
	{
		Id: 6994,
		CodigoHtml: "F1EBE1",
		Nombre: "Nilla Vanilla",
		ColorNum: "0003",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 185,
		colorR: 241,
		colorG: 235,
		colorB: 225,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [185],
		Triadicos: [185],
		Adjacentes: [185],
	},
	{
		Id: 6995,
		CodigoHtml: "F0EBE1",
		Nombre: "Child of Heaven",
		ColorNum: "0004",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 185,
		colorR: 240,
		colorG: 235,
		colorB: 225,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [185],
		Triadicos: [185],
		Adjacentes: [185],
	},
	{
		Id: 6996,
		CodigoHtml: "F3EBDC",
		Nombre: "Kiara",
		ColorNum: "0005",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 186,
		colorR: 243,
		colorG: 235,
		colorB: 220,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [186],
		Triadicos: [186],
		Adjacentes: [186],
	},
	{
		Id: 6997,
		CodigoHtml: "E9E0CF",
		Nombre: "Little Dove",
		ColorNum: "0006",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 186,
		colorR: 233,
		colorG: 224,
		colorB: 207,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [186],
		Triadicos: [186],
		Adjacentes: [186],
	},
	{
		Id: 6998,
		CodigoHtml: "F4EEDF",
		Nombre: "Cotton Ball",
		ColorNum: "0007",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 186,
		colorR: 244,
		colorG: 238,
		colorB: 223,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [186],
		Triadicos: [186],
		Adjacentes: [186],
	},
	{
		Id: 6999,
		CodigoHtml: "F3ECD9",
		Nombre: "Casa Blanca",
		ColorNum: "0008",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 186,
		colorR: 243,
		colorG: 236,
		colorB: 217,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [186],
		Triadicos: [186],
		Adjacentes: [186],
	},
	{
		Id: 7000,
		CodigoHtml: "F1EAD8",
		Nombre: "Chapel Wall",
		ColorNum: "0009",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 187,
		colorR: 241,
		colorG: 234,
		colorB: 216,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [187],
		Triadicos: [187],
		Adjacentes: [187],
	},
	{
		Id: 7001,
		CodigoHtml: "EEE4CF",
		Nombre: "Petticoat",
		ColorNum: "0010",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 187,
		colorR: 238,
		colorG: 228,
		colorB: 207,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [187],
		Triadicos: [187],
		Adjacentes: [187],
	},
	{
		Id: 7002,
		CodigoHtml: "F6F4EA",
		Nombre: "Sugar Dust",
		ColorNum: "0011",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 187,
		colorR: 246,
		colorG: 244,
		colorB: 234,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [187],
		Triadicos: [187],
		Adjacentes: [187],
	},
	{
		Id: 7003,
		CodigoHtml: "F4EFE3",
		Nombre: "Bunny Cake",
		ColorNum: "0012",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 187,
		colorR: 244,
		colorG: 239,
		colorB: 227,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [187],
		Triadicos: [187],
		Adjacentes: [187],
	},
	{
		Id: 7004,
		CodigoHtml: "F1E7CC",
		Nombre: "Sphere",
		ColorNum: "0013",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 188,
		colorR: 241,
		colorG: 231,
		colorB: 204,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [188],
		Triadicos: [188],
		Adjacentes: [188],
	},
	{
		Id: 7005,
		CodigoHtml: "EFE7D3",
		Nombre: "Twill",
		ColorNum: "0014",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 188,
		colorR: 239,
		colorG: 231,
		colorB: 211,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [188],
		Triadicos: [188],
		Adjacentes: [188],
	},
	{
		Id: 7006,
		CodigoHtml: "F7F2E1",
		Nombre: "White Glove",
		ColorNum: "0015",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 188,
		colorR: 247,
		colorG: 242,
		colorB: 225,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [188],
		Triadicos: [188],
		Adjacentes: [188],
	},
	{
		Id: 7007,
		CodigoHtml: "F7F0DF",
		Nombre: "Pumice Stone",
		ColorNum: "0016",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 188,
		colorR: 247,
		colorG: 240,
		colorB: 223,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [188],
		Triadicos: [188],
		Adjacentes: [188],
	},
	{
		Id: 7008,
		CodigoHtml: "EBE8E1",
		Nombre: "Luna Moon",
		ColorNum: "0017",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 189,
		colorR: 235,
		colorG: 232,
		colorB: 225,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [189],
		Triadicos: [189],
		Adjacentes: [189],
	},
	{
		Id: 7009,
		CodigoHtml: "E3E0D7",
		Nombre: " Dove White",
		ColorNum: "0018",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 189,
		colorR: 227,
		colorG: 224,
		colorB: 215,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [189],
		Triadicos: [189],
		Adjacentes: [189],
	},
	{
		Id: 7010,
		CodigoHtml: "E9E7E0",
		Nombre: "Mystic Fog",
		ColorNum: "0019",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 189,
		colorR: 233,
		colorG: 231,
		colorB: 224,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [189],
		Triadicos: [189],
		Adjacentes: [189],
	},
	{
		Id: 7011,
		CodigoHtml: "E4E0D6",
		Nombre: "Bonaire",
		ColorNum: "0020",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 189,
		colorR: 228,
		colorG: 224,
		colorB: 214,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [189],
		Triadicos: [189],
		Adjacentes: [189],
	},
	{
		Id: 7012,
		CodigoHtml: "E1E2DD",
		Nombre: "Barely White",
		ColorNum: "0021",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 190,
		colorR: 225,
		colorG: 226,
		colorB: 221,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [190],
		Triadicos: [190],
		Adjacentes: [190],
	},
	{
		Id: 7013,
		CodigoHtml: "DFDEDA",
		Nombre: "White Kitten",
		ColorNum: "0022",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 190,
		colorR: 223,
		colorG: 222,
		colorB: 218,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [190],
		Triadicos: [190],
		Adjacentes: [190],
	},
	{
		Id: 7014,
		CodigoHtml: "ECF0E9",
		Nombre: "Shell Tint",
		ColorNum: "0023",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 190,
		colorR: 236,
		colorG: 240,
		colorB: 233,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [190],
		Triadicos: [190],
		Adjacentes: [190],
	},
	{
		Id: 7015,
		CodigoHtml: "E7E7DF",
		Nombre: "Just About White",
		ColorNum: "0024",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 190,
		colorR: 231,
		colorG: 231,
		colorB: 223,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [190],
		Triadicos: [190],
		Adjacentes: [190],
	},
	{
		Id: 7016,
		CodigoHtml: "E9E7DD",
		Nombre: "Prismatic Pearl",
		ColorNum: "0025",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 191,
		colorR: 233,
		colorG: 231,
		colorB: 221,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [191],
		Triadicos: [191],
		Adjacentes: [191],
	},
	{
		Id: 7017,
		CodigoHtml: "DBD4C8",
		Nombre: "Moon Drop",
		ColorNum: "0026",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 191,
		colorR: 219,
		colorG: 212,
		colorB: 200,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [191],
		Triadicos: [191],
		Adjacentes: [191],
	},
	{
		Id: 7018,
		CodigoHtml: "E7E3D9",
		Nombre: "White Fence",
		ColorNum: "0027",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 191,
		colorR: 231,
		colorG: 227,
		colorB: 217,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [191],
		Triadicos: [191],
		Adjacentes: [191],
	},
	{
		Id: 7019,
		CodigoHtml: "E1E0D6",
		Nombre: "Bannister White",
		ColorNum: "0028",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 191,
		colorR: 225,
		colorG: 224,
		colorB: 214,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [191],
		Triadicos: [191],
		Adjacentes: [191],
	},
	{
		Id: 7020,
		CodigoHtml: "E5E2DA",
		Nombre: "Cherish is the Word",
		ColorNum: "0029",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 192,
		colorR: 229,
		colorG: 226,
		colorB: 218,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [192],
		Triadicos: [192],
		Adjacentes: [192],
	},
	{
		Id: 7021,
		CodigoHtml: "D7D7CD",
		Nombre: "Lady Nicole",
		ColorNum: "0030",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 192,
		colorR: 215,
		colorG: 215,
		colorB: 205,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [192],
		Triadicos: [192],
		Adjacentes: [192],
	},
	{
		Id: 7022,
		CodigoHtml: "d3ce22\r\n",
		Nombre: "Lanish Lemon\r\n",
		ColorNum: "3567",
		EsPrincipal: false,
		GroupNum: 5,
		SubGroupNum: 201,
		colorR: 211,
		colorG: 206,
		colorB: 34,
		MonoCromaticos: [44, 45, 46, 47, 48, 49, 50, 201],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 7023,
		CodigoHtml: "E8E9E1",
		Nombre: "Ice Dream",
		ColorNum: "0032",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 192,
		colorR: 232,
		colorG: 233,
		colorB: 225,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [192],
		Triadicos: [192],
		Adjacentes: [192],
	},
	{
		Id: 8363,
		CodigoHtml: "b3e4e2",
		Nombre: "Aqua Pura",
		ColorNum: "3919",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 193,
		colorR: 179,
		colorG: 228,
		colorB: 226,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8364,
		CodigoHtml: "71bcb9",
		Nombre: "Into the Mystic",
		ColorNum: "3922",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 193,
		colorR: 113,
		colorG: 188,
		colorB: 185,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8365,
		CodigoHtml: "717686",
		Nombre: "Fragant Flower",
		ColorNum: "3215",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 193,
		colorR: 113,
		colorG: 118,
		colorB: 134,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8366,
		CodigoHtml: "575c7c",
		Nombre: "Whirlwind",
		ColorNum: "3168",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 193,
		colorR: 87,
		colorG: 92,
		colorB: 124,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8367,
		CodigoHtml: "3187bc",
		Nombre: "Pristine Blue",
		ColorNum: "3050",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 193,
		colorR: 49,
		colorG: 135,
		colorB: 188,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8368,
		CodigoHtml: "614d86",
		Nombre: "Night´s Cloak",
		ColorNum: "3372",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 193,
		colorR: 97,
		colorG: 77,
		colorB: 134,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8369,
		CodigoHtml: "3b4ea1",
		Nombre: "Deep Sea Reef",
		ColorNum: "3120",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 193,
		colorR: 59,
		colorG: 78,
		colorB: 161,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8370,
		CodigoHtml: "38340b",
		Nombre: "Big Blue Star",
		ColorNum: "3274",
		EsPrincipal: false,
		GroupNum: 9,
		SubGroupNum: 194,
		colorR: 56,
		colorG: 52,
		colorB: 118,
		MonoCromaticos: [72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 193, 194],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8371,
		CodigoHtml: "7d865e",
		Nombre: "Lucky Four Leaf",
		ColorNum: "3727",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 195,
		colorR: 125,
		colorG: 134,
		colorB: 94,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8372,
		CodigoHtml: "36a5bc",
		Nombre: "Tuscana Blue",
		ColorNum: "3078",
		EsPrincipal: false,
		GroupNum: 7,
		SubGroupNum: 195,
		colorR: 54,
		colorG: 165,
		colorB: 188,
		MonoCromaticos: [57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 195],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8373,
		CodigoHtml: "bc8862",
		Nombre: "Harvest Time",
		ColorNum: "3411",
		EsPrincipal: false,
		GroupNum: 11,
		SubGroupNum: 196,
		colorR: 188,
		colorG: 136,
		colorB: 98,
		MonoCromaticos: [
			106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120,
			121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135,
			136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
			151, 152, 153, 154, 155, 196,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8374,
		CodigoHtml: "ffcca7",
		Nombre: "Morning Crescent",
		ColorNum: "3311",
		EsPrincipal: false,
		GroupNum: 3,
		SubGroupNum: 197,
		colorR: 255,
		colorG: 204,
		colorB: 167,
		MonoCromaticos: [
			13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 197,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8375,
		CodigoHtml: "e49ba3",
		Nombre: "Blooming in Pink",
		ColorNum: "3690",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 198,
		colorR: 228,
		colorG: 155,
		colorB: 163,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8376,
		CodigoHtml: "f14e16",
		Nombre: "Baccarat",
		ColorNum: "3045",
		EsPrincipal: false,
		GroupNum: 2,
		SubGroupNum: 198,
		colorR: 241,
		colorG: 78,
		colorB: 22,
		MonoCromaticos: [4, 5, 6, 7, 8, 9, 10, 11, 12, 198],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8377,
		CodigoHtml: "2f3036",
		Nombre: "Black Beard",
		ColorNum: "3267",
		EsPrincipal: false,
		GroupNum: 12,
		SubGroupNum: 199,
		colorR: 47,
		colorG: 48,
		colorB: 54,
		MonoCromaticos: [
			156, 157, 158, 159, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170,
			171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185,
			186, 187, 188, 189, 190, 191, 192, 199,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8378,
		CodigoHtml: "583e7d",
		Nombre: "Perfect Plum",
		ColorNum: "3302",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 200,
		colorR: 88,
		colorG: 62,
		colorB: 125,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
	{
		Id: 8379,
		CodigoHtml: "bc3963",
		Nombre: "Magenta Rose",
		ColorNum: "3316",
		EsPrincipal: false,
		GroupNum: 10,
		SubGroupNum: 200,
		colorR: 188,
		colorG: 57,
		colorB: 99,
		MonoCromaticos: [
			83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
			101, 102, 103, 104, 105, 200,
		],
		Complementarios: [],
		Triadicos: [],
		Adjacentes: [],
	},
];
