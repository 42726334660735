import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	fallbackLng: 'es',
	lng: 'es',
	resources: {
		pr: {
			translations: require('./locales/pr.json'),
		},
		es: {
			translations: require('./locales/es.json'),
		},
	},
	ns: [ 'translations' ],
	defaultNS: 'translations',
});

i18n.languages = [ 'pr', 'es' ];

export default i18n;